export default {
  tableHeader: 'Asset signals',
  showHidden: 'Show hidden asset signals',
  overFlowMenu: {
    tooltip: 'Additional functions',
    export: 'Export sensor settings',
    import: 'Import sensor settings',
  },
  placeholder: {
    meaningId: 'Select meaning',
    meaningInstanceNumber: 'Instance number',
  },
};

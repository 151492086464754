import {
  i18n,
} from '@i18n';
import dayjs, {
  ManipulateType, OpUnitType, QUnitType,
} from 'dayjs';
import {
  Duration,
} from 'dayjs/plugin/duration';
import {
  z,
} from 'zod';
import {
  formatDayjsDate,
} from '@/utils/date-formats.ts';

export const I18N_PREFIX = 'components/date-range-picker/date-range-picker';

/* The earliest data we have data fo.r */
export const MIN_YEAR = 1990;
/* Reasonable assumption of how far ahead our customers may want to plan. */
export const MAX_YEARS_INTO_FUTURE = 10;
export const MIN_ABS_DATE = dayjs().startOf('year').year(MIN_YEAR).toDate();
export const MAX_ABS_DATE = dayjs().endOf('year').add(MAX_YEARS_INTO_FUTURE, 'year').toDate();

export enum ECustomRangeType {
  // sorting is important. It determines the order of tabs in the date-range-picker component
  ABSOLUTE = 'absolute',
  RELATIVE = 'relative',
  CUSTOM = 'custom',
}
export const CustomRangeTypeIcons: Record<ECustomRangeType, string> = {
  [ECustomRangeType.ABSOLUTE]: 'pi pi-arrows-h',
  [ECustomRangeType.CUSTOM]: 'pi pi-arrow-right-arrow-left',
  [ECustomRangeType.RELATIVE]: 'pi pi-arrow-left',
};

export interface IDateInterval {
  from: Date,
  to: Date,
}

/*
 * A custom range always has a dedicated from and to date.
 */
export interface IRangeCustom extends IDateInterval {
  rangeType: ECustomRangeType.CUSTOM,
}
export const RangeCustomZod: z.ZodType<IRangeCustom> = z.object({
  rangeType: z.literal(ECustomRangeType.CUSTOM),
  from: z.coerce.date(),
  to: z.coerce.date(),
});

export type TRelativeTimeUnit = Extract<(ManipulateType | 'quarters' | 'isoWeek'), 'years' | 'months' | 'weeks' | 'days' | 'minutes' | 'hours'>;
/*
 * A relative time range always goes back into the past for a given amount of time.
 */
export interface IRangeRelative {
  rangeType: ECustomRangeType.RELATIVE,
  timeUnit: TRelativeTimeUnit,
  value: number,
}
export const RangeRelativeZod = z.object({
  rangeType: z.literal(ECustomRangeType.RELATIVE),
  timeUnit: z.enum([
    'years',
    'months',
    'weeks',
    'days',
    'minutes',
    'hours',
  ]),
  value: z.number(),
});

export type TAbsoluteTimeUnit = 'isoWeek' | 'months' | 'quarters' | 'years';
export interface TRangeAbsolute {
  rangeType: ECustomRangeType.ABSOLUTE,
  timeUnit: TAbsoluteTimeUnit,
  year: number
  value: number
}
export const RangeAbsoluteZod = z.object({
  rangeType: z.literal(ECustomRangeType.ABSOLUTE),
  timeUnit: z.enum([
    'isoWeek',
    'months',
    'quarters',
    'years',
  ]),
  year: z.number(),
  value: z.number(),
});

export type TRange = IRangeCustom | IRangeRelative | TRangeAbsolute
export const RangeZod = z.union([
  RangeCustomZod,
  RangeRelativeZod,
  RangeAbsoluteZod,
]);

export type TRangeLimits = {
  /* The absolute minimal Date that the user shall be able to select */
  minDate?: Date,
  /* The absolute maximal Date that the user shall be able to select */
  maxDate?: Date,
  /* Any range that exceeds the maxDuration will be hidden from the user */
  maxDuration?: Duration,
}

/* Some shared properties across many components and subcomponents */
export type TDatePickerProps = {
  modelValue: TRange | undefined,
  disabled?: boolean,
} & TRangeLimits;

export const DATE_PICKER_PROPS_DEFAULTS: Record<keyof Omit<TDatePickerProps, 'modelValue'>, any> = {
  disabled: false,
  minDate: MIN_ABS_DATE,
  maxDate: MAX_ABS_DATE,
  maxDuration: undefined,
};

/*
 * Converts any range definition into in actual date interval.
 */
export function rangeToDateInterval(definition: TRange): IDateInterval {
  switch (definition.rangeType) {
    case ECustomRangeType.CUSTOM:
      return {
        from: new Date(definition.from.getTime()),
        to: new Date(definition.to.getTime()),
      };

    case ECustomRangeType.RELATIVE:
      return {
        from: dayjs().subtract(definition.value, definition.timeUnit as ManipulateType).toDate(),
        to: new Date(),
      };

    case ECustomRangeType.ABSOLUTE:
      // eslint-disable-next-line no-case-declarations
      let pointer = dayjs().year(definition.year);
      switch (definition.timeUnit) {
        case 'years':
          break;
        case 'months':
          pointer = pointer.month(definition.value);
          break;
        case 'quarters':
          pointer = pointer.quarter(definition.value);
          break;
        case 'isoWeek':
          pointer = pointer.isoWeek(definition.value);
          break;
        default:
          throw new Error(
            // @ts-ignore: default may never be reached. But we want to keep it
            // in case we forget to add a case when we expand the possible cases
            `Unsupported timeUnit '${definition.timeUnit}' for absolute rangeType
              '${ECustomRangeType.ABSOLUTE}'.`,
          );
      }
      return {
        from: pointer.startOf(definition.timeUnit as (QUnitType | OpUnitType)).toDate(),
        to: pointer.endOf(definition.timeUnit as (QUnitType | OpUnitType)).toDate(),
      };

    default:
      // @ts-ignore: default may never be reached. But we want to keep it
      // in case we forget to add a case when we expand the possible cases
      throw new Error(`Unsupported rangeType '${definition.rangeType}'.`);
  }
}

/*
 * Converts a range to the duration in seconds
 */
export function rangeToDurationMs(range: TRange): number {
  const interval = rangeToDateInterval(range);
  return interval.to.getTime() - interval.from.getTime();
}

/*
 * Creates a human friendly display name for the range
 */
export function rangeToLabel(range: TRange, short?: boolean): string {
  const dateInterval = rangeToDateInterval(range);
  const dayJsInterval = {
    from: dayjs(dateInterval.from),
    to: dayjs(dateInterval.to),
  };

  switch (range.rangeType) {
    case ECustomRangeType.CUSTOM:
      return `${formatDayjsDate(dayJsInterval.from)} - ${formatDayjsDate(dayJsInterval.to)}`;
    case ECustomRangeType.RELATIVE:
      return [
        range.value,
        short
          ? i18n.global.t(`${I18N_PREFIX}.timeUnitsShort.${range.timeUnit}`)
          : i18n.global.tc(`${I18N_PREFIX}.timeUnits.${range.timeUnit}`, range.value),
      ].join(short ? '' : ' ');
    case ECustomRangeType.ABSOLUTE:
      switch (range.timeUnit) {
        case 'years':
          return range.year.toString();
        case 'months':
          return `${dayJsInterval.from.format('MMM')} ${dayJsInterval.from.year()}`;
        case 'quarters':
          return `Q${dayJsInterval.from.quarter()} ${dayJsInterval.from.year()}`;
        case 'isoWeek':
          switch (range.value - dayjs().isoWeek()) {
            case -1:
              return `${i18n.global.t(`${I18N_PREFIX}.previousWeek`)} (${i18n.global.t(`${I18N_PREFIX}.calendarWeek`)}${dayJsInterval.from.isoWeek()})`;
            case 0:
              return `${i18n.global.t(`${I18N_PREFIX}.currentWeek`)} (${i18n.global.t(`${I18N_PREFIX}.calendarWeek`)}${dayJsInterval.from.isoWeek()})`;
            case 1:
              return `${i18n.global.t(`${I18N_PREFIX}.nextWeek`)} (${i18n.global.t(`${I18N_PREFIX}.calendarWeek`)}${dayJsInterval.from.isoWeek()})`;
            default:
              // eslint-disable-next-line no-case-declarations
              let label = `${i18n.global.t(`${I18N_PREFIX}.calendarWeek`)}${dayJsInterval.from.isoWeek()}`;
              // eslint-disable-next-line no-case-declarations
              const currentYear = dayjs().year();
              // Both checks are required due to potential timezone differences.
              if (dayJsInterval.from.year() !== currentYear || dayJsInterval.to.year() !== currentYear) {
                label = `${dayJsInterval.from.year()} ${label}`;
              }
              return label;
          }
        default:
          throw new Error(
            // @ts-ignore: default may never be reached. But we want to keep it
            // in case we forget to add a case when we expand the possible cases
            `Unsupported timeUnit '${range.timeUnit}' for absolute rangeType
              '${ECustomRangeType.ABSOLUTE}'.`,
          );
      }
    default:
      // @ts-ignore: default may never be reached. But we want to keep it
      // in case we forget to add a case when we expand the possible cases
      throw new Error(`Unsupported rangeType '${range.rangeType}'.`);
  }
}

// enum for reusing same IDs on different pages
export enum ERangeId {
  CURRENT_WEEK = 'currentWeek',
  MINUTE_30 = '30min',
  HOUR_1 = '1h',
  HOUR_2 = '2h',
  HOUR_4 = '4h',
  HOUR_12 = '12h',
  HOUR_24 = '24h',
  DAY_7 = '7d',
  WEEK_1 = '1w',
  MONTH_1 = '1m',
  MONTH_3 = '3m',
  MONTH_6 = '6m',
  YEAR_1 = '1y',
  YEAR_2 = '2y',
  YEAR_5 = '5y',
}

export function rangeIdToRange(rangeId: ERangeId): TRange {
  switch (rangeId) {
    case ERangeId.CURRENT_WEEK:
      return {
        rangeType: ECustomRangeType.ABSOLUTE,
        timeUnit: 'isoWeek',
        year: dayjs().year(),
        value: dayjs().isoWeek(),
      };
    case ERangeId.MINUTE_30:
      return {
        rangeType: ECustomRangeType.RELATIVE,
        timeUnit: 'minutes',
        value: 30,
      };
    case ERangeId.HOUR_1:
      return {
        rangeType: ECustomRangeType.RELATIVE,
        timeUnit: 'hours',
        value: 1,
      };
    case ERangeId.HOUR_2:
      return {
        rangeType: ECustomRangeType.RELATIVE,
        timeUnit: 'hours',
        value: 2,
      };
    case ERangeId.HOUR_4:
      return {
        rangeType: ECustomRangeType.RELATIVE,
        timeUnit: 'hours',
        value: 4,
      };
    case ERangeId.HOUR_12:
      return {
        rangeType: ECustomRangeType.RELATIVE,
        timeUnit: 'hours',
        value: 12,
      };
    case ERangeId.HOUR_24:
      return {
        rangeType: ECustomRangeType.RELATIVE,
        timeUnit: 'hours',
        value: 24,
      };
    case ERangeId.DAY_7:
      return {
        rangeType: ECustomRangeType.RELATIVE,
        timeUnit: 'days',
        value: 7,
      };
    case ERangeId.WEEK_1:
      return {
        rangeType: ECustomRangeType.RELATIVE,
        timeUnit: 'weeks',
        value: 1,
      };
    case ERangeId.MONTH_1:
      return {
        rangeType: ECustomRangeType.RELATIVE,
        timeUnit: 'months',
        value: 1,
      };
    case ERangeId.MONTH_3:
      return {
        rangeType: ECustomRangeType.RELATIVE,
        timeUnit: 'months',
        value: 3,
      };
    case ERangeId.MONTH_6:
      return {
        rangeType: ECustomRangeType.RELATIVE,
        timeUnit: 'months',
        value: 6,
      };
    case ERangeId.YEAR_1:
      return {
        rangeType: ECustomRangeType.RELATIVE,
        timeUnit: 'years',
        value: 1,
      };
    case ERangeId.YEAR_2:
      return {
        rangeType: ECustomRangeType.RELATIVE,
        timeUnit: 'years',
        value: 2,
      };
    case ERangeId.YEAR_5:
      return {
        rangeType: ECustomRangeType.RELATIVE,
        timeUnit: 'years',
        value: 5,
      };
    default:
      throw new Error(`Unsupported ECustomRangeType value '${rangeId}'.`);
  }
}

export const DEFAULT_RANGES_LIST: ERangeId[] = [
  ERangeId.CURRENT_WEEK,
  ERangeId.DAY_7,
  ERangeId.MONTH_1,
  ERangeId.MONTH_3,
  ERangeId.YEAR_1,
];
export const DEFAULT_RANGE: TRange = rangeIdToRange(DEFAULT_RANGES_LIST[0]);

export type TChoice = TRange | ERangeId;

/* Return True if the given range is smaller than the maxDuration.
 * If the maxDuration is undefined the range is always considered valid unless
 * it's undefined too.
 */
export function isRangeValid(range: TRange | undefined, limits?: TRangeLimits): boolean {
  if (range === undefined) {
    return false;
  }
  if (limits === undefined) {
    return true;
  }
  if (limits.maxDuration !== undefined && rangeToDurationMs(range) > limits.maxDuration.asMilliseconds()) {
    return false;
  }

  const interval = rangeToDateInterval(range);
  if (limits.minDate !== undefined && interval.from < limits.minDate) {
    return false;
  }
  return !(limits.maxDate !== undefined && interval.to > limits.maxDate);
}

export function choicesToRanges(choices: TChoice[], limits?: TRangeLimits): TRange[] {
  const ranges = choices.map((choice) => (typeof choice === 'string' ? rangeIdToRange(choice) : choice));
  if (limits === undefined) {
    return ranges;
  }
  return ranges.filter((range) => isRangeValid(range, limits));
}

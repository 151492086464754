import {
  TComponentValue,
} from '@/types/openapi-type.ts';

const occurrenceDurationStatistics: Partial<Record<keyof TComponentValue<'AlarmStatistics'>['occurrenceDurationStatistics'], string>> = {
  count: 'Anzahl',
  sum: 'Gesamtdauer',
  average: 'Durschnittliche Dauer',
  median: 'Median Dauer',
};

export default {
  showAlarmWithoutOccurrences: 'Show all alarms',
  occurrenceDurationStatistics,
};

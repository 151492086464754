<template>
  <div>
    <component
      :is="loading ? PrmIconField : 'div'"
      v-bind="fieldProps"
    >
      <PrmInputText
        v-bind="inputProps"
        :model-value="modelValue"
        :disabled="loading || disabled || inputProps?.disabled"
        :readonly="loading || readonly || inputProps?.readonly"
        :invalid="invalid || inputProps?.invalid"
        :placeholder="placeholder"
        style="width: 100%"
        @update:model-value="emit('update:modelValue', $event as string)"
        @change="emit('change', modelValueRef)"
      />
      <PrmInputIcon
        v-if="loading"
        class="pi pi-spin pi-spinner"
        v-bind="iconProps"
      />
    </component>
    <span
      v-if="!!errorMessage"
      class="input-wrapper-error"
    >
      {{ errorMessage }}
    </span>
  </div>
</template>

<script lang="ts" setup>
import {
  shallowRef,
  watch,
} from 'vue';
import PrmInputText from 'primevue/inputtext';
import PrmInputIcon from 'primevue/inputicon';
import PrmIconField from 'primevue/iconfield';

const props = defineProps<{
  modelValue?: string | null,
  loading?: boolean,
  // is for convenience
  disabled?: boolean,
  // is for convenience
  invalid?: boolean,
  // is for convenience
  readonly?: boolean,
  placeholder?: string,
  errorMessage?: string,
  inputProps?: InstanceType<typeof PrmInputText>['$props'],
  fieldProps?: InstanceType<typeof PrmIconField>['$props'],
  iconProps?: InstanceType<typeof PrmInputIcon>['$props'],
}>();

const modelValueRef = shallowRef<string | null | undefined>(props.modelValue);
watch(() => props.modelValue, () => {
  modelValueRef.value = props.modelValue;
});

// eslint-disable-next-line func-call-spacing, no-spaced-func
const emit = defineEmits<{
  // fires on every key stroke
  (e: 'update:modelValue', value: string): void,
  // Fires when the value was changed. To understand when a value is considered,
  // changed, see:
  // https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/change_event#text_input_element
  (e: 'change', value: string | null | undefined): void,
}>();
</script>

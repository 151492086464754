import {
  components,
} from '@/types/openapi.ts';

export enum EMaintenanceState {
  PENDING = 0,
  ACCEPTED = 10,
  IN_PROGRESS = 20,
  DONE = 30,
  VALIDATED = 40,
}

export const MAINTENANCE_STATE_ICON: Record<EMaintenanceState, string> = {
  [EMaintenanceState.PENDING]: 'pi pi-circle',
  [EMaintenanceState.ACCEPTED]: 'pi pi-ellipsis-h',
  [EMaintenanceState.IN_PROGRESS]: 'pi pi-chart-pie',
  [EMaintenanceState.DONE]: 'pi pi-check',
  [EMaintenanceState.VALIDATED]: 'pi pi-check-circle',
};

export enum EMaintenanceServiceTab {
  ATTACHMENTS = 'attachments',
  DETAILS = 'details',
  REPORT = 'report',
}

export enum EMaintenancePriority {
  LOW = 0,
  NORMAL = 5,
  HIGH = 10,
}

export const MaintenancePriorityMap: Record<components['schemas']['MaintenancePriority'], keyof typeof EMaintenancePriority> = {
  0: 'LOW',
  5: 'NORMAL',
  10: 'HIGH',
};

export enum EMaintenanceTaskState {
  INCOMPLETE = 0,
  OK = 10,
  FAILED = 20,
  NOT_APPLICABLE = 30,
}

export enum ECalendarPeriod {
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
}

export enum ECalendarEventType {
  PLANNED_UNIT_DOWNTIME = 'PLANNED_UNIT_DOWNTIME',
  ACTUAL_UNIT_DOWNTIME = 'ACTUAL_UNIT_DOWNTIME',
}

// This little trick ensures that all options of the ECalendarEventType enum have valid values.
export const calendarEventTypeMap: Record<components['schemas']['OperationEventType'], keyof typeof ECalendarEventType> = {
  PLANNED_UNIT_DOWNTIME: 'PLANNED_UNIT_DOWNTIME',
  ACTUAL_UNIT_DOWNTIME: 'ACTUAL_UNIT_DOWNTIME',
};

export enum EMaintenanceRecurrencePeriod {
  NEVER = 'never',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  YEARLY = 'yearly',
}

export enum EMaintenanceRecurrenceMonth {
  JANUARY = 'jan',
  FEBRUARY = 'feb',
  MARCH = 'mar',
  APRIL = 'apr',
  MAY = 'may',
  JUNE = 'jun',
  JULY = 'jul',
  AUGUST = 'aug',
  SEPTEMBER = 'sep',
  OCTOBER = 'oct',
  NOVEMBER = 'nov',
  DECEMBER = 'dec',
}

export enum EMaintenanceRecurrenceWeekday {
  MONDAY = 'mon',
  TUESDAY = 'tue',
  WEDNESDAY = 'wed',
  THURSDAY = 'thu',
  FRIDAY = 'fri',
  SATURDAY = 'sat',
  SUNDAY = 'sun',
}

export enum EMaintenanceRecurrenceMonthDayValue {
  FIRST = 'first',
  SECOND = 'second',
  THIRD = 'third',
  FOURTH = 'fourth',
  LAST = 'last',
}

export enum EMaintenanceRecurrenceMonthDayDimension {
  WEEKDAY = 'WEEKDAY',
  WEEKEND_DAY = 'WEEKEND_DAY',
  DAY = 'DAY',
}

export enum EMaintenanceRecurrenceEndCondition {
  AFTER = 'after',
  ON_THIS_DAY = 'onThisDay',
}

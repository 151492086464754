<template>
  <label class="prime-checkbox">
    <i
      v-if="loading"
      class="pi pi-spin pi-spinner"
      style="
        font-size: 1.25rem;
        color: var(--color-197);
      "
    />
    <PrmCheckbox
      v-else
      :model-value="modelValue"
      binary
      :disabled="disabled"
      :readonly="readonly"
      @update:model-value="emit('update:modelValue', $event)"
    >
      <template #icon>
        <span
          :class="icon"
          :style="{
            color: color,
          }"
        />
      </template>
    </PrmCheckbox>
    <span v-if="label">
      {{ label }}
    </span>
  </label>
</template>

<script setup lang="ts">
import PrmCheckbox from 'primevue/checkbox';
import {
  computed,
} from 'vue';

const props = withDefaults(defineProps<{
  // this checkbox can have three states.
  // The “null” state is used, when checkbox is neither "true" or "false" and is in intermediate state.
  modelValue?: boolean | null,
  // eslint-disable-next-line vue/require-default-prop
  label?: string,
  disabled?: boolean,
  readonly?: boolean,
  loading?: boolean,
}>(), {
  modelValue: false,
});

// eslint-disable-next-line func-call-spacing, no-spaced-func
const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void
}>();

const icon = computed(() => {
  if (props.modelValue === null) {
    return 'pi pi-minus';
  }
  if (props.modelValue) {
    return 'pi pi-check';
  }
  return '';
});

const color = computed(() => {
  if (props.modelValue === null) {
    return 'var(--color-224)';
  }
  return 'var(--color-100)';
});
</script>

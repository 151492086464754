export default {
  operationEventHeader: {
    assetDisplayName: 'Anlage',
    calendarEventType: 'Ereignis',
    startAtUtc: 'Startdatum',
    endAtUtc: 'Erledigungsfrist',
  },
  maintenanceEventHeader: {
    assetDisplayName: 'Anlage',
    startAtUtc: 'Startdatum',
    endAtUtc: 'Erledigungsfrist',
    assigneeDisplayName: 'Beauftragter',
    state: 'Status',
  },
  hideCompletedEventList: 'Abgeschlossene Ereignisse ausblenden',
};

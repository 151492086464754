import {
  i18n,
} from '@i18n';
import {
  FunctionalComponent, SVGAttributes,
} from 'vue';
import {
  RouteMap,
} from 'vue-router';
import {
  TApp, TAppMap,
} from '@/types/app-type.ts';
import {
  components,
} from '@/types/openapi.ts';
import AlarmStatIcon from '@/assets/icon/alarm-stat.svg?component';
import AnomalyDetectionIcon from '@/assets/icon/anomaly-detection.svg?component';
import AssetManagementIcon from '@/assets/icon/asset-management.svg?component';
import CancelIcon from '@/assets/icon/cancel.svg?component';
import CertificationIcon from '@/assets/icon/certification.svg?component';
import GrafanaIcon from '@/assets/icon/grafana.svg?component';
import HistoryAndTrendsIcon from '@/assets/icon/history-and-trends.svg?component';
import MaintenanceCalendarIcon from '@/assets/icon/maintenance-calendar.svg?component';
import OperationCalendarIcon from '@/assets/icon/operation-calendar.svg?component';
import MaterialIcon from '@/assets/icon/material.svg?component';
import ModelBuilderIcon from '@/assets/icon/model-builder.svg?component';
import PerformanceDashboardIcon
  from '@/assets/icon/performance-dashboard.svg?component';
import ProductionLogsIcon from '@/assets/icon/production-logs.svg?component';
import ProductionStatisticsIcon
  from '@/assets/icon/production-statistics.svg?component';
import RecipeOnDemandIcon from '@/assets/icon/recipe-on-demand.svg?component';
import RecipeBuilderIcon from '@/assets/icon/recipe-builder.svg?component';
import SimulatorPackageIcon from '@/assets/icon/simulator-package.svg?component';
import PartIcon from '@/assets/icon/part.svg?component';
import CustomerAppIcon from '@/assets/icon/customer-app.svg?component';
import LiveAppIcon from '@/assets/icon/live-app.svg?component';
import RemoteControlIcon from '@/assets/icon/remote-control.svg?component';
import Pt9800ConfigurationIcon from '@/assets/icon/pt9800-configuration.svg?component';
import UserManagementIcon from '@/assets/icon/user-management.svg?component';
import ShopLayoutIcon from '@/assets/icon/shop-layout.svg?component';
import UpcToolboxIcon from '@/assets/icon/upc-toolbox.svg?component';
import TenantSettingsIcon from '@/assets/icon/tenant-settings.svg?component';
import {
  ERouteName,
} from '@/router/route-name.ts';
import {
  TGetAllAppsWithPermissionsResponse,
} from '@/generated/axios/openapi/core.ts';

export const appListMap: Record<components['schemas']['AppId'], {
   icon: FunctionalComponent<SVGAttributes, {}, any>,
   route: keyof RouteMap,
  }> = {
    1: {
      icon: ProductionLogsIcon,
      route: ERouteName.PRODUCTION_LOGS,
    },
    2: {
      icon: CancelIcon,
      route: ERouteName.APP_OVERVIEW,
    },
    3: {
      icon: AssetManagementIcon,
      route: ERouteName.ASSET_MANAGEMENT_OVERVIEW,
    },
    4: {
      icon: UpcToolboxIcon,
      route: ERouteName.UPC_PRO,
    },
    // 5: {
    //   icon: CancelIcon,
    //   route: ERouteName.APP_OVERVIEW,
    // },
    6: {
      icon: UserManagementIcon,
      route: ERouteName.USER_MANAGEMENT,
    },
    // 7: {
    //   icon: UserSettings,
    //   route: ERouteName.SETTINGS,
    // },
    8: {
      icon: Pt9800ConfigurationIcon,
      route: ERouteName.PT9800_CONFIGURATION,
    },
    9: {
      icon: SimulatorPackageIcon,
      route: ERouteName.SIMULATOR,
    },
    // 10: {
    //   icon: RecipesNitrideIcon,
    //   route: ERouteName.RECIPE_BUILDER_NPM,
    // },
    11: {
      icon: RemoteControlIcon,
      route: ERouteName.REMOTE_CONTROL,
    },
    // 12: {
    //   icon: CancelIcon,
    //   route: ERouteName.APP_OVERVIEW,
    // },
    13: {
      icon: LiveAppIcon,
      route: ERouteName.LIVE_APP,
    },
    14: {
      icon: RecipeBuilderIcon,
      route: ERouteName.RECIPE_BUILDER,
    },
    // 15: {
    //   icon: RecipesPtIcon,
    //   route: ERouteName.RECIPE_BUILDER_PT9800,
    // },
    16: {
      icon: ShopLayoutIcon,
      route: ERouteName.SHOP_LAYOUT,
    },
    17: {
      icon: RecipeOnDemandIcon,
      route: ERouteName.RECIPE_ON_DEMAND,
    },
    18: {
      icon: MaterialIcon,
      route: ERouteName.MATERIAL_LIST,
    },
    19: {
      icon: PerformanceDashboardIcon,
      route: ERouteName.PERFORMANCE_DASHBOARD,
    },
    20: {
      icon: AnomalyDetectionIcon,
      route: ERouteName.ANOMALY_DETECTION,
    },
    21: {
      icon: CancelIcon,
      route: ERouteName.APP_OVERVIEW,
    },
    22: {
      icon: ProductionStatisticsIcon,
      route: ERouteName.PRODUCTION_STATISTICS,
    },
    23: {
      icon: HistoryAndTrendsIcon,
      route: ERouteName.HISTORY_AND_TRENDS,
    },
    24: {
      icon: AlarmStatIcon,
      route: ERouteName.ALARM_STATISTICS,
    },
    // 25: {
    //   icon: MlPrototypingIcon,
    //   route: ERouteName.ML_PROTOTYPING,
    // },
    26: {
      icon: GrafanaIcon,
      route: ERouteName.GRAFANA,
    },
    27: {
      icon: CertificationIcon,
      route: ERouteName.CERTIFICATION,
    },
    28: {
      icon: MaintenanceCalendarIcon,
      route: ERouteName.MAINTENANCE_CALENDAR,
    },
    29: {
      icon: PartIcon,
      route: ERouteName.PART,
    },
    30: {
      icon: CustomerAppIcon,
      route: ERouteName.CUSTOMER,
    },
    31: {
      icon: OperationCalendarIcon,
      route: ERouteName.OPERATION_CALENDAR,
    },
    32: {
      icon: TenantSettingsIcon,
      route: ERouteName.SETTINGS,
    },
    33: {
      icon: ModelBuilderIcon,
      route: ERouteName.MODEL_BUILDER,
    },
  };

const SEMANTIC_GROUP_ORDER: Record<keyof TAppMap, number> = {
  Operation: 0,
  Process: 1,
  Data: 2,
  Maintenance: 3,
  Configuration: 4,
};

export function getAppMap(appList: TGetAllAppsWithPermissionsResponse, displayNameFilter?: string): TAppMap {
  const map: TAppMap = {} as TAppMap;
  appList?.forEach((item) => {
    if (!map[item.appGroupSemantic]) {
      map[item.appGroupSemantic] = [];
    }
    const app: TApp = {
      ...item,
      displayName: i18n.global.t(`api.AppId.${item.appId}`),
    };
    if (displayNameFilter) {
      if (!app.displayName.toLowerCase().includes(displayNameFilter.toLowerCase())
          && !app.appGroupSemantic.toLowerCase().includes(displayNameFilter.toLowerCase())
      ) {
        return;
      }
      map[item.appGroupSemantic].push(app);
    } else {
      map[item.appGroupSemantic].push(app);
    }
  });
  Object.values(map).forEach((_group) => _group.sort((a, b) => a.displayName!.localeCompare(b.displayName!)));
  const sortedGroups = Object.entries(map).sort((a, b) => SEMANTIC_GROUP_ORDER[a[0] as keyof TAppMap] - SEMANTIC_GROUP_ORDER[b[0] as keyof TAppMap]);
  const sortedMap = sortedGroups.reduce((obj, [
    key,
    value,
  ]) => ({
    ...obj,
    [key]: value,
  }), {});

  return sortedMap as TAppMap;
}

import {
  RecipeService,
} from '@/services/RecipeOnDemandService.js';

export default {
  state: {
    materials: [],
    loadingMaterials: false,
    recipes: [],
    loadingRecipes: false,
    recipe: {},
    loadingRecipe: false,
    process: {},
    profile: {},
  },

  mutations: {
    setMaterials(state, list) {
      state.materials = list;
    },
    clearMaterials(state) {
      state.materials = [];
    },
    isLoadingMaterials(state, loading) {
      state.loadingMaterials = loading;
    },
    setRecipes(state, list) {
      state.recipes = list.data;
    },
    clearRecipes(state) {
      state.recipes = [];
    },
    isLoadingRecipes(state, loading) {
      state.loadingRecipes = loading;
    },
    setRecipe(state, recipe) {
      state.recipe = recipe.data;
    },
    clearRecipe(state) {
      state.recipe = [];
    },
    isLoadingRecipe(state, loading) {
      state.loadingRecipe = loading;
    },
    setProcess(state, process) {
      state.process = process.data;
    },
    setProfile(state, profile) {
      state.profile = profile.data;
    },
  },

  actions: {
    async SET_MATERIALS(context, filter = '') {
      context.commit('clearMaterials');
      context.commit('isLoadingMaterials', true);
      try {
        const response = await RecipeService.getRecipeMaterials(filter);
        context.commit('setMaterials', response);
      } catch (e) {
        context.commit('clearMaterials');
        return e.response.status;
      } finally {
        context.commit('isLoadingMaterials', false);
      }
    },
    CLEAR_MATERIALS(context) {
      context.commit('clearMaterials');
    },
    async SET_RECIPES(context, filter) {
      context.commit('clearRecipes');
      context.commit('isLoadingRecipes', true);
      try {
        const response = await RecipeService.getRecipes(filter);
        context.commit('setRecipes', response);
      } catch (e) {
        context.commit('clearRecipes');
        return e.response.status;
      } finally {
        context.commit('isLoadingRecipes', false);
      }
    },
    async SET_RECIPE_FROM_ID(context, id) {
      context.commit('clearRecipe');
      context.commit('isLoadingRecipe', true);
      try {
        const response = await RecipeService.getRecipeFromId(id);
        context.commit('setRecipe', response);
      } catch (e) {
        context.commit('clearRecipe');
        return e.response.status;
      } finally {
        context.commit('isLoadingRecipe', false);
      }
    },
    async SET_PROCESS(context, id) {
      try {
        const response = await RecipeService.getProcess(id);
        context.commit('setProcess', response);
      } catch (e) {
        return e.response.status;
      }
    },
    async SET_PROFILE(context, id) {
      try {
        const response = await RecipeService.getProfile(id);
        context.commit('setProfile', response);
      } catch (e) {
        return e.response.status;
      }
    },
  },
};

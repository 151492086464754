/**
 * better to ask Felix if you're not sure if something is general term or not
 * do not mistake with "simple" translations like "submit" or "cancel"
 * never use for composition
 *
 * generalTerm translations are something, which are specific to project "domain" or "project"
 * "common" translations are everything, which can be reused across different projects
 *
 * TODO: extract "common" above this object in order to reuse some translations
 */
export const generalTerm = {
  // common
  loadId: 'Ladungs id',
  // do not mistake with "Load ID"
  // "Load ID" might or might not be indexing field for database
  // "Load Identifier" is a customer-written "comment" or "description" which they later use for internal usage
  loadIdentifier: 'Ladungsbezeichner',
  date: 'Datum',
  name: 'Name',
  description: 'Beschreibung',
  event: 'Ereignis | Ereignisse',
  mappedPT9800user: 'Der gemappte PT9800 Benutzer',
  // equipment || asset
  asset: 'Asset',
  assetId: 'Asset id',
  assetType: 'Asset Typ',
  customer: 'Kunde',
  composition: 'Zusammensetzung',
  element: 'Element | Elemente',
  root: 'Wurzel | Wurzeln',
  location: 'Standort | Standorte',
  locationId: 'Standort id',
  // job || load
  job: 'Job',
  jobDisplayName: 'Load No',
  jobStartedAtUtc: 'Startzeitpunkt',
  jobFinishedAtUtc: 'Endzeitpunkt',
  // sensor || meaning || signal
  sensor: 'Sensor',
  signal: 'Signal | Signale',
  unit: 'Einheit | Einheiten',
  recipe: 'Rezept | Rezepte',
  scadaType: 'Scada type',
  materialSystemList: 'Materials Systeme',
  measure: 'Messwert | Messwerte',
  setpoint: 'Sollwert | Sollwerte',
  reportId: 'Berichtsnummer',
  report: 'Bericht',
  reporter: 'Berichterstatter',
  jobId: 'Auftrags id',
  partId: 'Teile id',
  part: 'Teil',
  step: 'Schritt',
  recipeStep: 'Rezeptschritt',
  material: 'Material',
  drawingReference: 'Zeichnungsreferenz',
  displayName: 'Name',
  preset: 'Voreinstellung',
  materialCalculationModel: 'Materialberechnungsmodell',
  data: 'Daten',
  chart: 'Diagramm | Diagramme',
  austenite: 'Austenit',
  limitMinus: 'Limit -',
  limitPlus: 'Limit +',
  envelope: 'Grenze',
  email: 'E-Mail',
  sms: 'SMS',
  upload: 'Hochladen',
  orientation: 'Ausrichtung',
  height: 'Höhe',
  width: 'Breite',
  title: 'Titel',
  send: 'Send',
  fullName: 'Vollständiger Name',
  production: 'Produktion',
  color: 'Color',

  // TODO: migrate to "common"
  createdAt: 'Erstellt am',
  showDeleted: 'Gelöschte anzeigen',
  overwrite: 'Überschreiben',
  requirement: 'Anforderung',
  result: 'Ergebnis',

  schaefflerDiagram: 'Schäffler Diagramm',
  CCTDiagram: 'CCT diagramm',
  temperingCurveDiagram: 'Tempering curve',
  jominyDiagram: 'Jominy curve',

  carbonPotential: 'C-Pegel Status',
  nitrogenPotential: 'Stickstoffpotential',
  surfaceCarbon: 'Oberflächenkohlenstoff',
  surfaceNitrogen: 'Oberflächenstickstoff',
  surfaceCarbide: 'Oberflächenkarbide',

  recipeStage: 'Rezeptschritt',
  component: 'Komponente | Komponenten',
  automat: 'Automat | Automaten',
  equipment: 'Anlage | Anlagen',
  equipmentGroup: 'Anlagengruppe | Anlagengruppen',
  sparePart: 'Ersatzteil | Ersatzteile',
  manufacturer: 'Hersteller',
  partNumber: 'Teilenummer',
  chamber: 'Kammer | Kammern',
  company: 'Unternehmen | Unternehmen',
  heatTreatmentCapability: 'Wärmebehandlungsfähigkeit | Wärmebehandlungsfähigkeiten',
  preference: 'Präferenz | Präferenzen',
  heatTreatmentProcess: 'Wärmebehandlungsprozess | Wärmebehandlungsprozessen',
};

// used in tables or other places where you need to filter by general term
export const generalTermFilter = {
  asset: 'Suche Asset',
};

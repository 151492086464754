<template>
  <router-link
    v-slot="{ href, navigate }"
    :to="actualRouteTo"
    custom
  >
    <a
      v-ripple
      :href="href"
      class="rounded-corners"
      @click="navigate"
    >
      <button
        v-tooltip="{
          value: i18n.global.t(`api.AppId.${appId}`),
        }"
        type="button"
        class="router-link-app"
      >
        <div
          class="router-link-app__background rounded-corners"
          :style="{
            'background-color': appColor,
            'border-color': appColor,
          }"
        />
        <component
          :is="appListMap[appId].icon"
          class="router-link-app__icon"
        />
        <span class="router-link-app__label">&nbsp;</span>
      </button>
    </a>
  </router-link>
</template>

<script setup lang="ts">
import {
  computed,
} from 'vue';
import {
  i18n,
} from '@i18n';
import {
  RouteLocationRaw,
} from 'vue-router';
import {
  appTileColorMap,
} from '@/utils/app-tile.ts';
import {
  appListMap,
} from '@/types/app-util.ts';
import {
  EAppId,
} from '@/types/app-type.ts';
import {
  useAppStore,
} from '@/store/pinia/app.ts';
import {
  TGetAllAppsWithPermissionsResponse,
} from '@/generated/axios/openapi/core.ts';

const appStore = useAppStore();
appStore.loadAppList();

const props = defineProps<{
  appId: EAppId,
  /**
   * Optional overwrite of the route target. if not provided, the button will navigate
   * to the defaul page of the app.
   */
  routeTo?: RouteLocationRaw,
}>();

const appDetails = computed<TGetAllAppsWithPermissionsResponse[number] | undefined>(() => (appStore.appList?.find((app) => (app.appId
  // no idea how to solve this TS error:
  // Expression produces a union type that is too complex to represent.ts-plugin(2590)
  // @ts-ignore
  === props.appId
))));
const appColor = computed<string | undefined>(() => (appDetails.value ? appTileColorMap[appDetails.value.appGroupSemantic] : undefined));
const actualRouteTo = computed<RouteLocationRaw>(() => props.routeTo || {
  name: appListMap[props.appId].route as any,
});
</script>

<style scoped lang="scss">
.rounded-corners {
    border-radius: 6px;
}

.router-link-app {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--color-199);
  padding: .5rem;
  width: 1.75rem;
  height: 1.75rem;

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid;
    opacity: 0.0;
  }

  &:hover > .router-link-app__background {
    opacity: 0.075;
  }

  &__icon {
    min-width: 16px;
    min-height: 16px;
    width: 16px;
    height: 16px;
  }

  &__label {
    visibility: hidden;
    width: 0;
    flex: 0 0 auto;
  }
}
</style>

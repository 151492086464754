export default {
  operationEventHeader: {
    assetDisplayName: 'Asset',
    calendarEventType: 'Event',
    startAtUtc: 'Start date',
    endAtUtc: 'Due date',
  },
  maintenanceEventHeader: {
    assetDisplayName: 'Asset',
    startAtUtc: 'Start date',
    endAtUtc: 'Due date',
    assigneeDisplayName: 'Assignee',
    state: 'Status',
  },
  hideCompletedEventList: 'Hide completed events',
};

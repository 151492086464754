/* eslint-disable camelcase */
import {
  generalTerm,
} from './en.shared.ts';
import npmTranslation from '@/generated/locales/en.npm.ts';
import {
  TComponentNpmKeys,
  TComponentNpmValue,
} from '@/types/openapi-type.ts';

const RBBEProcessMode_String: Record<TComponentNpmValue<'RBBEProcessMode_String'>, string> = {
  STANDBY: 'Standby',
  PROCESS: 'Process',
  MAINTENANCE: 'Maintenance',
  NO_PROCESS: 'No process',
  EMERGENCY: 'Emergency',
  ABORT: 'Abort',
  PREHEAT: 'Preheat',
  PURGE: 'Purge',
  TRANSFER_IN: 'Transfer in',
  TRANSFER_OUT: 'Transfer out',
  FINISHED: 'Finished',
  ERROR: 'Error',
};

const LoadJobDto: Record<keyof TComponentNpmValue<'LoadJobDto'>, string> = {
  recipe_code: generalTerm.recipe,
  customer_code: 'Customer code',
  customer_name: 'Customer name',
  operator_name: 'Operator',
  shop_order: 'Shop order',
  part_numbers: 'Part numbers (comma separated)',
  part_count: 'Count',
  job_start_time: 'Start time',
  comment: 'Comment',
};

const RecipeListEntryDTO: Record<keyof TComponentNpmValue<'RecipeListEntryDTO'>, string> = {
  recipe_code: generalTerm.recipe,
  recipe_description: generalTerm.description,
  protected: 'Protected',
};

const RecipeDTO: Partial<TComponentNpmKeys<'RecipeDTO', Record<string, string> | string>> = {
  description: generalTerm.description,
  duration_min: npmTranslation.resourceId[1028],
};

const StageDTO: Partial<TComponentNpmKeys<'StageDTO', Record<string, string> | string>> = {
  stage_no: '#',
  name: generalTerm.displayName,
  EndCond: npmTranslation.resourceId[1035],
};

export default {
  LoadJobDto,
  RBBEProcessMode_String,
  RecipeListEntryDTO,
  RecipeDTO,
  StageDTO,
};

// sorting is important, DO NOT CHANGE ORDER WITHOUT CONSULTING!
// buttons will be ordered in this order from left to right
export const semanticButtonVariant = [
  // dangerous stuff
  'delete',
  // abort actions
  'reset',
  'cancel',
  'close',
  'back',
  'forward',

  // mutation actions
  'edit',
  'saveAsNew',
  'save', // update
  'accept',
  'create',

  // standalone actions
  'add',
  'settings',
  'tableNavigation',
] as const;
export type TSemanticButtonVariant = typeof semanticButtonVariant[number];

export const SEMANTIC_BUTTON_ICON_MAP: Record<TSemanticButtonVariant, string> = {
  accept: 'pi pi-check',
  add: 'pi pi-plus',
  back: 'pi pi-arrow-left',
  cancel: 'pi pi-times',
  close: 'pi pi-times',
  create: 'pi pi-plus',
  delete: 'pi pi-trash',
  edit: 'pi pi-pencil',
  forward: 'pi pi-arrow-right',
  reset: 'pi pi-undo',
  save: 'pi pi-save',
  saveAsNew: 'pi pi-copy',
  settings: 'pi pi-cog',
  tableNavigation: 'pi pi-arrow-right',
};

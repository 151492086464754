import {
  TComponentValue,
} from '@/types/openapi-type.ts';

const occurrenceDurationStatistics: Partial<Record<keyof TComponentValue<'AlarmStatistics'>['occurrenceDurationStatistics'], string>> = {
  count: 'Count',
  sum: 'Total duration',
  average: 'Average duration',
  median: 'Median duration',
};

export default {
  showAlarmWithoutOccurrences: 'Show all alarms',
  occurrenceDurationStatistics,
};

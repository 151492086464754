import {
  EConnectionStatus,
  EScaleOption,
} from './rendering-engine-view.ts';

const connectionStatus: Record<EConnectionStatus, string> = {
  [EConnectionStatus.CONNECTING]: 'Connecting',
  [EConnectionStatus.CONNECTED]: 'Connected',
  [EConnectionStatus.WAITING_FOR_DATA]: 'Waiting for data',
  [EConnectionStatus.ERROR]: 'Error',
};

const scaleOptions: Record<EScaleOption, string> = {
  [EScaleOption.FIT_SCREEN]: 'Fit screen',
  [EScaleOption.FULL_SIZE]: '100%',
};

export default {
  connectionStatus,
  latestUpdate: 'Last update',
  scaleLabel: 'Scaling',
  scaleOptions,
  viewLabel: 'Screen',
};

import {
  EMeasurementUnitType,
} from './measurement';
import {
  components,
} from './openapi';

export enum ECarburizingSimulationPartShape {
  ROUND_STEEL = 0,
  HEXAGON_OR_OCTAGON_STEEL = 1,
  SQUARE_OR_FLAT_STEEL = 2,
  SOLID_STEEL = 3,
  RING = 4,
  CYL_HOLLOW_BODY_LESS_80 = 5,
  CYL_HOLLOW_BODY_LESS_200 = 6,
  CYL_HOLLOW_BODY_GREATER_200 = 7,
  HOLLOW_BODY_CLOSED = 8,
}

export const carburizingSimulationPartShapeMap: Record<components['schemas']['CarburizingSimulationPartShape'], keyof typeof ECarburizingSimulationPartShape> = {
  0: 'ROUND_STEEL',
  1: 'HEXAGON_OR_OCTAGON_STEEL',
  2: 'SQUARE_OR_FLAT_STEEL',
  3: 'SOLID_STEEL',
  4: 'RING',
  5: 'CYL_HOLLOW_BODY_LESS_80',
  6: 'CYL_HOLLOW_BODY_LESS_200',
  7: 'CYL_HOLLOW_BODY_GREATER_200',
  8: 'HOLLOW_BODY_CLOSED',
};

export enum ECarburizingSimulatorRecipeType {
  CARBURIZING = 0,
  CARBONITRIDING = 1,
  LOW_PRESSURE_CARBURIZING = 2,
}

export const carburizingSimulatorRecipeTypeMap: Record<components['schemas']['CarburizingSimulatorRecipeType'], keyof typeof ECarburizingSimulatorRecipeType> = {
  0: 'CARBURIZING',
  1: 'CARBONITRIDING',
  2: 'LOW_PRESSURE_CARBURIZING',
};

export enum ECarburizingTemperatureControlType {
  HEAT = 0,
  COOL = 1,
  HEAT_UP = 2,
  HEAT_DOWN = 3,
  HOLD = 4,
}

export const carburizingTemperatureControlTypeToUnitMap: Record<components['schemas']['CarburizingTemperatureControlType'], EMeasurementUnitType> = {
  0: EMeasurementUnitType.DEGREES_CELSIUS_PER_MINUTE,
  1: EMeasurementUnitType.DEGREES_CELSIUS_PER_MINUTE,
  2: EMeasurementUnitType.DEGREES_CELSIUS,
  3: EMeasurementUnitType.DEGREES_CELSIUS,
  4: EMeasurementUnitType.DEGREES_CELSIUS,
};

export enum ECarburizingRecipeSimulationValuesType {
  AVG_VAL = 0,
  COMP_VAL = 1,
  USER_VAL = 2,
}

export const carburizingRecipeSimulationValuesTypeMap: Record<components['schemas']['CarburizingRecipeSimulationValuesType'], keyof typeof ECarburizingRecipeSimulationValuesType> = {
  0: 'AVG_VAL',
  1: 'COMP_VAL',
  2: 'USER_VAL',
};

export const carburizingTemperatureControlTypeMap: Record<components['schemas']['CarburizingTemperatureControlType'], keyof typeof ECarburizingTemperatureControlType> = {
  0: 'HEAT',
  1: 'COOL',
  2: 'HEAT_UP',
  3: 'HEAT_DOWN',
  4: 'HOLD',
};

export enum ECarburizingVolumeControlType {
  OFF = 0,
  ON = 1,
  AUTO = 2,
}

export const carburizingVolumeControlTypeToUnitMap: Record<components['schemas']['CarburizingVolumeControlType'], EMeasurementUnitType | undefined> = {
  0: undefined,
  1: EMeasurementUnitType.CUBIC_METRE_PER_HOUR,
  2: EMeasurementUnitType.CUBIC_METRE_PER_HOUR,
};

export const carburizingVolumeControlTypeMap: Record<components['schemas']['CarburizingVolumeControlType'], keyof typeof ECarburizingVolumeControlType> = {
  0: 'OFF',
  1: 'ON',
  2: 'AUTO',
};

export enum ECarburizingCarbonControlType {
  OFF = 0,
  ON = 1,
  SOOT_LIMIT = 2,
  AUTO_100_X = 3,
  SURFACE_CARBON = 4,
  PLUS_C = 5,
  MINUS_C = 6,
}

export const carburizingCarbonControlTypeToUnitMap: Record<components['schemas']['CarburizingCarbonControlType'], EMeasurementUnitType> = {
  0: EMeasurementUnitType.PERCENT,
  1: EMeasurementUnitType.PERCENT,
  2: EMeasurementUnitType.PERCENT,
  3: EMeasurementUnitType.PERCENT,
  4: EMeasurementUnitType.PERCENT,
  5: EMeasurementUnitType.PERCENT,
  6: EMeasurementUnitType.PERCENT,
};

export const carburizingCarbonControlTypeMap: Record<components['schemas']['CarburizingCarbonControlType'], keyof typeof ECarburizingCarbonControlType> = {
  0: 'OFF',
  1: 'ON',
  2: 'SOOT_LIMIT',
  3: 'AUTO_100_X',
  4: 'SURFACE_CARBON',
  5: 'PLUS_C',
  6: 'MINUS_C',
};

export enum ECarburizingEndControlType {
  END_TEMPERATURE = 0,
  CARBON_DEPTH = 1,
  RUNTIME = 2,
  SURFACE_CARBON = 3,
  CP_PERCENT = 4,
}

export const carburizingEndControlTypeToUnitMap: Record<components['schemas']['CarburizingEndControlType'], EMeasurementUnitType | undefined> = {
  0: EMeasurementUnitType.DEGREES_CELSIUS,
  1: EMeasurementUnitType.PERCENT,
  2: undefined,
  3: EMeasurementUnitType.PERCENT,
  4: EMeasurementUnitType.PERCENT,
};

export const carburizingEndControlTypeMap: Record<components['schemas']['CarburizingEndControlType'], keyof typeof ECarburizingEndControlType> = {
  0: 'END_TEMPERATURE',
  1: 'CARBON_DEPTH',
  2: 'RUNTIME',
  3: 'SURFACE_CARBON',
  4: 'CP_PERCENT',
};

export enum ECarburizingNitrogenControlType {
  OFF = 0,
  ON = 1,
  NITRIDE_100_X = 2,
  SURF_N = 3,
}

export const carburizingNitrogenControlTypeToUnitMap: Record<components['schemas']['CarburizingNitrogenControlType'], EMeasurementUnitType | undefined> = {
  0: undefined,
  1: EMeasurementUnitType.PERCENT,
  2: EMeasurementUnitType.PERCENT,
  3: EMeasurementUnitType.PERCENT,
};

export const carburizingNitrogenControlTypeMap: Record<components['schemas']['CarburizingNitrogenControlType'], keyof typeof ECarburizingNitrogenControlType> = {
  0: 'OFF',
  1: 'ON',
  2: 'NITRIDE_100_X',
  3: 'SURF_N',
};

export enum EActivityCoefficients {
  ALLOYING_ELEMENT_ID = 'alloyingElementId',
  E_JC_FERRITE = 'eJcFerrite',
  R_JJC_FERRITE = 'rJjcFerrite',
  E_JN_FERRITE = 'eJnFerrite',
  R_JJN_FERRITE = 'rJjnFerrite',
  E_JC_AUSTENITE = 'eJcAustenite',
  R_JJC_AUSTENITE = 'rJjcAustenite',
  E_JN_AUSTENITE = 'eJnAustenite',
  R_JJN_AUSTENITE = 'rJjnAustenite',
  DESCRIPTION = 'description',
}

export enum ESimulationStep {
  RUNTIME_SECONDS = 'runtimeSeconds',
  STAGE_INDEX = 'stageIndex',
  STAGE_RUNTIME_SECONDS = 'stageRuntimeSeconds',
  TEMPERATURE = 'temperature',
  CARBON_POTENTIAL = 'carbonPotential',
  NITROGEN_POTENTIAL = 'nitrogenPotential',
  CARBURIZING_DEPTH = 'carburizingDepth',
  CARBURIZING_DEPTH_SET_POINT = 'carburizingDepthSetPoint',
  CARBURIZING_DEPTH_CARBON_CONTENT = 'carburizingDepthCarbonContent',
  SURFACE_CARBON = 'surfaceCarbon',
  SURFACE_NITROGEN = 'surfaceNitrogen',
  SURFACE_CARBIDE = 'surfaceCarbide',
  SURFACE_HARDNESS_SETPOINT = 'surfaceHardnessSetpoint',
  EFFECTIVE_CASE_HARDNESS = 'effectiveCaseHardness',
  CARBIDE_LIMIT = 'carbideLimit',
  SOOT_LIMIT = 'sootLimit',
  CASE_DEPTH = 'caseDepth',
  CARBON_PROFILE = 'carbonProfile',
  CARBON_PROFILE_MIN = 'carbonProfileMin',
  CARBON_PROFILE_MAX = 'carbonProfileMax',
  NITROGEN_PROFILE = 'nitrogenProfile',
  NITROGEN_PROFILE_MIN = 'nitrogenProfileMin',
  NITROGEN_PROFILE_MAX = 'nitrogenProfileMax',
  CARBIDE_PROFILE = 'carbideProfile',
  CARBIDE_PROFILE_MIN = 'carbideProfileMin',
  CARBIDE_PROFILE_MAX = 'carbideProfileMax',
  HARDNESS_PROFILE = 'hardnessProfile',
  HARDNESS_PROFILE_MIN = 'hardnessProfileMin',
  HARDNESS_PROFILE_MAX = 'hardnessProfileMax',
  N_SOLUBILITY_PROFILE = 'nSolubilityProfile',
  NITRIDE_PROFILE = 'nitrideProfile',
  NITRIDE_PROFILE_MIN = 'nitrideProfileMin',
  NITRIDE_PROFILE_MAX = 'nitrideProfileMax',
  PROFILE_X_VALUES = 'profileXValues',
}

export const simulationStepMap: Record<keyof components['schemas']['SimulationStep'], ESimulationStep> = {
  runtimeSeconds: ESimulationStep.RUNTIME_SECONDS,
  stageIndex: ESimulationStep.STAGE_INDEX,
  stageRuntimeSeconds: ESimulationStep.STAGE_RUNTIME_SECONDS,
  temperature: ESimulationStep.TEMPERATURE,
  carbonPotential: ESimulationStep.CARBON_POTENTIAL,
  nitrogenPotential: ESimulationStep.NITROGEN_POTENTIAL,
  carburizingDepth: ESimulationStep.CARBURIZING_DEPTH,
  carburizingDepthSetPoint: ESimulationStep.CARBURIZING_DEPTH_SET_POINT,
  carburizingDepthCarbonContent: ESimulationStep.CARBURIZING_DEPTH_CARBON_CONTENT,
  surfaceCarbon: ESimulationStep.SURFACE_CARBON,
  surfaceNitrogen: ESimulationStep.SURFACE_NITROGEN,
  surfaceCarbide: ESimulationStep.SURFACE_CARBIDE,
  surfaceHardnessSetpoint: ESimulationStep.SURFACE_HARDNESS_SETPOINT,
  effectiveCaseHardness: ESimulationStep.EFFECTIVE_CASE_HARDNESS,
  carbideLimit: ESimulationStep.CARBIDE_LIMIT,
  sootLimit: ESimulationStep.SOOT_LIMIT,
  caseDepth: ESimulationStep.CASE_DEPTH,
  carbonProfile: ESimulationStep.CARBON_PROFILE,
  carbonProfileMin: ESimulationStep.CARBON_PROFILE_MIN,
  carbonProfileMax: ESimulationStep.CARBON_PROFILE_MAX,
  nitrogenProfile: ESimulationStep.NITROGEN_PROFILE,
  nitrogenProfileMin: ESimulationStep.NITROGEN_PROFILE_MIN,
  nitrogenProfileMax: ESimulationStep.NITROGEN_PROFILE_MAX,
  carbideProfile: ESimulationStep.CARBIDE_PROFILE,
  carbideProfileMin: ESimulationStep.CARBIDE_PROFILE_MIN,
  carbideProfileMax: ESimulationStep.CARBIDE_PROFILE_MAX,
  hardnessProfile: ESimulationStep.HARDNESS_PROFILE,
  hardnessProfileMin: ESimulationStep.HARDNESS_PROFILE_MIN,
  hardnessProfileMax: ESimulationStep.HARDNESS_PROFILE_MAX,
  nSolubilityProfile: ESimulationStep.N_SOLUBILITY_PROFILE,
  nitrideProfile: ESimulationStep.NITRIDE_PROFILE,
  nitrideProfileMin: ESimulationStep.NITRIDE_PROFILE_MIN,
  nitrideProfileMax: ESimulationStep.NITRIDE_PROFILE_MAX,
  profileXValues: ESimulationStep.PROFILE_X_VALUES,
};

export const carburizingRecipeSimulationValuesTypeToMaterialPropertySpectrum: Record<ECarburizingRecipeSimulationValuesType, (keyof components['schemas']['MaterialPropertySpectrum']) | undefined> = {
  [ECarburizingRecipeSimulationValuesType.AVG_VAL]: 'avg',
  [ECarburizingRecipeSimulationValuesType.COMP_VAL]: 'comp',
  [ECarburizingRecipeSimulationValuesType.USER_VAL]: undefined,
};

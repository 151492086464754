import {
  normalizeUrl,
} from '@/utils/url.js';

// The following object will be taken the config values from the public/config.js file
// when the app is running in the container. If running via Vite, the config values will
// be taken from the environment variables.
export default {
  VITE_APP_API_ERRORS_LOG_ENABLED: (window.config.VITE_APP_API_ERRORS_LOG_ENABLED || import.meta.env.VITE_APP_API_ERRORS_LOG_ENABLED).toLowerCase() === 'true',
  VITE_APP_API_PT9800_URL: normalizeUrl(window.config.VITE_APP_API_PT9800_URL || import.meta.env.VITE_APP_API_PT9800_URL),
  VITE_WS_APP_API_URL: normalizeUrl(window.config.VITE_WS_APP_API_URL || import.meta.env.VITE_WS_APP_API_URL),
  VITE_APP_API_URL: normalizeUrl(window.config.VITE_APP_API_URL || import.meta.env.VITE_APP_API_URL),
  VITE_APP_AUTH_API: normalizeUrl(window.config.VITE_APP_AUTH_API || import.meta.env.VITE_APP_AUTH_API),
  VITE_APP_FEATURE_FLAGS: window.config.VITE_APP_FEATURE_FLAGS || (import.meta.env.VITE_APP_FEATURE_FLAGS && JSON.parse(import.meta.env.VITE_APP_FEATURE_FLAGS)) || [],
  VITE_APP_GRAFANA_URL: normalizeUrl(window.config.VITE_APP_GRAFANA_URL || import.meta.env.VITE_APP_GRAFANA_URL),
  VITE_APP_ML_PROTOTYPING_URL: normalizeUrl(window.config.VITE_APP_ML_PROTOTYPING_URL || import.meta.env.VITE_APP_ML_PROTOTYPING_URL || 'http://ml-prototyping-not-a-feature'),
  VITE_APP_PT_CLOUD_URL: normalizeUrl(window.config.VITE_APP_PT_CLOUD_URL || import.meta.env.VITE_APP_PT_CLOUD_URL),
  VITE_APP_TENANT_NAME: window.config.VITE_APP_TENANT_NAME || import.meta.env.VITE_APP_TENANT_NAME || '',
  VITE_APP_USER_MANUAL_URL: normalizeUrl(window.config.VITE_APP_USER_MANUAL_URL || import.meta.env.VITE_APP_USER_MANUAL_URL),
  VITE_SENTRY_DSN: window.config.VITE_SENTRY_DSN || import.meta.env.VITE_SENTRY_DSN,
  VITE_SENTRY_ENVIRONMENT: window.config.VITE_SENTRY_ENVIRONMENT || import.meta.env.VITE_SENTRY_ENVIRONMENT || 'development',
};

import axios from 'axios';
import {
  stringify,
} from 'qs';
import {
  handleRequestRefresh,
} from '@/utils/axios/auth-middleware';
import {
  createAPIClient,
} from '@/pt9800/api/api-client.ts';
import {
  TokenService,
} from './TokenService.js';
import config from '@/config/config.js';

const authenticationHeaderPrefix = 'Bearer ';

export const API_URL = config.VITE_APP_API_URL;
export const API_PT9800_URL = config.VITE_APP_API_PT9800_URL;

const portalApi = axios.create({
  withCredentials: true,
  baseURL: `${API_URL}/api/v1`,
  paramsSerializer: (params) => stringify(params, {
    arrayFormat: 'repeat',
  }),
});

const portalV2Api = axios.create({
  withCredentials: true,
  baseURL: `${API_URL}/api/v2`,
  paramsSerializer: (params) => stringify(params, {
    arrayFormat: 'repeat',
  }),
});

const portalApiClient = axios.create({
  withCredentials: true,
  baseURL: API_URL,
  paramsSerializer: (params) => stringify(params, {
    arrayFormat: 'repeat',
  }),
});

const pt9800APIClient = createAPIClient({
  serverURL: API_PT9800_URL,
});

// 3. Add interceptor to your axios instance
portalApi.interceptors.request.use(handleRequestRefresh);
portalV2Api.interceptors.request.use(handleRequestRefresh);
portalApiClient.interceptors.request.use(handleRequestRefresh);
pt9800APIClient.axios.interceptors.request.use(handleRequestRefresh);

export const configureRequestConfigForSuppressError = (_: any, status: any) => {
  return {
    suppressClientErrorStatus: status,
  };
};

if (config.VITE_APP_API_ERRORS_LOG_ENABLED) {
  [
    portalApi,
    portalV2Api,
    portalApiClient,
  ].forEach((api) => {
    api.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        return Promise.reject(error);
      },
    );
  });
}

// If you're trying to use qmulusApi, be sure to build the sdk into qmulus service and use it there instead
export {
  portalApi, portalV2Api, portalApiClient, pt9800APIClient,
};

export const AxiosService = {

  /**
   * Sets the id token in the authorization headers in axios
   */
  setHeader() {
    const authHeader = authenticationHeaderPrefix + TokenService.getAccessToken();
    axios.defaults.headers.common.Authorization = authHeader;
    // @ts-ignore - not sure if it works, but TS says: Property 'Authorization' does not exist on type 'HeadersDefaults'.ts(2339)
    portalApi.defaults.headers.Authorization = authHeader;
    // @ts-ignore
    portalV2Api.defaults.headers.Authorization = authHeader;
    // @ts-ignore
    portalApiClient.defaults.headers.Authorization = authHeader;
  },

  logout() {
    delete axios.defaults.headers.common.Authorization;
    delete portalApi.defaults.headers.common.Authorization;
    delete portalV2Api.defaults.headers.common.Authorization;
    delete portalApiClient.defaults.headers.common.Authorization;
    TokenService.clearTokens();
  },

  /**
   * Removes all headers from axios
   */
  removeHeader() {
    axios.defaults.headers.common = {};
  },
};

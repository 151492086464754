import {
  TSourceType,
} from './modal-import-sensor-settings.ts';

const sourceType: Record<TSourceType, string> = {
  asset: 'Other asset',
  file: 'File import',
};

export default {
  copySensorSettings: 'Import selected sensor settings',
  sourceAssetId: 'Source asset',
  targetAssetId: 'Target asset',
  selectDataFormat: {
    placeholder: 'Select data format',
    selected: 'Data format: {dataFormat}',
  },
  selectSource: {
    placeholder: 'Choose source',
    selected: 'Source: {assetDisplayName}',
    sentence: 'Import sensor settings from {source} to {target}.',
    selectSourceType: 'Source type',
  },
  sourceType,
  verifyMapping: {
    headline: 'Verify properties',
    selectAttributes: 'Properties to import',
  },
  migrateProgress: {
    headline: 'Import sensor settings',
  },
  errorTable: 'Errors encountered during migration',
  unknownFileValidationError: 'Uploaded file could not be validated. Data is not as expected.',
  warning: {
    text: 'Changes made during the migration are not reversible. It is recommended to download a backup of the sensor settings beforehand. This backup can later be imported to restore the current state.',
    downloadBackup: 'Download backup',
  },
  next: 'Next',
  prev: 'Previous',
};

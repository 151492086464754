import {
  TComponentPT9800Keys, TComputedPT9800PlannedPartInput, TComponentPT9800Value
} from '@/types/openapi-type.ts';
import {
  generalTerm,
} from './en.shared.ts';

const processFlowManualCommand: TComponentPT9800Keys<'processFlowManualCommand'> = {
  acknowledged: 'Acknowledged',
  additionalInfoColumnCaption: 'Additional Info Caption',
  additionalInfoColumnValue: 'Additional Info Value',
  idFieldName: 'ID Field Name',
  idFieldValue: 'ID Field Value',
  ldArchivCrit1Name: 'Archiv-Crit1 Caption',
  ldArchivCrit1Value: 'Archiv-Crit1 Value',
  orderNumberCaption: 'Order Number Caption',
  orderNumberValue: 'Order Number Value',
  priority: 'Priority',
  rowId: 'Row ID',
  source: 'Source',
  sourceWpId: 'Source WP ID',
  target: 'Target',
  targetWpId: 'Target WP ID',
  wpId: 'Car WP ID',
};

const recipeListItem: TComponentPT9800Keys<'recipeListItem'> = {
  id: 'id',
  description: generalTerm.description,
  recipe: generalTerm.recipe,
  recipePrefix: 'Recipe prefix',
  version: 'Recipe version',
};

const recipeItemHeader: Partial<TComponentPT9800Keys<'recipeItemHeader'>> = {
  recipeName: 'Name',
  recipePrefix: 'Group',
  description: 'Description',
  processType: 'Process',
  processId: 'Operation',
  comment: 'Comment',
  diff: 'Activate',
  correction: 'Correction',
};

const material: Partial<TComponentPT9800Keys<'material'>> = {
  carbonContent: 'Carbon content',
  carbideLimit: 'Carbide limit',
  alloyFactor: 'Alloy factor',
};

const recipeItemPosition: Partial<TComponentPT9800Keys<'recipeItemPosition'>> = {};

const recipeItemPositionHeader: Partial<TComponentPT9800Keys<'recipeItemPositionHeader'>> = {
  positionNo: 'Step',
  description: 'Step name',
  chamberId: 'Chamber',
  chamberOperationId: 'Chamber operation',
  afterId: 'End condition',
  estimatedDurationInMin: 'Estimated duration',
};

const plannedPartInput: Partial<Record<keyof TComputedPT9800PlannedPartInput, string>> = {
  pos: 'Position',
  partNumber: 'Part number',
  partDescription: 'Part description',
  orderNo: generalTerm.loadIdentifier,
  orderRemark: 'Order remark',
  customerOrderNumber: 'Customer order No',
  amount: 'Amount',
  weight: 'Weight',
  totalWeight: 'Total weight',
};

const processFlow: Partial<TComponentPT9800Keys<'processFlow'>> = {
  name: generalTerm.name,
  description: generalTerm.description,
  updateTimestamp: 'Last update',
  automatNo: 'Automat No',
  comment1: 'Comment1',
  comment2: 'Comment2',
  comment3: 'Comment3',
};

const processFlowListItem: Partial<TComponentPT9800Keys<'processFlowListItem'>> = {
  name: generalTerm.name,
  description: generalTerm.description,
};

const planLoadInput: Partial<TComponentPT9800Keys<'planLoadInput'>> = {
  workplaceWoNumber: generalTerm.loadIdentifier,
};

const setPointItem: Partial<TComponentPT9800Keys<'setPointItem'>> & {
  unit: string,
} = {
  userComponentId: 'Setpoint',
  controlId: 'Ctrl',
  value: 'Value',
  unit: 'Unit',
  toleranceMax: 'Tol +',
  toleranceMin: 'Tol -',
  alarmStartTimeInMin: 'Time 1',
  alarmToleranceTimeInMin: 'Time 2',
  endControlId: 'Ctrl',
  endValue: 'End condition',
};

const furnaceAlarm: Partial<TComponentPT9800Keys<'furnaceAlarm'>> = {
  wpName: 'Workplace',
  ackTime: 'Ack',
  alarmBit: 'Bit',
  alarmByte: 'Byte',
  endTime: 'End',
  gate: 'Gate',
  alarmNo: 'Id',
  message: 'Message',
  priority: 'Priority',
  startTime: 'Start',
};

const load: Partial<TComponentPT9800Keys<'load'>> = {
  name: generalTerm.jobDisplayName,
  startTime: generalTerm.jobStartedAtUtc,
  endTime: generalTerm.jobFinishedAtUtc,
  identifier: generalTerm.loadIdentifier,
  recipe: generalTerm.recipe,
  partsText: plannedPartInput.partDescription,
  workOrderNo: plannedPartInput.orderNo,
  orderRemark: plannedPartInput.orderRemark,
  status: 'State',
};

const loadState: Record<TComponentPT9800Value<'load'>['status'], string> = {
  EXT_START: 'Started (external)',
  FINISHED: 'Finished',
  PLANNED: 'Planned',
  RUNNING: 'Running',
  RUNNING_LOADING_ENDED: 'Running (loading ended)',
  STARTED: 'Started',
  STOPPED: 'Stopped',
  TOO_OLD_NEVER_STARTED: 'Never started',
  UNKNOWN: 'Unknown',
  WAITING_FOR_CONDITIONS: 'Waiting for conditions',
  WAITING_FOR_LOADING: 'Waiting for loading',
};

export default {
  furnaceAlarm,
  load,
  loadState,
  material,
  planLoadInput,
  plannedPartInput,
  processFlow,
  processFlowListItem,
  processFlowManualCommand,
  recipeItemHeader,
  recipeItemPosition,
  recipeItemPositionHeader,
  recipeListItem,
  setPointItem,
};

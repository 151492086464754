export default {
  filter: {
    timeBucket: 'Granularity',
  },
  stackedPolarChart: {
    title: 'Productivity (Polar)',
  },
  clusteredStackedBarChart: {
    title: 'Productivity (Clustered Stacked bar)',
    hint: 'Due to the time filter, this timeframe is only represented partially. Hover over chart for more details.',
  },
  table: {
    title: 'Productivity (Table)',
  },
  stackedBarChart: {
    title: 'Productivity (Stacked bar)',
  },
  paretoChart: {
    title: 'Productivity (Pareto)',
  },
  visualisationModal: {
    title: 'Display settings',
    subtitle: 'Select the charts you want to display on the dashboard from the lists below.',
    aggregationTitle: 'Aggregation',
    aggregationDescription: 'These charts show the efficiency for the complete selected time range.',
  },
};

import {
  TSteelGradeGroup,
} from './select-alloying-elements';

const steelGradeGroup: Record<TSteelGradeGroup, string> = {
  caseHardeningSteel: 'Einsatzstahl',
  nitridingSteel: 'Nitrierstahl',
  temperingSteel: 'Vergütungsstahl',
  bearingSteel: 'Wälzlagerstahl',
};

export default {
  placeholder: 'Stahlgruppenauswahl',
  steelGradeGroup,
};

<template>
  <PrmDialog
    v-model:visible="visible"
    :draggable="false"
    modal
    :header="i18n.global.t(`${I18N_PREFIX}.uploadPicture`)"
    style="
      width: 850px;
      max-width: 100%;
    "
    @hide="emit('close')"
  >
    <PrmPanel>
      <template #header>
        <SemanticButton
          variant="add"
          @click="fileInput?.click()"
        />
        <input
          ref="fileInput"
          type="file"
          accept="image/*"
          style="display: none;"
          @change="selectImage"
        >
      </template>
      <div
        v-if="isFileTooBig"
        class="typo-h5"
        style="color: var(--color-219)"
      >
        {{ i18n.global.t('validation.fileTooBig', {size: bitsToMB(maxSize)}) }}
      </div>
      <form
        v-else-if="pictureDataMap.imageUrl"
        :id="FORM_ID"
        @submit.prevent="submit"
      >
        <div class="input-group">
          {{ i18n.global.t('common.fileName') }}
          <InputText
            v-model="pictureDataMap.displayName"
            :invalid="!!formattedErrorMap.displayName"
          />
        </div>
        <div
          class="input-group"
          style="
            display: flex;
            flex-direction: row;
            align-items: center;
          "
        >
          <img
            :src="pictureDataMap.imageUrl"
            alt=""
            style="
              max-width: 586px;
              max-height: 300px;
              object-fit: contain;
            "
          >
          <SemanticButton
            variant="delete"
            icon-only
            @click="clearForm"
          />
        </div>
        <div
          v-if="showDescriptionInput"
          class="input-group"
        >
          {{ i18n.global.t('common.description') }}
          <InputText v-model="pictureDataMap.description" />
        </div>
      </form>
    </PrmPanel>
    <template #footer>
      <div
        style="
          display: flex;
          flex-direction: row;
          gap: var(--gap-union);
        "
      >
        <SemanticButton
          variant="cancel"
          @click="emit('close')"
        />
        <SemanticButton
          type="submit"
          variant="save"
          :form="FORM_ID"
          :label="i18n.global.t('generalTerm.upload')"
        />
      </div>
    </template>
  </PrmDialog>
</template>

<script setup lang="ts">
import {
  shallowRef,
  ref,
} from 'vue';
import {
  i18n,
} from '@i18n';
import PrmDialog from 'primevue/dialog';
import PrmPanel from 'primevue/panel';
import {
  useToast,
} from 'primevue/usetoast';
import {
  z,
  ZodFormattedError,
} from 'zod';
import InputText from '@/components/input-text/input-text.vue';
import SemanticButton from '@/components/button/semantic-button.vue';
import {
  MAXIMUM_FILE_SIZE,
} from '@/constants/file-upload.ts';
import {
  TPictureData,
} from '@/components/upload-picture/modal-upload.ts';

const toast = useToast();

const I18N_PREFIX = 'components/upload-picture/modal-upload';

const props = withDefaults(defineProps<{
  pictureData: TPictureData,
  showDescriptionInput?: boolean,
  // bits
  maxSize?: number,
}>(), {
  maxSize: MAXIMUM_FILE_SIZE,
});

// eslint-disable-next-line func-call-spacing, no-spaced-func
const emit = defineEmits<{
  (e: 'submit', value: TPictureData): void,
  (e: 'close'): void,
}>();

function bitsToMB(bits: number) {
  return bits / 1024 / 1024;
}

const visible = shallowRef(true);

const FORM_ID = Math.random().toString();

const fileInput = shallowRef<HTMLElement>();

const pictureDataMap = ref<TPictureData>(props.pictureData);

const validationSchema = z.object({
  displayName: z.string().min(1),
});

type TFormattedError = ZodFormattedError<z.infer<typeof validationSchema>>;

const formattedErrorMap = shallowRef<TFormattedError>({} as TFormattedError);

function submit() {
  formattedErrorMap.value = {} as TFormattedError;
  const result = validationSchema.safeParse(pictureDataMap.value);
  if (!result.success) {
    formattedErrorMap.value = result.error.format();
    toast.add({
      severity: 'error',
      detail: result.error.errors.map((_error) => `${_error.path[0]}: ${_error.message}`).join('\n'),
    });
    return;
  }

  emit('submit', pictureDataMap.value);
}

function clearForm() {
  (fileInput.value as HTMLInputElement).files = null;
  (fileInput.value as HTMLInputElement).value = '';
  pictureDataMap.value.displayName = '';
  pictureDataMap.value.imageUrl = null;
  pictureDataMap.value.description = '';
}

const isFileTooBig = shallowRef(false);
function selectImage(event: Event) {
  isFileTooBig.value = false;
  const file = ((event.target as HTMLInputElement).files as FileList)[0];

  if (file.size > props.maxSize) {
    isFileTooBig.value = true;
    clearForm();
    return;
  }

  pictureDataMap.value.displayName = file.name;
  pictureDataMap.value.file = file;
  pictureDataMap.value.imageUrl = URL.createObjectURL(file);
}
</script>

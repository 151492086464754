import {
  components,
} from './openapi';

export enum EPhysicalPropertyMeaningMap {
  LOAD_MASS = 22000,
}

export const physicalPropertyMeaningMap: Record<components['schemas']['PhysicalPropertyMeaning'], keyof typeof EPhysicalPropertyMeaningMap> = {
  22000: 'LOAD_MASS',
};

export enum EConsumptionMeaningMap {
  DEPRECATED_AMMONIA_CONSUMPTION = 20000,
  DEPRECATED_AMMONIA_COST = 20001,
  DEPRECATED_CARBON_DIOXIDE_CONSUMPTION = 20002,
  DEPRECATED_CARBON_DIOXIDE_COST = 20003,
  DEPRECATED_CO_GAS_CONSUMPTION = 20004,
  DEPRECATED_CO_GAS_COST = 20005,
  DEPRECATED_DISSOCIATED_AMMONIA_CONSUMPTION = 20006,
  DEPRECATED_DISSOCIATED_AMMONIA_COST = 20007,
  DEPRECATED_ELECTRICITY_CONSUMPTION = 20008,
  DEPRECATED_ELECTRICITY_COST = 20009,
  DEPRECATED_ENDO_GAS_CONSUMPTION = 20010,
  DEPRECATED_ENDO_GAS_COST = 20011,
  DEPRECATED_HYDROGEN_CONSUMPTION = 20012,
  DEPRECATED_HYDROGEN_COST = 20013,
  DEPRECATED_NITROGEN_CONSUMPTION = 20014,
  DEPRECATED_NITROGEN_COST = 20015,
  ACETYLENE_C2H2_CONSUMPTION = 20016,
  AIR_CONSUMPTION = 20017,
  AMMONIA_NH3_CONSUMPTION = 20018,
  ARGON_CONSUMPTION = 20019,
  CARBON_DIOXIDE_CO2_CONSUMPTION = 20020,
  CARBON_MONOXIDE_CO_CONSUMPTION = 20021,
  DISSOCIATED_AMMONIA_NH3_CONSUMPTION = 20022,
  ENDO_GAS_NATURAL_CONSUMPTION = 20023,
  ENDO_GAS_PROPANE_CONSUMPTION = 20024,
  ETHENE_C2H4_CONSUMPTION = 20025,
  HELIUM_CONSUMPTION = 20026,
  HYDROGEN_H2_CONSUMPTION = 20027,
  METHANOL_CH3OH_CONSUMPTION = 20028,
  NATURAL_GAS_CONSUMPTION = 20029,
  NITROGEN_CONSUMPTION = 20030,
  NITROUS_OXIDE_N2O_CONSUMPTION = 20031,
  PROPANE_C3H8_CONSUMPTION = 20032,
  WATER_CONSUMPTION = 20033,
  ETHANE_C2H6_CONSUMPTION = 20034,
  METHANE_CH4_CONSUMPTION = 20035,
  ELECTRICAL_POWER_CONSUMPTION = 20100,
  USER_DEFINED_020 = 20780,
  USER_DEFINED_019 = 20781,
  USER_DEFINED_018 = 20782,
  USER_DEFINED_017 = 20783,
  USER_DEFINED_016 = 20784,
  USER_DEFINED_015 = 20785,
  USER_DEFINED_014 = 20786,
  USER_DEFINED_013 = 20787,
  USER_DEFINED_012 = 20788,
  USER_DEFINED_011 = 20789,
  USER_DEFINED_010 = 20790,
  USER_DEFINED_009 = 20791,
  USER_DEFINED_008 = 20792,
  USER_DEFINED_007 = 20793,
  USER_DEFINED_006 = 20794,
  USER_DEFINED_005 = 20795,
  USER_DEFINED_004 = 20796,
  USER_DEFINED_003 = 20797,
  USER_DEFINED_002 = 20798,
  USER_DEFINED_001 = 20799,
}

export const consumptionMeaningMap: Record<components['schemas']['ConsumptionMeaning'], keyof typeof EConsumptionMeaningMap> = {
  20000: 'DEPRECATED_AMMONIA_CONSUMPTION',
  20001: 'DEPRECATED_AMMONIA_COST',
  20002: 'DEPRECATED_CARBON_DIOXIDE_CONSUMPTION',
  20003: 'DEPRECATED_CARBON_DIOXIDE_COST',
  20004: 'DEPRECATED_CO_GAS_CONSUMPTION',
  20005: 'DEPRECATED_CO_GAS_COST',
  20006: 'DEPRECATED_DISSOCIATED_AMMONIA_CONSUMPTION',
  20007: 'DEPRECATED_DISSOCIATED_AMMONIA_COST',
  20008: 'DEPRECATED_ELECTRICITY_CONSUMPTION',
  20009: 'DEPRECATED_ELECTRICITY_COST',
  20010: 'DEPRECATED_ENDO_GAS_CONSUMPTION',
  20011: 'DEPRECATED_ENDO_GAS_COST',
  20012: 'DEPRECATED_HYDROGEN_CONSUMPTION',
  20013: 'DEPRECATED_HYDROGEN_COST',
  20014: 'DEPRECATED_NITROGEN_CONSUMPTION',
  20015: 'DEPRECATED_NITROGEN_COST',
  20016: 'ACETYLENE_C2H2_CONSUMPTION',
  20017: 'AIR_CONSUMPTION',
  20018: 'AMMONIA_NH3_CONSUMPTION',
  20019: 'ARGON_CONSUMPTION',
  20020: 'CARBON_DIOXIDE_CO2_CONSUMPTION',
  20021: 'CARBON_MONOXIDE_CO_CONSUMPTION',
  20022: 'DISSOCIATED_AMMONIA_NH3_CONSUMPTION',
  20023: 'ENDO_GAS_NATURAL_CONSUMPTION',
  20024: 'ENDO_GAS_PROPANE_CONSUMPTION',
  20025: 'ETHENE_C2H4_CONSUMPTION',
  20026: 'HELIUM_CONSUMPTION',
  20027: 'HYDROGEN_H2_CONSUMPTION',
  20028: 'METHANOL_CH3OH_CONSUMPTION',
  20029: 'NATURAL_GAS_CONSUMPTION',
  20030: 'NITROGEN_CONSUMPTION',
  20031: 'NITROUS_OXIDE_N2O_CONSUMPTION',
  20032: 'PROPANE_C3H8_CONSUMPTION',
  20033: 'WATER_CONSUMPTION',
  20034: 'ETHANE_C2H6_CONSUMPTION',
  20035: 'METHANE_CH4_CONSUMPTION',
  20100: 'ELECTRICAL_POWER_CONSUMPTION',
  20780: 'USER_DEFINED_020',
  20781: 'USER_DEFINED_019',
  20782: 'USER_DEFINED_018',
  20783: 'USER_DEFINED_017',
  20784: 'USER_DEFINED_016',
  20785: 'USER_DEFINED_015',
  20786: 'USER_DEFINED_014',
  20787: 'USER_DEFINED_013',
  20788: 'USER_DEFINED_012',
  20789: 'USER_DEFINED_011',
  20790: 'USER_DEFINED_010',
  20791: 'USER_DEFINED_009',
  20792: 'USER_DEFINED_008',
  20793: 'USER_DEFINED_007',
  20794: 'USER_DEFINED_006',
  20795: 'USER_DEFINED_005',
  20796: 'USER_DEFINED_004',
  20797: 'USER_DEFINED_003',
  20798: 'USER_DEFINED_002',
  20799: 'USER_DEFINED_001',
};

export enum EJobFeatureMeaningMap {
  ASSET_DWELLING_TIME = 20800,
  HEAT_LOSS_MIN_POWER = 21000,
  HEAT_LOSS_MAX_TEMPERATURE = 21001,
  TEMPERATURE_CONTROL_ACCURACY = 21002,
  TEMPERATURE_CONTROL_PRECISION = 21003,
  HEATING_POWER_OUTPUT_CONTROL_ACCURACY = 21004,
  HEATING_POWER_OUTPUT_CONTROL_PRECISION = 21005,
  ELECTRICAL_POWER_CONTROL_ACCURACY = 21006,
  ELECTRICAL_POWER_CONTROL_PRECISION = 21007,
  COOLING_POWER_OUTPUT_CONTROL_ACCURACY = 21008,
  COOLING_POWER_OUTPUT_CONTROL_PRECISION = 21009,
  RELATIVE_PRESSURE_CONTROL_ACCURACY = 21010,
  RELATIVE_PRESSURE_CONTROL_PRECISION = 21011,
  CHAMBER_TEMPERATURE_CONTROL_ACCURACY = 21012,
  CHAMBER_TEMPERATURE_CONTROL_PRECISION = 21013,
  ABSOLUTE_PRESSURE_CONTROL_ACCURACY = 21014,
  ABSOLUTE_PRESSURE_CONTROL_PRECISION = 21015,
  VACUUM_PRESSURE_CONTROL_ACCURACY = 21016,
  VACUUM_PRESSURE_CONTROL_PRECISION = 21017,
  ATMOSPHERIC_PRESSURE_CONTROL_ACCURACY = 21018,
  ATMOSPHERIC_PRESSURE_CONTROL_PRECISION = 21019,
  ROOM_TEMPERATURE_CONTROL_ACCURACY = 21020,
  ROOM_TEMPERATURE_CONTROL_PRECISION = 21021,
  ACTIVITY_AH_CONTROL_ACCURACY = 21022,
  ACTIVITY_AH_CONTROL_PRECISION = 21023,
  POTENTIAL_HP_CONTROL_ACCURACY = 21024,
  POTENTIAL_HP_CONTROL_PRECISION = 21025,
  POTENTIAL_KH_CONTROL_ACCURACY = 21026,
  POTENTIAL_KH_CONTROL_PRECISION = 21027,
  ACTIVITY_AO_CONTROL_ACCURACY = 21028,
  ACTIVITY_AO_CONTROL_PRECISION = 21029,
  POTENTIAL_OP_CONTROL_ACCURACY = 21030,
  POTENTIAL_OP_CONTROL_PRECISION = 21031,
  POTENTIAL_KO_CONTROL_ACCURACY = 21032,
  POTENTIAL_KO_CONTROL_PRECISION = 21033,
  ACTIVITY_AC_CONTROL_ACCURACY = 21034,
  ACTIVITY_AC_CONTROL_PRECISION = 21035,
  POTENTIAL_CP_CONTROL_ACCURACY = 21036,
  POTENTIAL_CP_CONTROL_PRECISION = 21037,
  POTENTIAL_KC_CONTROL_ACCURACY = 21038,
  POTENTIAL_KC_CONTROL_PRECISION = 21039,
  ACTIVITY_AN_CONTROL_ACCURACY = 21040,
  ACTIVITY_AN_CONTROL_PRECISION = 21041,
  POTENTIAL_NP_CONTROL_ACCURACY = 21042,
  POTENTIAL_NP_CONTROL_PRECISION = 21043,
  POTENTIAL_KN_CONTROL_ACCURACY = 21044,
  POTENTIAL_KN_CONTROL_PRECISION = 21045,
  ACTIVITY_AB_CONTROL_ACCURACY = 21046,
  ACTIVITY_AB_CONTROL_PRECISION = 21047,
  POTENTIAL_BP_CONTROL_ACCURACY = 21048,
  POTENTIAL_BP_CONTROL_PRECISION = 21049,
  POTENTIAL_KB_CONTROL_ACCURACY = 21050,
  POTENTIAL_KB_CONTROL_PRECISION = 21051,
  ACTIVITY_AS_CONTROL_ACCURACY = 21052,
  ACTIVITY_AS_CONTROL_PRECISION = 21053,
  POTENTIAL_SP_CONTROL_ACCURACY = 21054,
  POTENTIAL_SP_CONTROL_PRECISION = 21055,
  POTENTIAL_KS_CONTROL_ACCURACY = 21056,
  POTENTIAL_KS_CONTROL_PRECISION = 21057,
  ACETYLENE_C2H2_FLOW_CONTROL_ACCURACY = 21058,
  ACETYLENE_C2H2_FLOW_CONTROL_PRECISION = 21059,
  AIR_FLOW_CONTROL_ACCURACY = 21060,
  AIR_FLOW_CONTROL_PRECISION = 21061,
  AMMONIA_NH3_FLOW_CONTROL_ACCURACY = 21062,
  AMMONIA_NH3_FLOW_CONTROL_PRECISION = 21063,
  ARGON_FLOW_CONTROL_ACCURACY = 21064,
  ARGON_FLOW_CONTROL_PRECISION = 21065,
  CARBON_DIOXIDE_CO2_FLOW_CONTROL_ACCURACY = 21066,
  CARBON_DIOXIDE_CO2_FLOW_CONTROL_PRECISION = 21067,
  CARBON_MONOXIDE_CO_FLOW_CONTROL_ACCURACY = 21068,
  CARBON_MONOXIDE_CO_FLOW_CONTROL_PRECISION = 21069,
  DISSOCIATED_AMMONIA_NH3_FLOW_CONTROL_ACCURACY = 21070,
  DISSOCIATED_AMMONIA_NH3_FLOW_CONTROL_PRECISION = 21071,
  ENDO_GAS_NATURAL_FLOW_CONTROL_ACCURACY = 21072,
  ENDO_GAS_NATURAL_FLOW_CONTROL_PRECISION = 21073,
  ENDO_GAS_PROPANE_FLOW_CONTROL_ACCURACY = 21074,
  ENDO_GAS_PROPANE_FLOW_CONTROL_PRECISION = 21075,
  ETHENE_C2H4_FLOW_CONTROL_ACCURACY = 21076,
  ETHENE_C2H4_FLOW_CONTROL_PRECISION = 21077,
  HELIUM_FLOW_CONTROL_ACCURACY = 21078,
  HELIUM_FLOW_CONTROL_PRECISION = 21079,
  HYDROGEN_H2_FLOW_CONTROL_ACCURACY = 21080,
  HYDROGEN_H2_FLOW_CONTROL_PRECISION = 21081,
  METHANOL_CH3OH_FLOW_CONTROL_ACCURACY = 21082,
  METHANOL_CH3OH_FLOW_CONTROL_PRECISION = 21083,
  NATURAL_GAS_FLOW_CONTROL_ACCURACY = 21084,
  NATURAL_GAS_FLOW_CONTROL_PRECISION = 21085,
  NITROGEN_FLOW_CONTROL_ACCURACY = 21086,
  NITROGEN_FLOW_CONTROL_PRECISION = 21087,
  PROPANE_C3H8_FLOW_CONTROL_ACCURACY = 21088,
  PROPANE_C3H8_FLOW_CONTROL_PRECISION = 21089,
  WATER_FLOW_CONTROL_ACCURACY = 21090,
  WATER_FLOW_CONTROL_PRECISION = 21091,
  NITROUS_OXIDE_N2O_FLOW_CONTROL_ACCURACY = 21092,
  NITROUS_OXIDE_N2O_FLOW_CONTROL_PRECISION = 21093,
  TEMPERATURE_CONTROL_AGILITY = 21094,
  HEATING_POWER_OUTPUT_CONTROL_AGILITY = 21095,
  ELECTRICAL_POWER_CONTROL_AGILITY = 21096,
  COOLING_POWER_OUTPUT_CONTROL_AGILITY = 21097,
  RELATIVE_PRESSURE_CONTROL_AGILITY = 21098,
  CHAMBER_TEMPERATURE_CONTROL_AGILITY = 21099,
  ABSOLUTE_PRESSURE_CONTROL_AGILITY = 21100,
  VACUUM_PRESSURE_CONTROL_AGILITY = 21101,
  ATMOSPHERIC_PRESSURE_CONTROL_AGILITY = 21102,
  ROOM_TEMPERATURE_CONTROL_AGILITY = 21103,
  ACTIVITY_AH_CONTROL_AGILITY = 21104,
  POTENTIAL_HP_CONTROL_AGILITY = 21105,
  POTENTIAL_KH_CONTROL_AGILITY = 21106,
  ACTIVITY_AO_CONTROL_AGILITY = 21107,
  POTENTIAL_OP_CONTROL_AGILITY = 21108,
  POTENTIAL_KO_CONTROL_AGILITY = 21109,
  ACTIVITY_AC_CONTROL_AGILITY = 21110,
  POTENTIAL_CP_CONTROL_AGILITY = 21111,
  POTENTIAL_KC_CONTROL_AGILITY = 21112,
  ACTIVITY_AN_CONTROL_AGILITY = 21113,
  POTENTIAL_NP_CONTROL_AGILITY = 21114,
  POTENTIAL_KN_CONTROL_AGILITY = 21115,
  ACTIVITY_AB_CONTROL_AGILITY = 21116,
  POTENTIAL_BP_CONTROL_AGILITY = 21117,
  POTENTIAL_KB_CONTROL_AGILITY = 21118,
  ACTIVITY_AS_CONTROL_AGILITY = 21119,
  POTENTIAL_SP_CONTROL_AGILITY = 21120,
  POTENTIAL_KS_CONTROL_AGILITY = 21121,
  ACETYLENE_C2H2_FLOW_CONTROL_AGILITY = 21122,
  AIR_FLOW_CONTROL_AGILITY = 21123,
  AMMONIA_NH3_FLOW_CONTROL_AGILITY = 21124,
  ARGON_FLOW_CONTROL_AGILITY = 21125,
  CARBON_DIOXIDE_CO2_FLOW_CONTROL_AGILITY = 21126,
  CARBON_MONOXIDE_CO_FLOW_CONTROL_AGILITY = 21127,
  DISSOCIATED_AMMONIA_NH3_FLOW_CONTROL_AGILITY = 21128,
  ENDO_GAS_NATURAL_FLOW_CONTROL_AGILITY = 21129,
  ENDO_GAS_PROPANE_FLOW_CONTROL_AGILITY = 21130,
  ETHENE_C2H4_FLOW_CONTROL_AGILITY = 21131,
  HELIUM_FLOW_CONTROL_AGILITY = 21132,
  HYDROGEN_H2_FLOW_CONTROL_AGILITY = 21133,
  METHANOL_CH3OH_FLOW_CONTROL_AGILITY = 21134,
  NATURAL_GAS_FLOW_CONTROL_AGILITY = 21135,
  NITROGEN_FLOW_CONTROL_AGILITY = 21136,
  PROPANE_C3H8_FLOW_CONTROL_AGILITY = 21137,
  WATER_FLOW_CONTROL_AGILITY = 21138,
  NITROUS_OXIDE_N2O_FLOW_CONTROL_AGILITY = 21139,
  OXYGEN_O2_FLOW_CONTROL_ACCURACY = 21140,
  OXYGEN_O2_FLOW_CONTROL_PRECISION = 21141,
  OXYGEN_O2_FLOW_CONTROL_AGILITY = 21142,
  METHANE_CH4_FLOW_CONTROL_ACCURACY = 21143,
  METHANE_CH4_FLOW_CONTROL_PRECISION = 21144,
  METHANE_CH4_FLOW_CONTROL_AGILITY = 21145,
  DISSOCIATION_CONTROL_ACCURACY = 21146,
  DISSOCIATION_CONTROL_PRECISION = 21147,
  DISSOCIATION_CONTROL_AGILITY = 21148,
  PARTIAL_PRESSURE_ACETYLENE_C2H2_CONTROL_ACCURACY = 21170,
  PARTIAL_PRESSURE_ACETYLENE_C2H2_CONTROL_PRECISION = 21171,
  PARTIAL_PRESSURE_ACETYLENE_C2H2_CONTROL_AGILITY = 21172,
  PARTIAL_PRESSURE_AIR_CONTROL_ACCURACY = 21173,
  PARTIAL_PRESSURE_AIR_CONTROL_PRECISION = 21174,
  PARTIAL_PRESSURE_AIR_CONTROL_AGILITY = 21175,
  PARTIAL_PRESSURE_AMMONIA_NH3_CONTROL_ACCURACY = 21176,
  PARTIAL_PRESSURE_AMMONIA_NH3_CONTROL_PRECISION = 21177,
  PARTIAL_PRESSURE_AMMONIA_NH3_CONTROL_AGILITY = 21178,
  PARTIAL_PRESSURE_ARGON_CONTROL_ACCURACY = 21179,
  PARTIAL_PRESSURE_ARGON_CONTROL_PRECISION = 21180,
  PARTIAL_PRESSURE_ARGON_CONTROL_AGILITY = 21181,
  PARTIAL_PRESSURE_CARBON_DIOXIDE_CO2_CONTROL_ACCURACY = 21182,
  PARTIAL_PRESSURE_CARBON_DIOXIDE_CO2_CONTROL_PRECISION = 21183,
  PARTIAL_PRESSURE_CARBON_DIOXIDE_CO2_CONTROL_AGILITY = 21184,
  PARTIAL_PRESSURE_CARBON_MONOXIDE_CO_CONTROL_ACCURACY = 21185,
  PARTIAL_PRESSURE_CARBON_MONOXIDE_CO_CONTROL_PRECISION = 21186,
  PARTIAL_PRESSURE_CARBON_MONOXIDE_CO_CONTROL_AGILITY = 21187,
  PARTIAL_PRESSURE_DISSOCIATED_AMMONIA_NH3_CONTROL_ACCURACY = 21188,
  PARTIAL_PRESSURE_DISSOCIATED_AMMONIA_NH3_CONTROL_PRECISION = 21189,
  PARTIAL_PRESSURE_DISSOCIATED_AMMONIA_NH3_CONTROL_AGILITY = 21190,
  PARTIAL_PRESSURE_ENDO_GAS_NATURAL_CONTROL_ACCURACY = 21191,
  PARTIAL_PRESSURE_ENDO_GAS_NATURAL_CONTROL_PRECISION = 21192,
  PARTIAL_PRESSURE_ENDO_GAS_NATURAL_CONTROL_AGILITY = 21193,
  PARTIAL_PRESSURE_ENDO_GAS_PROPANE_CONTROL_ACCURACY = 21194,
  PARTIAL_PRESSURE_ENDO_GAS_PROPANE_CONTROL_PRECISION = 21195,
  PARTIAL_PRESSURE_ENDO_GAS_PROPANE_CONTROL_AGILITY = 21196,
  PARTIAL_PRESSURE_ETHENE_C2H4_CONTROL_ACCURACY = 21197,
  PARTIAL_PRESSURE_ETHENE_C2H4_CONTROL_PRECISION = 21198,
  PARTIAL_PRESSURE_ETHENE_C2H4_CONTROL_AGILITY = 21199,
  PARTIAL_PRESSURE_HELIUM_CONTROL_ACCURACY = 21200,
  PARTIAL_PRESSURE_HELIUM_CONTROL_PRECISION = 21201,
  PARTIAL_PRESSURE_HELIUM_CONTROL_AGILITY = 21202,
  PARTIAL_PRESSURE_HYDROGEN_H2_CONTROL_ACCURACY = 21203,
  PARTIAL_PRESSURE_HYDROGEN_H2_CONTROL_PRECISION = 21204,
  PARTIAL_PRESSURE_HYDROGEN_H2_CONTROL_AGILITY = 21205,
  PARTIAL_PRESSURE_METHANOL_CH3OH_CONTROL_ACCURACY = 21206,
  PARTIAL_PRESSURE_METHANOL_CH3OH_CONTROL_PRECISION = 21207,
  PARTIAL_PRESSURE_METHANOL_CH3OH_CONTROL_AGILITY = 21208,
  PARTIAL_PRESSURE_NATURAL_GAS_CONTROL_ACCURACY = 21209,
  PARTIAL_PRESSURE_NATURAL_GAS_CONTROL_PRECISION = 21210,
  PARTIAL_PRESSURE_NATURAL_GAS_CONTROL_AGILITY = 21211,
  PARTIAL_PRESSURE_NITROGEN_CONTROL_ACCURACY = 21212,
  PARTIAL_PRESSURE_NITROGEN_CONTROL_PRECISION = 21213,
  PARTIAL_PRESSURE_NITROGEN_CONTROL_AGILITY = 21214,
  PARTIAL_PRESSURE_NITROUS_OXIDE_N2O_CONTROL_ACCURACY = 21215,
  PARTIAL_PRESSURE_NITROUS_OXIDE_N2O_CONTROL_PRECISION = 21216,
  PARTIAL_PRESSURE_NITROUS_OXIDE_N2O_CONTROL_AGILITY = 21217,
  PARTIAL_PRESSURE_PROPANE_C3H8_CONTROL_ACCURACY = 21218,
  PARTIAL_PRESSURE_PROPANE_C3H8_CONTROL_PRECISION = 21219,
  PARTIAL_PRESSURE_PROPANE_C3H8_CONTROL_AGILITY = 21220,
  PARTIAL_PRESSURE_WATER_CONTROL_ACCURACY = 21221,
  PARTIAL_PRESSURE_WATER_CONTROL_PRECISION = 21222,
  PARTIAL_PRESSURE_WATER_CONTROL_AGILITY = 21223,
  PARTIAL_PRESSURE_ACETYLENE_C2H2_PERCENT_CONTROL_ACCURACY = 21224,
  PARTIAL_PRESSURE_ACETYLENE_C2H2_PERCENT_CONTROL_PRECISION = 21225,
  PARTIAL_PRESSURE_ACETYLENE_C2H2_PERCENT_CONTROL_AGILITY = 21226,
  PARTIAL_PRESSURE_AIR_PERCENT_CONTROL_ACCURACY = 21227,
  PARTIAL_PRESSURE_AIR_PERCENT_CONTROL_PRECISION = 21228,
  PARTIAL_PRESSURE_AIR_PERCENT_CONTROL_AGILITY = 21229,
  PARTIAL_PRESSURE_AMMONIA_NH3_PERCENT_CONTROL_ACCURACY = 21230,
  PARTIAL_PRESSURE_AMMONIA_NH3_PERCENT_CONTROL_PRECISION = 21231,
  PARTIAL_PRESSURE_AMMONIA_NH3_PERCENT_CONTROL_AGILITY = 21232,
  PARTIAL_PRESSURE_ARGON_PERCENT_CONTROL_ACCURACY = 21233,
  PARTIAL_PRESSURE_ARGON_PERCENT_CONTROL_PRECISION = 21234,
  PARTIAL_PRESSURE_ARGON_PERCENT_CONTROL_AGILITY = 21235,
  PARTIAL_PRESSURE_CARBON_DIOXIDE_CO2_PERCENT_CONTROL_ACCURACY = 21236,
  PARTIAL_PRESSURE_CARBON_DIOXIDE_CO2_PERCENT_CONTROL_PRECISION = 21237,
  PARTIAL_PRESSURE_CARBON_DIOXIDE_CO2_PERCENT_CONTROL_AGILITY = 21238,
  PARTIAL_PRESSURE_CARBON_MONOXIDE_CO_PERCENT_CONTROL_ACCURACY = 21239,
  PARTIAL_PRESSURE_CARBON_MONOXIDE_CO_PERCENT_CONTROL_PRECISION = 21240,
  PARTIAL_PRESSURE_CARBON_MONOXIDE_CO_PERCENT_CONTROL_AGILITY = 21241,
  PARTIAL_PRESSURE_DISSOCIATED_AMMONIA_NH3_PERCENT_CONTROL_ACCURACY = 21242,
  PARTIAL_PRESSURE_DISSOCIATED_AMMONIA_NH3_PERCENT_CONTROL_PRECISION = 21243,
  PARTIAL_PRESSURE_DISSOCIATED_AMMONIA_NH3_PERCENT_CONTROL_AGILITY = 21244,
  PARTIAL_PRESSURE_ENDO_GAS_NATURAL_PERCENT_CONTROL_ACCURACY = 21245,
  PARTIAL_PRESSURE_ENDO_GAS_NATURAL_PERCENT_CONTROL_PRECISION = 21246,
  PARTIAL_PRESSURE_ENDO_GAS_NATURAL_PERCENT_CONTROL_AGILITY = 21247,
  PARTIAL_PRESSURE_ENDO_GAS_PROPANE_PERCENT_CONTROL_ACCURACY = 21248,
  PARTIAL_PRESSURE_ENDO_GAS_PROPANE_PERCENT_CONTROL_PRECISION = 21249,
  PARTIAL_PRESSURE_ENDO_GAS_PROPANE_PERCENT_CONTROL_AGILITY = 21250,
  PARTIAL_PRESSURE_ETHENE_C2H4_PERCENT_CONTROL_ACCURACY = 21251,
  PARTIAL_PRESSURE_ETHENE_C2H4_PERCENT_CONTROL_PRECISION = 21252,
  PARTIAL_PRESSURE_ETHENE_C2H4_PERCENT_CONTROL_AGILITY = 21253,
  PARTIAL_PRESSURE_HELIUM_PERCENT_CONTROL_ACCURACY = 21254,
  PARTIAL_PRESSURE_HELIUM_PERCENT_CONTROL_PRECISION = 21255,
  PARTIAL_PRESSURE_HELIUM_PERCENT_CONTROL_AGILITY = 21256,
  PARTIAL_PRESSURE_HYDROGEN_H2_PERCENT_CONTROL_ACCURACY = 21257,
  PARTIAL_PRESSURE_HYDROGEN_H2_PERCENT_CONTROL_PRECISION = 21258,
  PARTIAL_PRESSURE_HYDROGEN_H2_PERCENT_CONTROL_AGILITY = 21259,
  PARTIAL_PRESSURE_METHANOL_CH3OH_PERCENT_CONTROL_ACCURACY = 21260,
  PARTIAL_PRESSURE_METHANOL_CH3OH_PERCENT_CONTROL_PRECISION = 21261,
  PARTIAL_PRESSURE_METHANOL_CH3OH_PERCENT_CONTROL_AGILITY = 21262,
  PARTIAL_PRESSURE_NATURAL_GAS_PERCENT_CONTROL_ACCURACY = 21263,
  PARTIAL_PRESSURE_NATURAL_GAS_PERCENT_CONTROL_PRECISION = 21264,
  PARTIAL_PRESSURE_NATURAL_GAS_PERCENT_CONTROL_AGILITY = 21265,
  PARTIAL_PRESSURE_NITROGEN_PERCENT_CONTROL_ACCURACY = 21266,
  PARTIAL_PRESSURE_NITROGEN_PERCENT_CONTROL_PRECISION = 21267,
  PARTIAL_PRESSURE_NITROGEN_PERCENT_CONTROL_AGILITY = 21268,
  PARTIAL_PRESSURE_NITROUS_OXIDE_N2O_PERCENT_CONTROL_ACCURACY = 21269,
  PARTIAL_PRESSURE_NITROUS_OXIDE_N2O_PERCENT_CONTROL_PRECISION = 21270,
  PARTIAL_PRESSURE_NITROUS_OXIDE_N2O_PERCENT_CONTROL_AGILITY = 21271,
  PARTIAL_PRESSURE_PROPANE_C3H8_PERCENT_CONTROL_ACCURACY = 21272,
  PARTIAL_PRESSURE_PROPANE_C3H8_PERCENT_CONTROL_PRECISION = 21273,
  PARTIAL_PRESSURE_PROPANE_C3H8_PERCENT_CONTROL_AGILITY = 21274,
  PARTIAL_PRESSURE_WATER_PERCENT_CONTROL_ACCURACY = 21275,
  PARTIAL_PRESSURE_WATER_PERCENT_CONTROL_PRECISION = 21276,
  PARTIAL_PRESSURE_WATER_PERCENT_CONTROL_AGILITY = 21277,
  PARTIAL_PRESSURE_OXYGEN_CONTROL_ACCURACY = 21278,
  PARTIAL_PRESSURE_OXYGEN_CONTROL_PRECISION = 21279,
  PARTIAL_PRESSURE_OXYGEN_CONTROL_AGILITY = 21280,
  PARTIAL_PRESSURE_CARBON_CONTROL_ACCURACY = 21281,
  PARTIAL_PRESSURE_CARBON_CONTROL_PRECISION = 21282,
  PARTIAL_PRESSURE_CARBON_CONTROL_AGILITY = 21283,
  PARTIAL_PRESSURE_BORON_CONTROL_ACCURACY = 21284,
  PARTIAL_PRESSURE_BORON_CONTROL_PRECISION = 21285,
  PARTIAL_PRESSURE_BORON_CONTROL_AGILITY = 21286,
  PARTIAL_PRESSURE_SULFUR_CONTROL_ACCURACY = 21287,
  PARTIAL_PRESSURE_SULFUR_CONTROL_PRECISION = 21288,
  PARTIAL_PRESSURE_SULFUR_CONTROL_AGILITY = 21289,
  PARTIAL_PRESSURE_OXYGEN_PERCENT_CONTROL_ACCURACY = 21290,
  PARTIAL_PRESSURE_OXYGEN_PERCENT_CONTROL_PRECISION = 21291,
  PARTIAL_PRESSURE_OXYGEN_PERCENT_CONTROL_AGILITY = 21292,
  PARTIAL_PRESSURE_CARBON_PERCENT_CONTROL_ACCURACY = 21293,
  PARTIAL_PRESSURE_CARBON_PERCENT_CONTROL_PRECISION = 21294,
  PARTIAL_PRESSURE_CARBON_PERCENT_CONTROL_AGILITY = 21295,
  PARTIAL_PRESSURE_BORON_PERCENT_CONTROL_ACCURACY = 21296,
  PARTIAL_PRESSURE_BORON_PERCENT_CONTROL_PRECISION = 21297,
  PARTIAL_PRESSURE_BORON_PERCENT_CONTROL_AGILITY = 21298,
  PARTIAL_PRESSURE_SULFUR_PERCENT_CONTROL_ACCURACY = 21299,
  PARTIAL_PRESSURE_SULFUR_PERCENT_CONTROL_PRECISION = 21300,
  PARTIAL_PRESSURE_SULFUR_PERCENT_CONTROL_AGILITY = 21301,
  ETHANE_C2H6_FLOW_CONTROL_ACCURACY = 21302,
  ETHANE_C2H6_FLOW_CONTROL_AGILITY = 21303,
  ETHANE_C2H6_FLOW_CONTROL_PRECISION = 21304,
  PARTIAL_PRESSURE_ETHANE_C2H6_CONTROL_ACCURACY = 21305,
  PARTIAL_PRESSURE_ETHANE_C2H6_CONTROL_AGILITY = 21306,
  PARTIAL_PRESSURE_ETHANE_C2H6_CONTROL_PRECISION = 21307,
  PARTIAL_PRESSURE_ETHANE_C2H6_PERCENT_CONTROL_ACCURACY = 21308,
  PARTIAL_PRESSURE_ETHANE_C2H6_PERCENT_CONTROL_AGILITY = 21309,
  PARTIAL_PRESSURE_ETHANE_C2H6_PERCENT_CONTROL_PRECISION = 21310,
  PARTIAL_PRESSURE_METHANE_CH4_CONTROL_ACCURACY = 21311,
  PARTIAL_PRESSURE_METHANE_CH4_CONTROL_AGILITY = 21312,
  PARTIAL_PRESSURE_METHANE_CH4_CONTROL_PRECISION = 21313,
  PARTIAL_PRESSURE_METHANE_CH4_PERCENT_CONTROL_ACCURACY = 21314,
  PARTIAL_PRESSURE_METHANE_CH4_PERCENT_CONTROL_AGILITY = 21315,
  PARTIAL_PRESSURE_METHANE_CH4_PERCENT_CONTROL_PRECISION = 21316,
  HEAT_LOSS_AT_500C = 21317,
  HIGH_VACUUM_PRESSURE_CONTROL_ACCURACY = 21318,
  HIGH_VACUUM_PRESSURE_CONTROL_AGILITY = 21319,
  HIGH_VACUUM_PRESSURE_CONTROL_PRECISION = 21320,
  LOW_VACUUM_PRESSURE_CONTROL_ACCURACY = 21321,
  LOW_VACUUM_PRESSURE_CONTROL_AGILITY = 21322,
  LOW_VACUUM_PRESSURE_CONTROL_PRECISION = 21323,
  VACUUM_TIME_TO_CROSS_OVER = 21324,
  VACUUM_MAX_LEAK_UP_RATE = 21325,
  LEAD_TIME_CONTROL_ACCURACY = 21326,
  LEAD_TIME_CONTROL_AGILITY = 21327,
  LEAD_TIME_CONTROL_PRECISION = 21328,
  BELT_SPEED_CONTROL_ACCURACY = 21329,
  BELT_SPEED_CONTROL_AGILITY = 21330,
  BELT_SPEED_CONTROL_PRECISION = 21331,
  PEARSON_CORRELATION = 21900,
  SPEARMAN_CORRELATION = 21901,
  USER_DEFINED_080 = 21920,
  USER_DEFINED_079 = 21921,
  USER_DEFINED_078 = 21922,
  USER_DEFINED_077 = 21923,
  USER_DEFINED_076 = 21924,
  USER_DEFINED_075 = 21925,
  USER_DEFINED_074 = 21926,
  USER_DEFINED_073 = 21927,
  USER_DEFINED_072 = 21928,
  USER_DEFINED_071 = 21929,
  USER_DEFINED_070 = 21930,
  USER_DEFINED_069 = 21931,
  USER_DEFINED_068 = 21932,
  USER_DEFINED_067 = 21933,
  USER_DEFINED_066 = 21934,
  USER_DEFINED_065 = 21935,
  USER_DEFINED_064 = 21936,
  USER_DEFINED_063 = 21937,
  USER_DEFINED_062 = 21938,
  USER_DEFINED_061 = 21939,
  USER_DEFINED_060 = 21940,
  USER_DEFINED_059 = 21941,
  USER_DEFINED_058 = 21942,
  USER_DEFINED_057 = 21943,
  USER_DEFINED_056 = 21944,
  USER_DEFINED_055 = 21945,
  USER_DEFINED_054 = 21946,
  USER_DEFINED_053 = 21947,
  USER_DEFINED_052 = 21948,
  USER_DEFINED_051 = 21949,
  USER_DEFINED_050 = 21950,
  USER_DEFINED_049 = 21951,
  USER_DEFINED_048 = 21952,
  USER_DEFINED_047 = 21953,
  USER_DEFINED_046 = 21954,
  USER_DEFINED_045 = 21955,
  USER_DEFINED_044 = 21956,
  USER_DEFINED_043 = 21957,
  USER_DEFINED_042 = 21958,
  USER_DEFINED_041 = 21959,
  USER_DEFINED_040 = 21960,
  USER_DEFINED_039 = 21961,
  USER_DEFINED_038 = 21962,
  USER_DEFINED_037 = 21963,
  USER_DEFINED_036 = 21964,
  USER_DEFINED_035 = 21965,
  USER_DEFINED_034 = 21966,
  USER_DEFINED_033 = 21967,
  USER_DEFINED_032 = 21968,
  USER_DEFINED_031 = 21969,
  USER_DEFINED_030 = 21970,
  USER_DEFINED_029 = 21971,
  USER_DEFINED_028 = 21972,
  USER_DEFINED_027 = 21973,
  USER_DEFINED_026 = 21974,
  USER_DEFINED_025 = 21975,
  USER_DEFINED_024 = 21976,
  USER_DEFINED_023 = 21977,
  USER_DEFINED_022 = 21978,
  USER_DEFINED_021 = 21979,
  USER_DEFINED_020 = 21980,
  USER_DEFINED_019 = 21981,
  USER_DEFINED_018 = 21982,
  USER_DEFINED_017 = 21983,
  USER_DEFINED_016 = 21984,
  USER_DEFINED_015 = 21985,
  USER_DEFINED_014 = 21986,
  USER_DEFINED_013 = 21987,
  USER_DEFINED_012 = 21988,
  USER_DEFINED_011 = 21989,
  USER_DEFINED_010 = 21990,
  USER_DEFINED_009 = 21991,
  USER_DEFINED_008 = 21992,
  USER_DEFINED_007 = 21993,
  USER_DEFINED_006 = 21994,
  USER_DEFINED_005 = 21995,
  USER_DEFINED_004 = 21996,
  USER_DEFINED_003 = 21997,
  USER_DEFINED_002 = 21998,
  USER_DEFINED_001 = 21999,
}

export const jobFeatureMeaningMap: Record<components['schemas']['JobFeatureMeaning'], keyof typeof EJobFeatureMeaningMap> = {
  20800: 'ASSET_DWELLING_TIME',
  21000: 'HEAT_LOSS_MIN_POWER',
  21001: 'HEAT_LOSS_MAX_TEMPERATURE',
  21002: 'TEMPERATURE_CONTROL_ACCURACY',
  21003: 'TEMPERATURE_CONTROL_PRECISION',
  21004: 'HEATING_POWER_OUTPUT_CONTROL_ACCURACY',
  21005: 'HEATING_POWER_OUTPUT_CONTROL_PRECISION',
  21006: 'ELECTRICAL_POWER_CONTROL_ACCURACY',
  21007: 'ELECTRICAL_POWER_CONTROL_PRECISION',
  21008: 'COOLING_POWER_OUTPUT_CONTROL_ACCURACY',
  21009: 'COOLING_POWER_OUTPUT_CONTROL_PRECISION',
  21010: 'RELATIVE_PRESSURE_CONTROL_ACCURACY',
  21011: 'RELATIVE_PRESSURE_CONTROL_PRECISION',
  21012: 'CHAMBER_TEMPERATURE_CONTROL_ACCURACY',
  21013: 'CHAMBER_TEMPERATURE_CONTROL_PRECISION',
  21014: 'ABSOLUTE_PRESSURE_CONTROL_ACCURACY',
  21015: 'ABSOLUTE_PRESSURE_CONTROL_PRECISION',
  21016: 'VACUUM_PRESSURE_CONTROL_ACCURACY',
  21017: 'VACUUM_PRESSURE_CONTROL_PRECISION',
  21018: 'ATMOSPHERIC_PRESSURE_CONTROL_ACCURACY',
  21019: 'ATMOSPHERIC_PRESSURE_CONTROL_PRECISION',
  21020: 'ROOM_TEMPERATURE_CONTROL_ACCURACY',
  21021: 'ROOM_TEMPERATURE_CONTROL_PRECISION',
  21022: 'ACTIVITY_AH_CONTROL_ACCURACY',
  21023: 'ACTIVITY_AH_CONTROL_PRECISION',
  21024: 'POTENTIAL_HP_CONTROL_ACCURACY',
  21025: 'POTENTIAL_HP_CONTROL_PRECISION',
  21026: 'POTENTIAL_KH_CONTROL_ACCURACY',
  21027: 'POTENTIAL_KH_CONTROL_PRECISION',
  21028: 'ACTIVITY_AO_CONTROL_ACCURACY',
  21029: 'ACTIVITY_AO_CONTROL_PRECISION',
  21030: 'POTENTIAL_OP_CONTROL_ACCURACY',
  21031: 'POTENTIAL_OP_CONTROL_PRECISION',
  21032: 'POTENTIAL_KO_CONTROL_ACCURACY',
  21033: 'POTENTIAL_KO_CONTROL_PRECISION',
  21034: 'ACTIVITY_AC_CONTROL_ACCURACY',
  21035: 'ACTIVITY_AC_CONTROL_PRECISION',
  21036: 'POTENTIAL_CP_CONTROL_ACCURACY',
  21037: 'POTENTIAL_CP_CONTROL_PRECISION',
  21038: 'POTENTIAL_KC_CONTROL_ACCURACY',
  21039: 'POTENTIAL_KC_CONTROL_PRECISION',
  21040: 'ACTIVITY_AN_CONTROL_ACCURACY',
  21041: 'ACTIVITY_AN_CONTROL_PRECISION',
  21042: 'POTENTIAL_NP_CONTROL_ACCURACY',
  21043: 'POTENTIAL_NP_CONTROL_PRECISION',
  21044: 'POTENTIAL_KN_CONTROL_ACCURACY',
  21045: 'POTENTIAL_KN_CONTROL_PRECISION',
  21046: 'ACTIVITY_AB_CONTROL_ACCURACY',
  21047: 'ACTIVITY_AB_CONTROL_PRECISION',
  21048: 'POTENTIAL_BP_CONTROL_ACCURACY',
  21049: 'POTENTIAL_BP_CONTROL_PRECISION',
  21050: 'POTENTIAL_KB_CONTROL_ACCURACY',
  21051: 'POTENTIAL_KB_CONTROL_PRECISION',
  21052: 'ACTIVITY_AS_CONTROL_ACCURACY',
  21053: 'ACTIVITY_AS_CONTROL_PRECISION',
  21054: 'POTENTIAL_SP_CONTROL_ACCURACY',
  21055: 'POTENTIAL_SP_CONTROL_PRECISION',
  21056: 'POTENTIAL_KS_CONTROL_ACCURACY',
  21057: 'POTENTIAL_KS_CONTROL_PRECISION',
  21058: 'ACETYLENE_C2H2_FLOW_CONTROL_ACCURACY',
  21059: 'ACETYLENE_C2H2_FLOW_CONTROL_PRECISION',
  21060: 'AIR_FLOW_CONTROL_ACCURACY',
  21061: 'AIR_FLOW_CONTROL_PRECISION',
  21062: 'AMMONIA_NH3_FLOW_CONTROL_ACCURACY',
  21063: 'AMMONIA_NH3_FLOW_CONTROL_PRECISION',
  21064: 'ARGON_FLOW_CONTROL_ACCURACY',
  21065: 'ARGON_FLOW_CONTROL_PRECISION',
  21066: 'CARBON_DIOXIDE_CO2_FLOW_CONTROL_ACCURACY',
  21067: 'CARBON_DIOXIDE_CO2_FLOW_CONTROL_PRECISION',
  21068: 'CARBON_MONOXIDE_CO_FLOW_CONTROL_ACCURACY',
  21069: 'CARBON_MONOXIDE_CO_FLOW_CONTROL_PRECISION',
  21070: 'DISSOCIATED_AMMONIA_NH3_FLOW_CONTROL_ACCURACY',
  21071: 'DISSOCIATED_AMMONIA_NH3_FLOW_CONTROL_PRECISION',
  21072: 'ENDO_GAS_NATURAL_FLOW_CONTROL_ACCURACY',
  21073: 'ENDO_GAS_NATURAL_FLOW_CONTROL_PRECISION',
  21074: 'ENDO_GAS_PROPANE_FLOW_CONTROL_ACCURACY',
  21075: 'ENDO_GAS_PROPANE_FLOW_CONTROL_PRECISION',
  21076: 'ETHENE_C2H4_FLOW_CONTROL_ACCURACY',
  21077: 'ETHENE_C2H4_FLOW_CONTROL_PRECISION',
  21078: 'HELIUM_FLOW_CONTROL_ACCURACY',
  21079: 'HELIUM_FLOW_CONTROL_PRECISION',
  21080: 'HYDROGEN_H2_FLOW_CONTROL_ACCURACY',
  21081: 'HYDROGEN_H2_FLOW_CONTROL_PRECISION',
  21082: 'METHANOL_CH3OH_FLOW_CONTROL_ACCURACY',
  21083: 'METHANOL_CH3OH_FLOW_CONTROL_PRECISION',
  21084: 'NATURAL_GAS_FLOW_CONTROL_ACCURACY',
  21085: 'NATURAL_GAS_FLOW_CONTROL_PRECISION',
  21086: 'NITROGEN_FLOW_CONTROL_ACCURACY',
  21087: 'NITROGEN_FLOW_CONTROL_PRECISION',
  21088: 'PROPANE_C3H8_FLOW_CONTROL_ACCURACY',
  21089: 'PROPANE_C3H8_FLOW_CONTROL_PRECISION',
  21090: 'WATER_FLOW_CONTROL_ACCURACY',
  21091: 'WATER_FLOW_CONTROL_PRECISION',
  21092: 'NITROUS_OXIDE_N2O_FLOW_CONTROL_ACCURACY',
  21093: 'NITROUS_OXIDE_N2O_FLOW_CONTROL_PRECISION',
  21094: 'TEMPERATURE_CONTROL_AGILITY',
  21095: 'HEATING_POWER_OUTPUT_CONTROL_AGILITY',
  21096: 'ELECTRICAL_POWER_CONTROL_AGILITY',
  21097: 'COOLING_POWER_OUTPUT_CONTROL_AGILITY',
  21098: 'RELATIVE_PRESSURE_CONTROL_AGILITY',
  21099: 'CHAMBER_TEMPERATURE_CONTROL_AGILITY',
  21100: 'ABSOLUTE_PRESSURE_CONTROL_AGILITY',
  21101: 'VACUUM_PRESSURE_CONTROL_AGILITY',
  21102: 'ATMOSPHERIC_PRESSURE_CONTROL_AGILITY',
  21103: 'ROOM_TEMPERATURE_CONTROL_AGILITY',
  21104: 'ACTIVITY_AH_CONTROL_AGILITY',
  21105: 'POTENTIAL_HP_CONTROL_AGILITY',
  21106: 'POTENTIAL_KH_CONTROL_AGILITY',
  21107: 'ACTIVITY_AO_CONTROL_AGILITY',
  21108: 'POTENTIAL_OP_CONTROL_AGILITY',
  21109: 'POTENTIAL_KO_CONTROL_AGILITY',
  21110: 'ACTIVITY_AC_CONTROL_AGILITY',
  21111: 'POTENTIAL_CP_CONTROL_AGILITY',
  21112: 'POTENTIAL_KC_CONTROL_AGILITY',
  21113: 'ACTIVITY_AN_CONTROL_AGILITY',
  21114: 'POTENTIAL_NP_CONTROL_AGILITY',
  21115: 'POTENTIAL_KN_CONTROL_AGILITY',
  21116: 'ACTIVITY_AB_CONTROL_AGILITY',
  21117: 'POTENTIAL_BP_CONTROL_AGILITY',
  21118: 'POTENTIAL_KB_CONTROL_AGILITY',
  21119: 'ACTIVITY_AS_CONTROL_AGILITY',
  21120: 'POTENTIAL_SP_CONTROL_AGILITY',
  21121: 'POTENTIAL_KS_CONTROL_AGILITY',
  21122: 'ACETYLENE_C2H2_FLOW_CONTROL_AGILITY',
  21123: 'AIR_FLOW_CONTROL_AGILITY',
  21124: 'AMMONIA_NH3_FLOW_CONTROL_AGILITY',
  21125: 'ARGON_FLOW_CONTROL_AGILITY',
  21126: 'CARBON_DIOXIDE_CO2_FLOW_CONTROL_AGILITY',
  21127: 'CARBON_MONOXIDE_CO_FLOW_CONTROL_AGILITY',
  21128: 'DISSOCIATED_AMMONIA_NH3_FLOW_CONTROL_AGILITY',
  21129: 'ENDO_GAS_NATURAL_FLOW_CONTROL_AGILITY',
  21130: 'ENDO_GAS_PROPANE_FLOW_CONTROL_AGILITY',
  21131: 'ETHENE_C2H4_FLOW_CONTROL_AGILITY',
  21132: 'HELIUM_FLOW_CONTROL_AGILITY',
  21133: 'HYDROGEN_H2_FLOW_CONTROL_AGILITY',
  21134: 'METHANOL_CH3OH_FLOW_CONTROL_AGILITY',
  21135: 'NATURAL_GAS_FLOW_CONTROL_AGILITY',
  21136: 'NITROGEN_FLOW_CONTROL_AGILITY',
  21137: 'PROPANE_C3H8_FLOW_CONTROL_AGILITY',
  21138: 'WATER_FLOW_CONTROL_AGILITY',
  21139: 'NITROUS_OXIDE_N2O_FLOW_CONTROL_AGILITY',
  21140: 'OXYGEN_O2_FLOW_CONTROL_ACCURACY',
  21141: 'OXYGEN_O2_FLOW_CONTROL_PRECISION',
  21142: 'OXYGEN_O2_FLOW_CONTROL_AGILITY',
  21143: 'METHANE_CH4_FLOW_CONTROL_ACCURACY',
  21144: 'METHANE_CH4_FLOW_CONTROL_PRECISION',
  21145: 'METHANE_CH4_FLOW_CONTROL_AGILITY',
  21146: 'DISSOCIATION_CONTROL_ACCURACY',
  21147: 'DISSOCIATION_CONTROL_PRECISION',
  21148: 'DISSOCIATION_CONTROL_AGILITY',
  21170: 'PARTIAL_PRESSURE_ACETYLENE_C2H2_CONTROL_ACCURACY',
  21171: 'PARTIAL_PRESSURE_ACETYLENE_C2H2_CONTROL_PRECISION',
  21172: 'PARTIAL_PRESSURE_ACETYLENE_C2H2_CONTROL_AGILITY',
  21173: 'PARTIAL_PRESSURE_AIR_CONTROL_ACCURACY',
  21174: 'PARTIAL_PRESSURE_AIR_CONTROL_PRECISION',
  21175: 'PARTIAL_PRESSURE_AIR_CONTROL_AGILITY',
  21176: 'PARTIAL_PRESSURE_AMMONIA_NH3_CONTROL_ACCURACY',
  21177: 'PARTIAL_PRESSURE_AMMONIA_NH3_CONTROL_PRECISION',
  21178: 'PARTIAL_PRESSURE_AMMONIA_NH3_CONTROL_AGILITY',
  21179: 'PARTIAL_PRESSURE_ARGON_CONTROL_ACCURACY',
  21180: 'PARTIAL_PRESSURE_ARGON_CONTROL_PRECISION',
  21181: 'PARTIAL_PRESSURE_ARGON_CONTROL_AGILITY',
  21182: 'PARTIAL_PRESSURE_CARBON_DIOXIDE_CO2_CONTROL_ACCURACY',
  21183: 'PARTIAL_PRESSURE_CARBON_DIOXIDE_CO2_CONTROL_PRECISION',
  21184: 'PARTIAL_PRESSURE_CARBON_DIOXIDE_CO2_CONTROL_AGILITY',
  21185: 'PARTIAL_PRESSURE_CARBON_MONOXIDE_CO_CONTROL_ACCURACY',
  21186: 'PARTIAL_PRESSURE_CARBON_MONOXIDE_CO_CONTROL_PRECISION',
  21187: 'PARTIAL_PRESSURE_CARBON_MONOXIDE_CO_CONTROL_AGILITY',
  21188: 'PARTIAL_PRESSURE_DISSOCIATED_AMMONIA_NH3_CONTROL_ACCURACY',
  21189: 'PARTIAL_PRESSURE_DISSOCIATED_AMMONIA_NH3_CONTROL_PRECISION',
  21190: 'PARTIAL_PRESSURE_DISSOCIATED_AMMONIA_NH3_CONTROL_AGILITY',
  21191: 'PARTIAL_PRESSURE_ENDO_GAS_NATURAL_CONTROL_ACCURACY',
  21192: 'PARTIAL_PRESSURE_ENDO_GAS_NATURAL_CONTROL_PRECISION',
  21193: 'PARTIAL_PRESSURE_ENDO_GAS_NATURAL_CONTROL_AGILITY',
  21194: 'PARTIAL_PRESSURE_ENDO_GAS_PROPANE_CONTROL_ACCURACY',
  21195: 'PARTIAL_PRESSURE_ENDO_GAS_PROPANE_CONTROL_PRECISION',
  21196: 'PARTIAL_PRESSURE_ENDO_GAS_PROPANE_CONTROL_AGILITY',
  21197: 'PARTIAL_PRESSURE_ETHENE_C2H4_CONTROL_ACCURACY',
  21198: 'PARTIAL_PRESSURE_ETHENE_C2H4_CONTROL_PRECISION',
  21199: 'PARTIAL_PRESSURE_ETHENE_C2H4_CONTROL_AGILITY',
  21200: 'PARTIAL_PRESSURE_HELIUM_CONTROL_ACCURACY',
  21201: 'PARTIAL_PRESSURE_HELIUM_CONTROL_PRECISION',
  21202: 'PARTIAL_PRESSURE_HELIUM_CONTROL_AGILITY',
  21203: 'PARTIAL_PRESSURE_HYDROGEN_H2_CONTROL_ACCURACY',
  21204: 'PARTIAL_PRESSURE_HYDROGEN_H2_CONTROL_PRECISION',
  21205: 'PARTIAL_PRESSURE_HYDROGEN_H2_CONTROL_AGILITY',
  21206: 'PARTIAL_PRESSURE_METHANOL_CH3OH_CONTROL_ACCURACY',
  21207: 'PARTIAL_PRESSURE_METHANOL_CH3OH_CONTROL_PRECISION',
  21208: 'PARTIAL_PRESSURE_METHANOL_CH3OH_CONTROL_AGILITY',
  21209: 'PARTIAL_PRESSURE_NATURAL_GAS_CONTROL_ACCURACY',
  21210: 'PARTIAL_PRESSURE_NATURAL_GAS_CONTROL_PRECISION',
  21211: 'PARTIAL_PRESSURE_NATURAL_GAS_CONTROL_AGILITY',
  21212: 'PARTIAL_PRESSURE_NITROGEN_CONTROL_ACCURACY',
  21213: 'PARTIAL_PRESSURE_NITROGEN_CONTROL_PRECISION',
  21214: 'PARTIAL_PRESSURE_NITROGEN_CONTROL_AGILITY',
  21215: 'PARTIAL_PRESSURE_NITROUS_OXIDE_N2O_CONTROL_ACCURACY',
  21216: 'PARTIAL_PRESSURE_NITROUS_OXIDE_N2O_CONTROL_PRECISION',
  21217: 'PARTIAL_PRESSURE_NITROUS_OXIDE_N2O_CONTROL_AGILITY',
  21218: 'PARTIAL_PRESSURE_PROPANE_C3H8_CONTROL_ACCURACY',
  21219: 'PARTIAL_PRESSURE_PROPANE_C3H8_CONTROL_PRECISION',
  21220: 'PARTIAL_PRESSURE_PROPANE_C3H8_CONTROL_AGILITY',
  21221: 'PARTIAL_PRESSURE_WATER_CONTROL_ACCURACY',
  21222: 'PARTIAL_PRESSURE_WATER_CONTROL_PRECISION',
  21223: 'PARTIAL_PRESSURE_WATER_CONTROL_AGILITY',
  21224: 'PARTIAL_PRESSURE_ACETYLENE_C2H2_PERCENT_CONTROL_ACCURACY',
  21225: 'PARTIAL_PRESSURE_ACETYLENE_C2H2_PERCENT_CONTROL_PRECISION',
  21226: 'PARTIAL_PRESSURE_ACETYLENE_C2H2_PERCENT_CONTROL_AGILITY',
  21227: 'PARTIAL_PRESSURE_AIR_PERCENT_CONTROL_ACCURACY',
  21228: 'PARTIAL_PRESSURE_AIR_PERCENT_CONTROL_PRECISION',
  21229: 'PARTIAL_PRESSURE_AIR_PERCENT_CONTROL_AGILITY',
  21230: 'PARTIAL_PRESSURE_AMMONIA_NH3_PERCENT_CONTROL_ACCURACY',
  21231: 'PARTIAL_PRESSURE_AMMONIA_NH3_PERCENT_CONTROL_PRECISION',
  21232: 'PARTIAL_PRESSURE_AMMONIA_NH3_PERCENT_CONTROL_AGILITY',
  21233: 'PARTIAL_PRESSURE_ARGON_PERCENT_CONTROL_ACCURACY',
  21234: 'PARTIAL_PRESSURE_ARGON_PERCENT_CONTROL_PRECISION',
  21235: 'PARTIAL_PRESSURE_ARGON_PERCENT_CONTROL_AGILITY',
  21236: 'PARTIAL_PRESSURE_CARBON_DIOXIDE_CO2_PERCENT_CONTROL_ACCURACY',
  21237: 'PARTIAL_PRESSURE_CARBON_DIOXIDE_CO2_PERCENT_CONTROL_PRECISION',
  21238: 'PARTIAL_PRESSURE_CARBON_DIOXIDE_CO2_PERCENT_CONTROL_AGILITY',
  21239: 'PARTIAL_PRESSURE_CARBON_MONOXIDE_CO_PERCENT_CONTROL_ACCURACY',
  21240: 'PARTIAL_PRESSURE_CARBON_MONOXIDE_CO_PERCENT_CONTROL_PRECISION',
  21241: 'PARTIAL_PRESSURE_CARBON_MONOXIDE_CO_PERCENT_CONTROL_AGILITY',
  21242: 'PARTIAL_PRESSURE_DISSOCIATED_AMMONIA_NH3_PERCENT_CONTROL_ACCURACY',
  21243: 'PARTIAL_PRESSURE_DISSOCIATED_AMMONIA_NH3_PERCENT_CONTROL_PRECISION',
  21244: 'PARTIAL_PRESSURE_DISSOCIATED_AMMONIA_NH3_PERCENT_CONTROL_AGILITY',
  21245: 'PARTIAL_PRESSURE_ENDO_GAS_NATURAL_PERCENT_CONTROL_ACCURACY',
  21246: 'PARTIAL_PRESSURE_ENDO_GAS_NATURAL_PERCENT_CONTROL_PRECISION',
  21247: 'PARTIAL_PRESSURE_ENDO_GAS_NATURAL_PERCENT_CONTROL_AGILITY',
  21248: 'PARTIAL_PRESSURE_ENDO_GAS_PROPANE_PERCENT_CONTROL_ACCURACY',
  21249: 'PARTIAL_PRESSURE_ENDO_GAS_PROPANE_PERCENT_CONTROL_PRECISION',
  21250: 'PARTIAL_PRESSURE_ENDO_GAS_PROPANE_PERCENT_CONTROL_AGILITY',
  21251: 'PARTIAL_PRESSURE_ETHENE_C2H4_PERCENT_CONTROL_ACCURACY',
  21252: 'PARTIAL_PRESSURE_ETHENE_C2H4_PERCENT_CONTROL_PRECISION',
  21253: 'PARTIAL_PRESSURE_ETHENE_C2H4_PERCENT_CONTROL_AGILITY',
  21254: 'PARTIAL_PRESSURE_HELIUM_PERCENT_CONTROL_ACCURACY',
  21255: 'PARTIAL_PRESSURE_HELIUM_PERCENT_CONTROL_PRECISION',
  21256: 'PARTIAL_PRESSURE_HELIUM_PERCENT_CONTROL_AGILITY',
  21257: 'PARTIAL_PRESSURE_HYDROGEN_H2_PERCENT_CONTROL_ACCURACY',
  21258: 'PARTIAL_PRESSURE_HYDROGEN_H2_PERCENT_CONTROL_PRECISION',
  21259: 'PARTIAL_PRESSURE_HYDROGEN_H2_PERCENT_CONTROL_AGILITY',
  21260: 'PARTIAL_PRESSURE_METHANOL_CH3OH_PERCENT_CONTROL_ACCURACY',
  21261: 'PARTIAL_PRESSURE_METHANOL_CH3OH_PERCENT_CONTROL_PRECISION',
  21262: 'PARTIAL_PRESSURE_METHANOL_CH3OH_PERCENT_CONTROL_AGILITY',
  21263: 'PARTIAL_PRESSURE_NATURAL_GAS_PERCENT_CONTROL_ACCURACY',
  21264: 'PARTIAL_PRESSURE_NATURAL_GAS_PERCENT_CONTROL_PRECISION',
  21265: 'PARTIAL_PRESSURE_NATURAL_GAS_PERCENT_CONTROL_AGILITY',
  21266: 'PARTIAL_PRESSURE_NITROGEN_PERCENT_CONTROL_ACCURACY',
  21267: 'PARTIAL_PRESSURE_NITROGEN_PERCENT_CONTROL_PRECISION',
  21268: 'PARTIAL_PRESSURE_NITROGEN_PERCENT_CONTROL_AGILITY',
  21269: 'PARTIAL_PRESSURE_NITROUS_OXIDE_N2O_PERCENT_CONTROL_ACCURACY',
  21270: 'PARTIAL_PRESSURE_NITROUS_OXIDE_N2O_PERCENT_CONTROL_PRECISION',
  21271: 'PARTIAL_PRESSURE_NITROUS_OXIDE_N2O_PERCENT_CONTROL_AGILITY',
  21272: 'PARTIAL_PRESSURE_PROPANE_C3H8_PERCENT_CONTROL_ACCURACY',
  21273: 'PARTIAL_PRESSURE_PROPANE_C3H8_PERCENT_CONTROL_PRECISION',
  21274: 'PARTIAL_PRESSURE_PROPANE_C3H8_PERCENT_CONTROL_AGILITY',
  21275: 'PARTIAL_PRESSURE_WATER_PERCENT_CONTROL_ACCURACY',
  21276: 'PARTIAL_PRESSURE_WATER_PERCENT_CONTROL_PRECISION',
  21277: 'PARTIAL_PRESSURE_WATER_PERCENT_CONTROL_AGILITY',
  21278: 'PARTIAL_PRESSURE_OXYGEN_CONTROL_ACCURACY',
  21279: 'PARTIAL_PRESSURE_OXYGEN_CONTROL_PRECISION',
  21280: 'PARTIAL_PRESSURE_OXYGEN_CONTROL_AGILITY',
  21281: 'PARTIAL_PRESSURE_CARBON_CONTROL_ACCURACY',
  21282: 'PARTIAL_PRESSURE_CARBON_CONTROL_PRECISION',
  21283: 'PARTIAL_PRESSURE_CARBON_CONTROL_AGILITY',
  21284: 'PARTIAL_PRESSURE_BORON_CONTROL_ACCURACY',
  21285: 'PARTIAL_PRESSURE_BORON_CONTROL_PRECISION',
  21286: 'PARTIAL_PRESSURE_BORON_CONTROL_AGILITY',
  21287: 'PARTIAL_PRESSURE_SULFUR_CONTROL_ACCURACY',
  21288: 'PARTIAL_PRESSURE_SULFUR_CONTROL_PRECISION',
  21289: 'PARTIAL_PRESSURE_SULFUR_CONTROL_AGILITY',
  21290: 'PARTIAL_PRESSURE_OXYGEN_PERCENT_CONTROL_ACCURACY',
  21291: 'PARTIAL_PRESSURE_OXYGEN_PERCENT_CONTROL_PRECISION',
  21292: 'PARTIAL_PRESSURE_OXYGEN_PERCENT_CONTROL_AGILITY',
  21293: 'PARTIAL_PRESSURE_CARBON_PERCENT_CONTROL_ACCURACY',
  21294: 'PARTIAL_PRESSURE_CARBON_PERCENT_CONTROL_PRECISION',
  21295: 'PARTIAL_PRESSURE_CARBON_PERCENT_CONTROL_AGILITY',
  21296: 'PARTIAL_PRESSURE_BORON_PERCENT_CONTROL_ACCURACY',
  21297: 'PARTIAL_PRESSURE_BORON_PERCENT_CONTROL_PRECISION',
  21298: 'PARTIAL_PRESSURE_BORON_PERCENT_CONTROL_AGILITY',
  21299: 'PARTIAL_PRESSURE_SULFUR_PERCENT_CONTROL_ACCURACY',
  21300: 'PARTIAL_PRESSURE_SULFUR_PERCENT_CONTROL_PRECISION',
  21301: 'PARTIAL_PRESSURE_SULFUR_PERCENT_CONTROL_AGILITY',
  21302: 'ETHANE_C2H6_FLOW_CONTROL_ACCURACY',
  21303: 'ETHANE_C2H6_FLOW_CONTROL_AGILITY',
  21304: 'ETHANE_C2H6_FLOW_CONTROL_PRECISION',
  21305: 'PARTIAL_PRESSURE_ETHANE_C2H6_CONTROL_ACCURACY',
  21306: 'PARTIAL_PRESSURE_ETHANE_C2H6_CONTROL_AGILITY',
  21307: 'PARTIAL_PRESSURE_ETHANE_C2H6_CONTROL_PRECISION',
  21308: 'PARTIAL_PRESSURE_ETHANE_C2H6_PERCENT_CONTROL_ACCURACY',
  21309: 'PARTIAL_PRESSURE_ETHANE_C2H6_PERCENT_CONTROL_AGILITY',
  21310: 'PARTIAL_PRESSURE_ETHANE_C2H6_PERCENT_CONTROL_PRECISION',
  21311: 'PARTIAL_PRESSURE_METHANE_CH4_CONTROL_ACCURACY',
  21312: 'PARTIAL_PRESSURE_METHANE_CH4_CONTROL_AGILITY',
  21313: 'PARTIAL_PRESSURE_METHANE_CH4_CONTROL_PRECISION',
  21314: 'PARTIAL_PRESSURE_METHANE_CH4_PERCENT_CONTROL_ACCURACY',
  21315: 'PARTIAL_PRESSURE_METHANE_CH4_PERCENT_CONTROL_AGILITY',
  21316: 'PARTIAL_PRESSURE_METHANE_CH4_PERCENT_CONTROL_PRECISION',
  21317: 'HEAT_LOSS_AT_500C',
  21318: 'HIGH_VACUUM_PRESSURE_CONTROL_ACCURACY',
  21319: 'HIGH_VACUUM_PRESSURE_CONTROL_AGILITY',
  21320: 'HIGH_VACUUM_PRESSURE_CONTROL_PRECISION',
  21321: 'LOW_VACUUM_PRESSURE_CONTROL_ACCURACY',
  21322: 'LOW_VACUUM_PRESSURE_CONTROL_AGILITY',
  21323: 'LOW_VACUUM_PRESSURE_CONTROL_PRECISION',
  21324: 'VACUUM_TIME_TO_CROSS_OVER',
  21325: 'VACUUM_MAX_LEAK_UP_RATE',
  21326: 'LEAD_TIME_CONTROL_ACCURACY',
  21327: 'LEAD_TIME_CONTROL_AGILITY',
  21328: 'LEAD_TIME_CONTROL_PRECISION',
  21329: 'BELT_SPEED_CONTROL_ACCURACY',
  21330: 'BELT_SPEED_CONTROL_AGILITY',
  21331: 'BELT_SPEED_CONTROL_PRECISION',
  21900: 'PEARSON_CORRELATION',
  21901: 'SPEARMAN_CORRELATION',
  21920: 'USER_DEFINED_080',
  21921: 'USER_DEFINED_079',
  21922: 'USER_DEFINED_078',
  21923: 'USER_DEFINED_077',
  21924: 'USER_DEFINED_076',
  21925: 'USER_DEFINED_075',
  21926: 'USER_DEFINED_074',
  21927: 'USER_DEFINED_073',
  21928: 'USER_DEFINED_072',
  21929: 'USER_DEFINED_071',
  21930: 'USER_DEFINED_070',
  21931: 'USER_DEFINED_069',
  21932: 'USER_DEFINED_068',
  21933: 'USER_DEFINED_067',
  21934: 'USER_DEFINED_066',
  21935: 'USER_DEFINED_065',
  21936: 'USER_DEFINED_064',
  21937: 'USER_DEFINED_063',
  21938: 'USER_DEFINED_062',
  21939: 'USER_DEFINED_061',
  21940: 'USER_DEFINED_060',
  21941: 'USER_DEFINED_059',
  21942: 'USER_DEFINED_058',
  21943: 'USER_DEFINED_057',
  21944: 'USER_DEFINED_056',
  21945: 'USER_DEFINED_055',
  21946: 'USER_DEFINED_054',
  21947: 'USER_DEFINED_053',
  21948: 'USER_DEFINED_052',
  21949: 'USER_DEFINED_051',
  21950: 'USER_DEFINED_050',
  21951: 'USER_DEFINED_049',
  21952: 'USER_DEFINED_048',
  21953: 'USER_DEFINED_047',
  21954: 'USER_DEFINED_046',
  21955: 'USER_DEFINED_045',
  21956: 'USER_DEFINED_044',
  21957: 'USER_DEFINED_043',
  21958: 'USER_DEFINED_042',
  21959: 'USER_DEFINED_041',
  21960: 'USER_DEFINED_040',
  21961: 'USER_DEFINED_039',
  21962: 'USER_DEFINED_038',
  21963: 'USER_DEFINED_037',
  21964: 'USER_DEFINED_036',
  21965: 'USER_DEFINED_035',
  21966: 'USER_DEFINED_034',
  21967: 'USER_DEFINED_033',
  21968: 'USER_DEFINED_032',
  21969: 'USER_DEFINED_031',
  21970: 'USER_DEFINED_030',
  21971: 'USER_DEFINED_029',
  21972: 'USER_DEFINED_028',
  21973: 'USER_DEFINED_027',
  21974: 'USER_DEFINED_026',
  21975: 'USER_DEFINED_025',
  21976: 'USER_DEFINED_024',
  21977: 'USER_DEFINED_023',
  21978: 'USER_DEFINED_022',
  21979: 'USER_DEFINED_021',
  21980: 'USER_DEFINED_020',
  21981: 'USER_DEFINED_019',
  21982: 'USER_DEFINED_018',
  21983: 'USER_DEFINED_017',
  21984: 'USER_DEFINED_016',
  21985: 'USER_DEFINED_015',
  21986: 'USER_DEFINED_014',
  21987: 'USER_DEFINED_013',
  21988: 'USER_DEFINED_012',
  21989: 'USER_DEFINED_011',
  21990: 'USER_DEFINED_010',
  21991: 'USER_DEFINED_009',
  21992: 'USER_DEFINED_008',
  21993: 'USER_DEFINED_007',
  21994: 'USER_DEFINED_006',
  21995: 'USER_DEFINED_005',
  21996: 'USER_DEFINED_004',
  21997: 'USER_DEFINED_003',
  21998: 'USER_DEFINED_002',
  21999: 'USER_DEFINED_001',
};

export type TProductionStatisticsMeaningMap = EJobFeatureMeaningMap | EConsumptionMeaningMap;
export type TProductionStatisticsMeaningMapWithSuffix = `${keyof typeof consumptionMeaningMap}_sum`
  | `${keyof typeof consumptionMeaningMap}_average`
  | `${keyof typeof jobFeatureMeaningMap}_sum`
  | `${keyof typeof jobFeatureMeaningMap}_average`;

export enum EBlobMeaning {
  MANUAL = 30000,
  DRAWING = 30001,
  CONFIGURATION = 30002,
  BILL_OF_MATERIALS = 30003,
  TENANT_LOGO = 32000,
}

export const BlobMeaningMap: Record<components['schemas']['BlobMeaning'], keyof typeof EBlobMeaning> = {
  30000: 'MANUAL',
  30001: 'DRAWING',
  30002: 'CONFIGURATION',
  30003: 'BILL_OF_MATERIALS',
  32000: 'TENANT_LOGO',
};

import {
  generalTerm,
} from '@/constants/locales/de.shared.ts';

export default {
  tabMenu: {
    view: 'Übersicht',
    alarm: 'Alarme',
    loadList: 'Jobs',
    recipe: generalTerm.recipe,
  },
};

import {
  TSteelGradeGroup,
} from './select-alloying-elements';

const steelGradeGroup: Record<TSteelGradeGroup, string> = {
  caseHardeningSteel: 'Case-hardening steel',
  nitridingSteel: 'Nitriding steel',
  temperingSteel: 'Tempering steel',
  bearingSteel: 'Bearing steel',
};

export default {
  placeholder: 'Select steel grade group',
  steelGradeGroup,
};

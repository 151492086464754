export default {
  assetDocumentation: 'Asset Dokumentation',
  noAssetBlobs: 'Asset {asset} hat keine Dokumentation.',
  overheadTime: 'Verwaltungszeit',
  never: 'Niemals',
  engineerNote: 'Zugewiesene Person',
  managerFeedback: 'Manager',
  deleteLastEventMessage: 'Sie sind dabei, das letzte Event in der Serie zu löschen. Dies führt zur Löschung der gesamten Serie. {text}',
  inProgressStateWarning: 'Bitte kennzeichnen Sie die Aufgaben als abgeschlossen oder ausgelassen. Falls Aufgaben ausgelassen wurden, hinterlassen Sie bitte für jede ausgelassene Aufgabe einen Kommentar.',
  inReviewStateWarning: 'Um ein Ereignis als „Abgeschlossen“ zu markieren, müssen alle Aufgaben als „Akzeptiert“ gekennzeichnet sein.',
};

import {
  EScaleOption,
  EConnectionStatus,
} from './rendering-engine-view.ts';

const connectionStatus: Record<EConnectionStatus, string> = {
  [EConnectionStatus.CONNECTING]: 'Stelle Verbindung her',
  [EConnectionStatus.CONNECTED]: 'Verbunden',
  [EConnectionStatus.WAITING_FOR_DATA]: 'Warte auf Daten',
  [EConnectionStatus.ERROR]: 'Fehler',
};

const scaleOptions: Record<EScaleOption, string> = {
  [EScaleOption.FIT_SCREEN]: 'An Bildschirm anpassen',
  [EScaleOption.FULL_SIZE]: '100%',
};

export default {
  connectionStatus,
  latestUpdate: 'Letzte Aktualisierung',
  scaleLabel: 'Skalierung',
  scaleOptions,
  viewLabel: 'Ansicht',
};

export default {
  engineerNote: 'Assigned person',
  managerFeedback: 'Manager',
  placeholder: 'Add a comment...',
  taskRejected: 'Task rejected',
  taskApproved: 'Task approved',
  accepted: 'Accepted',
  rejected: 'Rejected',
  reviewPending: 'Review pending',
  finished: 'Finished',
  omitted: 'Omitted',
  pending: 'Pending',
};

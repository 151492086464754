import moment from '@/utils/moment';
import 'moment-duration-format';
import {
  useCustomUserStorageStore,
} from '@/store/pinia/custom-user-storage.ts';
// import { i18n } from "@/plugins/i18n.ts";

export default {
  methods: {
    formatTime(value) {
      const hours = Math.floor(value / 60);
      const minutes = value % 60;
      let hourValue;
      let minuteValue;
      if (hours < 10) {
        hourValue = `0${hours}`;
      }
      if (minutes < 10) {
        minuteValue = `0${minutes}`;
      }
      if (hours >= 10) {
        hourValue = hours;
      }
      if (minutes >= 10) {
        minuteValue = minutes;
      }
      if (hours >= 100) {
        hourValue = hours;
      }
      return `${hourValue}:${minuteValue}`;
    },

    /**
     * Convert to date format supported by all browsers
     * @param {String|Number} date date string
     * @returns {String} returns the date in the format YYYY-MM-DD HH:mm
     */
    convertToDateTimeString(date) {
      return moment(date).format('YYYY-MM-DDTHH:mm');
    },
  },
  computed: {
    language() {
      return useCustomUserStorageStore().customUserSettings.language;
    },
  },
};

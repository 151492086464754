export const ALARMS_STATISTICS_PATH = 'alarm-statistics';
export const BLOB_STORE_PATH = 'blob-store';
export const BUSINESS_CONFIGURATION = 'business-configuration';
export const CHART = 'chart';
export const COMPONENTS_PATH = 'components';
export const CUSTOMER_REQUESTS_PATH = 'customer-requests';
export const DASHBOARD_LOCATION_PATH = 'location';
export const DOCUMENT_CATEGORIES_PATH = 'categories';
export const DOCUMENT_PATH = 'documents';
export const DOWNLOAD_PATH = 'download';
export const DRAWING_DOCUMENTS_PATH = 'documents/drawings';
export const EQUIPMENTS_AUTHORIZATION_PATH = 'authorization';
export const EQUIPMENTS_PATH = 'equipments';
export const EQUIPMENTS_OVERVIEW_PATH = 'overview';
export const INSTRUCTIONS_PATH = 'instructions';
export const LOCATIONS_PATH = 'locations';
export const MAINTENANCE_DASHBOARDS_PATH = 'maintenance-dashboard';
export const MAINTENANCE_CALENDAR_PATH = 'maintenance-calendar';
export const MODEL_PROCESS_PATH = 'model-process';
export const NOTES_PATH = 'notes';
export const NPM_CONTROLLER = 'npm-controller';
export const RECIPES_ON_DEMAND_MATERIALS_PATH = 'materials';
export const RECIPES_ON_DEMAND_RECIPES_PATH = 'recipes';
export const RECIPE_ON_DEMAND_PATH = 'recipe-on-demand';
export const SPARE_PARTS_PATH = 'spareparts';
export const SYSTEM_DETAILS_PATH = 'system-details';
export const TREE_NAVIGATION_PATH = 'tree-navigation';

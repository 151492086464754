import {
  TSourceType,
} from './modal-import-sensor-settings.ts';

const sourceType: Record<TSourceType, string> = {
  asset: 'Anderes Asset',
  file: 'Import aus Datei',
};

export default {
  copySensorSettings: 'Ausgewählte Sensor-Einstellungen importieren',
  sourceAssetId: 'Quelle',
  targetAssetId: 'Ziel',
  selectDataFormat: {
    placeholder: 'Datenformat wählen',
    selected: 'Datenformat: {dataFormat}',
  },
  selectSource: {
    placeholder: 'Quelle wählen',
    selected: 'Quelle: {assetDisplayName}',
    sentence: 'Sensor-Einstellungen von {source} zu {target} importieren.',
    selectSourceType: 'Quellentyp',
  },
  sourceType,
  verifyMapping: {
    headline: 'Eigenschaften prüfen',
    selectAttributes: 'Eigenschaften die importiert werden sollen',
  },
  migrateProgress: {
    headline: 'Importiere Sensor-Einstellungen',
  },
  errorTable: 'Migrationsfehler',
  unknownFileValidationError: 'Datei konnte nicht validiert werden. Das schema der Daten entspricht nicht den Erwartungen.',
  warning: {
    text: 'Änderungen die während der Migration vorgenommen werden sind nicht reversibel. Es wird empfohlen vorher eine Sicherung der Sensor-Einstellungen herunterzuladen. Diese kann anschließend wieder importiert werden um den jetzigen Stand wiederherzustellen.',
    downloadBackup: 'Download Backup',
  },
  next: 'Weiter',
  prev: 'Zurück',
};

import {
  FilterService,
} from 'primevue/api';
import {
  TGetMaterialListResponse,
} from '@/api/material.ts';
import {
  tokenizedContainsFilterFunction,
} from '@/utils/tokenized-contains-filter-function.ts';

FilterService.register('tokenizedContains', tokenizedContainsFilterFunction);

FilterService.register('materialFilter', (value: TGetMaterialListResponse['items'] | string, filter: string) => {
  const filterWords = filter.split(' ').map((word) => word.toLowerCase());

  if (Array.isArray(value)) {
    const result = !!value.find((item) => {
      return filterWords.every((word) => item.displayName.toLowerCase().includes(word));
    });
    return result;
  }

  return filterWords.every((word) => value.toLowerCase().includes(word));
});

// This is a fake filter that always returns the entire list.
// It is used to circumvent PrimeVue filtering,
// because the actual filtering occurs through the backend in a paginated list.
FilterService.register('alwaysTrueFakeFilter', () => true);

import PrmButton from 'primevue/button';
import {
  ButtonHTMLAttributes,
  FunctionalComponent,
  SVGAttributes,
} from 'vue';

type TPrmButtonProps = InstanceType<typeof PrmButton>['$props'];

export const qmulusButtonSeverity = [
  'primary',
  'secondary',
  'tertiary',
  'danger',
] as const;
export type TQmulusButtonSeverity = typeof qmulusButtonSeverity[number];

export interface IQmulusButtonProps {
  label: string,
  severity?: TQmulusButtonSeverity,
  icon?: string | FunctionalComponent<SVGAttributes, {}, any, {}>,
  disabled?: boolean,
  loading?: boolean,
  iconOnly?: boolean,
  isInlineAction?: boolean,
  tooltip?: string,
  /* If set to true, long text will be truncated and suffixed with ... */
  truncateLabel?: boolean,
  onClick?: ButtonHTMLAttributes['onClick'],
  /*
  If you need to stop the parent event "@click.stop" or "(event.preventDefault() & event.stopPropagation())"
  you should pass a function to onClickCapture.
  Here is an example of how to use it:
  <template>
    <QmulusButton
      label=""
      severity="primary"
      icon="pi pi-pencil"
      is-inline-action
      style="flex-shrink: 0;"
      @click-capture="triggerTask(option, $event)"
    />
  </template>
  <script setup lang="ts">
    function triggerTask(task: components['schemas']['TaskTemplateOverview'] | null, event: MouseEvent) {
      activeTask.value = task;
      showTaskModal.value = true;
      event.preventDefault();
      event.stopPropagation();
    }
  </script>
  */
  onClickCapture?: ButtonHTMLAttributes['onClick'],
}

export const severityMap: Record<TQmulusButtonSeverity, TPrmButtonProps> = {
  primary: {},
  secondary: {
    severity: 'secondary',
  },
  tertiary: {
    severity: 'secondary',
    outlined: true,
  },
  danger: {
    severity: 'danger',
    text: true,
  },
};

export default {
  engineerNote: 'Zugewiesene Person',
  managerFeedback: 'Manager',
  placeholder: 'Fügen Sie einen Kommentar hinzu...',
  taskRejected: 'Aufgabe abgelehnt',
  taskApproved: 'Aufgabe genehmigt',
  accepted: 'Akzeptiert',
  rejected: 'Abgelehnt',
  reviewPending: 'Prüfung ausstehend',
  finished: 'Abgeschlossen',
  omitted: 'Ausgelassen',
  pending: 'Ausstehend',
};

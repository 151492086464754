export default {
  tableHeader: 'Asset-Signale',
  showHidden: 'Versteckte Asset-Signale anzeigen',
  overFlowMenu: {
    tooltip: 'Weitere Funktionen',
    export: 'Export Sensor-Einstellungen',
    import: 'Import Sensor-Einstellungen',
  },
  placeholder: {
    meaningId: 'Bedeutung auswählen',
    meaningInstanceNumber: 'Instanznummer',
  },
};

import {
  generalTerm,
} from '@/constants/locales/en.shared.ts';

export default {
  tabMenu: {
    view: 'Layout',
    alarm: 'Alarms',
    loadList: 'Load list',
    recipe: generalTerm.recipe,
  },
};

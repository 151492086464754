import {
  Selector,
} from '@/pt9800/generated/zeus';

export const sharedOptionItem = Selector('SHARED_OptionItem')({
  id: true,
  value: true,
});

export const sharedDigitalOutput = Selector('SHARED_DigitalOutput')({
  name: true,
  value: true,
});

export const sharedRecipeVersion = Selector('SHARED_RecipeVersion')({
  id: true,
  startingTime: true,
  versionText: true,
});

export const sharedRecipeShortHeader = Selector('SHARED_RecipeShortHeader')({
  recipeName: true,
  actualStep: true,
  loadIdentifier: true,
  loadName: true,
  versions: sharedRecipeVersion,
});

export const sharedValueViewComponent = Selector('SHARED_ValueViewComponent')({
  id: true,
  type: true,
  value: true,
  status: true,
  digitsBeforeComma: true,
  digitsAfterComma: true,
  textBefore: true,
  textAfter: true,
  xPos: true,
  yPos: true,
  colorIndex: true,
  colorIndex0: true,
  colorIndex1: true,
  colorIndex2: true,
  colorIndex3: true,
  colorBG: true,
  numberFormat: true,
  fontSize: true,
});

export const sharedTextView = Selector('SHARED_TextView')({
  id: true,
  type: true,
  value: true,
  xPos: true,
  yPos: true,
  color: true,
  colorBG: true,
  fontSize: true,
});

export const sharedBackgroundView = Selector('SHARED_BackgroundView')({
  id: true,
  type: true,
  filePath: true,
  xPos: true,
  yPos: true,
  width: true,
  height: true,
});

export const hourInfo = Selector('HourInfo')({
  id: true,
  xPos: true,
  yPos: true,
  fontSize: true,
  text: true,
  hourType: true,
  zoneId: true,
  time: true,
  colorIndex: true,
  colorBG: true,
});

export const loadInfo = Selector('LoadInfo')({
  id: true,
  xPos: true,
  yPos: true,
  fontSize: true,
  text: true,
  time: true,
  infoType: true,
  colorIndex: true,
  colorBG: true,
});

export const sharedLoadButton = Selector('SHARED_LoadButton')({
  id: true,
  type: true,
  xPos: true,
  yPos: true,
  xPos0: true,
  yPos0: true,
  xPos1: true,
  yPos1: true,
  xPos2: true,
  yPos2: true,
  xPos3: true,
  yPos3: true,
  colorIndex: true,
  colorIndex0: true,
  colorIndex1: true,
  colorIndex2: true,
  colorIndex3: true,
  xSize: true,
  ySize: true,
  chamberId: true,
});

export const sharedBitmap = Selector('SHARED_Bitmap')({
  id: true,
  type: true,
  filePath: true,
  xPos: true,
  yPos: true,
  xPos0: true,
  yPos0: true,
  xPos1: true,
  yPos1: true,
  colorIndex: true,
  colorIndex0: true,
  colorIndex1: true,
  colorIndex2: true,
  colorIndex3: true,
  colorBG: true,
});

export const sharedGif = Selector('SHARED_Gif')({
  id: true,
  type: true,
  xPos0: true,
  yPos0: true,
  xPos1: true,
  yPos1: true,
  xPos2: true,
  yPos2: true,
  xPos3: true,
  yPos3: true,
  xPos: true,
  yPos: true,
  filePath: true,
});

export const sharedMovingPic = Selector('SHARED_MovingPic')({
  id: true,
  type: true,
  filePath: true,
  startX: true,
  startY: true,
  endX: true,
  endY: true,
  width: true,
  height: true,
  componentZero: true,
  componentSpan: true,
});

export const sharedLabel = Selector('SHARED_Label')({
  id: true,
  type: true,
  text: true,
  xPos: true,
  yPos: true,
  colorIndex: true,
  colorIndex0: true,
  colorIndex1: true,
  colorIndex2: true,
  colorIndex3: true,
  colorBG: true,
  fontSize: true,
});

export const sharedLabelPlus = Selector('SHARED_LabelPlus')({
  id: true,
  type: true,
  xPos: true,
  yPos: true,
  colorIndex0: true,
  colorIndex1: true,
  colorIndex2: true,
  colorIndex3: true,
  colorIndex: true,
  colorBG: true,
  text: true,
  text0: true,
  text1: true,
  text2: true,
  text3: true,
  fontSize: true,
});

export const sharedLine = Selector('SHARED_Line')({
  id: true,
  xPos: true,
  yPos: true,
  width: true,
  height: true,
  type: true,
  colorIndex: true,
});

export const sharedEditBox = Selector('SHARED_EditBox')({
  id: true,
  type: true,
  xPos: true,
  yPos: true,
  xPos0: true,
  yPos0: true,
  xPos1: true,
  yPos1: true,
  xPos2: true,
  yPos2: true,
  xPos3: true,
  yPos3: true,
  colorIndex: true,
  colorIndex0: true,
  colorIndex1: true,
  colorIndex2: true,
  colorIndex3: true,
  colorBG: true,
  xSize: true,
  ySize: true,
  focusOrderNumber: true,
  variableType: true,
  isEditable: true,
  digitsBeforeComma: true,
  digitsAfterComma: true,
  minValue: true,
  maxValue: true,
  numberFormat: true,
  fontSize: true,
  editValue: true,
  componentTypeId: true,
  userInputId: true,
  outputId: true,
});

export const sharedProgressBar = Selector('SHARED_ProgressBar')({
  id: true,
  type: true,
  xPos: true,
  yPos: true,
  xSize: true,
  ySize: true,
  fullColor: true,
  emptyColor: true,
  colorBG: true,
  value: true,
  minValue: true,
  maxValue: true,
});

export const sharedPlace = Selector('SHARED_Place')({
  id: true,
  xPos: true,
  yPos: true,
  xSize: true,
  ySize: true,
  layoutOrder: true,
  name: true,
  colorPalette: true,
  colorBG: true,
  colorIndex: true,
  systemColorBG: true,
});

export const sharedRectangle = Selector('SHARED_Rectangle')({
  id: true,
  type: true,
  xPos: true,
  yPos: true,
  xPos0: true,
  yPos0: true,
  xPos1: true,
  yPos1: true,
  xPos2: true,
  yPos2: true,
  xPos3: true,
  yPos3: true,
  colorIndex: true,
  colorIndex0: true,
  colorIndex1: true,
  colorIndex2: true,
  colorIndex3: true,
  colorBG: true,
  xSize: true,
  ySize: true,
});

export const button = Selector('Button')({
  id: true,
  type: true,
  xPos: true,
  yPos: true,
  userInputId: true,
  name: true,
  mode: true,
  userLevel: true,
  btnText0: true,
  btnText1: true,
  helpText0: true,
  helpText1: true,
  value: true,
});

export const furnaceViewComponents = Selector('FurnaceViewComponents')({
  id: true,
  name: true,
  background: sharedBackgroundView,
  text: sharedTextView,
  value: sharedValueViewComponent,
  hourInfo,
  loadInfo,
  loadButton: sharedLoadButton,
  bitmap: sharedBitmap,
  label: sharedLabel,
  labelPlus: sharedLabelPlus,
  rectangle: sharedRectangle,
  button,
  gif: sharedGif,
  movingPic: sharedMovingPic,
  place: sharedPlace,
  progressBar: sharedProgressBar,
  editBox: sharedEditBox,
  line: sharedLine,
  windowSizeId: true,
});

export const systemColor = Selector('SystemColor')({
  id: true,
  r: true,
  g: true,
  b: true,
});

export const sharedElementValueList = Selector('SHARED_ElementValueList')({
  c: true,
  si: true,
  mn: true,
  cr: true,
  ni: true,
  v: true,
  al: true,
  cu: true,
  p: true,
  s: true,
  mo: true,
  b: true,
  ti: true,
});

export const sharedMaterial = Selector('SHARED_Material')({
  carbonContent: true,
  alloyFactor: true,
  legFactorN: true,
  carbideLimit: true,
  temperature: true,
  specialNitrides: true,
  analysis: sharedElementValueList,
  co: true,
  h2: true,
  quenchingIntensity: true,
  representativeDiameter: true,
  requiredHardness: true,
  grainSize: true,
  endTemperature: true,
  targetSurfC: true,
  customerMaterialId: true,
});

export const sharedSpEntries = Selector('SHARED_SpEntries')({
  furnaceSwitchEntry: true,
  recipeDescription2: true,
  recipeDescription3: true,
  recipeDescription4: true,
  gasEntryValues: true,
});

export const actSetPoint = Selector('ActSetPoint')({
  userComponentId: true,
  valueType: true,
  meaning: true,
  name: true,
  control: sharedOptionItem,
  value: true,
  unit: true,
  toleranceMax: true,
  toleranceMin: true,
  alarmStartTimeInMin: true,
  alarmToleranceTimeInMin: true,
  actualValue: true,
  endControl: sharedOptionItem,
  endValue: true,
});

export const actRecipePositionHeader = Selector('ActRecipePositionHeader')({
  positionNo: true,
  chamberOption: sharedOptionItem,
  chamberOperationOption: sharedOptionItem,
  description: true,
  afterTimeInMin: true,
  estimatedDurationInMin: true,
  actualDurationInMin: true,
  afterOption: sharedOptionItem,
});

export const actRecipePosition = Selector('ActRecipePosition')({
  header: actRecipePositionHeader,
  setpoints: actSetPoint,
  digitalOutputList: sharedDigitalOutput,
  digitalOutputs: true,
  endAfter: true,
});

export const actRecipeHeader = Selector('ActRecipeHeader')({
  id: true,
  name: true,
  recipePrefix: true,
  description: true,
  comment: true,
  version: true,
  processOption: sharedOptionItem,
  processType: true,
  processDepth: true,
  processDepthAt: true,
  correction: true,
  diff: true,
  loadIdentifier: true,
  loadName: true,
  actualStep: true,
  startingTime: true,
  user: sharedOptionItem,
});

export const actRecipeVersion = Selector('ActRecipeVersion')({
  correctionProfile: true,
  header: actRecipeHeader,
  positions: actRecipePosition,
  spEntries: sharedSpEntries,
  material: sharedMaterial,
});

export const sharedOldCustomerMaterial = Selector('SHARED_OLD_CustomerMaterial')({
  id: true,
  name: true,
  description: true,
  normId: true,
  normName: true,
  normDescription: true,
  groupId: true,
  groupName: true,
  elementValues: sharedElementValueList,
  averageAlloyFactor: true,
  averageAlloyFactorN: true,
  carbideLimit: true,
  modifiedOn: true,
  modifiedBy: true,
});

export const metaDataSetPoint = Selector('MetaDataSetPoint')({
  name: true,
  userComponentId: true,
  meaning: true,
  relevant: true,
  unit: true,
  valueType: true,
});

export const controlMetadata = Selector('ControlMetadata')({
  setPoints: metaDataSetPoint,
  digitalInputs: metaDataSetPoint,
  digitalOutputs: metaDataSetPoint,
});

export const operationMetadata = Selector('OperationMetadata')({
  name: true,
  description: true,
  setPoints: metaDataSetPoint,
  digitalInputs: metaDataSetPoint,
  digitalOutputs: metaDataSetPoint,
});

export const metaDataChamberOption = Selector('MetaDataChamberOption')({
  chamberId: true,
  name: true,
  operationOptions: sharedOptionItem,
  recipeControl: controlMetadata,
  operationControl: operationMetadata,
  setPoints: metaDataSetPoint,
});

export const sharedOperation = Selector('SHARED_Operation')({
  id: true,
  name: true,
  operationType: true,
  comment: true,
  order: true,
  relevantForStat: true,
  mode: true,
  type: true,
  modifiedOn: true,
  modifiedBy: true,
});

export const recipeMetadata = Selector('RecipeMetadata')({
  afterOptions: sharedOptionItem,
  chamberOptions: metaDataChamberOption,
  controlOptions: sharedOptionItem,
  endControlOptions: sharedOptionItem,
  materialList: sharedOldCustomerMaterial,
  operationProcessList: sharedOperation,
});

export const furnaceView = Selector('FurnaceView')({
  workplaceId: true,
  state: true,
  hold: true,
  remote: true,
  connected: true,
  updateTime: true,
  colors: systemColor,
  // not needed for now
  // furnaceViews: ValueTypes.FurnaceViewComponents,
  recipeHeader: sharedRecipeShortHeader,
  furnaceViews: furnaceViewComponents,
});

export const shopLayoutMenuItems = Selector('TO_REMOVE_ShopLayoutMenuItem')({
  id: true,
  description: true,
  name: true,
});

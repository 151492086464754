import {
  ERouteName,
} from '@/router/route-name.ts';

export default {
  TabMenuLabel: {
    [ERouteName.ASSET_MANAGEMENT_DOCUMENT]: 'Documents',
    [ERouteName.ASSET_MANAGEMENT_MAIN]: 'Main',
    [ERouteName.ASSET_MANAGEMENT_MAIN_EQUIPMENT]: 'Main',
    [ERouteName.ASSET_MANAGEMENT_SENSOR_MAPPING]: 'Sensor mapping',
    [ERouteName.ASSET_MANAGEMENT_SENSOR_SETTINGS]: 'Sensor settings',
    [ERouteName.ASSET_MANAGEMENT_DATA_SOURCES]: 'Data sources',
  },
};

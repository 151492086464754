export enum ERouteName {
  ALARM_STATISTICS = 'alarm-statistics',
  ALARM_STATISTICS_EQUIPMENT = 'alarm-statistics-equipment',
  ANOMALY_DETECTION = 'anomaly-detection',
  ANOMALY_DETECTION_EQUIPMENT = 'anomaly-detection-equipment',
  ANOMALY_DETECTION_EQUIPMENT_SETTINGS = 'anomaly-detection-equipment-settings',
  ANOMALY_DETECTION_JOB_FEATURE_DETAILS = 'anomaly-detection-job-feature-details',
  APP_OVERVIEW = 'app-overview',
  ASSET_MANAGEMENT_DATA_SOURCES = 'asset_management_data_sources',
  ASSET_MANAGEMENT_DOCUMENT = 'asset_management_document',
  ASSET_MANAGEMENT_MAIN = 'asset_management_main',
  ASSET_MANAGEMENT_MAIN_EQUIPMENT = 'asset_management_main_equipment',
  ASSET_MANAGEMENT_OVERVIEW = 'asset_management_overview',
  ASSET_MANAGEMENT_OVERVIEW_DUMMY = 'asset_management_overview_dummy',
  ASSET_MANAGEMENT_SENSOR_MAPPING = 'asset_management_sensor_mapping',
  ASSET_MANAGEMENT_SENSOR_SETTINGS = 'asset_management_sensor_settings',
  CERTIFICATION = 'certification',
  CERTIFICATION_AUDIT = 'certification_audit',
  CERTIFICATION_AUDIT_CONFIGURATION = 'certification_audit_configuration',
  CERTIFICATION_AUDIT_DRAFT = 'certification_audit_draft',
  CERTIFICATION_AUDIT_RESULT = 'certification_audit_result',
  CERTIFICATION_QUALITY = 'certification_quality',
  CERTIFICATION_QUALITY_CONFIGURATION = 'certification_quality_configuration',
  CERTIFICATION_QUALITY_DRAFT = 'certification_quality_draft',
  CERTIFICATION_QUALITY_RESULT = 'certification_quality_result',
  CERTIFICATION_QUALITY_SETTINGS = 'certification_quality_settings',
  CERTIFICATION_QUALITY_STATISTICS = 'certification_quality_statistics',
  CUSTOMER = 'customer',
  CUSTOMER_CREATE = 'customer-create',
  CUSTOMER_DETAILS = 'customer-details',
  CUSTOMER_EDIT = 'customer-edit',
  GRAFANA = 'grafana',
  HISTORY_AND_TRENDS = 'history-and-trends',
  HOME = 'home',
  LIVE_APP = 'live_app',
  LIVE_APP_EQUIPMENT = 'live_app_equipment',
  LIVE_APP_LOCATION = 'live_app_location',
  LOGIN = 'login',
  MAINTENANCE_CALENDAR = 'maintenance-calendar',
  MAINTENANCE_CALENDAR_JOB_LIST = 'maintenance-calendar-job-list',
  MAINTENANCE_CALENDAR_REPORT_LIST = 'maintenance-calendar-report-list',
  MAINTENANCE_CALENDAR_TASK_LIST = 'maintenance-calendar-task-list',
  MATERIAL_CREATE = 'material-create',
  MATERIAL_DETAILS = 'material-details',
  MATERIAL_EDIT = 'material-edit',
  MATERIAL_GROUPS = 'material-groups',
  MATERIAL_LIST = 'material-list',
  MATERIAL_SETTINGS = 'material-settings',
  MODEL_BUILDER = 'model-builder',
  MODEL_BUILDER_MODEL_TRAINING = 'model-builder-model-training',
  MODEL_BUILDER_MODEL_DETAILS = 'model-builder-model-details',
  NOTIFICATION = 'notification',
  // TODO: Remove old operation-calendar if operation-calendar V2 is finished
  // OPERATION_CALENDAR = 'calendar',
  OPERATION_CALENDAR = 'operation-calendar',
  OPERATION_CALENDAR_MONTH = 'operation-calendar-month',
  OPERATION_CALENDAR_WEEK = 'operation-calendar-week',
  OPERATION_CALENDAR_YEAR = 'operation-calendar-year',
  OPERATION_CALENDAR_CALENDAR = 'operation-calendar-calendar',
  PART = 'parts',
  PART_CREATE = 'parts-create',
  PART_DETAILS = 'parts-details',
  PART_EDIT = 'parts-edit',
  PART_SETTINGS = 'parts-settings',
  RECIPE_BUILDER_PT9800_PROCESS_FLOW_LIST = 'recipe-builder-pt9800-process-flow-list',
  RECIPE_BUILDER_PT9800_PROCESS_FLOW_DETAILS = 'recipe-builder-pt9800-process-flow-details',
  PERFORMANCE_DASHBOARD = 'performance-dashboard',
  PERFORMANCE_DASHBOARD_DETAILS = 'performance-dashboard-details',
  PLAYGROUND = 'playground',
  PRIVACY_POLICY = 'privacy-policy',
  PRODUCTION_LOGS = 'production-logs',
  PRODUCTION_LOGS_JOB_DETAILS = 'production-logs-job-details',
  PRODUCTION_LOGS_JOB_COMPARISON = 'production-logs-job-comparison',
  PRODUCTION_STATISTICS = 'production-statistics',
  PRODUCTION_STATISTICS_DETAILS = 'production-statistics-details',
  PRODUCTION_STATISTICS_DETAILS_JOB_RECIPE = 'production-statistics-details-job-recipe',
  PT9800_CONFIGURATION = 'pt9800-configuration',
  RECIPE_BUILDER = 'recipe_builder',
  RECIPE_BUILDER_NPM_OVERVIEW = 'recipe_builder_npm_overview',
  RECIPE_BUILDER_PT9800_DETAILS = 'recipe_builder_pt9800_details',
  RECIPE_BUILDER_PT9800_RECIPE_CREATE = 'recipe_builder_pt9800_recipe_create',
  RECIPE_BUILDER_PT9800_RECIPE_DETAILS = 'recipe_builder_pt9800_recipe_details',
  RECIPE_BUILDER_PT9800_RECIPE_EDIT = 'recipe_builder_pt9800_recipe_edit',
  RECIPE_BUILDER_NPM_DETAIL = 'recipe_builder_npm_detail',
  RECIPE_ON_DEMAND = 'recipe-on-demand',
  RELEASE_NOTES = 'release-notes',
  REMOTE_CONTROL = 'remote-control',
  REMOTE_CONTROL_DETAILS = 'remote-control-details',
  ROLE_MANAGEMENT = 'role-management',
  SETTINGS = 'settings',
  SETTINGS_APPEARANCE = 'settings-appearance',
  SETTINGS_TENANT = 'settings-tenant',
  SETTINGS_NOTIFICATIONS_TENANT = 'settings-notifications-tenant',
  SETTINGS_NOTIFICATIONS_CHANNEL = 'settings-notifications-channel',
  SETTINGS_NOTIFICATIONS_CHANNEL_EMAIL_CREATE = 'settings-notifications-channel_email_create',
  SETTINGS_NOTIFICATIONS_CHANNEL_EMAIL_EDIT = 'settings-notifications-channel_email_edit',
  SETTINGS_NOTIFICATIONS_CHANNEL_EMAIL_DETAILS = 'settings-notifications-channel_email_details',
  SETTINGS_NOTIFICATIONS_CURRENT_USER = 'settings-notifications-current-user',
  SETTINGS_API_KEY = 'settings-api-key',
  SETTINGS_MEANINGS = 'settings-meanings',
  REPORT_BUILDER = 'report-builder',
  SHOP_LAYOUT = 'shop-layout',
  SHOP_LAYOUT_DETAILS = 'shop-layout-details',
  SIMULATOR = 'simulator',
  SIMULATOR_SETTINGS = 'simulator-settings',
  SIMULATOR_SETTINGS_MATERIAL_CALCULATION_MODEL_CREATE = 'simulator-settings-material-calculation-model_create',
  SIMULATOR_SETTINGS_MATERIAL_CALCULATION_MODEL_DETAILS = 'simulator-settings-material-calculation-model_details',
  SIMULATOR_SETTINGS_MATERIAL_CALCULATION_MODEL_EDIT = 'simulator-settings-material-calculation-model_edit',
  SIMULATOR_SETTINGS_QUENCHING_MEDIA_CREATE = 'simulator-settings-quenching-media_create',
  SIMULATOR_SETTINGS_QUENCHING_MEDIA_DETAILS = 'simulator-settings-quenching-media_details',
  SIMULATOR_SETTINGS_QUENCHING_MEDIA_EDIT = 'simulator-settings-quenching-media_edit',
  UPC_PRO = 'upc-pro',
  UPC_PRO_CARBON_OXYGEN = 'upc-pro-carbon-oxygen',
  USER_MANAGEMENT = 'user-management',
}

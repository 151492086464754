// @ts-ignore
import {
  AxiosResponse,
} from 'axios';
// @ts-ignore
import {
  paths,
} from '@/types/openapi_pt9800';
// @ts-ignore
import {
  TApiPayload,
} from '@/api/type/export';
// @ts-ignore
import {
  axiosClientRestPT9800,
} from '@/api/client';
// @ts-ignore
import {
  interpolateUrl,
} from '@/utils/url.ts';
// @ts-ignore
import {
  QueryKey,
} from '@tanstack/vue-query';

export const TRunBenchmarkPath = '/{ptDbId}/gui/benchmark';
export type TRunBenchmarkPath = paths['/{ptDbId}/gui/benchmark']['get']['parameters']['path'];
export type TRunBenchmarkPayload = TApiPayload<TRunBenchmarkPath, undefined, undefined>;
export type TRunBenchmarkResponse200 = paths['/{ptDbId}/gui/benchmark']['get']['responses']['200']['content']['text/plain'];
export type TRunBenchmarkResponse = TRunBenchmarkResponse200;
export const runBenchmarkQueryKey = (ptDbId: QueryKey[number]): QueryKey => ([...["gui","benchmark"], ptDbId]);
export function runBenchmark(payload: TRunBenchmarkPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TRunBenchmarkResponse>> {
  return axiosClientRestPT9800.get(interpolateUrl(TRunBenchmarkPath, payload.path), { signal: abortSignal });
}

export const TGetShopLayoutViewPath = '/{ptDbId}/gui/shop/{shopWpId}';
export type TGetShopLayoutViewPath = paths['/{ptDbId}/gui/shop/{shopWpId}']['get']['parameters']['path'];
export type TGetShopLayoutViewPayload = TApiPayload<TGetShopLayoutViewPath, undefined, undefined>;
export type TGetShopLayoutViewResponse200 = paths['/{ptDbId}/gui/shop/{shopWpId}']['get']['responses']['200']['content']['*/*'];
export type TGetShopLayoutViewResponse = TGetShopLayoutViewResponse200;
export const getShopLayoutViewQueryKey = (ptDbId: QueryKey[number], shopWpId: QueryKey[number]): QueryKey => ([...["gui","shop"], ptDbId, shopWpId]);
export function getShopLayoutView(payload: TGetShopLayoutViewPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetShopLayoutViewResponse>> {
  return axiosClientRestPT9800.get(interpolateUrl(TGetShopLayoutViewPath, payload.path), { signal: abortSignal });
}

export const TGetAllWorkplaceOverviewsPath = '/{ptDbId}/gui/workplace/{wpId}';
export type TGetAllWorkplaceOverviewsPath = paths['/{ptDbId}/gui/workplace/{wpId}']['get']['parameters']['path'];
export type TGetAllWorkplaceOverviewsPayload = TApiPayload<TGetAllWorkplaceOverviewsPath, undefined, undefined>;
export type TGetAllWorkplaceOverviewsResponse200 = paths['/{ptDbId}/gui/workplace/{wpId}']['get']['responses']['200']['content']['*/*'];
export type TGetAllWorkplaceOverviewsResponse = TGetAllWorkplaceOverviewsResponse200;
export const getAllWorkplaceOverviewsQueryKey = (ptDbId: QueryKey[number], wpId: QueryKey[number]): QueryKey => ([...["gui","workplace"], ptDbId, wpId]);
export function getAllWorkplaceOverviews(payload: TGetAllWorkplaceOverviewsPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetAllWorkplaceOverviewsResponse>> {
  return axiosClientRestPT9800.get(interpolateUrl(TGetAllWorkplaceOverviewsPath, payload.path), { signal: abortSignal });
}

export const TGetWorkPlaceOverviewByIdPath = '/{ptDbId}/gui/workplace/{wpId}/{overviewId}';
export type TGetWorkPlaceOverviewByIdPath = paths['/{ptDbId}/gui/workplace/{wpId}/{overviewId}']['get']['parameters']['path'];
export type TGetWorkPlaceOverviewByIdPayload = TApiPayload<TGetWorkPlaceOverviewByIdPath, undefined, undefined>;
export type TGetWorkPlaceOverviewByIdResponse200 = paths['/{ptDbId}/gui/workplace/{wpId}/{overviewId}']['get']['responses']['200']['content']['*/*'];
export type TGetWorkPlaceOverviewByIdResponse = TGetWorkPlaceOverviewByIdResponse200;
export const getWorkPlaceOverviewByIdQueryKey = (ptDbId: QueryKey[number], wpId: QueryKey[number], overviewId: QueryKey[number]): QueryKey => ([...["gui","workplace"], ptDbId, wpId, overviewId]);
export function getWorkPlaceOverviewById(payload: TGetWorkPlaceOverviewByIdPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TGetWorkPlaceOverviewByIdResponse>> {
  return axiosClientRestPT9800.get(interpolateUrl(TGetWorkPlaceOverviewByIdPath, payload.path), { signal: abortSignal });
}

export const TExecuteButtonActionPath = '/{ptDbId}/gui/workplace/{wpId}/{overviewId}/pressButton/{buttonUserKomponentId}';
export type TExecuteButtonActionPath = paths['/{ptDbId}/gui/workplace/{wpId}/{overviewId}/pressButton/{buttonUserKomponentId}']['put']['parameters']['path'];
export type TExecuteButtonActionPayload = TApiPayload<TExecuteButtonActionPath, undefined, undefined>;
export type TExecuteButtonActionResponse200 = paths['/{ptDbId}/gui/workplace/{wpId}/{overviewId}/pressButton/{buttonUserKomponentId}']['put']['responses']['200']['content']['*/*'];
export type TExecuteButtonActionResponse = TExecuteButtonActionResponse200;
export const executeButtonActionQueryKey = (ptDbId: QueryKey[number], wpId: QueryKey[number], overviewId: QueryKey[number], buttonUserKomponentId: QueryKey[number]): QueryKey => ([...["gui","workplace","pressButton"], ptDbId, wpId, overviewId, buttonUserKomponentId]);
export function executeButtonAction(payload: TExecuteButtonActionPayload, abortSignal?: AbortSignal): Promise<AxiosResponse<TExecuteButtonActionResponse>> {
  return axiosClientRestPT9800.put(interpolateUrl(TExecuteButtonActionPath, payload.path), undefined, { signal: abortSignal });
}


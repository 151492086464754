export default {
  header: 'Bedeutungen importieren',
  selectFile: {
    placeholder: 'Quelle wählen',
    selected: 'Quelle: {fileName}',
  },
  verifyMapping: {
    headline: 'Eigenschaften prüfen',
    selectAttributes: 'Eigenschaften die importiert werden sollen',
    doImport: 'Importiere ausgewählte Bedeutungen',
  },
  migrateProgress: {
    headline: 'Importiere Bedeutungen',
  },
  warning: {
    text: 'Änderungen die während der Migration vorgenommen werden sind nicht reversibel. Es wird empfohlen vorher eine Sicherung der Bedeutungen herunterzuladen. Diese kann anschließend wieder importiert werden um den jetzigen Stand wiederherzustellen.',
    downloadBackup: 'Download Backup',
  },
  next: 'Weiter',
  prev: 'Zurück',
};

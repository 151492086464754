/* eslint-disable max-classes-per-file */
import {
  getAccessToken, clearAuthTokens,
} from 'axios-jwt';
import {
  useAppStore,
} from '@/store/pinia/app.ts';

export class TokenServiceValidationError extends Error {}
export class TokenServiceTokenNotFoundError extends Error {}

export const TokenService = {

  /**
   * @returns the stored access token
   */
  getAccessToken() {
    return getAccessToken();
  },

  /**
   * Clears local storage
   */
  clearTokens() {
    clearAuthTokens();
  },

  /**
   * Takes idToken from storage and it decodes it
   * @returns {Object} returns object resulted from decoded token
   * @throws {String} if decoding fails or decoded object is invalid
   */
  async decodedIdToken() {
    const token = this.getAccessToken();

    if (!token) {
      return Promise.reject(new TokenServiceTokenNotFoundError('Token not found.'));
    }

    const user = await useAppStore().loadCurrentUser(false);

    return {
      fullName: user.displayName,
      email: user.email,
      tiles: [],
      role: 0,
      id: user.userId,
    };
  },
};

import {
  TComponentPT9800Keys, TComponentPT9800Value,
  TComputedPT9800PlannedPartInput,
} from '@/types/openapi-type.ts';
import {
  generalTerm,
} from '@/constants/locales/de.shared.ts';

const processFlowManualCommand: TComponentPT9800Keys<'processFlowManualCommand'> = {
  acknowledged: 'Bestätigt',
  additionalInfoColumnCaption: 'Zusätzliche Informationen Titel',
  additionalInfoColumnValue: 'Zusätzliche Informationen Wert',
  idFieldName: 'ID-Feldname',
  idFieldValue: 'ID-Feldwert',
  ldArchivCrit1Name: 'Archiv-Crit1 Titel',
  ldArchivCrit1Value: 'Archiv-Crit1 Wert',
  orderNumberCaption: 'Bestellnummer Titel',
  orderNumberValue: 'Bestellnummer Wert',
  priority: 'Priorität',
  rowId: 'Zeilen-ID',
  source: 'Quelle',
  sourceWpId: 'Quell-WP-ID',
  target: 'Ziel',
  targetWpId: 'Ziel-WP-ID',
  wpId: 'Car-WP-ID',
};

const plannedPartInput: Partial<Record<keyof TComputedPT9800PlannedPartInput, string>> = {
  pos: 'Position',
  partNumber: 'Teilenummer',
  partDescription: 'Teilebeschreibung',
  orderNo: generalTerm.loadIdentifier,
  orderRemark: 'Bestellbemerkung',
  customerOrderNumber: 'Kundenbestellnummer',
  amount: 'Menge',
  weight: 'Gewicht',
  totalWeight: 'Gesamtgewicht',
};

const planLoadInput: Partial<TComponentPT9800Keys<'planLoadInput'>> = {
  workplaceWoNumber: generalTerm.loadIdentifier,
};

const recipeListItem: TComponentPT9800Keys<'recipeListItem'> = {
  id: 'id',
  description: generalTerm.description,
  recipe: generalTerm.recipe,
  recipePrefix: 'Rezeptpräfix',
  version: 'Rezept version',
};

const recipeItemHeader: Partial<TComponentPT9800Keys<'recipeItemHeader'>> = {
  recipeName: 'Name',
  recipePrefix: 'Gruppe',
  description: 'Beschreibung',
  processType: 'Prozess',
  processId: 'Betrieb',
  comment: 'Kommentar',
  diff: 'Aktivieren',
  correction: 'Korrektur',
};

const material: Partial<TComponentPT9800Keys<'material'>> = {
  carbonContent: 'Kohlenstoffgehalt',
  carbideLimit: 'Karbidgrenze',
  alloyFactor: 'Legierungsfaktor',
};

const recipeItemPosition: Partial<TComponentPT9800Keys<'recipeItemPosition'>> = {};

const processFlow: Partial<TComponentPT9800Keys<'processFlow'>> = {
  name: 'Name',
  description: 'Beschreibung',
  updateTimestamp: 'Letzte Aktualisierung',
  automatNo: 'Automat Nr',
  comment1: 'Kommentar1',
  comment2: 'Kommentar2',
  comment3: 'Kommentar3',
};

const processFlowListItem: Partial<TComponentPT9800Keys<'processFlowListItem'>> = {
  name: generalTerm.name,
  description: generalTerm.description,
};

const recipeItemPositionHeader: Partial<TComponentPT9800Keys<'recipeItemPositionHeader'>> = {
  positionNo: 'Schritt',
  description: 'Schrittname',
  chamberId: 'Kammer',
  chamberOperationId: 'Kammerbetrieb',
  afterId: 'Endbedingung',
  estimatedDurationInMin: 'Geschätzte Dauer',
};

const setPointItem: Partial<TComponentPT9800Keys<'setPointItem'>> & {
  unit: string,
} = {
  userComponentId: 'Sollwert',
  controlId: 'Ctrl',
  value: 'Wert',
  unit: 'Einheit',
  toleranceMax: 'Tol +',
  toleranceMin: 'Tol -',
  alarmStartTimeInMin: 'Zeit 1',
  alarmToleranceTimeInMin: 'Zeit 2',
  endControlId: 'Ctrl',
  endValue: 'Endbedingung',
};

const furnaceAlarm: Partial<TComponentPT9800Keys<'furnaceAlarm'>> = {
  wpName: 'Workplace',
  ackTime: 'Ack',
  alarmBit: 'Bit',
  alarmByte: 'Byte',
  endTime: 'Ende',
  gate: 'Gate',
  alarmNo: 'Id',
  message: 'Nachricht',
  priority: 'Priorität',
  startTime: 'Start',
};

const load: Partial<TComponentPT9800Keys<'load'>> = {
  name: generalTerm.jobDisplayName,
  startTime: generalTerm.jobStartedAtUtc,
  endTime: generalTerm.jobFinishedAtUtc,
  identifier: generalTerm.loadIdentifier,
  recipe: generalTerm.recipe,
  partsText: plannedPartInput.partDescription,
  workOrderNo: plannedPartInput.orderNo,
  orderRemark: plannedPartInput.orderRemark,
  status: 'Status',
};

const loadState: Record<TComponentPT9800Value<'load'>['status'], string> = {
  EXT_START: 'Gestartet (extern)',
  FINISHED: 'Beended',
  PLANNED: 'Geplant',
  RUNNING: 'Läuft',
  RUNNING_LOADING_ENDED: 'Läuft (Belanden abgeschlossen)',
  STARTED: 'Gestartet',
  STOPPED: 'Gestopped',
  TOO_OLD_NEVER_STARTED: 'Zu alt / Nie gestartet',
  UNKNOWN: 'Unbekannt',
  WAITING_FOR_CONDITIONS: 'Warte auf Startbedingung',
  WAITING_FOR_LOADING: 'Warte auf Beladung',
};

export default {
  furnaceAlarm,
  load,
  loadState,
  material,
  planLoadInput,
  plannedPartInput,
  processFlow,
  processFlowListItem,
  processFlowManualCommand,
  recipeItemHeader,
  recipeItemPosition,
  recipeItemPositionHeader,
  recipeListItem,
  setPointItem,
};

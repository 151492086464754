import {
  TagProps,
} from 'primevue/tag';

export enum EConnectionStatus {
  // We rely on the values of the Enum to be ordered from non-operational to operational
  ERROR = -1,
  CONNECTING = 0,
  WAITING_FOR_DATA = 1,
  CONNECTED = 2,
}

type TStatusDetails = {
  icon: string,
  severity: TagProps['severity'],
}
export const CONNECTION_STATUS_MAP: Record<EConnectionStatus, TStatusDetails> = {
  [EConnectionStatus.ERROR]: {
    icon: 'pi pi-times-circle',
    severity: 'danger',
  },
  [EConnectionStatus.CONNECTING]: {
    icon: 'pi pi-spin pi-spinner',
    severity: 'warning',
  },
  [EConnectionStatus.WAITING_FOR_DATA]: {
    icon: 'pi pi-spin pi-spinner',
    severity: 'warning',
  },
  [EConnectionStatus.CONNECTED]: {
    icon: 'pi pi-check',
    severity: 'success',
  },
};

export enum EScaleOption {
  FIT_SCREEN,
  FULL_SIZE,
}

interface IView {
  endpoint: string,
  parameters: Object
}

export interface IShopLayoutView extends IView {
  endpoint: 'shop-layout',
  parameters: {
    ptDbId: number,
    shopWpId: number,
  }
}

export interface IRemoteControlView extends IView {
  endpoint: 'remote-control',
  parameters: {
    assetId: number,
  }
}

export type TView = IShopLayoutView | IRemoteControlView;

export default {
  placeholder: 'Select a color',
  color: {
    custom: 'Custom',
    105: 'Grey 50',
    106: 'Grey 100',
    107: 'Grey 200',
    108: 'Grey 300',
    109: 'Grey 400',
    110: 'Grey 500',
    111: 'Grey 600',
    112: 'Grey 700',
    113: 'Grey 800',
    114: 'Grey 900',
    115: 'Blue 50',
    116: 'Blue 100',
    117: 'Blue 200',
    118: 'Blue 300',
    119: 'Blue 400',
    120: 'Blue 500',
    121: 'Blue 600',
    122: 'Blue 700',
    123: 'Blue 800',
    124: 'Blue 900',
    125: 'Green 50',
    126: 'Green 100',
    127: 'Green 200',
    128: 'Green 300',
    129: 'Green 400',
    130: 'Green 500',
    131: 'Green 600',
    132: 'Green 700',
    133: 'Green 800',
    134: 'Green 900',
    135: 'Yellow 50',
    136: 'Yellow 100',
    137: 'Yellow 200',
    138: 'Yellow 300',
    139: 'Yellow 400',
    140: 'Yellow 500',
    141: 'Yellow 600',
    142: 'Yellow 700',
    143: 'Yellow 800',
    144: 'Yellow 900',
    145: 'Cyan 50',
    146: 'Cyan 100',
    147: 'Cyan 200',
    148: 'Cyan 400',
    149: 'Cyan 500',
    150: 'Cyan 600',
    151: 'Cyan 700',
    152: 'Cyan 800',
    153: 'Cyan 900',
    154: 'Pink 50',
    155: 'Pink 100',
    156: 'Pink 200',
    157: 'Pink 300',
    158: 'Pink 400',
    159: 'Pink 500',
    160: 'Pink 600',
    161: 'Pink 700',
    162: 'Pink 800',
    163: 'Pink 900',
    164: 'Indigo 50',
    165: 'Indigo 100',
    167: 'Indigo 200',
    168: 'Indigo 300',
    169: 'Indigo 400',
    170: 'Indigo 500',
    171: 'Indigo 600',
    172: 'Indigo 700',
    173: 'Indigo 800',
    174: 'Indigo 900',
    175: 'Teal 50',
    176: 'Teal 100',
    177: 'Teal 200',
    178: 'Teal 300',
    179: 'Teal 400',
    180: 'Teal 500',
    181: 'Teal 600',
    182: 'Teal 700',
    183: 'Teal 800',
    184: 'Teal 900',
    185: 'Orange 50',
    186: 'Orange 100',
    187: 'Orange 200',
    188: 'Orange 400',
    189: 'Orange 500',
    190: 'Orange 600',
    191: 'Orange 700',
    192: 'Orange 800',
    193: 'Orange 900',
    194: 'Bluegrey 50',
    195: 'Bluegrey 100',
    196: 'Bluegrey 200',
    197: 'Bluegrey 300',
    198: 'Bluegrey 400',
    199: 'Bluegrey 500',
    200: 'Bluegrey 600',
    201: 'Bluegrey 700',
    202: 'Bluegrey 800',
    203: 'Bluegrey 900',
    204: 'Purple 50',
    205: 'Purple 100',
    206: 'Purple 200',
    207: 'Purple 300',
    208: 'Purple 400',
    209: 'Purple 500',
    210: 'Purple 600',
    211: 'Purple 700',
    212: 'Purple 800',
    213: 'Purple 900',
    214: 'Red 50',
    215: 'Red 100',
    216: 'Red 200',
    217: 'Red 300',
    218: 'Red 400',
    219: 'Red 500',
    220: 'Red 600',
    221: 'Red 700',
    222: 'Red 800',
    223: 'Red 900',
  },
};

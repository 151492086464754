import npmApiTranslations from './en.api.npm.ts';
import pt9800ApiTranslations from './en.api.pt9800.ts';
import {
  ETimeseriesMeaning,
  TComponentKeys,
  TComponentLegacyKeys, TComponentValue,
} from '@/types/openapi-type.ts';
import {
  generalTerm,
} from '@/constants/locales/en.shared.ts';
import {
  EMeasurementUnitType,
} from '@/types/measurement.ts';
import {
  EAuditResultType,
} from '@/types/audit-result.ts';
import {
  EBlobMeaning,
  EConsumptionMeaningMap, EJobFeatureMeaningMap,
  EPhysicalPropertyMeaningMap,
} from '@/types/meaning-type.ts';
import {
  EPhysicalDimension,
} from '@/types/physical-dimension.ts';
import {
  ECalendarEventType,
  ECalendarPeriod,
  EMaintenancePriority,
  EMaintenanceRecurrenceEndCondition,
  EMaintenanceRecurrenceMonthDayDimension,
  EMaintenanceRecurrenceMonthDayValue,
  EMaintenanceState,
} from '@/types/calendar.ts';
import {
  EHierarchy,
} from '@/types/asset-tree.ts';
import {
  ENotificationChannelType,
} from '@/types/notification-channel-type.ts';
import {
  EActivityCoefficients,
  ECarburizingCarbonControlType,
  ECarburizingEndControlType, ECarburizingNitrogenControlType,
  ECarburizingRecipeSimulationValuesType,
  ECarburizingSimulationPartShape,
  ECarburizingSimulatorRecipeType,
  ECarburizingTemperatureControlType, ECarburizingVolumeControlType,
  ESimulationStep,
} from '@/types/simulator.ts';
import {
  EAssetProfileStatus,
} from '@/types/asset-profile-status.ts';
import {
  EAssetProfileCombinator,
} from '@/types/asset-profile-combinator.ts';
import {
  EObservationType,
} from '@/types/observation-type.ts';
import {
  EChamberType, EHeatTreatmentProcessType,
} from '@/types/chamber.ts';
import {
  EAppGroupCommercial, EAppGroupSemantic, EAppId,
} from '@/types/app-type.ts';
import {
  components,
} from '@/types/openapi.ts';
import {
  ESubscriptionTreeLabel,
} from '@/types/notification.ts';
import {
  EDataFormat,
} from '@/types/metadata.ts';
import {
  TFunctionCategory,
  TFunctionDefinition,
  TOperatorDefinition,
} from '@/types/function.ts';
import {
  EModelLifecycleState,
} from '@/types/model-builder.ts';

const SparePart: Partial<TComponentKeys<'SparePart'>> = {
  displayName: generalTerm.name,
  description: generalTerm.description,
  manufacturerId: generalTerm.manufacturer,
  manufacturerDisplayName: generalTerm.manufacturer,
};

const HeatTreatmentCapability: Partial<TComponentKeys<'HeatTreatmentCapability'>> = {
  heatTreatmentProcess: generalTerm.heatTreatmentProcess,
  preference: generalTerm.preference,
};

const Chamber: Partial<TComponentKeys<'Chamber'>> = {
  displayName: generalTerm.name,
  description: generalTerm.description,
};

const MeasurementDisplayName: Record<EMeasurementUnitType, string> = {
  [EMeasurementUnitType.AMPERE]: 'Ampere',
  [EMeasurementUnitType.ATMOSPHERE]: 'Atmosphere',
  [EMeasurementUnitType.BAR]: 'Bar',
  [EMeasurementUnitType.BAR_PER_MINUTE]: 'Bar per minute',
  [EMeasurementUnitType.BRINELL_HARDNESS]: 'Brinell hardness',
  [EMeasurementUnitType.BRINELL_HARDNESS_10_1500_10]: 'Brinell hardness 10/1500/10',
  [EMeasurementUnitType.BRINELL_HARDNESS_10_1500_15]: 'Brinell hardness 10/1500/15',
  [EMeasurementUnitType.BRINELL_HARDNESS_10_3000_10]: 'Brinell hardness 10/3000/10',
  [EMeasurementUnitType.BRINELL_HARDNESS_10_3000_15]: 'Brinell hardness 10/3000/15',
  [EMeasurementUnitType.BRINELL_HARDNESS_10_500_10]: 'Brinell hardness 10/500/10',
  [EMeasurementUnitType.BRINELL_HARDNESS_10_500_15]: 'Brinell hardness 10/500/15',
  [EMeasurementUnitType.BRINELL_HARDNESS_6_1500_10]: 'Brinell hardness 6/1500/10',
  [EMeasurementUnitType.BRINELL_HARDNESS_6_1500_15]: 'Brinell hardness 6/1500/15',
  [EMeasurementUnitType.BRINELL_HARDNESS_6_3000_10]: 'Brinell hardness 6/3000/10',
  [EMeasurementUnitType.BRINELL_HARDNESS_6_3000_15]: 'Brinell hardness 6/3000/15',
  [EMeasurementUnitType.BRINELL_HARDNESS_6_500_10]: 'Brinell hardness 6/500/10/',
  [EMeasurementUnitType.BRINELL_HARDNESS_6_500_15]: 'Brinell hardness 6/500/15',
  [EMeasurementUnitType.BTU]: 'British thermal unit',
  [EMeasurementUnitType.BTU_PER_HOUR]: 'British thermal unit per hour',
  [EMeasurementUnitType.BTU_PER_MINUTE]: 'British thermal unit per minute',
  [EMeasurementUnitType.CUBIC_CENTIMETRE_PER_MINUTE]: 'Cubic centimetre per minute',
  [EMeasurementUnitType.CUBIC_FEET]: 'Cubic feet',
  [EMeasurementUnitType.CUBIC_FEET_PER_HOUR]: 'Cubic feet per hour',
  [EMeasurementUnitType.CUBIC_FEET_PER_MINUTE]: 'Cubic feet per minute',
  [EMeasurementUnitType.CUBIC_METRES]: 'Cubic meters',
  [EMeasurementUnitType.CUBIC_METRE_PER_HOUR]: 'Cubic meters per hour',
  [EMeasurementUnitType.CUBIC_METRE_PER_MINUTE]: 'Cubic meters per minute',
  [EMeasurementUnitType.CUBIC_METRE_PER_SECOND]: 'Cubic meters per second',
  [EMeasurementUnitType.DAY]: 'Day',
  [EMeasurementUnitType.DEGREES_CELSIUS]: 'Degrees celsius',
  [EMeasurementUnitType.DEGREES_CELSIUS_PER_MINUTE]: 'Degrees celsius per minute',
  [EMeasurementUnitType.DEGREES_FAHRENHEIT]: 'Degrees fahrenheit',
  [EMeasurementUnitType.DEGREES_FAHRENHEIT_PER_MINUTE]: 'Degrees fahrenheit per minute',
  [EMeasurementUnitType.DEGREES_KELVIN]: 'Degrees kelvin',
  [EMeasurementUnitType.DELTA_DEGREES_CELSIUS]: 'Delta degrees celsius',
  [EMeasurementUnitType.DELTA_DEGREES_FAHRENHEIT]: 'Delta degrees fahrenheit',
  [EMeasurementUnitType.DELTA_DEGREES_KELVIN]: 'Delta degrees kelvin',
  [EMeasurementUnitType.FEET_OF_WATER_COLUMN]: 'Feet of water column',
  [EMeasurementUnitType.GALLONS_PER_HOUR]: 'Gallons per hour',
  [EMeasurementUnitType.GALLONS_PER_MINUTE]: 'Gallons per minute',
  [EMeasurementUnitType.GRAM]: 'Gram',
  [EMeasurementUnitType.GRAM_PER_SECOND]: 'Gram per second',
  [EMeasurementUnitType.HECTO_PASCAL]: 'Hectopascal',
  [EMeasurementUnitType.HORSEPOWER]: 'Horsepower',
  [EMeasurementUnitType.HOUR]: 'Hour',
  [EMeasurementUnitType.INCH]: 'Inch',
  [EMeasurementUnitType.INCH_OF_MERCURY]: 'Inch of mercury',
  [EMeasurementUnitType.INCH_OF_WATER_COLUMN]: 'Inch of water column',
  [EMeasurementUnitType.KILOGRAM]: 'Kilogram',
  [EMeasurementUnitType.KILOGRAM_PER_HOUR]: 'Kilogram per hour',
  [EMeasurementUnitType.KILOGRAM_PER_MINUTE]: 'Kilogram per minute',
  [EMeasurementUnitType.KILOGRAM_PER_SECOND]: 'Kilogram per second',
  [EMeasurementUnitType.KILOMETER]: 'Kilometer',
  [EMeasurementUnitType.KILOVOLT]: 'Kilovolt',
  [EMeasurementUnitType.KILOVOLT_AMPERE]: 'Kilovolt ampere',
  [EMeasurementUnitType.KILOWATT]: 'Kilowatt',
  [EMeasurementUnitType.KILOWATT_HOUR]: 'Kilowatt-hour',
  [EMeasurementUnitType.KILO_PASCAL]: 'Kilo pascal',
  [EMeasurementUnitType.KNOOP_HARDNESS]: 'Knoop hardness',
  [EMeasurementUnitType.KNOOP_HARDNESS_100]: 'Knoop hardness 100',
  [EMeasurementUnitType.KNOOP_HARDNESS_200]: 'Knoop hardness 200',
  [EMeasurementUnitType.KNOOP_HARDNESS_500]: 'Knoop hardness 500',
  [EMeasurementUnitType.KNOOP_HARDNESS_50]: 'Knoop hardness 05',
  [EMeasurementUnitType.LITRE]: 'Litre',
  [EMeasurementUnitType.LITRE_PER_HOUR]: 'Litre per hour',
  [EMeasurementUnitType.LITRE_PER_MINUTE]: 'Litre per mitute',
  [EMeasurementUnitType.MEGAWATT]: 'Megawatt',
  [EMeasurementUnitType.METRES_PER_MINUTE]: 'Metre per minute',
  [EMeasurementUnitType.METRES_PER_SECOND]: 'Metre per second',
  [EMeasurementUnitType.METRE]: 'Metre',
  [EMeasurementUnitType.MICROBAR]: 'Microbar',
  [EMeasurementUnitType.MICROBAR_PER_MINUTE]: 'Microbar per minute',
  [EMeasurementUnitType.MICRON]: 'Micron',
  [EMeasurementUnitType.MICRON_HG_PER_HOUR]: 'Micron HG per hour',
  [EMeasurementUnitType.MICRON_HG_PER_MINUTE]: 'Micron HG per minute',
  [EMeasurementUnitType.MICRON_OF_MERCURY]: 'Micron of  mercury',
  [EMeasurementUnitType.MILLIBAR]: 'Millibar',
  [EMeasurementUnitType.MILLIBAR_PER_MINUTE]: 'Millibar per minute',
  [EMeasurementUnitType.MILLIGRAM]: 'Milligram',
  [EMeasurementUnitType.MILLILITRE]: 'Millilitre',
  [EMeasurementUnitType.MILLILITRE_PER_MINUTE]: 'Millilitre per minute',
  [EMeasurementUnitType.MILLIMETRE]: 'Millimetre',
  [EMeasurementUnitType.MILLIMETRE_OF_MERCURY]: 'Millimetre of mercury',
  [EMeasurementUnitType.MILLIMETRE_OF_WATER_COLUMN]: 'Millimetre of water column',
  [EMeasurementUnitType.MILLIMETRE_PER_SECOND]: 'Millilitre per second',
  [EMeasurementUnitType.MILLITORR_PER_HOUR]: 'Millitorr per hour',
  [EMeasurementUnitType.MILLIVOLT]: 'Millivolt',
  [EMeasurementUnitType.MILLI_AMPERE]: 'Milli ampere',
  [EMeasurementUnitType.MINUTE]: 'Minute',
  [EMeasurementUnitType.MONTH]: 'Month',
  [EMeasurementUnitType.NORMAL_LITRE_PER_SQUARE_METERS_PER_HOUR]: 'Normal litre per square meter per hour',
  [EMeasurementUnitType.NUMBER]: 'Number',
  [EMeasurementUnitType.PARTS_PER_MILLION]: 'Parts per million',
  [EMeasurementUnitType.PASCAL]: 'Pascal',
  [EMeasurementUnitType.PERCENT]: 'Percent',
  [EMeasurementUnitType.PERCENT_CARBON]: 'Percent carbon',
  [EMeasurementUnitType.PERCENT_HYDROGEN]: 'Percent hydrogen',
  [EMeasurementUnitType.PERCENT_NITROGEN]: 'Percent nitrogen',
  [EMeasurementUnitType.POUND]: 'Pound',
  [EMeasurementUnitType.PSI]: 'PSI',
  [EMeasurementUnitType.ROCKWELL_HARDNESS]: 'Rockwell hardness',
  [EMeasurementUnitType.ROCKWELL_HARDNESS_15_N]: 'Rockwell hardness 15N',
  [EMeasurementUnitType.ROCKWELL_HARDNESS_15_T]: 'Rockwell hardness 15T',
  [EMeasurementUnitType.ROCKWELL_HARDNESS_15_W]: 'Rockwell hardness 15W',
  [EMeasurementUnitType.ROCKWELL_HARDNESS_15_X]: 'Rockwell hardness 15X',
  [EMeasurementUnitType.ROCKWELL_HARDNESS_15_Y]: 'Rockwell hardness 15Y',
  [EMeasurementUnitType.ROCKWELL_HARDNESS_30_N]: 'Rockwell hardness 30N',
  [EMeasurementUnitType.ROCKWELL_HARDNESS_30_T]: 'Rockwell hardness 30T',
  [EMeasurementUnitType.ROCKWELL_HARDNESS_30_W]: 'Rockwell hardness 30W',
  [EMeasurementUnitType.ROCKWELL_HARDNESS_30_X]: 'Rockwell hardness 30X',
  [EMeasurementUnitType.ROCKWELL_HARDNESS_30_Y]: 'Rockwell hardness 30Y',
  [EMeasurementUnitType.ROCKWELL_HARDNESS_45_N]: 'Rockwell hardness 45N',
  [EMeasurementUnitType.ROCKWELL_HARDNESS_45_T]: 'Rockwell hardness 45T',
  [EMeasurementUnitType.ROCKWELL_HARDNESS_45_W]: 'Rockwell hardness 45W',
  [EMeasurementUnitType.ROCKWELL_HARDNESS_45_X]: 'Rockwell hardness 45X',
  [EMeasurementUnitType.ROCKWELL_HARDNESS_45_Y]: 'Rockwell hardness 45Y',
  [EMeasurementUnitType.ROCKWELL_HARDNESS_A]: 'Rockwell hardness A',
  [EMeasurementUnitType.ROCKWELL_HARDNESS_B]: 'Rockwell hardness B',
  [EMeasurementUnitType.ROCKWELL_HARDNESS_C]: 'Rockwell hardness C',
  [EMeasurementUnitType.ROCKWELL_HARDNESS_D]: 'Rockwell hardness D',
  [EMeasurementUnitType.ROCKWELL_HARDNESS_E]: 'Rockwell hardness E',
  [EMeasurementUnitType.ROCKWELL_HARDNESS_F]: 'Rockwell hardness F',
  [EMeasurementUnitType.ROCKWELL_HARDNESS_G]: 'Rockwell hardness G',
  [EMeasurementUnitType.ROCKWELL_HARDNESS_H]: 'Rockwell hardness H',
  [EMeasurementUnitType.ROCKWELL_HARDNESS_K]: 'Rockwell hardness K',
  [EMeasurementUnitType.ROCKWELL_HARDNESS_L]: 'Rockwell hardness L',
  [EMeasurementUnitType.ROCKWELL_HARDNESS_M]: 'Rockwell hardness M',
  [EMeasurementUnitType.ROCKWELL_HARDNESS_P]: 'Rockwell hardness P',
  [EMeasurementUnitType.ROCKWELL_HARDNESS_R]: 'Rockwell hardness R',
  [EMeasurementUnitType.ROCKWELL_HARDNESS_S]: 'Rockwell hardness S',
  [EMeasurementUnitType.ROCKWELL_HARDNESS_V]: 'Rockwell hardness V',
  [EMeasurementUnitType.RPM]: 'RPM',
  [EMeasurementUnitType.SECOND]: 'Second',
  [EMeasurementUnitType.TONNE]: 'Tonne',
  [EMeasurementUnitType.TORR]: 'Torr',
  [EMeasurementUnitType.TORR_PER_MINUTE]: 'Torr per minute',
  [EMeasurementUnitType.UNIT_LESS]: 'Not specified',
  [EMeasurementUnitType.VICKERS_HARDNESS]: 'Vickers hardness',
  [EMeasurementUnitType.VICKERS_HARDNESS_0_1]: 'Vickers hardness 0.1',
  [EMeasurementUnitType.VICKERS_HARDNESS_0_2]: 'Vickers hardness 0.2',
  [EMeasurementUnitType.VICKERS_HARDNESS_0_3]: 'Vickers hardness 0.3',
  [EMeasurementUnitType.VICKERS_HARDNESS_0_4]: 'Vickers hardness 0.4',
  [EMeasurementUnitType.VICKERS_HARDNESS_0_5]: 'Vickers hardness 0.5',
  [EMeasurementUnitType.VICKERS_HARDNESS_0_6]: 'Vickers hardness 0.6',
  [EMeasurementUnitType.VICKERS_HARDNESS_0_7]: 'Vickers hardness 0.7',
  [EMeasurementUnitType.VICKERS_HARDNESS_0_8]: 'Vickers hardness 0.8',
  [EMeasurementUnitType.VICKERS_HARDNESS_0_9]: 'Vickers hardness 0.9',
  [EMeasurementUnitType.VICKERS_HARDNESS_100]: 'Vickers hardness 100',
  [EMeasurementUnitType.VICKERS_HARDNESS_10]: 'Vickers hardness 10',
  [EMeasurementUnitType.VICKERS_HARDNESS_1]: 'Vickers hardness 1',
  [EMeasurementUnitType.VICKERS_HARDNESS_20]: 'Vickers hardness 20',
  [EMeasurementUnitType.VICKERS_HARDNESS_30]: 'Vickers hardness 30',
  [EMeasurementUnitType.VICKERS_HARDNESS_40]: 'Vickers hardness 40',
  [EMeasurementUnitType.VICKERS_HARDNESS_50]: 'Vickers hardness 50',
  [EMeasurementUnitType.VICKERS_HARDNESS_60]: 'Vickers hardness 60',
  [EMeasurementUnitType.VICKERS_HARDNESS_70]: 'Vickers hardness 70',
  [EMeasurementUnitType.VICKERS_HARDNESS_80]: 'Vickers hardness 80',
  [EMeasurementUnitType.VICKERS_HARDNESS_90]: 'Vickers hardness 90',
  [EMeasurementUnitType.VOLT]: 'Volt',
  [EMeasurementUnitType.VOLT_AMPERE]: 'Volt ampere',
  [EMeasurementUnitType.WATT]: 'Watt',
  [EMeasurementUnitType.WEEK]: 'Week',
  [EMeasurementUnitType.WEIGHT_PERCENT]: 'Weight percent',
  [EMeasurementUnitType.YEAR]: 'Year',
};

const AuditResultType: Record<EAuditResultType, string> = {
  [EAuditResultType.FAILED]: 'Failed',
  [EAuditResultType.INCONCLUSIVE]: 'Inconclusive',
  [EAuditResultType.PASSED]: 'Passed',
};

const PhysicalPropertyMeaning: Record<EPhysicalPropertyMeaningMap, string> = {
  [EPhysicalPropertyMeaningMap.LOAD_MASS]: 'Load mass',
};

const ConsumptionMeaning: Record<EConsumptionMeaningMap, string> = {
  [EConsumptionMeaningMap.DEPRECATED_AMMONIA_CONSUMPTION]: 'Ammonia consumption',
  [EConsumptionMeaningMap.DEPRECATED_AMMONIA_COST]: 'Ammonia cost',
  [EConsumptionMeaningMap.DEPRECATED_CARBON_DIOXIDE_CONSUMPTION]: 'Carbon dioxide consumption',
  [EConsumptionMeaningMap.DEPRECATED_CARBON_DIOXIDE_COST]: 'Carbon dioxide cost',
  [EConsumptionMeaningMap.DEPRECATED_CO_GAS_CONSUMPTION]: 'CO gas consumption',
  [EConsumptionMeaningMap.DEPRECATED_CO_GAS_COST]: 'CO gas cost',
  [EConsumptionMeaningMap.DEPRECATED_DISSOCIATED_AMMONIA_CONSUMPTION]: 'Dissociated ammonia consumption',
  [EConsumptionMeaningMap.DEPRECATED_DISSOCIATED_AMMONIA_COST]: 'Dissociated ammonia cost',
  [EConsumptionMeaningMap.DEPRECATED_ELECTRICITY_CONSUMPTION]: 'Electricity consumption',
  [EConsumptionMeaningMap.DEPRECATED_ELECTRICITY_COST]: 'Electricity cost',
  [EConsumptionMeaningMap.DEPRECATED_ENDO_GAS_CONSUMPTION]: 'Endo gas consumption',
  [EConsumptionMeaningMap.DEPRECATED_ENDO_GAS_COST]: 'Endo gas cost',
  [EConsumptionMeaningMap.DEPRECATED_HYDROGEN_CONSUMPTION]: 'Hydrogen consumption',
  [EConsumptionMeaningMap.DEPRECATED_HYDROGEN_COST]: 'Hydrogen cost',
  [EConsumptionMeaningMap.DEPRECATED_NITROGEN_CONSUMPTION]: 'Nitrogen consumption',
  [EConsumptionMeaningMap.DEPRECATED_NITROGEN_COST]: 'Nitrogen cost',
  [EConsumptionMeaningMap.ACETYLENE_C2H2_CONSUMPTION]: 'Acetylene (C2H2) consumption',
  [EConsumptionMeaningMap.AIR_CONSUMPTION]: 'Air consumption',
  [EConsumptionMeaningMap.AMMONIA_NH3_CONSUMPTION]: 'Ammonia (NH3) consumption',
  [EConsumptionMeaningMap.ARGON_CONSUMPTION]: 'Argon consumption',
  [EConsumptionMeaningMap.CARBON_DIOXIDE_CO2_CONSUMPTION]: 'Carbon dioxide (CO2) consumption',
  [EConsumptionMeaningMap.CARBON_MONOXIDE_CO_CONSUMPTION]: 'Carbon monoxide (CO) consumption',
  [EConsumptionMeaningMap.DISSOCIATED_AMMONIA_NH3_CONSUMPTION]: 'Dissociated ammonia (NH3) consumption',
  [EConsumptionMeaningMap.ENDO_GAS_NATURAL_CONSUMPTION]: 'Endo gas (natural) consumption',
  [EConsumptionMeaningMap.ENDO_GAS_PROPANE_CONSUMPTION]: 'Endo gas (propane) consumption',
  [EConsumptionMeaningMap.ETHENE_C2H4_CONSUMPTION]: 'Ethene (C2H4) consumption',
  [EConsumptionMeaningMap.HELIUM_CONSUMPTION]: 'Helium consumption',
  [EConsumptionMeaningMap.HYDROGEN_H2_CONSUMPTION]: 'Hydrogen (H2) consumption',
  [EConsumptionMeaningMap.METHANOL_CH3OH_CONSUMPTION]: 'Methanol (CH3OH) consumption',
  [EConsumptionMeaningMap.NATURAL_GAS_CONSUMPTION]: 'Natural gas consumption',
  [EConsumptionMeaningMap.NITROGEN_CONSUMPTION]: 'Nitrogen consumption',
  [EConsumptionMeaningMap.NITROUS_OXIDE_N2O_CONSUMPTION]: 'Nitrous oxide (N2O) consumption',
  [EConsumptionMeaningMap.PROPANE_C3H8_CONSUMPTION]: 'Propane (C3H8) consumption',
  [EConsumptionMeaningMap.WATER_CONSUMPTION]: 'Water consumption',
  [EConsumptionMeaningMap.ETHANE_C2H6_CONSUMPTION]: 'Ethane (C2H6) consumption',
  [EConsumptionMeaningMap.METHANE_CH4_CONSUMPTION]: 'Methane (CH4) consumption',
  [EConsumptionMeaningMap.ELECTRICAL_POWER_CONSUMPTION]: 'Electrical Power consumption',
  [EConsumptionMeaningMap.USER_DEFINED_001]: 'User-defined consumption meaning 001',
  [EConsumptionMeaningMap.USER_DEFINED_002]: 'User-defined consumption meaning 002',
  [EConsumptionMeaningMap.USER_DEFINED_003]: 'User-defined consumption meaning 003',
  [EConsumptionMeaningMap.USER_DEFINED_004]: 'User-defined consumption meaning 004',
  [EConsumptionMeaningMap.USER_DEFINED_005]: 'User-defined consumption meaning 005',
  [EConsumptionMeaningMap.USER_DEFINED_006]: 'User-defined consumption meaning 006',
  [EConsumptionMeaningMap.USER_DEFINED_007]: 'User-defined consumption meaning 007',
  [EConsumptionMeaningMap.USER_DEFINED_008]: 'User-defined consumption meaning 008',
  [EConsumptionMeaningMap.USER_DEFINED_009]: 'User-defined consumption meaning 009',
  [EConsumptionMeaningMap.USER_DEFINED_010]: 'User-defined consumption meaning 010',
  [EConsumptionMeaningMap.USER_DEFINED_011]: 'User-defined consumption meaning 011',
  [EConsumptionMeaningMap.USER_DEFINED_012]: 'User-defined consumption meaning 012',
  [EConsumptionMeaningMap.USER_DEFINED_013]: 'User-defined consumption meaning 013',
  [EConsumptionMeaningMap.USER_DEFINED_014]: 'User-defined consumption meaning 014',
  [EConsumptionMeaningMap.USER_DEFINED_015]: 'User-defined consumption meaning 015',
  [EConsumptionMeaningMap.USER_DEFINED_016]: 'User-defined consumption meaning 016',
  [EConsumptionMeaningMap.USER_DEFINED_017]: 'User-defined consumption meaning 017',
  [EConsumptionMeaningMap.USER_DEFINED_018]: 'User-defined consumption meaning 018',
  [EConsumptionMeaningMap.USER_DEFINED_019]: 'User-defined consumption meaning 019',
  [EConsumptionMeaningMap.USER_DEFINED_020]: 'User-defined consumption meaning 020',
};

const JobFeatureMeaning: Record<EJobFeatureMeaningMap, string> = {
  [EJobFeatureMeaningMap.HEAT_LOSS_MIN_POWER]: 'Heat loss min power',
  [EJobFeatureMeaningMap.HEAT_LOSS_MAX_TEMPERATURE]: 'Heat loss max temperature',
  [EJobFeatureMeaningMap.TEMPERATURE_CONTROL_ACCURACY]: 'Temperature control accuracy',
  [EJobFeatureMeaningMap.TEMPERATURE_CONTROL_PRECISION]: 'Temperature control precision',
  [EJobFeatureMeaningMap.HEATING_POWER_OUTPUT_CONTROL_ACCURACY]: 'Heating power output control accuracy',
  [EJobFeatureMeaningMap.HEATING_POWER_OUTPUT_CONTROL_PRECISION]: 'Heating power output control precision',
  [EJobFeatureMeaningMap.ELECTRICAL_POWER_CONTROL_ACCURACY]: 'Electrical power control accuracy',
  [EJobFeatureMeaningMap.ELECTRICAL_POWER_CONTROL_PRECISION]: 'Electrical power control precision',
  [EJobFeatureMeaningMap.COOLING_POWER_OUTPUT_CONTROL_ACCURACY]: 'Cooling power output control accuracy',
  [EJobFeatureMeaningMap.COOLING_POWER_OUTPUT_CONTROL_PRECISION]: 'Cooling power output control precision',
  [EJobFeatureMeaningMap.RELATIVE_PRESSURE_CONTROL_ACCURACY]: 'Relative pressure control accuracy',
  [EJobFeatureMeaningMap.RELATIVE_PRESSURE_CONTROL_PRECISION]: 'Relative pressure control precision',
  [EJobFeatureMeaningMap.CHAMBER_TEMPERATURE_CONTROL_ACCURACY]: 'Chamber temperature control accuracy',
  [EJobFeatureMeaningMap.CHAMBER_TEMPERATURE_CONTROL_PRECISION]: 'Chamber temperature control precision',
  [EJobFeatureMeaningMap.ABSOLUTE_PRESSURE_CONTROL_ACCURACY]: 'Absolute pressure control accuracy',
  [EJobFeatureMeaningMap.ABSOLUTE_PRESSURE_CONTROL_PRECISION]: 'Absolute pressure control precision',
  [EJobFeatureMeaningMap.VACUUM_PRESSURE_CONTROL_ACCURACY]: 'Vacuum pressure control accuracy',
  [EJobFeatureMeaningMap.VACUUM_PRESSURE_CONTROL_PRECISION]: 'Vacuum pressure control precision',
  [EJobFeatureMeaningMap.ATMOSPHERIC_PRESSURE_CONTROL_ACCURACY]: 'Atmospheric pressure control accuracy',
  [EJobFeatureMeaningMap.ATMOSPHERIC_PRESSURE_CONTROL_PRECISION]: 'Atmospheric pressure control precision',
  [EJobFeatureMeaningMap.ROOM_TEMPERATURE_CONTROL_ACCURACY]: 'Room temperature control accuracy',
  [EJobFeatureMeaningMap.ROOM_TEMPERATURE_CONTROL_PRECISION]: 'Room temperature control precision',
  [EJobFeatureMeaningMap.ACTIVITY_AH_CONTROL_ACCURACY]: 'Activity aH control accuracy',
  [EJobFeatureMeaningMap.ACTIVITY_AH_CONTROL_PRECISION]: 'Activity aH control precision',
  [EJobFeatureMeaningMap.POTENTIAL_HP_CONTROL_ACCURACY]: 'Potential HP control accuracy',
  [EJobFeatureMeaningMap.POTENTIAL_HP_CONTROL_PRECISION]: 'Potential HP control precision',
  [EJobFeatureMeaningMap.POTENTIAL_KH_CONTROL_ACCURACY]: 'Potential kH control accuracy',
  [EJobFeatureMeaningMap.POTENTIAL_KH_CONTROL_PRECISION]: 'Potential kH control precision',
  [EJobFeatureMeaningMap.ACTIVITY_AO_CONTROL_ACCURACY]: 'Activity aO control accuracy',
  [EJobFeatureMeaningMap.ACTIVITY_AO_CONTROL_PRECISION]: 'Activity aO control precision',
  [EJobFeatureMeaningMap.POTENTIAL_OP_CONTROL_ACCURACY]: 'Potential OP control accuracy',
  [EJobFeatureMeaningMap.POTENTIAL_OP_CONTROL_PRECISION]: 'Potential OP control precision',
  [EJobFeatureMeaningMap.POTENTIAL_KO_CONTROL_ACCURACY]: 'Potential kO control accuracy',
  [EJobFeatureMeaningMap.POTENTIAL_KO_CONTROL_PRECISION]: 'Potential kO control precision',
  [EJobFeatureMeaningMap.ACTIVITY_AC_CONTROL_ACCURACY]: 'Activity aC control accuracy',
  [EJobFeatureMeaningMap.ACTIVITY_AC_CONTROL_PRECISION]: 'Activity aC control precision',
  [EJobFeatureMeaningMap.POTENTIAL_CP_CONTROL_ACCURACY]: 'Potential CP control accuracy',
  [EJobFeatureMeaningMap.POTENTIAL_CP_CONTROL_PRECISION]: 'Potential CP control precision',
  [EJobFeatureMeaningMap.POTENTIAL_KC_CONTROL_ACCURACY]: 'Potential kC control accuracy',
  [EJobFeatureMeaningMap.POTENTIAL_KC_CONTROL_PRECISION]: 'Potential kC control precision',
  [EJobFeatureMeaningMap.ACTIVITY_AN_CONTROL_ACCURACY]: 'Activity aN control accuracy',
  [EJobFeatureMeaningMap.ACTIVITY_AN_CONTROL_PRECISION]: 'Activity aN control precision',
  [EJobFeatureMeaningMap.POTENTIAL_NP_CONTROL_ACCURACY]: 'Potential NP control accuracy',
  [EJobFeatureMeaningMap.POTENTIAL_NP_CONTROL_PRECISION]: 'Potential NP control precision',
  [EJobFeatureMeaningMap.POTENTIAL_KN_CONTROL_ACCURACY]: 'Potential kN control accuracy',
  [EJobFeatureMeaningMap.POTENTIAL_KN_CONTROL_PRECISION]: 'Potential kN control precision',
  [EJobFeatureMeaningMap.ACTIVITY_AB_CONTROL_ACCURACY]: 'Activity aB control accuracy',
  [EJobFeatureMeaningMap.ACTIVITY_AB_CONTROL_PRECISION]: 'Activity aB control precision',
  [EJobFeatureMeaningMap.POTENTIAL_BP_CONTROL_ACCURACY]: 'Potential BP control accuracy',
  [EJobFeatureMeaningMap.POTENTIAL_BP_CONTROL_PRECISION]: 'Potential BP control precision',
  [EJobFeatureMeaningMap.POTENTIAL_KB_CONTROL_ACCURACY]: 'Potential kB control accuracy',
  [EJobFeatureMeaningMap.POTENTIAL_KB_CONTROL_PRECISION]: 'Potential kB control precision',
  [EJobFeatureMeaningMap.ACTIVITY_AS_CONTROL_ACCURACY]: 'Activity aS control accuracy',
  [EJobFeatureMeaningMap.ACTIVITY_AS_CONTROL_PRECISION]: 'Activity aS control precision',
  [EJobFeatureMeaningMap.POTENTIAL_SP_CONTROL_ACCURACY]: 'Potential SP control accuracy',
  [EJobFeatureMeaningMap.POTENTIAL_SP_CONTROL_PRECISION]: 'Potential SP control precision',
  [EJobFeatureMeaningMap.POTENTIAL_KS_CONTROL_ACCURACY]: 'Potential kS control accuracy',
  [EJobFeatureMeaningMap.POTENTIAL_KS_CONTROL_PRECISION]: 'Potential kS control precision',
  [EJobFeatureMeaningMap.ACETYLENE_C2H2_FLOW_CONTROL_ACCURACY]: 'Acetylene C2H2 flow control accuracy',
  [EJobFeatureMeaningMap.ACETYLENE_C2H2_FLOW_CONTROL_PRECISION]: 'Acetylene C2H2 flow control precision',
  [EJobFeatureMeaningMap.AIR_FLOW_CONTROL_ACCURACY]: 'Air flow control accuracy',
  [EJobFeatureMeaningMap.AIR_FLOW_CONTROL_PRECISION]: 'Air flow control precision',
  [EJobFeatureMeaningMap.AMMONIA_NH3_FLOW_CONTROL_ACCURACY]: 'Ammonia NH3 flow control accuracy',
  [EJobFeatureMeaningMap.AMMONIA_NH3_FLOW_CONTROL_PRECISION]: 'Ammonia NH3 flow control precision',
  [EJobFeatureMeaningMap.ARGON_FLOW_CONTROL_ACCURACY]: 'Argon flow control accuracy',
  [EJobFeatureMeaningMap.ARGON_FLOW_CONTROL_PRECISION]: 'Argon flow control precision',
  [EJobFeatureMeaningMap.CARBON_DIOXIDE_CO2_FLOW_CONTROL_ACCURACY]: 'Carbon dioxide CO2 flow control accuracy',
  [EJobFeatureMeaningMap.CARBON_DIOXIDE_CO2_FLOW_CONTROL_PRECISION]: 'Carbon dioxide CO2 flow control precision',
  [EJobFeatureMeaningMap.CARBON_MONOXIDE_CO_FLOW_CONTROL_ACCURACY]: 'Carbon monoxide CO flow control accuracy',
  [EJobFeatureMeaningMap.CARBON_MONOXIDE_CO_FLOW_CONTROL_PRECISION]: 'Carbon monoxide CO flow control precision',
  [EJobFeatureMeaningMap.DISSOCIATED_AMMONIA_NH3_FLOW_CONTROL_ACCURACY]: 'Dissociated ammonia NH3 flow control accuracy',
  [EJobFeatureMeaningMap.DISSOCIATED_AMMONIA_NH3_FLOW_CONTROL_PRECISION]: 'Dissociated ammonia NH3 flow control precision',
  [EJobFeatureMeaningMap.ENDO_GAS_NATURAL_FLOW_CONTROL_ACCURACY]: 'Endo gas natural flow control accuracy',
  [EJobFeatureMeaningMap.ENDO_GAS_NATURAL_FLOW_CONTROL_PRECISION]: 'Endo gas natural flow control precision',
  [EJobFeatureMeaningMap.ENDO_GAS_PROPANE_FLOW_CONTROL_ACCURACY]: 'Endo gas propane flow control accuracy',
  [EJobFeatureMeaningMap.ENDO_GAS_PROPANE_FLOW_CONTROL_PRECISION]: 'Endo gas propane flow control precision',
  [EJobFeatureMeaningMap.ETHENE_C2H4_FLOW_CONTROL_ACCURACY]: 'Ethene C2H4 flow control accuracy',
  [EJobFeatureMeaningMap.ETHENE_C2H4_FLOW_CONTROL_PRECISION]: 'Ethene C2H4 flow control precision',
  [EJobFeatureMeaningMap.HELIUM_FLOW_CONTROL_ACCURACY]: 'Helium flow control accuracy',
  [EJobFeatureMeaningMap.HELIUM_FLOW_CONTROL_PRECISION]: 'Helium flow control precision',
  [EJobFeatureMeaningMap.HYDROGEN_H2_FLOW_CONTROL_ACCURACY]: 'Hydrogen H2 flow control accuracy',
  [EJobFeatureMeaningMap.HYDROGEN_H2_FLOW_CONTROL_PRECISION]: 'Hydrogen H2 flow control precision',
  [EJobFeatureMeaningMap.METHANOL_CH3OH_FLOW_CONTROL_ACCURACY]: 'Methanol CH3OH flow control accuracy',
  [EJobFeatureMeaningMap.METHANOL_CH3OH_FLOW_CONTROL_PRECISION]: 'Methanol CH3OH flow control precision',
  [EJobFeatureMeaningMap.NATURAL_GAS_FLOW_CONTROL_ACCURACY]: 'Natural gas flow control accuracy',
  [EJobFeatureMeaningMap.NATURAL_GAS_FLOW_CONTROL_PRECISION]: 'Natural gas flow control precision',
  [EJobFeatureMeaningMap.NITROGEN_FLOW_CONTROL_ACCURACY]: 'Nitrogen flow control accuracy',
  [EJobFeatureMeaningMap.NITROGEN_FLOW_CONTROL_PRECISION]: 'Nitrogen flow control precision',
  [EJobFeatureMeaningMap.PROPANE_C3H8_FLOW_CONTROL_ACCURACY]: 'Propane C3H8 flow control accuracy',
  [EJobFeatureMeaningMap.PROPANE_C3H8_FLOW_CONTROL_PRECISION]: 'Propane C3H8 flow control precision',
  [EJobFeatureMeaningMap.WATER_FLOW_CONTROL_ACCURACY]: 'Water flow control accuracy',
  [EJobFeatureMeaningMap.WATER_FLOW_CONTROL_PRECISION]: 'Water flow control precision',
  [EJobFeatureMeaningMap.NITROUS_OXIDE_N2O_FLOW_CONTROL_ACCURACY]: 'Nitrous oxide N2O flow control accuracy',
  [EJobFeatureMeaningMap.NITROUS_OXIDE_N2O_FLOW_CONTROL_PRECISION]: 'Nitrous oxide N2O flow control precision',
  [EJobFeatureMeaningMap.TEMPERATURE_CONTROL_AGILITY]: 'Temperature control agility',
  [EJobFeatureMeaningMap.HEATING_POWER_OUTPUT_CONTROL_AGILITY]: 'Heating power output control agility',
  [EJobFeatureMeaningMap.ELECTRICAL_POWER_CONTROL_AGILITY]: 'Electrical power control agility',
  [EJobFeatureMeaningMap.COOLING_POWER_OUTPUT_CONTROL_AGILITY]: 'Cooling power output control agility',
  [EJobFeatureMeaningMap.RELATIVE_PRESSURE_CONTROL_AGILITY]: 'Relative pressure control agility',
  [EJobFeatureMeaningMap.CHAMBER_TEMPERATURE_CONTROL_AGILITY]: 'Chamber temperature control agility',
  [EJobFeatureMeaningMap.ABSOLUTE_PRESSURE_CONTROL_AGILITY]: 'Absolute pressure control agility',
  [EJobFeatureMeaningMap.VACUUM_PRESSURE_CONTROL_AGILITY]: 'Vacuum pressure control agility',
  [EJobFeatureMeaningMap.ATMOSPHERIC_PRESSURE_CONTROL_AGILITY]: 'Atmospheric pressure control agility',
  [EJobFeatureMeaningMap.ROOM_TEMPERATURE_CONTROL_AGILITY]: 'Room temperature control agility',
  [EJobFeatureMeaningMap.ACTIVITY_AH_CONTROL_AGILITY]: 'Activity aH control agility',
  [EJobFeatureMeaningMap.POTENTIAL_HP_CONTROL_AGILITY]: 'Potential HP control agility',
  [EJobFeatureMeaningMap.POTENTIAL_KH_CONTROL_AGILITY]: 'Potential kH control agility',
  [EJobFeatureMeaningMap.ACTIVITY_AO_CONTROL_AGILITY]: 'Activity aO control agility',
  [EJobFeatureMeaningMap.POTENTIAL_OP_CONTROL_AGILITY]: 'Potential OP control agility',
  [EJobFeatureMeaningMap.POTENTIAL_KO_CONTROL_AGILITY]: 'Potential kO control agility',
  [EJobFeatureMeaningMap.ACTIVITY_AC_CONTROL_AGILITY]: 'Activity aC control agility',
  [EJobFeatureMeaningMap.POTENTIAL_CP_CONTROL_AGILITY]: 'Potential CP control agility',
  [EJobFeatureMeaningMap.POTENTIAL_KC_CONTROL_AGILITY]: 'Potential kC control agility',
  [EJobFeatureMeaningMap.ACTIVITY_AN_CONTROL_AGILITY]: 'Activity aN control agility',
  [EJobFeatureMeaningMap.POTENTIAL_NP_CONTROL_AGILITY]: 'Potential NP control agility',
  [EJobFeatureMeaningMap.POTENTIAL_KN_CONTROL_AGILITY]: 'Potential kN control agility',
  [EJobFeatureMeaningMap.ACTIVITY_AB_CONTROL_AGILITY]: 'Activity aB control agility',
  [EJobFeatureMeaningMap.POTENTIAL_BP_CONTROL_AGILITY]: 'Potential BP control agility',
  [EJobFeatureMeaningMap.POTENTIAL_KB_CONTROL_AGILITY]: 'Potential kB control agility',
  [EJobFeatureMeaningMap.ACTIVITY_AS_CONTROL_AGILITY]: 'Activity aS control agility',
  [EJobFeatureMeaningMap.POTENTIAL_SP_CONTROL_AGILITY]: 'Potential SP control agility',
  [EJobFeatureMeaningMap.POTENTIAL_KS_CONTROL_AGILITY]: 'Potential kS control agility',
  [EJobFeatureMeaningMap.ACETYLENE_C2H2_FLOW_CONTROL_AGILITY]: 'Acetylene C2H2 flow control agility',
  [EJobFeatureMeaningMap.AIR_FLOW_CONTROL_AGILITY]: 'Air flow control agility',
  [EJobFeatureMeaningMap.AMMONIA_NH3_FLOW_CONTROL_AGILITY]: 'Ammonia NH3 flow control agility',
  [EJobFeatureMeaningMap.ARGON_FLOW_CONTROL_AGILITY]: 'Argon flow control agility',
  [EJobFeatureMeaningMap.CARBON_DIOXIDE_CO2_FLOW_CONTROL_AGILITY]: 'Carbon dioxide CO2 flow control agility',
  [EJobFeatureMeaningMap.CARBON_MONOXIDE_CO_FLOW_CONTROL_AGILITY]: 'Carbon monoxide CO flow control agility',
  [EJobFeatureMeaningMap.DISSOCIATED_AMMONIA_NH3_FLOW_CONTROL_AGILITY]: 'Dissociated ammonia NH3 flow control agility',
  [EJobFeatureMeaningMap.ENDO_GAS_NATURAL_FLOW_CONTROL_AGILITY]: 'Endo gas natural flow control agility',
  [EJobFeatureMeaningMap.ENDO_GAS_PROPANE_FLOW_CONTROL_AGILITY]: 'Endo gas propane flow control agility',
  [EJobFeatureMeaningMap.ETHENE_C2H4_FLOW_CONTROL_AGILITY]: 'Ethene C2H4 flow control agility',
  [EJobFeatureMeaningMap.HELIUM_FLOW_CONTROL_AGILITY]: 'Helium flow control agility',
  [EJobFeatureMeaningMap.HYDROGEN_H2_FLOW_CONTROL_AGILITY]: 'Hydrogen H2 flow control agility',
  [EJobFeatureMeaningMap.METHANOL_CH3OH_FLOW_CONTROL_AGILITY]: 'Methanol CH3OH flow control agility',
  [EJobFeatureMeaningMap.NATURAL_GAS_FLOW_CONTROL_AGILITY]: 'Natural gas flow control agility',
  [EJobFeatureMeaningMap.NITROGEN_FLOW_CONTROL_AGILITY]: 'Nitrogen flow control agility',
  [EJobFeatureMeaningMap.PROPANE_C3H8_FLOW_CONTROL_AGILITY]: 'Propane C3H8 flow control agility',
  [EJobFeatureMeaningMap.WATER_FLOW_CONTROL_AGILITY]: 'Water flow control agility',
  [EJobFeatureMeaningMap.NITROUS_OXIDE_N2O_FLOW_CONTROL_AGILITY]: 'Nitrous oxide N2O flow control agility',
  [EJobFeatureMeaningMap.OXYGEN_O2_FLOW_CONTROL_ACCURACY]: 'Oxygen O2 flow control accuracy',
  [EJobFeatureMeaningMap.OXYGEN_O2_FLOW_CONTROL_PRECISION]: 'Oxygen O2 flow control precision',
  [EJobFeatureMeaningMap.OXYGEN_O2_FLOW_CONTROL_AGILITY]: 'Oxygen O2 flow control agility',
  [EJobFeatureMeaningMap.METHANE_CH4_FLOW_CONTROL_ACCURACY]: 'Methane CH4 flow control accuracy',
  [EJobFeatureMeaningMap.METHANE_CH4_FLOW_CONTROL_PRECISION]: 'Methane CH4 flow control precision',
  [EJobFeatureMeaningMap.METHANE_CH4_FLOW_CONTROL_AGILITY]: 'Methane CH4 flow control agility',
  [EJobFeatureMeaningMap.DISSOCIATION_CONTROL_ACCURACY]: 'Dissociation control accuracy',
  [EJobFeatureMeaningMap.DISSOCIATION_CONTROL_PRECISION]: 'Dissociation control precision',
  [EJobFeatureMeaningMap.DISSOCIATION_CONTROL_AGILITY]: 'Dissociation control agility',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ACETYLENE_C2H2_CONTROL_ACCURACY]: 'Partial pressure acetylene C2H2 control accuracy',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ACETYLENE_C2H2_CONTROL_PRECISION]: 'Partial pressure acetylene C2H2 control precision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ACETYLENE_C2H2_CONTROL_AGILITY]: 'Partial pressure acetylene C2H2 control agility',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_AIR_CONTROL_ACCURACY]: 'Partial pressure air control accuracy',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_AIR_CONTROL_PRECISION]: 'Partial pressure air control precision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_AIR_CONTROL_AGILITY]: 'Partial pressure air control agility',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_AMMONIA_NH3_CONTROL_ACCURACY]: 'Partial pressure ammonia NH3 control accuracy',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_AMMONIA_NH3_CONTROL_PRECISION]: 'Partial pressure ammonia NH3 control precision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_AMMONIA_NH3_CONTROL_AGILITY]: 'Partial pressure ammonia NH3 control agility',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ARGON_CONTROL_ACCURACY]: 'Partial pressure argon control accuracy',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ARGON_CONTROL_PRECISION]: 'Partial pressure argon control precision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ARGON_CONTROL_AGILITY]: 'Partial pressure argon control agility',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_CARBON_DIOXIDE_CO2_CONTROL_ACCURACY]: 'Partial pressure carbon dioxide CO2 control accuracy',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_CARBON_DIOXIDE_CO2_CONTROL_PRECISION]: 'Partial pressure carbon dioxide CO2 control precision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_CARBON_DIOXIDE_CO2_CONTROL_AGILITY]: 'Partial pressure carbon dioxide CO2 control agility',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_CARBON_MONOXIDE_CO_CONTROL_ACCURACY]: 'Partial pressure carbon monoxide CO control accuracy',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_CARBON_MONOXIDE_CO_CONTROL_PRECISION]: 'Partial pressure carbon monoxide CO control precision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_CARBON_MONOXIDE_CO_CONTROL_AGILITY]: 'Partial pressure carbon monoxide CO control agility',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_DISSOCIATED_AMMONIA_NH3_CONTROL_ACCURACY]: 'Partial pressure dissociated ammonia NH3 control accuracy',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_DISSOCIATED_AMMONIA_NH3_CONTROL_PRECISION]: 'Partial pressure dissociated ammonia NH3 control precision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_DISSOCIATED_AMMONIA_NH3_CONTROL_AGILITY]: 'Partial pressure dissociated ammonia NH3 control agility',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ENDO_GAS_NATURAL_CONTROL_ACCURACY]: 'Partial pressure endo gas natural control accuracy',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ENDO_GAS_NATURAL_CONTROL_PRECISION]: 'Partial pressure endo gas natural control precision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ENDO_GAS_NATURAL_CONTROL_AGILITY]: 'Partial pressure endo gas natural control agility',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ENDO_GAS_PROPANE_CONTROL_ACCURACY]: 'Partial pressure endo gas propane control accuracy',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ENDO_GAS_PROPANE_CONTROL_PRECISION]: 'Partial pressure endo gas propane control precision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ENDO_GAS_PROPANE_CONTROL_AGILITY]: 'Partial pressure endo gas propane control agility',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ETHENE_C2H4_CONTROL_ACCURACY]: 'Partial pressure ethene C2H4 control accuracy',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ETHENE_C2H4_CONTROL_PRECISION]: 'Partial pressure ethene C2H4 control precision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ETHENE_C2H4_CONTROL_AGILITY]: 'Partial pressure ethene C2H4 control agility',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_HELIUM_CONTROL_ACCURACY]: 'Partial pressure helium control accuracy',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_HELIUM_CONTROL_PRECISION]: 'Partial pressure helium control precision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_HELIUM_CONTROL_AGILITY]: 'Partial pressure helium control agility',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_HYDROGEN_H2_CONTROL_ACCURACY]: 'Partial pressure hydrogen H2 control accuracy',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_HYDROGEN_H2_CONTROL_PRECISION]: 'Partial pressure hydrogen H2 control precision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_HYDROGEN_H2_CONTROL_AGILITY]: 'Partial pressure hydrogen H2 control agility',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_METHANOL_CH3OH_CONTROL_ACCURACY]: 'Partial pressure methanol CH3OH control accuracy',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_METHANOL_CH3OH_CONTROL_PRECISION]: 'Partial pressure methanol CH3OH control precision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_METHANOL_CH3OH_CONTROL_AGILITY]: 'Partial pressure methanol CH3OH control agility',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_NATURAL_GAS_CONTROL_ACCURACY]: 'Partial pressure natural gas control accuracy',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_NATURAL_GAS_CONTROL_PRECISION]: 'Partial pressure natural gas control precision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_NATURAL_GAS_CONTROL_AGILITY]: 'Partial pressure natural gas control agility',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_NITROGEN_CONTROL_ACCURACY]: 'Partial pressure nitrogen control accuracy',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_NITROGEN_CONTROL_PRECISION]: 'Partial pressure nitrogen control precision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_NITROGEN_CONTROL_AGILITY]: 'Partial pressure nitrogen control agility',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_NITROUS_OXIDE_N2O_CONTROL_ACCURACY]: 'Partial pressure nitrous oxide N2O control accuracy',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_NITROUS_OXIDE_N2O_CONTROL_PRECISION]: 'Partial pressure nitrous oxide N2O control precision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_NITROUS_OXIDE_N2O_CONTROL_AGILITY]: 'Partial pressure nitrous oxide N2O control agility',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_PROPANE_C3H8_CONTROL_ACCURACY]: 'Partial pressure propane C3H8 control accuracy',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_PROPANE_C3H8_CONTROL_PRECISION]: 'Partial pressure propane C3H8 control precision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_PROPANE_C3H8_CONTROL_AGILITY]: 'Partial pressure propane C3H8 control agility',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_WATER_CONTROL_ACCURACY]: 'Partial pressure water control accuracy',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_WATER_CONTROL_PRECISION]: 'Partial pressure water control precision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_WATER_CONTROL_AGILITY]: 'Partial pressure water control agility',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ACETYLENE_C2H2_PERCENT_CONTROL_ACCURACY]: 'Partial pressure acetylene C2H2 percent control accuracy',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ACETYLENE_C2H2_PERCENT_CONTROL_PRECISION]: 'Partial pressure acetylene C2H2 percent control precision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ACETYLENE_C2H2_PERCENT_CONTROL_AGILITY]: 'Partial pressure acetylene C2H2 percent control agility',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_AIR_PERCENT_CONTROL_ACCURACY]: 'Partial pressure air percent control accuracy',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_AIR_PERCENT_CONTROL_PRECISION]: 'Partial pressure air percent control precision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_AIR_PERCENT_CONTROL_AGILITY]: 'Partial pressure air percent control agility',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_AMMONIA_NH3_PERCENT_CONTROL_ACCURACY]: 'Partial pressure ammonia NH3 percent control accuracy',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_AMMONIA_NH3_PERCENT_CONTROL_PRECISION]: 'Partial pressure ammonia NH3 percent control precision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_AMMONIA_NH3_PERCENT_CONTROL_AGILITY]: 'Partial pressure ammonia NH3 percent control agility',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ARGON_PERCENT_CONTROL_ACCURACY]: 'Partial pressure argon percent control accuracy',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ARGON_PERCENT_CONTROL_PRECISION]: 'Partial pressure argon percent control precision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ARGON_PERCENT_CONTROL_AGILITY]: 'Partial pressure argon percent control agility',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_CARBON_DIOXIDE_CO2_PERCENT_CONTROL_ACCURACY]: 'Partial pressure carbon dioxide CO2 percent control accuracy',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_CARBON_DIOXIDE_CO2_PERCENT_CONTROL_PRECISION]: 'Partial pressure carbon dioxide CO2 percent control precision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_CARBON_DIOXIDE_CO2_PERCENT_CONTROL_AGILITY]: 'Partial pressure carbon dioxide CO2 percent control agility',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_CARBON_MONOXIDE_CO_PERCENT_CONTROL_ACCURACY]: 'Partial pressure carbon monoxide CO percent control accuracy',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_CARBON_MONOXIDE_CO_PERCENT_CONTROL_PRECISION]: 'Partial pressure carbon monoxide CO percent control precision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_CARBON_MONOXIDE_CO_PERCENT_CONTROL_AGILITY]: 'Partial pressure carbon monoxide CO percent control agility',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_DISSOCIATED_AMMONIA_NH3_PERCENT_CONTROL_ACCURACY]: 'Partial pressure dissociated ammonia NH3 percent control accuracy',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_DISSOCIATED_AMMONIA_NH3_PERCENT_CONTROL_PRECISION]: 'Partial pressure dissociated ammonia NH3 percent control precision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_DISSOCIATED_AMMONIA_NH3_PERCENT_CONTROL_AGILITY]: 'Partial pressure dissociated ammonia NH3 percent control agility',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ENDO_GAS_NATURAL_PERCENT_CONTROL_ACCURACY]: 'Partial pressure endo gas natural percent control accuracy',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ENDO_GAS_NATURAL_PERCENT_CONTROL_PRECISION]: 'Partial pressure endo gas natural percent control precision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ENDO_GAS_NATURAL_PERCENT_CONTROL_AGILITY]: 'Partial pressure endo gas natural percent control agility',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ENDO_GAS_PROPANE_PERCENT_CONTROL_ACCURACY]: 'Partial pressure endo gas propane percent control accuracy',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ENDO_GAS_PROPANE_PERCENT_CONTROL_PRECISION]: 'Partial pressure endo gas propane percent control precision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ENDO_GAS_PROPANE_PERCENT_CONTROL_AGILITY]: 'Partial pressure endo gas propane percent control agility',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ETHENE_C2H4_PERCENT_CONTROL_ACCURACY]: 'Partial pressure ethene C2H4 percent control accuracy',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ETHENE_C2H4_PERCENT_CONTROL_PRECISION]: 'Partial pressure ethene C2H4 percent control precision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ETHENE_C2H4_PERCENT_CONTROL_AGILITY]: 'Partial pressure ethene C2H4 percent control agility',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_HELIUM_PERCENT_CONTROL_ACCURACY]: 'Partial pressure helium percent control accuracy',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_HELIUM_PERCENT_CONTROL_PRECISION]: 'Partial pressure helium percent control precision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_HELIUM_PERCENT_CONTROL_AGILITY]: 'Partial pressure helium percent control agility',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_HYDROGEN_H2_PERCENT_CONTROL_ACCURACY]: 'Partial pressure hydrogen H2 percent control accuracy',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_HYDROGEN_H2_PERCENT_CONTROL_PRECISION]: 'Partial pressure hydrogen H2 percent control precision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_HYDROGEN_H2_PERCENT_CONTROL_AGILITY]: 'Partial pressure hydrogen H2 percent control agility',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_METHANOL_CH3OH_PERCENT_CONTROL_ACCURACY]: 'Partial pressure methanol CH3OH percent control accuracy',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_METHANOL_CH3OH_PERCENT_CONTROL_PRECISION]: 'Partial pressure methanol CH3OH percent control precision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_METHANOL_CH3OH_PERCENT_CONTROL_AGILITY]: 'Partial pressure methanol CH3OH percent control agility',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_NATURAL_GAS_PERCENT_CONTROL_ACCURACY]: 'Partial pressure natural gas percent control accuracy',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_NATURAL_GAS_PERCENT_CONTROL_PRECISION]: 'Partial pressure natural gas percent control precision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_NATURAL_GAS_PERCENT_CONTROL_AGILITY]: 'Partial pressure natural gas percent control agility',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_NITROGEN_PERCENT_CONTROL_ACCURACY]: 'Partial pressure nitrogen percent control accuracy',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_NITROGEN_PERCENT_CONTROL_PRECISION]: 'Partial pressure nitrogen percent control precision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_NITROGEN_PERCENT_CONTROL_AGILITY]: 'Partial pressure nitrogen percent control agility',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_NITROUS_OXIDE_N2O_PERCENT_CONTROL_ACCURACY]: 'Partial pressure nitrous oxide N2O percent control accuracy',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_NITROUS_OXIDE_N2O_PERCENT_CONTROL_PRECISION]: 'Partial pressure nitrous oxide N2O percent control precision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_NITROUS_OXIDE_N2O_PERCENT_CONTROL_AGILITY]: 'Partial pressure nitrous oxide N2O percent control agility',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_PROPANE_C3H8_PERCENT_CONTROL_ACCURACY]: 'Partial pressure propane C3H8 percent control accuracy',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_PROPANE_C3H8_PERCENT_CONTROL_PRECISION]: 'Partial pressure propane C3H8 percent control precision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_PROPANE_C3H8_PERCENT_CONTROL_AGILITY]: 'Partial pressure propane C3H8 percent control agility',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_WATER_PERCENT_CONTROL_ACCURACY]: 'Partial pressure water percent control accuracy',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_WATER_PERCENT_CONTROL_PRECISION]: 'Partial pressure water percent control precision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_WATER_PERCENT_CONTROL_AGILITY]: 'Partial pressure water percent control agility',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_OXYGEN_CONTROL_ACCURACY]: 'Partial pressure oxygen control accuracy',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_OXYGEN_CONTROL_PRECISION]: 'Partial pressure oxygen control precision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_OXYGEN_CONTROL_AGILITY]: 'Partial pressure oxygen control agility',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_CARBON_CONTROL_ACCURACY]: 'Partial pressure carbon control accuracy',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_CARBON_CONTROL_PRECISION]: 'Partial pressure carbon control precision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_CARBON_CONTROL_AGILITY]: 'Partial pressure carbon control agility',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_BORON_CONTROL_ACCURACY]: 'Partial pressure boron control accuracy',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_BORON_CONTROL_PRECISION]: 'Partial pressure boron control precision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_BORON_CONTROL_AGILITY]: 'Partial pressure boron control agility',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_SULFUR_CONTROL_ACCURACY]: 'Partial pressure sulfur control accuracy',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_SULFUR_CONTROL_PRECISION]: 'Partial pressure sulfur control precision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_SULFUR_CONTROL_AGILITY]: 'Partial pressure sulfur control agility',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_OXYGEN_PERCENT_CONTROL_ACCURACY]: 'Partial pressure oxygen percent control accuracy',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_OXYGEN_PERCENT_CONTROL_PRECISION]: 'Partial pressure oxygen percent control precision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_OXYGEN_PERCENT_CONTROL_AGILITY]: 'Partial pressure oxygen percent control agility',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_CARBON_PERCENT_CONTROL_ACCURACY]: 'Partial pressure carbon percent control accuracy',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_CARBON_PERCENT_CONTROL_PRECISION]: 'Partial pressure carbon percent control precision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_CARBON_PERCENT_CONTROL_AGILITY]: 'Partial pressure carbon percent control agility',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_BORON_PERCENT_CONTROL_ACCURACY]: 'Partial pressure boron percent control accuracy',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_BORON_PERCENT_CONTROL_PRECISION]: 'Partial pressure boron percent control precision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_BORON_PERCENT_CONTROL_AGILITY]: 'Partial pressure boron percent control agility',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_SULFUR_PERCENT_CONTROL_ACCURACY]: 'Partial pressure sulfur percent control accuracy',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_SULFUR_PERCENT_CONTROL_PRECISION]: 'Partial pressure sulfur percent control precision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_SULFUR_PERCENT_CONTROL_AGILITY]: 'Partial pressure sulfur percent control agility',
  [EJobFeatureMeaningMap.ETHANE_C2H6_FLOW_CONTROL_ACCURACY]: 'Ethane C2H6 flow control accuracy',
  [EJobFeatureMeaningMap.ETHANE_C2H6_FLOW_CONTROL_AGILITY]: 'Ethane C2H6 flow control agility',
  [EJobFeatureMeaningMap.ETHANE_C2H6_FLOW_CONTROL_PRECISION]: 'Ethane C2H6 flow control precision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ETHANE_C2H6_CONTROL_ACCURACY]: 'Partial pressure ethane C2H6 control accuracy',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ETHANE_C2H6_CONTROL_AGILITY]: 'Partial pressure ethane C2H6 control agility',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ETHANE_C2H6_CONTROL_PRECISION]: 'Partial pressure ethane C2H6 control precision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ETHANE_C2H6_PERCENT_CONTROL_ACCURACY]: 'Partial pressure ethane C2H6 percent control accuracy',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ETHANE_C2H6_PERCENT_CONTROL_AGILITY]: 'Partial pressure ethane C2H6 percent control agility',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_ETHANE_C2H6_PERCENT_CONTROL_PRECISION]: 'Partial pressure ethane C2H6 percent control precision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_METHANE_CH4_CONTROL_ACCURACY]: 'Partial pressure methane CH4 control accuracy',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_METHANE_CH4_CONTROL_AGILITY]: 'Partial pressure methane CH4 control agility',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_METHANE_CH4_CONTROL_PRECISION]: 'Partial pressure methane CH4 control precision',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_METHANE_CH4_PERCENT_CONTROL_ACCURACY]: 'Partial pressure methane CH4 percent control accuracy',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_METHANE_CH4_PERCENT_CONTROL_AGILITY]: 'Partial pressure methane CH4 percent control agility',
  [EJobFeatureMeaningMap.PARTIAL_PRESSURE_METHANE_CH4_PERCENT_CONTROL_PRECISION]: 'Partial pressure methane CH4 percent control precision',
  [EJobFeatureMeaningMap.HEAT_LOSS_AT_500C]: 'Heat loss (v2)',
  [EJobFeatureMeaningMap.ASSET_DWELLING_TIME]: 'Asset dwelling time per job',
  [EJobFeatureMeaningMap.HIGH_VACUUM_PRESSURE_CONTROL_ACCURACY]: 'High vacuum pressure control accuracy',
  [EJobFeatureMeaningMap.HIGH_VACUUM_PRESSURE_CONTROL_AGILITY]: 'High vacuum pressure control agility',
  [EJobFeatureMeaningMap.HIGH_VACUUM_PRESSURE_CONTROL_PRECISION]: 'High vacuum pressure control precision',
  [EJobFeatureMeaningMap.LOW_VACUUM_PRESSURE_CONTROL_ACCURACY]: 'Low vacuum pressure control accuracy',
  [EJobFeatureMeaningMap.LOW_VACUUM_PRESSURE_CONTROL_AGILITY]: 'Low vacuum pressure control agility',
  [EJobFeatureMeaningMap.LOW_VACUUM_PRESSURE_CONTROL_PRECISION]: 'Low vacuum pressure control precision',
  [EJobFeatureMeaningMap.VACUUM_TIME_TO_CROSS_OVER]: 'Vaccum time to cross over',
  [EJobFeatureMeaningMap.VACUUM_MAX_LEAK_UP_RATE]: 'Maximum leak up time',
  [EJobFeatureMeaningMap.LEAD_TIME_CONTROL_ACCURACY]: 'Lead time control accuracy',
  [EJobFeatureMeaningMap.LEAD_TIME_CONTROL_AGILITY]: 'Lead time control agility',
  [EJobFeatureMeaningMap.LEAD_TIME_CONTROL_PRECISION]: 'Lead time control precision',
  [EJobFeatureMeaningMap.BELT_SPEED_CONTROL_ACCURACY]: 'Belt speed control accuracy',
  [EJobFeatureMeaningMap.BELT_SPEED_CONTROL_AGILITY]: 'Belt speed control agility',
  [EJobFeatureMeaningMap.BELT_SPEED_CONTROL_PRECISION]: 'Belt speed control precision',
  [EJobFeatureMeaningMap.PEARSON_CORRELATION]: 'Pearson Correlation',
  [EJobFeatureMeaningMap.SPEARMAN_CORRELATION]: 'Spearman Correlation',
  [EJobFeatureMeaningMap.USER_DEFINED_001]: 'User-defined job feature meaning 001',
  [EJobFeatureMeaningMap.USER_DEFINED_002]: 'User-defined job feature meaning 002',
  [EJobFeatureMeaningMap.USER_DEFINED_003]: 'User-defined job feature meaning 003',
  [EJobFeatureMeaningMap.USER_DEFINED_004]: 'User-defined job feature meaning 004',
  [EJobFeatureMeaningMap.USER_DEFINED_005]: 'User-defined job feature meaning 005',
  [EJobFeatureMeaningMap.USER_DEFINED_006]: 'User-defined job feature meaning 006',
  [EJobFeatureMeaningMap.USER_DEFINED_007]: 'User-defined job feature meaning 007',
  [EJobFeatureMeaningMap.USER_DEFINED_008]: 'User-defined job feature meaning 008',
  [EJobFeatureMeaningMap.USER_DEFINED_009]: 'User-defined job feature meaning 009',
  [EJobFeatureMeaningMap.USER_DEFINED_010]: 'User-defined job feature meaning 010',
  [EJobFeatureMeaningMap.USER_DEFINED_011]: 'User-defined job feature meaning 011',
  [EJobFeatureMeaningMap.USER_DEFINED_012]: 'User-defined job feature meaning 012',
  [EJobFeatureMeaningMap.USER_DEFINED_013]: 'User-defined job feature meaning 013',
  [EJobFeatureMeaningMap.USER_DEFINED_014]: 'User-defined job feature meaning 014',
  [EJobFeatureMeaningMap.USER_DEFINED_015]: 'User-defined job feature meaning 015',
  [EJobFeatureMeaningMap.USER_DEFINED_016]: 'User-defined job feature meaning 016',
  [EJobFeatureMeaningMap.USER_DEFINED_017]: 'User-defined job feature meaning 017',
  [EJobFeatureMeaningMap.USER_DEFINED_018]: 'User-defined job feature meaning 018',
  [EJobFeatureMeaningMap.USER_DEFINED_019]: 'User-defined job feature meaning 019',
  [EJobFeatureMeaningMap.USER_DEFINED_020]: 'User-defined job feature meaning 020',
  [EJobFeatureMeaningMap.USER_DEFINED_021]: 'User-defined job feature meaning 021',
  [EJobFeatureMeaningMap.USER_DEFINED_022]: 'User-defined job feature meaning 022',
  [EJobFeatureMeaningMap.USER_DEFINED_023]: 'User-defined job feature meaning 023',
  [EJobFeatureMeaningMap.USER_DEFINED_024]: 'User-defined job feature meaning 024',
  [EJobFeatureMeaningMap.USER_DEFINED_025]: 'User-defined job feature meaning 025',
  [EJobFeatureMeaningMap.USER_DEFINED_026]: 'User-defined job feature meaning 026',
  [EJobFeatureMeaningMap.USER_DEFINED_027]: 'User-defined job feature meaning 027',
  [EJobFeatureMeaningMap.USER_DEFINED_028]: 'User-defined job feature meaning 028',
  [EJobFeatureMeaningMap.USER_DEFINED_029]: 'User-defined job feature meaning 029',
  [EJobFeatureMeaningMap.USER_DEFINED_030]: 'User-defined job feature meaning 030',
  [EJobFeatureMeaningMap.USER_DEFINED_031]: 'User-defined job feature meaning 031',
  [EJobFeatureMeaningMap.USER_DEFINED_032]: 'User-defined job feature meaning 032',
  [EJobFeatureMeaningMap.USER_DEFINED_033]: 'User-defined job feature meaning 033',
  [EJobFeatureMeaningMap.USER_DEFINED_034]: 'User-defined job feature meaning 034',
  [EJobFeatureMeaningMap.USER_DEFINED_035]: 'User-defined job feature meaning 035',
  [EJobFeatureMeaningMap.USER_DEFINED_036]: 'User-defined job feature meaning 036',
  [EJobFeatureMeaningMap.USER_DEFINED_037]: 'User-defined job feature meaning 037',
  [EJobFeatureMeaningMap.USER_DEFINED_038]: 'User-defined job feature meaning 038',
  [EJobFeatureMeaningMap.USER_DEFINED_039]: 'User-defined job feature meaning 039',
  [EJobFeatureMeaningMap.USER_DEFINED_040]: 'User-defined job feature meaning 040',
  [EJobFeatureMeaningMap.USER_DEFINED_041]: 'User-defined job feature meaning 041',
  [EJobFeatureMeaningMap.USER_DEFINED_042]: 'User-defined job feature meaning 042',
  [EJobFeatureMeaningMap.USER_DEFINED_043]: 'User-defined job feature meaning 043',
  [EJobFeatureMeaningMap.USER_DEFINED_044]: 'User-defined job feature meaning 044',
  [EJobFeatureMeaningMap.USER_DEFINED_045]: 'User-defined job feature meaning 045',
  [EJobFeatureMeaningMap.USER_DEFINED_046]: 'User-defined job feature meaning 046',
  [EJobFeatureMeaningMap.USER_DEFINED_047]: 'User-defined job feature meaning 047',
  [EJobFeatureMeaningMap.USER_DEFINED_048]: 'User-defined job feature meaning 048',
  [EJobFeatureMeaningMap.USER_DEFINED_049]: 'User-defined job feature meaning 049',
  [EJobFeatureMeaningMap.USER_DEFINED_050]: 'User-defined job feature meaning 050',
  [EJobFeatureMeaningMap.USER_DEFINED_051]: 'User-defined job feature meaning 051',
  [EJobFeatureMeaningMap.USER_DEFINED_052]: 'User-defined job feature meaning 052',
  [EJobFeatureMeaningMap.USER_DEFINED_053]: 'User-defined job feature meaning 053',
  [EJobFeatureMeaningMap.USER_DEFINED_054]: 'User-defined job feature meaning 054',
  [EJobFeatureMeaningMap.USER_DEFINED_055]: 'User-defined job feature meaning 055',
  [EJobFeatureMeaningMap.USER_DEFINED_056]: 'User-defined job feature meaning 056',
  [EJobFeatureMeaningMap.USER_DEFINED_057]: 'User-defined job feature meaning 057',
  [EJobFeatureMeaningMap.USER_DEFINED_058]: 'User-defined job feature meaning 058',
  [EJobFeatureMeaningMap.USER_DEFINED_059]: 'User-defined job feature meaning 059',
  [EJobFeatureMeaningMap.USER_DEFINED_060]: 'User-defined job feature meaning 060',
  [EJobFeatureMeaningMap.USER_DEFINED_061]: 'User-defined job feature meaning 061',
  [EJobFeatureMeaningMap.USER_DEFINED_062]: 'User-defined job feature meaning 062',
  [EJobFeatureMeaningMap.USER_DEFINED_063]: 'User-defined job feature meaning 063',
  [EJobFeatureMeaningMap.USER_DEFINED_064]: 'User-defined job feature meaning 064',
  [EJobFeatureMeaningMap.USER_DEFINED_065]: 'User-defined job feature meaning 065',
  [EJobFeatureMeaningMap.USER_DEFINED_066]: 'User-defined job feature meaning 066',
  [EJobFeatureMeaningMap.USER_DEFINED_067]: 'User-defined job feature meaning 067',
  [EJobFeatureMeaningMap.USER_DEFINED_068]: 'User-defined job feature meaning 068',
  [EJobFeatureMeaningMap.USER_DEFINED_069]: 'User-defined job feature meaning 069',
  [EJobFeatureMeaningMap.USER_DEFINED_070]: 'User-defined job feature meaning 070',
  [EJobFeatureMeaningMap.USER_DEFINED_071]: 'User-defined job feature meaning 071',
  [EJobFeatureMeaningMap.USER_DEFINED_072]: 'User-defined job feature meaning 072',
  [EJobFeatureMeaningMap.USER_DEFINED_073]: 'User-defined job feature meaning 073',
  [EJobFeatureMeaningMap.USER_DEFINED_074]: 'User-defined job feature meaning 074',
  [EJobFeatureMeaningMap.USER_DEFINED_075]: 'User-defined job feature meaning 075',
  [EJobFeatureMeaningMap.USER_DEFINED_076]: 'User-defined job feature meaning 076',
  [EJobFeatureMeaningMap.USER_DEFINED_077]: 'User-defined job feature meaning 077',
  [EJobFeatureMeaningMap.USER_DEFINED_078]: 'User-defined job feature meaning 078',
  [EJobFeatureMeaningMap.USER_DEFINED_079]: 'User-defined job feature meaning 079',
  [EJobFeatureMeaningMap.USER_DEFINED_080]: 'User-defined job feature meaning 080',
};

const TimeseriesMeaning: Record<ETimeseriesMeaning, string> = {
  [ETimeseriesMeaning.TEMPERATURE_SETPOINT]: 'Temperature setpoint',
  [ETimeseriesMeaning.TEMPERATURE_READING]: 'Temperature reading',
  [ETimeseriesMeaning.HEATING_POWER_OUTPUT_SETPOINT]: 'Heating power output setpoint',
  [ETimeseriesMeaning.HEATING_POWER_OUTPUT_READING]: 'Heating power output reading',
  [ETimeseriesMeaning.HEATING_POWER_ENABLED]: 'Heating power enabled',
  [ETimeseriesMeaning.ELECTRICAL_POWER_SETPOINT]: 'Electrical power setpoint',
  [ETimeseriesMeaning.ELECTRICAL_POWER_READING]: 'Electrical power reading',
  [ETimeseriesMeaning.TOTAL_HEATING_GAS_SUPPLY_FLOW_READING]: 'Total heating gas supply flow reading',
  [ETimeseriesMeaning.COOLING_POWER_OUTPUT_SETPOINT]: 'Cooling power output setpoint',
  [ETimeseriesMeaning.COOLING_POWER_OUTPUT_READING]: 'Cooling power output reading',
  [ETimeseriesMeaning.COOLING_POWER_ENABLED]: 'Cooling power enabled',
  [ETimeseriesMeaning.TOTAL_COOLING_GAS_SUPPLY_FLOW_READING]: 'Total cooling gas supply flow reading',
  [ETimeseriesMeaning.RELATIVE_PRESSURE_SETPOINT]: 'Relative pressure setpoint',
  [ETimeseriesMeaning.RELATIVE_PRESSURE_READING]: 'Relative pressure reading',
  [ETimeseriesMeaning.CHAMBER_TEMPERATURE_SETPOINT]: 'Chamber temperature setpoint',
  [ETimeseriesMeaning.CHAMBER_TEMPERATURE_READING]: 'Chamber temperature reading',
  [ETimeseriesMeaning.ABSOLUTE_PRESSURE_SETPOINT]: 'Absolute pressure setpoint',
  [ETimeseriesMeaning.ABSOLUTE_PRESSURE_READING]: 'Absolute pressure reading',
  [ETimeseriesMeaning.VACUUM_PRESSURE_SETPOINT]: 'Vacuum pressure setpoint',
  [ETimeseriesMeaning.VACUUM_PRESSURE_READING]: 'Vacuum pressure reading',
  [ETimeseriesMeaning.ATMOSPHERIC_PRESSURE_SETPOINT]: 'Atmospheric pressure setpoint',
  [ETimeseriesMeaning.ATMOSPHERIC_PRESSURE_READING]: 'Atmospheric pressure reading',
  [ETimeseriesMeaning.ROOM_TEMPERATURE_SETPOINT]: 'Room temperature setpoint',
  [ETimeseriesMeaning.ROOM_TEMPERATURE_READING]: 'Room temperature reading',
  [ETimeseriesMeaning.ACTIVITY_AH_SETPOINT]: 'Activity aH setpoint',
  [ETimeseriesMeaning.ACTIVITY_AH_CALCULATION]: 'Activity aH calculation',
  [ETimeseriesMeaning.POTENTIAL_HP_SETPOINT]: 'Potential HP setpoint',
  [ETimeseriesMeaning.POTENTIAL_HP_CALCULATION]: 'Potential HP calculation',
  [ETimeseriesMeaning.POTENTIAL_KH_SETPOINT]: 'Potential kH setpoint',
  [ETimeseriesMeaning.POTENTIAL_KH_CALCULATION]: 'Potential kH calculation',
  [ETimeseriesMeaning.ACTIVITY_AO_SETPOINT]: 'Activity aO setpoint',
  [ETimeseriesMeaning.ACTIVITY_AO_CALCULATION]: 'Activity aO calculation',
  [ETimeseriesMeaning.POTENTIAL_OP_SETPOINT]: 'Potential OP setpoint',
  [ETimeseriesMeaning.POTENTIAL_OP_CALCULATION]: 'Potential OP calculation',
  [ETimeseriesMeaning.POTENTIAL_KO_SETPOINT]: 'Potential kO setpoint',
  [ETimeseriesMeaning.POTENTIAL_KO_CALCULATION]: 'Potential kO calculation',
  [ETimeseriesMeaning.ACTIVITY_AC_SETPOINT]: 'Activity aC setpoint',
  [ETimeseriesMeaning.ACTIVITY_AC_CALCULATION]: 'Activity aC calculation',
  [ETimeseriesMeaning.POTENTIAL_CP_SETPOINT]: 'Potential CP setpoint',
  [ETimeseriesMeaning.POTENTIAL_CP_CALCULATION]: 'Potential CP calculation',
  [ETimeseriesMeaning.POTENTIAL_KC_SETPOINT]: 'Potential kC setpoint',
  [ETimeseriesMeaning.POTENTIAL_KC_CALCULATION]: 'Potential kC calculation',
  [ETimeseriesMeaning.ACTIVITY_AN_SETPOINT]: 'Activity aN setpoint',
  [ETimeseriesMeaning.ACTIVITY_AN_CALCULATION]: 'Activity aN calculation',
  [ETimeseriesMeaning.POTENTIAL_NP_SETPOINT]: 'Potential NP setpoint',
  [ETimeseriesMeaning.POTENTIAL_NP_CALCULATION]: 'Potential NP calculation',
  [ETimeseriesMeaning.POTENTIAL_KN_SETPOINT]: 'Potential kN setpoint',
  [ETimeseriesMeaning.POTENTIAL_KN_CALCULATION]: 'Potential kN calculation',
  [ETimeseriesMeaning.ACTIVITY_AB_SETPOINT]: 'Activity aB setpoint',
  [ETimeseriesMeaning.ACTIVITY_AB_CALCULATION]: 'Activity aB calculation',
  [ETimeseriesMeaning.POTENTIAL_BP_SETPOINT]: 'Potential BP setpoint',
  [ETimeseriesMeaning.POTENTIAL_BP_CALCULATION]: 'Potential BP calculation',
  [ETimeseriesMeaning.POTENTIAL_KB_SETPOINT]: 'Potential kB setpoint',
  [ETimeseriesMeaning.POTENTIAL_KB_CALCULATION]: 'Potential kB calculation',
  [ETimeseriesMeaning.ACTIVITY_AS_SETPOINT]: 'Activity aS setpoint',
  [ETimeseriesMeaning.ACTIVITY_AS_CALCULATION]: 'Activity aS calculation',
  [ETimeseriesMeaning.POTENTIAL_SP_SETPOINT]: 'Potential SP setpoint',
  [ETimeseriesMeaning.POTENTIAL_SP_CALCULATION]: 'Potential SP calculation',
  [ETimeseriesMeaning.POTENTIAL_KS_SETPOINT]: 'Potential kS setpoint',
  [ETimeseriesMeaning.POTENTIAL_KS_CALCULATION]: 'Potential kS calculation',
  [ETimeseriesMeaning.ACETYLENE_C2H2_FLOW_SETPOINT]: 'Acetylene C2H2 flow setpoint',
  [ETimeseriesMeaning.ACETYLENE_C2H2_FLOW_READING]: 'Acetylene C2H2 flow reading',
  [ETimeseriesMeaning.AIR_FLOW_SETPOINT]: 'Air flow setpoint',
  [ETimeseriesMeaning.AIR_FLOW_READING]: 'Air flow reading',
  [ETimeseriesMeaning.AMMONIA_NH3_FLOW_SETPOINT]: 'Ammonia NH3 flow setpoint',
  [ETimeseriesMeaning.AMMONIA_NH3_FLOW_READING]: 'Ammonia NH3 flow reading',
  [ETimeseriesMeaning.ARGON_FLOW_SETPOINT]: 'Argon flow setpoint',
  [ETimeseriesMeaning.ARGON_FLOW_READING]: 'Argon flow reading',
  [ETimeseriesMeaning.CARBON_DIOXIDE_CO2_FLOW_SETPOINT]: 'Carbon dioxide CO2 flow setpoint',
  [ETimeseriesMeaning.CARBON_DIOXIDE_CO2_FLOW_READING]: 'Carbon dioxide CO2 flow reading',
  [ETimeseriesMeaning.CARBON_MONOXIDE_CO_FLOW_SETPOINT]: 'Carbon monoxide CO flow setpoint',
  [ETimeseriesMeaning.CARBON_MONOXIDE_CO_FLOW_READING]: 'Carbon monoxide CO flow reading',
  [ETimeseriesMeaning.DISSOCIATED_AMMONIA_NH3_FLOW_SETPOINT]: 'Dissociated ammonia NH3 flow setpoint',
  [ETimeseriesMeaning.DISSOCIATED_AMMONIA_NH3_FLOW_READING]: 'Dissociated ammonia NH3 flow reading',
  [ETimeseriesMeaning.ENDO_GAS_NATURAL_FLOW_SETPOINT]: 'Endo gas natural flow setpoint',
  [ETimeseriesMeaning.ENDO_GAS_NATURAL_FLOW_READING]: 'Endo gas natural flow reading',
  [ETimeseriesMeaning.ENDO_GAS_PROPANE_FLOW_SETPOINT]: 'Endo gas propane flow setpoint',
  [ETimeseriesMeaning.ENDO_GAS_PROPANE_FLOW_READING]: 'Endo gas propane flow reading',
  [ETimeseriesMeaning.ETHENE_C2H4_FLOW_SETPOINT]: 'Ethene C2H4 flow setpoint',
  [ETimeseriesMeaning.ETHENE_C2H4_FLOW_READING]: 'Ethene C2H4 flow reading',
  [ETimeseriesMeaning.HELIUM_FLOW_SETPOINT]: 'Helium flow setpoint',
  [ETimeseriesMeaning.HELIUM_FLOW_READING]: 'Helium flow reading',
  [ETimeseriesMeaning.HYDROGEN_H2_FLOW_SETPOINT]: 'Hydrogen H2 flow setpoint',
  [ETimeseriesMeaning.HYDROGEN_H2_FLOW_READING]: 'Hydrogen H2 flow reading',
  [ETimeseriesMeaning.METHANOL_CH3OH_FLOW_SETPOINT]: 'Methanol CH3OH flow setpoint',
  [ETimeseriesMeaning.METHANOL_CH3OH_FLOW_READING]: 'Methanol CH3OH flow reading',
  [ETimeseriesMeaning.NATURAL_GAS_FLOW_SETPOINT]: 'Natural gas flow setpoint',
  [ETimeseriesMeaning.NATURAL_GAS_FLOW_READING]: 'Natural gas flow reading',
  [ETimeseriesMeaning.NITROGEN_FLOW_SETPOINT]: 'Nitrogen flow setpoint',
  [ETimeseriesMeaning.NITROGEN_FLOW_READING]: 'Nitrogen flow reading',
  [ETimeseriesMeaning.PROPANE_C3H8_FLOW_SETPOINT]: 'Propane C3H8 flow setpoint',
  [ETimeseriesMeaning.PROPANE_C3H8_FLOW_READING]: 'Propane C3H8 flow reading',
  [ETimeseriesMeaning.WATER_FLOW_SETPOINT]: 'Water flow setpoint',
  [ETimeseriesMeaning.WATER_FLOW_READING]: 'Water flow reading',
  [ETimeseriesMeaning.NITROUS_OXIDE_N2O_FLOW_SETPOINT]: 'Nitrous oxide N2O flow setpoint',
  [ETimeseriesMeaning.NITROUS_OXIDE_N2O_FLOW_READING]: 'Nitrous oxide N2O flow reading',
  [ETimeseriesMeaning.METHANE_CH4_FLOW_SETPOINT]: 'Methane CH4 flow setpoint',
  [ETimeseriesMeaning.METHANE_CH4_FLOW_READING]: 'Methane CH4 flow reading',
  [ETimeseriesMeaning.OXYGEN_O2_FLOW_SETPOINT]: 'Oxygen O2 flow setpoint',
  [ETimeseriesMeaning.OXYGEN_O2_FLOW_READING]: 'Oxygen O2 flow reading',
  [ETimeseriesMeaning.DISSOCIATION_SETPOINT]: 'Dissociation setpoint',
  [ETimeseriesMeaning.DISSOCIATION_READING]: 'Dissociation reading',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_ACETYLENE_C2H2_SETPOINT]: 'Partial pressure acetylene C2H2 setpoint',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_ACETYLENE_C2H2_READING]: 'Partial pressure acetylene C2H2 reading',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_AIR_SETPOINT]: 'Partial pressure air setpoint',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_AIR_READING]: 'Partial pressure air reading',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_AMMONIA_NH3_SETPOINT]: 'Partial pressure ammonia NH3 setpoint',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_AMMONIA_NH3_READING]: 'Partial pressure ammonia NH3 reading',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_ARGON_SETPOINT]: 'Partial pressure argon setpoint',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_ARGON_READING]: 'Partial pressure argon reading',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_CARBON_DIOXIDE_CO2_SETPOINT]: 'Partial pressure carbon dioxide CO2 setpoint',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_CARBON_DIOXIDE_CO2_READING]: 'Partial pressure carbon dioxide CO2 reading',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_CARBON_MONOXIDE_CO_SETPOINT]: 'Partial pressure carbon monoxide CO setpoint',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_CARBON_MONOXIDE_CO_READING]: 'Partial pressure carbon monoxide CO reading',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_DISSOCIATED_AMMONIA_NH3_SETPOINT]: 'Partial pressure dissociated ammonia NH3 setpoint',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_DISSOCIATED_AMMONIA_NH3_READING]: 'Partial pressure dissociated ammonia NH3 reading',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_ENDO_GAS_NATURAL_SETPOINT]: 'Partial pressure endo gas natural setpoint',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_ENDO_GAS_NATURAL_READING]: 'Partial pressure endo gas natural reading',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_ENDO_GAS_PROPANE_SETPOINT]: 'Partial pressure endo gas propane setpoint',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_ENDO_GAS_PROPANE_READING]: 'Partial pressure endo gas propane reading',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_ETHENE_C2H4_SETPOINT]: 'Partial pressure ethene C2H4 setpoint',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_ETHENE_C2H4_READING]: 'Partial pressure ethene C2H4 reading',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_HELIUM_SETPOINT]: 'Partial pressure helium setpoint',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_HELIUM_READING]: 'Partial pressure helium reading',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_HYDROGEN_H2_SETPOINT]: 'Partial pressure hydrogen H2 setpoint',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_HYDROGEN_H2_READING]: 'Partial pressure hydrogen H2 reading',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_METHANOL_CH3OH_SETPOINT]: 'Partial pressure methanol CH3OH setpoint',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_METHANOL_CH3OH_READING]: 'Partial pressure methanol CH3OH reading',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_NATURAL_GAS_SETPOINT]: 'Partial pressure natural gas setpoint',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_NATURAL_GAS_READING]: 'Partial pressure natural gas reading',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_NITROGEN_SETPOINT]: 'Partial pressure nitrogen setpoint',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_NITROGEN_READING]: 'Partial pressure nitrogen reading',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_NITROUS_OXIDE_N2O_SETPOINT]: 'Partial pressure nitrous oxide N2O setpoint',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_NITROUS_OXIDE_N2O_READING]: 'Partial pressure nitrous oxide N2O reading',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_PROPANE_C3H8_SETPOINT]: 'Partial pressure propane C3H8 setpoint',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_PROPANE_C3H8_READING]: 'Partial pressure propane C3H8 reading',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_WATER_SETPOINT]: 'Partial pressure water setpoint',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_WATER_READING]: 'Partial pressure water reading',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_ACETYLENE_C2H2_PERCENT_SETPOINT]: 'Partial pressure acetylene C2H2 percent setpoint',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_ACETYLENE_C2H2_PERCENT_READING]: 'Partial pressure acetylene C2H2 percent reading',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_AIR_PERCENT_SETPOINT]: 'Partial pressure air percent setpoint',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_AIR_PERCENT_READING]: 'Partial pressure air percent reading',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_AMMONIA_NH3_PERCENT_SETPOINT]: 'Partial pressure ammonia NH3 percent setpoint',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_AMMONIA_NH3_PERCENT_READING]: 'Partial pressure ammonia NH3 percent reading',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_ARGON_PERCENT_SETPOINT]: 'Partial pressure argon percent setpoint',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_ARGON_PERCENT_READING]: 'Partial pressure argon percent reading',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_CARBON_DIOXIDE_CO2_PERCENT_SETPOINT]: 'Partial pressure carbon dioxide CO2 percent setpoint',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_CARBON_DIOXIDE_CO2_PERCENT_READING]: 'Partial pressure carbon dioxide CO2 percent reading',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_CARBON_MONOXIDE_CO_PERCENT_SETPOINT]: 'Partial pressure carbon monoxide CO percent setpoint',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_CARBON_MONOXIDE_CO_PERCENT_READING]: 'Partial pressure carbon monoxide CO percent reading',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_DISSOCIATED_AMMONIA_NH3_PERCENT_SETPOINT]: 'Partial pressure dissociated ammonia NH3 percent setpoint',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_DISSOCIATED_AMMONIA_NH3_PERCENT_READING]: 'Partial pressure dissociated ammonia NH3 percent reading',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_ENDO_GAS_NATURAL_PERCENT_SETPOINT]: 'Partial pressure endo gas natural percent setpoint',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_ENDO_GAS_NATURAL_PERCENT_READING]: 'Partial pressure endo gas natural percent reading',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_ENDO_GAS_PROPANE_PERCENT_SETPOINT]: 'Partial pressure endo gas propane percent setpoint',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_ENDO_GAS_PROPANE_PERCENT_READING]: 'Partial pressure endo gas propane percent reading',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_ETHENE_C2H4_PERCENT_SETPOINT]: 'Partial pressure ethene C2H4 percent setpoint',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_ETHENE_C2H4_PERCENT_READING]: 'Partial pressure ethene C2H4 percent reading',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_HELIUM_PERCENT_SETPOINT]: 'Partial pressure helium percent setpoint',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_HELIUM_PERCENT_READING]: 'Partial pressure helium percent reading',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_HYDROGEN_H2_PERCENT_SETPOINT]: 'Partial pressure hydrogen H2 percent setpoint',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_HYDROGEN_H2_PERCENT_READING]: 'Partial pressure hydrogen H2 percent reading',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_METHANOL_CH3OH_PERCENT_SETPOINT]: 'Partial pressure methanol CH3OH percent setpoint',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_METHANOL_CH3OH_PERCENT_READING]: 'Partial pressure methanol CH3OH percent reading',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_NATURAL_GAS_PERCENT_SETPOINT]: 'Partial pressure natural gas percent setpoint',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_NATURAL_GAS_PERCENT_READING]: 'Partial pressure natural gas percent reading',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_NITROGEN_PERCENT_SETPOINT]: 'Partial pressure nitrogen percent setpoint',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_NITROGEN_PERCENT_READING]: 'Partial pressure nitrogen percent reading',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_NITROUS_OXIDE_N2O_PERCENT_SETPOINT]: 'Partial pressure nitrous oxide N2O percent setpoint',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_NITROUS_OXIDE_N2O_PERCENT_READING]: 'Partial pressure nitrous oxide N2O percent reading',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_PROPANE_C3H8_PERCENT_SETPOINT]: 'Partial pressure propane C3H8 percent setpoint',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_PROPANE_C3H8_PERCENT_READING]: 'Partial pressure propane C3H8 percent reading',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_WATER_PERCENT_SETPOINT]: 'Partial pressure water percent setpoint',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_WATER_PERCENT_READING]: 'Partial pressure water percent reading',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_OXYGEN_SETPOINT]: 'Partial pressure oxygen setpoint',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_OXYGEN_READING]: 'Partial pressure oxygen reading',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_CARBON_SETPOINT]: 'Partial pressure carbon setpoint',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_CARBON_READING]: 'Partial pressure carbon reading',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_BORON_SETPOINT]: 'Partial pressure boron setpoint',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_BORON_READING]: 'Partial pressure boron reading',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_SULFUR_SETPOINT]: 'Partial pressure sulfur setpoint',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_SULFUR_READING]: 'Partial pressure sulfur reading',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_OXYGEN_PERCENT_SETPOINT]: 'Partial pressure oxygen percent setpoint',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_OXYGEN_PERCENT_READING]: 'Partial pressure oxygen percent reading',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_CARBON_PERCENT_SETPOINT]: 'Partial pressure carbon percent setpoint',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_CARBON_PERCENT_READING]: 'Partial pressure carbon percent reading',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_BORON_PERCENT_SETPOINT]: 'Partial pressure boron percent setpoint',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_BORON_PERCENT_READING]: 'Partial pressure boron percent reading',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_SULFUR_PERCENT_SETPOINT]: 'Partial pressure sulfur percent setpoint',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_SULFUR_PERCENT_READING]: 'Partial pressure sulfur percent reading',
  [ETimeseriesMeaning.ETHANE_C2H6_FLOW_SETPOINT]: 'Ethane C2H6 flow setpoint',
  [ETimeseriesMeaning.ETHANE_C2H6_FLOW_READING]: 'Ethane C2H6 flow reading',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_ETHANE_C2H6_SETPOINT]: 'Partial pressure ethane C2H6 setpoint',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_ETHANE_C2H6_READING]: 'Partial pressure ethane C2H6 reading',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_ETHANE_C2H6_PERCENT_SETPOINT]: 'Partial pressure ethane C2H6 percent setpoint',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_ETHANE_C2H6_PERCENT_READING]: 'Partial pressure ethane C2H6 percent reading',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_METHANE_CH4_SETPOINT]: 'Partial pressure methane CH4 setpoint',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_METHANE_CH4_READING]: 'Partial pressure methane CH4 reading',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_METHANE_CH4_PERCENT_SETPOINT]: 'Partial pressure methane CH4 percent setpoint',
  [ETimeseriesMeaning.PARTIAL_PRESSURE_METHANE_CH4_PERCENT_READING]: 'Partial pressure methane CH4 percent reading',
  [ETimeseriesMeaning.HIGH_VACUUM_PRESSURE_SETPOINT]: 'High vacuum pressure setpoint',
  [ETimeseriesMeaning.HIGH_VACUUM_PRESSURE_READING]: 'High vacuum pressure reading',
  [ETimeseriesMeaning.LOW_VACUUM_PRESSURE_SETPOINT]: 'Low vacuum pressure setpoint',
  [ETimeseriesMeaning.LOW_VACUUM_PRESSURE_READING]: 'Low vacuum pressure reading',
  [ETimeseriesMeaning.LEAD_TIME_SETPOINT]: 'Lead time setpoint',
  [ETimeseriesMeaning.LEAD_TIME_READING]: 'Lead time reading',
  [ETimeseriesMeaning.BELT_SPEED_SETPOINT]: 'Belt speed setpoint',
  [ETimeseriesMeaning.BELT_SPEED_READING]: 'Belt speed reading',
  [ETimeseriesMeaning.USER_DEFINED_001]: 'User-defined timeseries meaning 001',
  [ETimeseriesMeaning.USER_DEFINED_002]: 'User-defined timeseries meaning 002',
  [ETimeseriesMeaning.USER_DEFINED_003]: 'User-defined timeseries meaning 003',
  [ETimeseriesMeaning.USER_DEFINED_004]: 'User-defined timeseries meaning 004',
  [ETimeseriesMeaning.USER_DEFINED_005]: 'User-defined timeseries meaning 005',
  [ETimeseriesMeaning.USER_DEFINED_006]: 'User-defined timeseries meaning 006',
  [ETimeseriesMeaning.USER_DEFINED_007]: 'User-defined timeseries meaning 007',
  [ETimeseriesMeaning.USER_DEFINED_008]: 'User-defined timeseries meaning 008',
  [ETimeseriesMeaning.USER_DEFINED_009]: 'User-defined timeseries meaning 009',
  [ETimeseriesMeaning.USER_DEFINED_010]: 'User-defined timeseries meaning 010',
  [ETimeseriesMeaning.USER_DEFINED_011]: 'User-defined timeseries meaning 011',
  [ETimeseriesMeaning.USER_DEFINED_012]: 'User-defined timeseries meaning 012',
  [ETimeseriesMeaning.USER_DEFINED_013]: 'User-defined timeseries meaning 013',
  [ETimeseriesMeaning.USER_DEFINED_014]: 'User-defined timeseries meaning 014',
  [ETimeseriesMeaning.USER_DEFINED_015]: 'User-defined timeseries meaning 015',
  [ETimeseriesMeaning.USER_DEFINED_016]: 'User-defined timeseries meaning 016',
  [ETimeseriesMeaning.USER_DEFINED_017]: 'User-defined timeseries meaning 017',
  [ETimeseriesMeaning.USER_DEFINED_018]: 'User-defined timeseries meaning 018',
  [ETimeseriesMeaning.USER_DEFINED_019]: 'User-defined timeseries meaning 019',
  [ETimeseriesMeaning.USER_DEFINED_020]: 'User-defined timeseries meaning 020',
  [ETimeseriesMeaning.USER_DEFINED_021]: 'User-defined timeseries meaning 021',
  [ETimeseriesMeaning.USER_DEFINED_022]: 'User-defined timeseries meaning 022',
  [ETimeseriesMeaning.USER_DEFINED_023]: 'User-defined timeseries meaning 023',
  [ETimeseriesMeaning.USER_DEFINED_024]: 'User-defined timeseries meaning 024',
  [ETimeseriesMeaning.USER_DEFINED_025]: 'User-defined timeseries meaning 025',
  [ETimeseriesMeaning.USER_DEFINED_026]: 'User-defined timeseries meaning 026',
  [ETimeseriesMeaning.USER_DEFINED_027]: 'User-defined timeseries meaning 027',
  [ETimeseriesMeaning.USER_DEFINED_028]: 'User-defined timeseries meaning 028',
  [ETimeseriesMeaning.USER_DEFINED_029]: 'User-defined timeseries meaning 029',
  [ETimeseriesMeaning.USER_DEFINED_030]: 'User-defined timeseries meaning 030',
  [ETimeseriesMeaning.USER_DEFINED_031]: 'User-defined timeseries meaning 031',
  [ETimeseriesMeaning.USER_DEFINED_032]: 'User-defined timeseries meaning 032',
  [ETimeseriesMeaning.USER_DEFINED_033]: 'User-defined timeseries meaning 033',
  [ETimeseriesMeaning.USER_DEFINED_034]: 'User-defined timeseries meaning 034',
  [ETimeseriesMeaning.USER_DEFINED_035]: 'User-defined timeseries meaning 035',
  [ETimeseriesMeaning.USER_DEFINED_036]: 'User-defined timeseries meaning 036',
  [ETimeseriesMeaning.USER_DEFINED_037]: 'User-defined timeseries meaning 037',
  [ETimeseriesMeaning.USER_DEFINED_038]: 'User-defined timeseries meaning 038',
  [ETimeseriesMeaning.USER_DEFINED_039]: 'User-defined timeseries meaning 039',
  [ETimeseriesMeaning.USER_DEFINED_040]: 'User-defined timeseries meaning 040',
  [ETimeseriesMeaning.USER_DEFINED_041]: 'User-defined timeseries meaning 041',
  [ETimeseriesMeaning.USER_DEFINED_042]: 'User-defined timeseries meaning 042',
  [ETimeseriesMeaning.USER_DEFINED_043]: 'User-defined timeseries meaning 043',
  [ETimeseriesMeaning.USER_DEFINED_044]: 'User-defined timeseries meaning 044',
  [ETimeseriesMeaning.USER_DEFINED_045]: 'User-defined timeseries meaning 045',
  [ETimeseriesMeaning.USER_DEFINED_046]: 'User-defined timeseries meaning 046',
  [ETimeseriesMeaning.USER_DEFINED_047]: 'User-defined timeseries meaning 047',
  [ETimeseriesMeaning.USER_DEFINED_048]: 'User-defined timeseries meaning 048',
  [ETimeseriesMeaning.USER_DEFINED_049]: 'User-defined timeseries meaning 049',
  [ETimeseriesMeaning.USER_DEFINED_050]: 'User-defined timeseries meaning 050',
  [ETimeseriesMeaning.USER_DEFINED_051]: 'User-defined timeseries meaning 051',
  [ETimeseriesMeaning.USER_DEFINED_052]: 'User-defined timeseries meaning 052',
  [ETimeseriesMeaning.USER_DEFINED_053]: 'User-defined timeseries meaning 053',
  [ETimeseriesMeaning.USER_DEFINED_054]: 'User-defined timeseries meaning 054',
  [ETimeseriesMeaning.USER_DEFINED_055]: 'User-defined timeseries meaning 055',
  [ETimeseriesMeaning.USER_DEFINED_056]: 'User-defined timeseries meaning 056',
  [ETimeseriesMeaning.USER_DEFINED_057]: 'User-defined timeseries meaning 057',
  [ETimeseriesMeaning.USER_DEFINED_058]: 'User-defined timeseries meaning 058',
  [ETimeseriesMeaning.USER_DEFINED_059]: 'User-defined timeseries meaning 059',
  [ETimeseriesMeaning.USER_DEFINED_060]: 'User-defined timeseries meaning 060',
  [ETimeseriesMeaning.USER_DEFINED_061]: 'User-defined timeseries meaning 061',
  [ETimeseriesMeaning.USER_DEFINED_062]: 'User-defined timeseries meaning 062',
  [ETimeseriesMeaning.USER_DEFINED_063]: 'User-defined timeseries meaning 063',
  [ETimeseriesMeaning.USER_DEFINED_064]: 'User-defined timeseries meaning 064',
  [ETimeseriesMeaning.USER_DEFINED_065]: 'User-defined timeseries meaning 065',
  [ETimeseriesMeaning.USER_DEFINED_066]: 'User-defined timeseries meaning 066',
  [ETimeseriesMeaning.USER_DEFINED_067]: 'User-defined timeseries meaning 067',
  [ETimeseriesMeaning.USER_DEFINED_068]: 'User-defined timeseries meaning 068',
  [ETimeseriesMeaning.USER_DEFINED_069]: 'User-defined timeseries meaning 069',
  [ETimeseriesMeaning.USER_DEFINED_070]: 'User-defined timeseries meaning 070',
  [ETimeseriesMeaning.USER_DEFINED_071]: 'User-defined timeseries meaning 071',
  [ETimeseriesMeaning.USER_DEFINED_072]: 'User-defined timeseries meaning 072',
  [ETimeseriesMeaning.USER_DEFINED_073]: 'User-defined timeseries meaning 073',
  [ETimeseriesMeaning.USER_DEFINED_074]: 'User-defined timeseries meaning 074',
  [ETimeseriesMeaning.USER_DEFINED_075]: 'User-defined timeseries meaning 075',
  [ETimeseriesMeaning.USER_DEFINED_076]: 'User-defined timeseries meaning 076',
  [ETimeseriesMeaning.USER_DEFINED_077]: 'User-defined timeseries meaning 077',
  [ETimeseriesMeaning.USER_DEFINED_078]: 'User-defined timeseries meaning 078',
  [ETimeseriesMeaning.USER_DEFINED_079]: 'User-defined timeseries meaning 079',
  [ETimeseriesMeaning.USER_DEFINED_080]: 'User-defined timeseries meaning 080',
  [ETimeseriesMeaning.USER_DEFINED_081]: 'User-defined timeseries meaning 081',
  [ETimeseriesMeaning.USER_DEFINED_082]: 'User-defined timeseries meaning 082',
  [ETimeseriesMeaning.USER_DEFINED_083]: 'User-defined timeseries meaning 083',
  [ETimeseriesMeaning.USER_DEFINED_084]: 'User-defined timeseries meaning 084',
  [ETimeseriesMeaning.USER_DEFINED_085]: 'User-defined timeseries meaning 085',
  [ETimeseriesMeaning.USER_DEFINED_086]: 'User-defined timeseries meaning 086',
  [ETimeseriesMeaning.USER_DEFINED_087]: 'User-defined timeseries meaning 087',
  [ETimeseriesMeaning.USER_DEFINED_088]: 'User-defined timeseries meaning 088',
  [ETimeseriesMeaning.USER_DEFINED_089]: 'User-defined timeseries meaning 089',
  [ETimeseriesMeaning.USER_DEFINED_090]: 'User-defined timeseries meaning 090',
  [ETimeseriesMeaning.USER_DEFINED_091]: 'User-defined timeseries meaning 091',
  [ETimeseriesMeaning.USER_DEFINED_092]: 'User-defined timeseries meaning 092',
  [ETimeseriesMeaning.USER_DEFINED_093]: 'User-defined timeseries meaning 093',
  [ETimeseriesMeaning.USER_DEFINED_094]: 'User-defined timeseries meaning 094',
  [ETimeseriesMeaning.USER_DEFINED_095]: 'User-defined timeseries meaning 095',
  [ETimeseriesMeaning.USER_DEFINED_096]: 'User-defined timeseries meaning 096',
  [ETimeseriesMeaning.USER_DEFINED_097]: 'User-defined timeseries meaning 097',
  [ETimeseriesMeaning.USER_DEFINED_098]: 'User-defined timeseries meaning 098',
  [ETimeseriesMeaning.USER_DEFINED_099]: 'User-defined timeseries meaning 099',
  [ETimeseriesMeaning.USER_DEFINED_100]: 'User-defined timeseries meaning 100',
};

const PhysicalDimension: Record<EPhysicalDimension, string> = {
  [EPhysicalDimension.ACCELERATION]: 'Acceleration',
  [EPhysicalDimension.ADMITTANCE]: 'Admittance',
  [EPhysicalDimension.AMOUNT]: 'Amount',
  [EPhysicalDimension.ANGLE]: 'Angle',
  [EPhysicalDimension.ANGULAR_ACCELERATION]: 'Angular acceleration',
  [EPhysicalDimension.ANGULAR_MOMENTUM]: 'Angular momentum',
  [EPhysicalDimension.ANGULAR_VELOCITY]: 'Angular velocity',
  [EPhysicalDimension.APPARENT_ELECTRICAL_POWER]: 'Apparent electrical power',
  [EPhysicalDimension.AREA]: 'Area',
  [EPhysicalDimension.CAPACITANCE]: 'Capacitance',
  [EPhysicalDimension.COOLING_RATE]: 'Cooling rate',
  [EPhysicalDimension.CURRENCY]: 'Currency',
  [EPhysicalDimension.DATETIME]: 'Datetime',
  [EPhysicalDimension.DATE]: 'Date',
  [EPhysicalDimension.DENSITY]: 'Density',
  [EPhysicalDimension.DURATION]: 'Duration',
  [EPhysicalDimension.ELECTRICAL_REACTIVE_POWER]: 'Electrical reactive power',
  [EPhysicalDimension.ELECTRICAL_RESISTANCE]: 'Electrical resistance',
  [EPhysicalDimension.ELECTRIC_CHARGE]: 'Electric charge',
  [EPhysicalDimension.ELECTRIC_CURRENT]: 'Electric current',
  [EPhysicalDimension.ENERGY]: 'Energy',
  [EPhysicalDimension.FLOW]: 'Flow',
  [EPhysicalDimension.FLOW_PER_AREA]: 'Flow per area',
  [EPhysicalDimension.FORCE]: 'Force',
  [EPhysicalDimension.FREQUENCY]: 'Frequency',
  [EPhysicalDimension.HARDNESS]: 'Hardness',
  [EPhysicalDimension.HEAT_FLUX]: 'Heat flux',
  [EPhysicalDimension.IDENTITY]: 'Identity',
  [EPhysicalDimension.INDUCTIVITY]: 'Inductivity',
  [EPhysicalDimension.INTERVAL]: 'Interval',
  [EPhysicalDimension.LENGTH]: 'Length',
  [EPhysicalDimension.MAGNETIC_FLUX]: 'Magnetic flux',
  [EPhysicalDimension.MASS]: 'Mass',
  [EPhysicalDimension.MASS_FLOW_RATE]: 'Mass flow rate',
  [EPhysicalDimension.MASS_FRACTION]: 'Mass fraction',
  [EPhysicalDimension.MODULUS_OF_ELASTICITY]: 'Modulus of elasticity',
  [EPhysicalDimension.MOMENTUM]: 'Momentum',
  [EPhysicalDimension.MOMENT_OF_INERTIA]: 'Moment of inertia',
  [EPhysicalDimension.POWER]: 'Power',
  [EPhysicalDimension.POWER_DENSITY]: 'Power density',
  [EPhysicalDimension.PRESSURE]: 'Pressure',
  [EPhysicalDimension.PRESSURE_RATE]: 'Pressure rate',
  [EPhysicalDimension.QUANTITY]: 'Quantity',
  [EPhysicalDimension.RATIO]: 'Ratio',
  [EPhysicalDimension.ROTATIONAL_VELOCITY]: 'Rotational velocity',
  [EPhysicalDimension.SOUND_PRESSURE]: 'Sound pressure',
  [EPhysicalDimension.STRESS]: 'Stress',
  [EPhysicalDimension.SURFACE_TENSION]: 'Surface tension',
  [EPhysicalDimension.TEMPERATURE]: 'Temperature',
  [EPhysicalDimension.THERMAL_EXPANSION]: 'Thermal expansion',
  [EPhysicalDimension.THERMAL_RESISTANCE]: 'Thermal resistance',
  [EPhysicalDimension.TIME]: 'Time',
  [EPhysicalDimension.TORQUE]: 'Torque',
  [EPhysicalDimension.VELOCITY]: 'Velocity',
  [EPhysicalDimension.VISCOSITY]: 'Viscosity',
  [EPhysicalDimension.VOLTAGE]: 'Voltage',
  [EPhysicalDimension.VOLUME]: 'Volume',
  [EPhysicalDimension.WORK]: 'Work',
};

const CalendarEventType: Record<ECalendarEventType, string> = {
  [ECalendarEventType.ACTUAL_UNIT_DOWNTIME]: 'Actual Down Time',
  [ECalendarEventType.PLANNED_UNIT_DOWNTIME]: 'Planned Downtime',
};

const MaintenanceEventType: Record<components['schemas']['MaintenanceEventType'], string> = {
  MAINTENANCE: 'Maintenance',
};

const MaintenanceStateType: Record<components['schemas']['MaintenanceEventStateType'], string> = {
  ASSIGNED: 'Assigned',
  COMPLETED: 'Completed',
  IN_PROGRESS: 'In progress',
  IN_REVIEW: 'In review',
  PENDING: 'Pending',
  REJECTED: 'Rejected',
};

const HierarchyLabel: Record<EHierarchy, string> = {
  [EHierarchy.CHAMBER]: generalTerm.chamber,
  [EHierarchy.COMPANY]: generalTerm.company,
  [EHierarchy.COMPONENT]: generalTerm.component,
  [EHierarchy.EQUIPMENT]: generalTerm.equipment,
  [EHierarchy.AUTOMAT]: generalTerm.automat,
  [EHierarchy.EQUIPMENT_GROUP]: generalTerm.equipmentGroup,
  [EHierarchy.LOCATION]: generalTerm.location,
  [EHierarchy.ROOT]: generalTerm.root,
};

export const NotificationChannelType: Record<ENotificationChannelType, string> = {
  [ENotificationChannelType.EMAIL]: generalTerm.email,
  [ENotificationChannelType.IN_APP]: 'In app',
  [ENotificationChannelType.SMS]: generalTerm.sms,
};

export const SimulationStep: Record<ESimulationStep, string> = {
  [ESimulationStep.RUNTIME_SECONDS]: 'Runtime in seconds',
  [ESimulationStep.STAGE_INDEX]: 'Stage index',
  [ESimulationStep.STAGE_RUNTIME_SECONDS]: 'Stage runtime in seconds',
  [ESimulationStep.TEMPERATURE]: 'Temperature',
  [ESimulationStep.CARBON_POTENTIAL]: 'Carbon potential',
  [ESimulationStep.NITROGEN_POTENTIAL]: 'Nitrogen potential',
  [ESimulationStep.CARBURIZING_DEPTH]: 'Carburizing depth',
  [ESimulationStep.CARBURIZING_DEPTH_SET_POINT]: 'Carburizing depth set point',
  [ESimulationStep.CARBURIZING_DEPTH_CARBON_CONTENT]: 'Carbon content at carburizing depth',
  [ESimulationStep.SURFACE_CARBON]: 'Surface carbon',
  [ESimulationStep.SURFACE_NITROGEN]: 'Surface nitrogen',
  [ESimulationStep.SURFACE_CARBIDE]: 'Surface carbide',
  [ESimulationStep.SURFACE_HARDNESS_SETPOINT]: 'Surface hardness set point',
  [ESimulationStep.EFFECTIVE_CASE_HARDNESS]: 'Effective case hardness',
  [ESimulationStep.CARBIDE_LIMIT]: 'Carbide limit',
  [ESimulationStep.SOOT_LIMIT]: 'Soot limit',
  [ESimulationStep.CASE_DEPTH]: 'Case depth',
  [ESimulationStep.CARBON_PROFILE]: 'Carbon profile through depth',
  [ESimulationStep.CARBON_PROFILE_MIN]: 'Minimum carbon profile value',
  [ESimulationStep.CARBON_PROFILE_MAX]: 'Maximum carbon profile value',
  [ESimulationStep.NITROGEN_PROFILE]: 'Nitrogen profile through depth',
  [ESimulationStep.NITROGEN_PROFILE_MIN]: 'Minimum nitrogen profile value',
  [ESimulationStep.NITROGEN_PROFILE_MAX]: 'Maximum nitrogen profile value',
  [ESimulationStep.CARBIDE_PROFILE]: 'Carbide profile through depth',
  [ESimulationStep.CARBIDE_PROFILE_MIN]: 'Minimum carbide profile value',
  [ESimulationStep.CARBIDE_PROFILE_MAX]: 'Maximum carbide profile value',
  [ESimulationStep.HARDNESS_PROFILE]: 'Hardness profile through depth',
  [ESimulationStep.HARDNESS_PROFILE_MIN]: 'Minimum hardness profile value',
  [ESimulationStep.HARDNESS_PROFILE_MAX]: 'Maximum hardness profile value',
  [ESimulationStep.N_SOLUBILITY_PROFILE]: 'Actual nitride limit',
  [ESimulationStep.NITRIDE_PROFILE]: 'Nitride profile through depth',
  [ESimulationStep.NITRIDE_PROFILE_MIN]: 'Minimum nitride profile value',
  [ESimulationStep.NITRIDE_PROFILE_MAX]: 'Maximum nitride profile value',
  [ESimulationStep.PROFILE_X_VALUES]: 'Values along the profile from outside to inside in mm',
};

const Asset: Partial<TComponentKeys<'Asset'>> = {
  displayName: generalTerm.name,
  description: generalTerm.description,
};

const AssetProfileStatus: Record<EAssetProfileStatus, string> = {
  [EAssetProfileStatus.COMPLETE]: 'Complete',
  [EAssetProfileStatus.INCOMPLETE]: 'Incomplete',
  [EAssetProfileStatus.INVALID]: 'Invalid',
};

const AssetProfileCombinator: Record<EAssetProfileCombinator, string> = {
  [EAssetProfileCombinator.ANY_OF]: 'Any of',
  [EAssetProfileCombinator.ALL_OF]: 'All of',
  [EAssetProfileCombinator.ONE_OF]: 'One of',
};

const ObservationType: Record<EObservationType, string> = {
  [EObservationType.LOWER_CONTROL_LIMIT_EXCEEDED]: 'Lower control limit exceeded',
  [EObservationType.UPPER_CONTROL_LIMIT_EXCEEDED]: 'Upper control limit exceeded',
  [EObservationType.NEGATIVE_TREND_DETECTED]: 'Negative trend detected',
  [EObservationType.POSITIVE_TREND_DETECTED]: 'Positive trend detected',
  [EObservationType.LOWER_CUSTOM_LIMIT_EXCEEDED]: 'Lower custom limit exceeded',
  [EObservationType.UPPER_CUSTOM_LIMIT_EXCEEDED]: 'Upper custom limit exceeded',
};

const ChamberType: Record<EChamberType, string> = {
  [EChamberType.HOT]: 'Hot',
  [EChamberType.ISO_THERMAL]: 'Isothermal',
  [EChamberType.QUENCH]: 'Quenching',
  [EChamberType.VACUM]: 'Vacuum',
};

const HeatTreatmentProcessType: Record<EHeatTreatmentProcessType, string> = {
  [EHeatTreatmentProcessType.ANNEALING]: 'Annealing',
  [EHeatTreatmentProcessType.QUENCHING]: 'Quenching',
  [EHeatTreatmentProcessType.NEUTRAL_HARDENING]: 'Neutral hardening',
  [EHeatTreatmentProcessType.CRYOGENIC_TREATMENT]: 'Cryogenic treatment',
  [EHeatTreatmentProcessType.TEMPERING]: 'Tempering',
  [EHeatTreatmentProcessType.AGING]: 'Aging',
  [EHeatTreatmentProcessType.CARBURIZING]: 'Carburizing',
  [EHeatTreatmentProcessType.CARBONITRIDING]: 'Carbonitriding',
  [EHeatTreatmentProcessType.NITRIDING]: 'Nitriding',
  [EHeatTreatmentProcessType.NITROCARBURIZING]: 'Nitrocarburizing',
  [EHeatTreatmentProcessType.PRE_OXIDATION]: 'Pre-oxidation',
  [EHeatTreatmentProcessType.POST_OXIDATION]: 'Post-oxidation',
  [EHeatTreatmentProcessType.ACTIVATION]: 'Activation',
};

const AppGroupSemantic: Record<EAppGroupSemantic, string> = {
  [EAppGroupSemantic.OPERATION]: 'Operation',
  [EAppGroupSemantic.DATA]: 'Data/Insight',
  [EAppGroupSemantic.MAINTENANCE]: 'Maintenance',
  [EAppGroupSemantic.PROCESS]: 'Process',
  [EAppGroupSemantic.CONFIGURATION]: 'Configuration',
};

const AppId: Record<EAppId, string> = {
  [EAppId.ALARM_STATISTICS]: 'Alarm Statistics',
  [EAppId.ANOMALY_DETECTION]: 'Anomaly Detection',
  [EAppId.ASSET_MANAGEMENT]: 'Asset Management',
  [EAppId.CERTIFICATION]: 'Audit & Quality',
  [EAppId.CUSTOMER]: 'Customer',
  [EAppId.DEPRECATED_MODEL_PROCESS]: 'Model Process Builder',
  // [EAppId.DEPRECATED_NITREX_LIVE]: 'Nitrex Connect',
  // [EAppId.DEPRECATED_PT9800_RECIPES]: 'Recipes (PT9800)',
  // [EAppId.DEPRECATED_PT_9800_CLOUD]: 'PT9800 Web Client',
  // [EAppId.DEPRECATED_RECIPE_NPM]: 'Recipes (Nitride)',
  // [EAppId.DEPRECATED_USER_SETTINGS]: 'User Settings',
  [EAppId.GRAFANA]: 'Grafana',
  [EAppId.HISTORY_AND_TRENDS]: 'History and Trends',
  [EAppId.LIVE]: 'Live app',
  [EAppId.MAINTENANCE_CALENDAR]: 'Maintenance Calendar',
  [EAppId.MAINTENANCE_DASHBOARD]: 'Maintenance Dashboard',
  [EAppId.MATERIALS]: 'Materials',
  // [EAppId.ML_PROTOTYPING]: 'ML Prototyping',
  [EAppId.MODEL_BUILDER]: 'Model Builder',
  [EAppId.OPERATION_CALENDAR]: 'Operation Calendar',
  [EAppId.PARTS]: 'Parts',
  [EAppId.PERFORMANCE_DASHBOARD]: 'Performance Dashboard',
  [EAppId.PRODUCTION_LOGS]: 'Production Logs',
  [EAppId.PRODUCTION_STATISTICS]: 'Production Statistics',
  [EAppId.PT9800_CONFIGURATION]: 'PT9800 Configuration',
  [EAppId.RECIPE]: 'Recipes',
  [EAppId.RECIPE_ON_DEMAND]: 'Recipe on Demand',
  [EAppId.REMOTE_CONTROL]: 'Remote Control',
  [EAppId.SHOP_LAYOUT]: 'Shop Layout',
  [EAppId.SIMULATOR]: 'Simulators',
  [EAppId.TENANT_SETTINGS]: 'Settings',
  [EAppId.UPC_PRO]: 'UPC Toolbox',
  [EAppId.USER_MANAGEMENT]: 'User Management',
};

const CarburizingSimulationPartShape: Record<ECarburizingSimulationPartShape, string> = {
  [ECarburizingSimulationPartShape.ROUND_STEEL]: 'Round steel',
  [ECarburizingSimulationPartShape.HEXAGON_OR_OCTAGON_STEEL]: 'Hexagon or octagon steel',
  [ECarburizingSimulationPartShape.SQUARE_OR_FLAT_STEEL]: 'Square or flat steel',
  [ECarburizingSimulationPartShape.SOLID_STEEL]: 'Solid steel (disc internal diameter < 200 mm)',
  [ECarburizingSimulationPartShape.RING]: 'Ring (disc internal diameter > 200 mm)',
  [ECarburizingSimulationPartShape.CYL_HOLLOW_BODY_LESS_80]: 'Cyl. hollow body internal diameter < 80 mm',
  [ECarburizingSimulationPartShape.CYL_HOLLOW_BODY_LESS_200]: 'Cyl. hollow body internal diameter < 200 mm',
  [ECarburizingSimulationPartShape.CYL_HOLLOW_BODY_GREATER_200]: 'Cyl. hollow body internal diameter > 200 mm',
  [ECarburizingSimulationPartShape.HOLLOW_BODY_CLOSED]: 'Hollow body closed from one or two sides',
};

const CarburizingSimulationPartShapeSize: Record<ECarburizingSimulationPartShape, string> = {
  [ECarburizingSimulationPartShape.ROUND_STEEL]: 'Diameter',
  [ECarburizingSimulationPartShape.HEXAGON_OR_OCTAGON_STEEL]: 'Distance between two sides',
  [ECarburizingSimulationPartShape.SQUARE_OR_FLAT_STEEL]: 'Length of the smallest side',
  [ECarburizingSimulationPartShape.SOLID_STEEL]: 'Length of the smallest side',
  [ECarburizingSimulationPartShape.RING]: 'Length of the smallest side',
  [ECarburizingSimulationPartShape.CYL_HOLLOW_BODY_LESS_80]: 'Wall thickness',
  [ECarburizingSimulationPartShape.CYL_HOLLOW_BODY_LESS_200]: 'Wall thickness',
  [ECarburizingSimulationPartShape.CYL_HOLLOW_BODY_GREATER_200]: 'Wall thickness',
  [ECarburizingSimulationPartShape.HOLLOW_BODY_CLOSED]: 'Wall thickness',
};

const CarburizingEndControlType: Record<ECarburizingEndControlType, string> = {
  [ECarburizingEndControlType.END_TEMPERATURE]: 'End temperature',
  [ECarburizingEndControlType.CARBON_DEPTH]: 'Carb. depth',
  [ECarburizingEndControlType.RUNTIME]: 'Runtime',
  [ECarburizingEndControlType.SURFACE_CARBON]: 'Surf carbon %',
  [ECarburizingEndControlType.CP_PERCENT]: 'CP%',
};

const CarburizingSimulatorRecipeType: Record<ECarburizingSimulatorRecipeType, string> = {
  [ECarburizingSimulatorRecipeType.CARBURIZING]: 'Carburizing [C]',
  [ECarburizingSimulatorRecipeType.CARBONITRIDING]: 'Carbonitriding [CN]',
  [ECarburizingSimulatorRecipeType.LOW_PRESSURE_CARBURIZING]: 'Low pressure carburizing [LPC]',
};

const PartMaterialProperties: TComponentKeys<'PartMaterialProperties'> = {
  surfaceCarbon: 'Surface carbon [%]',
  referenceCarbon: 'Reference carbon [%]',
  alloyFactorCAustenite: 'Alloy factor [Carbon]',
  carbideLimit: 'Carbide limit [%aC=1]',
  alloyFactorNAustenite: 'Alloy factor [Nitrogen]',
  nitrideLimit: 'Nitride limit [%FexNy]',
  coreHardness: 'Core hardness untreated [HV]',
};

const SimulatorRecipe: Record<
  keyof components['schemas']['CarburizingRecipe']
  | keyof components['schemas']['CarbonitridingRecipe']
  | keyof components['schemas']['LowPressureCarburizingRecipe'],
  string> = {
    c2H2Content: 'C2H2',
    c3H8Content: 'C3H8',
    coContent: 'CO',
    h2Content: 'H2',
    description: generalTerm.description,
    displayName: generalTerm.name,
    isGasValuesConstant: '',
    recipeType: 'Heat treatment type',
    simulationValuesType: 'Type',
    simulatorCarburizingRecipeId: '',
    simulatorPartId: '',
    stages: '',
    userDefinedAlloyFactor: 'Alloy factor C',
    userDefinedCarbideLimit: 'Carbide limit',
    userDefinedCarbonContent: 'Core carbon content',
    userDefinedCarburizingDepth: 'Carburizing depth',
    userDefinedReferenceCarbon: 'Carbon at carb. depth',
  };

const NotificationTable: Record<ESubscriptionTreeLabel, Record<'event' | 'description', string>> = {
  [ESubscriptionTreeLabel.ALARMS]: {
    event: 'Alarms',
    description: 'Receive a notification when an alarm event of any severity occurs.',
  },
  [ESubscriptionTreeLabel.ALARM_SEVERITY_ALARM_OCCURRED]: {
    event: 'Alarm',
    description: 'Notification for alarm events',
  },
  [ESubscriptionTreeLabel.ALARM_SEVERITY_EMERGENCY_OCCURRED]: {
    event: 'Emergency',
    description: 'Notification for emergency events. Emergency events are not supported by all controller types. Emergency events cause the respective job to be immediately stopped or interrupted and may require human intervention.',
  },
  [ESubscriptionTreeLabel.ALARM_SEVERITY_WARNING_OCCURRED]: {
    event: 'Warning',
    description: 'Notification for warning events. Warnings are not supported by all controller types.',
  },
  [ESubscriptionTreeLabel.JOBS]: {
    event: 'Jobs',
    description: 'Receive a notification whenever the status of a job changes.',
  },
  [ESubscriptionTreeLabel.JOB_FINISHED]: {
    event: 'Job Finished',
    description: 'A job has been finished.',
  },
  [ESubscriptionTreeLabel.JOB_STARTED]: {
    event: 'Job Started',
    description: 'A job has been started.',
  },
  [ESubscriptionTreeLabel.TIMESERIES_LAG_DETECTED]: {
    event: 'Timeseries lag detected',
    description: 'The synchronisation between the datasource lags more than 30 minutes.',
  },
  [ESubscriptionTreeLabel.NEW_QMULUS_RELEASE]: {
    event: 'New Qmulus release',
    description: 'A new version of Qmulus has been released.',
  },
  [ESubscriptionTreeLabel.ANOMALIES_AND_OBSERVATIONS]: {
    event: 'Anomalies & Observations',
    description: 'Receive a notification whenever an anomaly has been observed.',
  },
  [ESubscriptionTreeLabel.CONTROL_LEVEL_EXCEEDED]: {
    event: 'Control Level Exceeded',
    description: 'Sensor control level exceeded.',
  },
  [ESubscriptionTreeLabel.TREND_DETECTED]: {
    event: 'Trend Detected',
    description: 'A new sensor readings trend detected.',
  },
  [ESubscriptionTreeLabel.DRIFT_DETECTED]: {
    event: 'Drift Detected',
    description: 'Sensor readings are drifting from the reference values.',
  },
};

const SubscriptionTreeNode: Partial<TComponentKeys<'SubscriptionTreeNode'>> = {
  notificationMetadataId: generalTerm.description,
  label: generalTerm.event,
  children: '',
  subscriptions: '',
  isLeaf: '',
};

const CarburizingRecipeSimulationValuesType: Record<ECarburizingRecipeSimulationValuesType, string> = {
  [ECarburizingRecipeSimulationValuesType.AVG_VAL]: 'Avg',
  [ECarburizingRecipeSimulationValuesType.COMP_VAL]: 'Comp',
  [ECarburizingRecipeSimulationValuesType.USER_VAL]: 'User defined',
};

const AppGroupCommercial: Record<EAppGroupCommercial, string> = {
  [EAppGroupCommercial.QMULUS_ESSENTIAL]: 'QMULUS Essential',
  [EAppGroupCommercial.QMULUS_LITE]: 'QMULUS Lite',
  [EAppGroupCommercial.SETTINGS]: 'Settings',
  [EAppGroupCommercial.QMULUS_EXPERT]: 'QMULUS Expert',
  [EAppGroupCommercial.SIMULATORS]: 'Simulators',
  [EAppGroupCommercial.QMULUS_OPERATIONS]: 'QMULUS Operations',
  [EAppGroupCommercial.QMULUS_PERFORMANCE]: 'QMULUS Performance',
  [EAppGroupCommercial.QMULUS_AI]: 'QMULUS AI',
  [EAppGroupCommercial.QMULUS_ANALYTICS]: 'QMULUS Analytics',
  [EAppGroupCommercial.QMULUS_AUDIT]: 'QMULUS Audit',
  [EAppGroupCommercial.QMULUS_QUALITY]: 'QMULUS Quality',
  [EAppGroupCommercial.QMULUS_PLANNER]: 'QMULUS Planner',
  [EAppGroupCommercial.QMULUS_TOOLING_AND_GAUGE_MANAGER]: 'QMULUS Tooling and Gauge Manager',
  [EAppGroupCommercial.QMULUS_AUTOMATION]: 'QMULUS Automation',
};

const DataFormat: Record<EDataFormat, string> = {
  [EDataFormat.TIME_SERIES]: 'Time series',
  [EDataFormat.JOB_AGGREGATE]: 'Job value',
  [EDataFormat.EVENT]: 'Event',
  [EDataFormat.ALARM_EVENT]: 'Alarm',
  [EDataFormat.BLOB]: 'Binary Large Object (BLOB)',
};

const CarburizingTemperatureControlType: Record<ECarburizingTemperatureControlType, string> = {
  [ECarburizingTemperatureControlType.HEAT]: 'Heat',
  [ECarburizingTemperatureControlType.COOL]: 'Cool',
  [ECarburizingTemperatureControlType.HEAT_UP]: 'Heat up',
  [ECarburizingTemperatureControlType.HEAT_DOWN]: 'Heat down',
  [ECarburizingTemperatureControlType.HOLD]: 'Hold',
};

const CarburizingVolumeControlType: Record<ECarburizingVolumeControlType, string> = {
  [ECarburizingVolumeControlType.OFF]: 'Off',
  [ECarburizingVolumeControlType.ON]: 'On',
  [ECarburizingVolumeControlType.AUTO]: 'Auto',
};

const CarburizingCarbonControlType: Record<ECarburizingCarbonControlType, string> = {
  [ECarburizingCarbonControlType.OFF]: 'Off',
  [ECarburizingCarbonControlType.ON]: 'On',
  [ECarburizingCarbonControlType.SOOT_LIMIT]: 'Soot limit %',
  [ECarburizingCarbonControlType.AUTO_100_X]: 'Auto (100-x) %',
  [ECarburizingCarbonControlType.SURFACE_CARBON]: 'Surface carbon %',
  [ECarburizingCarbonControlType.PLUS_C]: 'Plus C %',
  [ECarburizingCarbonControlType.MINUS_C]: 'Minus C %',
};

const CarburizingEndControlTypeStrings: Record<ECarburizingEndControlType, string> = {
  [ECarburizingEndControlType.END_TEMPERATURE]: 'End temperature',
  [ECarburizingEndControlType.CARBON_DEPTH]: 'Carb. depth',
  [ECarburizingEndControlType.RUNTIME]: 'Runtime',
  [ECarburizingEndControlType.SURFACE_CARBON]: 'Surf carbon %',
  [ECarburizingEndControlType.CP_PERCENT]: 'CP%',
};

const CarburizingNitrogenControlType: Record<ECarburizingNitrogenControlType, string> = {
  [ECarburizingNitrogenControlType.OFF]: 'Off',
  [ECarburizingNitrogenControlType.ON]: 'On',
  [ECarburizingNitrogenControlType.NITRIDE_100_X]: 'Nitride (100-x)%',
  [ECarburizingNitrogenControlType.SURF_N]: 'Surf N %',
};

const ObservationTypeHeader: Record<string, string> = {
  name: 'Observation type name',
};

const MaintenanceJob: TComponentLegacyKeys<'MaintenanceJob'> = {
  description: 'Description',
  displayName: 'Job name',
  durationOverheadMinutes: 'Total duration',
  id: 'Id',
  tasks: 'Tasks',
};

const MaintenanceTask: TComponentLegacyKeys<'MaintenanceTask'> = {
  description: 'Description',
  displayName: 'Task name',
  durationMinutes: 'Duration',
  id: 'Id',
};

const AssetUtilization: Partial<TComponentKeys<'AssetUtilization'>> = {
  assetDisplayName: generalTerm.asset,
  noProductionSeconds: 'No production',
  actualProductionTimeSeconds: 'Actual production time',
  actualUnitDowntimeSeconds: 'Actual down time',
  plannedDowntimeSeconds: 'Planned down time',
  noProductionPercentage: 'No production',
  actualProductionTimePercentage: 'Actual production time',
  actualUnitDowntimePercentage: 'Actual down time',
  plannedDowntimePercentage: 'Planned down time',
  effectiveTargetProductivityPercentage: 'Target productivity',
};

const AuditSpecificationResultOverview: TComponentKeys<'AuditSpecificationResultOverview'> = {
  assetId: generalTerm.assetId,
  assetDisplayName: generalTerm.asset,
  auditResultId: 'Report No',
  auditedAtUtc: generalTerm.date,
  auditedByUserName: 'Reporter',
  jobName: generalTerm.jobDisplayName,
  recipeDisplayName: generalTerm.recipe,
  jobId: '',
};

const AuditEnvelopeNonPassedReasons: Record<components['schemas']['AuditEnvelopeNonPassedReasons'], string> = {
  no_start_point_found: 'No start point found',
  values_out_of_band: 'Values out of band',
  max_duration_exceeded: 'Max duration exceeded',
  min_duration_not_reached: 'Min duration not reached',
  job_is_running: 'Job is running',
  meanings_missing: 'No signals available with mapped meanings for the asset.',
  no_data: 'No data',
  data_ended_unexpected: 'Data ended unexpectedly',
};

const Part: Partial<TComponentKeys<'Part'>> = {
  displayName: 'Part',
  description: 'Part description',
  materialDisplayName: 'Material',
  treatmentRemarks: 'Treatment remark',
  customerDisplayName: 'Customer',
  drawingReference: 'Drawing',
  lastModifiedAtUtc: 'Modified on',
  lastModifiedByUserDisplayName: 'Modified by',
};

const Customer: Partial<TComponentKeys<'Customer'>> = {
  displayName: 'Company',
  email: 'Mail',
  phone: 'Phone',
  cellphone: 'Cell phone',
  fax: 'Fax',
};

const Material: Partial<TComponentKeys<'Material-Output'>> = {
  displayName: 'Material name',
  materialComposition: 'Material composition',
  materialGroups: 'Material groups',
  materialNames: 'Material names',
  materialId: 'Material ID',
};

const MaterialNamingSystem: Partial<TComponentKeys<'MaterialNamingSystem'>> = {
  displayName: 'Name',
};

const ChartAssessmentTestRequirementType: Record<components['schemas']['ChartAssessmentTestRequirementType'], string> = {
  setpoints_greater_than_measures: 'Measures < Setpoints',
  setpoints_greater_than_or_equal_to_measures: 'Measures ≤ Setpoints',
  setpoints_less_than_measures: 'Measures > Setpoints',
  setpoints_less_than_or_equal_to_measures: 'Measures ≥ Setpoints',
};

const User: Partial<TComponentKeys<'User'>> = {
  firstName: 'First name',
  lastName: 'Last name',
  email: 'Email',
  rolesDisplayName: 'Roles',
};

const OpenAPIPrimitiveType: Record<components['schemas']['OpenAPIPrimitiveType'], string> = {
  boolean: 'Boolean',
  integer: 'Integer',
  number: 'Float integer',
  string: 'Text',
};

const ProductionStatistics: Partial<TComponentKeys<'ProductionStatistics'>> = {
  displayName: generalTerm.name,
  earliestJobStartedAtUtc: 'Earliest job start time',
  latestJobFinishedAtUtc: 'Latest job end time',
  jobCnt: 'Number of jobs',
};

const CsvSeperator: Record<components['schemas']['CsvSeperator'], string> = {
  comma: 'Comma',
  semicolon: 'Semicolon',
  tab: 'Tab',
  pipe: 'Pipe',
  space: 'Space',
};

const CsvDatetimeFormat: Record<components['schemas']['CsvDatetimeFormat'], string> = {
  iso_8601: 'ISO 8601',
  unix_timestamp: 'UNIX',
  us: 'US',
};

const CsvQuoteStyle: Record<components['schemas']['CsvQuoteStyle'], string> = {
  necessary: 'Necessary',
  always: 'Always',
  non_numeric: 'Non numeric',
  never: 'Never',
};

const CsvQuoteStyleHint: Record<components['schemas']['CsvQuoteStyle'], string> = {
  necessary: 'They are necessary when fields contain a quote, separator or record terminator. Quotes are also necessary when writing an empty record (which is indistinguishable from a record with one empty field). This is the default.',
  always: 'This puts quotes around every field. Always.',
  non_numeric: 'This puts quotes around all fields that are non-numeric. Namely, when writing a field that does not parse as a valid float or integer, then quotes will be used even if they aren`t strictly necessary.',
  never: 'This never puts quotes around fields, even if that results in invalid CSV data (e.g.: by not quoting strings containing the separator).',
};

const CsvLineTerminator: Record<components['schemas']['CsvLineTerminator'], string> = {
  posix: 'POSIX',
  windows: 'Windows',
};

const CsvQuoteCharacter: Record<components['schemas']['CsvQuoteCharacter'], string> = {
  single_quote: 'Single Quote',
  double_quote: 'Double Quote',
};

const PartAssetQualification: Partial<TComponentKeys<'PartAssetQualification'>> = {
  assetDisplayName: generalTerm.asset,
  assetId: generalTerm.asset,
  maxNumberOfPartsPerRost: 'Max number of parts per rost',
  recipeDisplayName: generalTerm.recipe,
  recipeId: generalTerm.recipe,
};

const PartAttribute: Partial<TComponentKeys<'PartAttribute'>> & {
  value: string,
} = {
  displayName: 'Attributes',
  unitOfMeasureId: 'Unit',
  value: 'Value',
  valueType: 'Value type',
};

const AuditSpecification: TComponentKeys<'AuditSpecification'> = {
  auditSpecificationId: 'Audit specification Id',
  description: 'Description',
  displayName: 'Specification name',
  envelopes: 'Nb of envelopes',
  revision: 'Revision id',
  isOneTimeSpec: '',
};

const AlarmCollection: TComponentKeys<'AlarmCollection'> = {
  dataId: 'Data id',
  displayName: 'Name',
  eventType: 'Alarm type',
  occurrenceData: generalTerm.date,
  severity: 'Alarm type',
};

const CounterAction: Partial<TComponentKeys<'CounterAction'>> = {
  displayName: 'Counter Action',
  description: generalTerm.description,
};

const Metadata: Partial<TComponentKeys<'Metadata'>> = {
  color: generalTerm.color,
  dataFormat: 'Data format',
  dataSourceReference: 'Data source reference',
  description: generalTerm.description,
  displayName: generalTerm.name,
  guaranteedColor: generalTerm.color,
  isHidden: 'Visibility',
  meaningId: 'Meaning',
  significantDigits: 'Significant digits',
  unitOfMeasureId: 'Unit',
};

const MetadataUpdate: Partial<TComponentKeys<'MetadataUpdate'>> = {
  ...Metadata,
  significantDigitsOverwrite: Metadata.significantDigits,
};

const Sample: Partial<TComponentKeys<'Sample'>> = {
  displayName: 'Sample',
};

const TableAssessmentTest: Partial<TComponentKeys<'TableAssessmentTest'>> = {
  displayName: generalTerm.name,
  isOverwritten: generalTerm.overwrite,
};

const TableAssessmentTestItem: Partial<Record<keyof components['schemas']['TableAssessmentTest']['items'][number], string>> = {
  displayName: generalTerm.name,
  requirement: generalTerm.requirement,
  result: generalTerm.result,
};

const AssessmentTestResultType = 'Test result';

const ChartAssessmentTest: Partial<TComponentKeys<'ChartAssessmentTest'>> = {
  displayName: generalTerm.name,
  description: generalTerm.description,
  xUnitId: 'X Unit',
  yUnitId: 'Y Unit',
  requirement: 'Check',
  isOverwritten: generalTerm.overwrite,
};

const MaterialPropertySpectrum: Record<keyof components['schemas']['MaterialPropertySpectrum']['avg'], string> = {
  alloyFactorCAustenite: 'Alloy factor [Carbon]',
  carbideLimit: 'Carbide limit [%aC=1]',
  alloyFactorNAustenite: 'Alloy factor [Nitrogen]',
  nitrideLimit: 'Nitride limit [%FexNy]',
  coreHardness: 'Core hardness untreated [HV]',
};

const FileAssessmentTest: Partial<TComponentKeys<'FileAssessmentTest'>> = {
  displayName: generalTerm.name,
  result: 'Test result',
  resultComment: 'Comment',
  isOverwritten: generalTerm.overwrite,
};

const ReportOverview: Partial<TComponentKeys<'ReportOverview'>> = {
  reportId: generalTerm.reportId,
  reporterDisplayName: generalTerm.reporter,
  jobId: generalTerm.jobId,
  jobDisplayName: generalTerm.job,
  assetId: generalTerm.assetId,
  assetDisplayName: generalTerm.asset,
  assetLocation: generalTerm.location,
  recipeDisplayName: generalTerm.recipe,
  partId: generalTerm.partId,
  partDisplayName: generalTerm.part,
  partCustomerDisplayName: generalTerm.customer,
  partMaterialDisplayName: generalTerm.material,
  partDrawingReference: generalTerm.drawingReference,
  orderNumbers: 'Orders',
  isAutogenerated: 'Imported from a foreign system',
  orderCreatedAtUtc: 'Order date',
  isDeleted: generalTerm.showDeleted,
};

const Job: Partial<TComponentKeys<'Job'>> = {
  assetDisplayName: generalTerm.asset,
  displayName: generalTerm.jobDisplayName,
  locationDisplayName: 'Location',
  durationSeconds: 'Duration',
  description: generalTerm.description,
  startedAtUtc: generalTerm.jobStartedAtUtc,
  finishedAtUtc: generalTerm.jobFinishedAtUtc,
  recipeDisplayName: generalTerm.recipe,
  hasAlarms: 'Alarms',
  jobVerdict: 'Rating',
  processFlowId: 'Process flow',
  isProductive: 'Mark job as productive',
};

const MaterialCalculationModelOutput: Partial<TComponentKeys<'MaterialCalculationModel-Output'>> = {
  displayName: 'Name',
  description: 'Description',
};

const JobStage: Partial<TComponentKeys<'JobStage'>> | Record<'state', string> = {
  displayName: 'Stage',
  state: 'Status',
  jobDisplayName: generalTerm.jobDisplayName,
  startedAtUtc: generalTerm.jobStartedAtUtc,
  finishedAtUtc: generalTerm.jobFinishedAtUtc,
  durationSeconds: 'Duration',
  stageNo: 'No',
};

const AlarmSeverity: Record<components['schemas']['AlarmSeverity'], string> = {
  0: 'Info',
  1: 'Warning',
  2: 'Alarm',
  3: 'Emergency',
};

const MaintenanceOccurrenceIdentifier: Partial<TComponentLegacyKeys<'MaintenanceOccurrenceIdentifier'>> = {
  id: 'Request ID',
  maintenanceSeriesDisplayName: 'Description',
  state: 'Request status',
  assignedTo: 'Assignee',
  locationDisplayName: 'Location',
  assetDisplayName: 'Asset',
  scheduledCompletedAtUtc: generalTerm.date,
  scheduledAtUtc: generalTerm.date,
  maintenanceJobDisplayName: 'Load No',
};

const MaintenanceOccurrence: Partial<TComponentLegacyKeys<'MaintenanceOccurrence'>> = {
  assetDisplayName: 'Furnace name',
  scheduledAtUtc: 'Maintenance start',
  scheduledCompletedAtUtc: 'Maintenance end',
  maintenanceSeriesDisplayName: 'Maintenance type',
  description: 'Description',
  report: 'Report',
};

const MaintenanceSeries: Partial<TComponentLegacyKeys<'MaintenanceSeries'>> = {
  displayName: 'Task name',
  description: 'Description',
  maintenanceJobId: 'Maintenance job',
  recurrence: 'Recurrence',
  priority: 'Priority',
  color: generalTerm.color,
  validFromUtc: 'Scheduled date',
  assetId: 'Asset',
  ownerId: 'Assign to',
};

const RootCauseGroup: Partial<TComponentKeys<'RootCauseGroup'>> = {
  displayName: 'Root cause group name',
  description: generalTerm.description,
};

const EquipmentProfile: Partial<TComponentKeys<'ProfileTreeNode'>> = {
  label: 'Profile name',
  combinator: 'Combination type',
};

// eslint-disable-next-line no-underscore-dangle, camelcase
const TimeBucket_AssetUtilization_: Partial<TComponentKeys<'TimeBucket_AssetUtilization_'>> = {
  earliestDateUtc: 'Start date',
  latestDateUtc: 'End date',
};

const EquipmentProfileMeaning: Partial<TComponentKeys<'Meaning'>> = {
  label: 'Meaning name',
  description: 'Description',
  physicalDimensionId: 'Physical quantity',
  preferredUnitId: 'Preferred unit',
};

const EquipmentTreeNavigation: Partial<TComponentKeys<'AssetTreeNode'>> = {
  displayName: 'Name',
};

const MaterialGroup: Partial<TComponentKeys<'MaterialGroup'>> = {
  displayName: generalTerm.name,
  description: generalTerm.description,
  materialIds: 'Materials',
};

const SettingsActivityCoefficients: Record<EActivityCoefficients, string> = {
  [EActivityCoefficients.ALLOYING_ELEMENT_ID]: 'Element',
  [EActivityCoefficients.DESCRIPTION]: 'Comment',
  [EActivityCoefficients.E_JC_AUSTENITE]: 'eJC (Austenite)',
  [EActivityCoefficients.E_JC_FERRITE]: 'eJC (Ferrite)',
  [EActivityCoefficients.E_JN_AUSTENITE]: 'eJN (Austenite)',
  [EActivityCoefficients.E_JN_FERRITE]: 'eJN (Ferrite)',
  [EActivityCoefficients.R_JJC_AUSTENITE]: 'rJJC (Austenite)',
  [EActivityCoefficients.R_JJC_FERRITE]: 'rJJC (Ferrite)',
  [EActivityCoefficients.R_JJN_AUSTENITE]: 'rJJN (Austenite)',
  [EActivityCoefficients.R_JJN_FERRITE]: 'rJJN (Ferrite)',
};

const MaintenanceState: Record<EMaintenanceState, string> = {
  [EMaintenanceState.PENDING]: 'Pending',
  [EMaintenanceState.ACCEPTED]: 'Accepted',
  [EMaintenanceState.IN_PROGRESS]: 'In progress',
  [EMaintenanceState.DONE]: 'Done',
  [EMaintenanceState.VALIDATED]: 'Validated',
};

const MaintenancePriority: Record<EMaintenancePriority, string> = {
  [EMaintenancePriority.LOW]: 'Low',
  [EMaintenancePriority.NORMAL]: 'Normal',
  [EMaintenancePriority.HIGH]: 'High',
};

const MaintenancePeriod: Record<ECalendarPeriod, string> = {
  [ECalendarPeriod.WEEK]: 'Week',
  [ECalendarPeriod.MONTH]: 'Month',
  [ECalendarPeriod.YEAR]: 'Year',
};

const MaintenanceRecurrenceMonthDayValue: Record<EMaintenanceRecurrenceMonthDayValue, string> = {
  [EMaintenanceRecurrenceMonthDayValue.FIRST]: 'First',
  [EMaintenanceRecurrenceMonthDayValue.SECOND]: 'Second',
  [EMaintenanceRecurrenceMonthDayValue.THIRD]: 'Third',
  [EMaintenanceRecurrenceMonthDayValue.FOURTH]: 'Fourth',
  [EMaintenanceRecurrenceMonthDayValue.LAST]: 'Last',
};

const MaintenanceRecurrenceMonthDayDimension: Record<EMaintenanceRecurrenceMonthDayDimension, string> = {
  [EMaintenanceRecurrenceMonthDayDimension.WEEKDAY]: 'Weekday',
  [EMaintenanceRecurrenceMonthDayDimension.WEEKEND_DAY]: 'Weekend day',
  [EMaintenanceRecurrenceMonthDayDimension.DAY]: 'Day',
};

const MaintenanceRecurrenceEndCondition: Record<EMaintenanceRecurrenceEndCondition, string> = {
  [EMaintenanceRecurrenceEndCondition.AFTER]: 'after',
  [EMaintenanceRecurrenceEndCondition.ON_THIS_DAY]: 'on this day',
};

const MetadataWithChartConfigInput: Partial<TComponentKeys<'MetadataWithChartConfig'>> = {
  ...Metadata,
};

const RootCause: Partial<TComponentKeys<'RootCause'> & {
  count: string,
}> = {
  displayName: 'Root Cause',
  description: generalTerm.description,
  count: 'Count',
};

const JobValueData: Partial<TComponentKeys<'JobValueData'>> = {
  jobId: 'JobId',
  jobDisplayName: generalTerm.jobDisplayName,
  jobStartUtc: generalTerm.jobStartedAtUtc,
  jobEndUtc: generalTerm.jobFinishedAtUtc,
  value: 'Value',
  recipeId: 'RecipeId',
  recipeRevision: 'RecipeRevision',
  recipeDisplayName: generalTerm.recipe,
};

const QualityPreset: Partial<TComponentKeys<'QualityPreset'>> = {
  displayName: generalTerm.name,
};

const Observation: Partial<TComponentKeys<'Observation'>> = {
  displayName: 'Observation name',
  observationTimeAtUtc: 'Observation occurred at',
  jobFeatureDisplayName: 'Job Feature',
  observationType: 'Observation type',
  jobValue: 'Value',
  jobDisplayName: generalTerm.jobDisplayName,
  jobStartedAtUtc: generalTerm.jobStartedAtUtc,
  jobFinishedAtUtc: generalTerm.jobFinishedAtUtc,
};

const QuenchingMedia: Partial<TComponentKeys<'QuenchingMedia'>> = {
  displayName: generalTerm.name,
  maxIntensity: 'Max intensity',
  minIntensity: 'Min intensity',
  quenchingMediaId: 'ID',
};

const expressionFunctionCategories: Record<TFunctionCategory, string> & {all: string} = {
  all: 'All',
  arithmetic: 'Arithmetic functions',
  custom: 'Custom functions',
  cumulative: 'Cumulative functions',
  exponential: 'Exponential functions',
  generative: 'Generative functions',
  imputation: 'Imputation functions',
  logical: 'Logical functions',
  numerical: 'Numerical functions',
  operator: 'Operator',
  other: 'Other functions',
  sections: 'Section functions',
  statistical: 'Statistical functions',
  trigonometric: 'Trigonometric functions',
};

const expressionFunctionsExplanations: {
  functions: Record<TFunctionDefinition['functionType'], string>,
  operators: Record<TOperatorDefinition['operatorType'], string>,
} = {
  functions: {
    abs: 'This function computes the absolute values of a signal element-wise.',
    acos: 'This function computes the arccosine of each element of a signal.',
    add: 'Addition. Adds two operands together.',
    asin: 'This function computes the arcsine of each element of a signal.',
    atan2: 'This function computes the arctangent of a/b for each corresponding pair of elements.',
    atan: 'This function computes the arctangent of each element of a signal.',
    avg: 'If multiple arguments are given this function calculates the average over each signal. Otherwise it calculates the average of all samples.',
    avg_windowed: 'This function calculates the mean of the signal values over the given window length in seconds. A negative window length will be converted to a 0 window length, resulting in NaN.',
    bfill: ' Fill NaN values by using the next valid value to fill the gap.',
    ceil: 'This function rounds up each element of a signal to the nearest integer.',
    clamp: 'This function clamps the values of a signal within a specified range.',
    cos: 'This function computes the cosine of each element of a signal.',
    count_samples: 'Accumulates the number of samples where the input signal evaluates to true.',
    count_seconds: 'Accumulates the seconds where the input signal evaluates to true.',
    counter: 'This functions increments a counter by 1 for each time step.',
    cumulative_avg: 'Return the cumulative mean of the signal.',
    cumulative_integrate: 'Returns the cumulative integral (using trapezoid method over seconds).',
    cumulative_max: 'Return the cumulative maximum of the signal.',
    cumulative_mean: 'Return the cumulative mean of the signal.',
    cumulative_median: 'Return the cumulative median of the signal.',
    cumulative_min: 'Return the cumulative minimum of the signal.',
    cumulative_range: 'Return the cumulative range of the signal.',
    cumulative_std: 'Return the cumulative standard deviation of the signal.',
    cumulative_sum: 'Return the cumulative sum of the signal treating Not a Numbers (NaNs) as zero. The cumulative sum does not change when NaNs are encountered and leading NaNs are replaced by zeros. Zeros are returned for signals that are all-NaN or empty.',
    cumulative_var: 'Return the cumulative variance of the signal.',
    degrees: 'This function converts each element of a signal from radians to degrees.',
    denormalize: 'This function denormalizes the values of a signal from the range [0, 1] to the specified range.',
    diff: 'This function computes the difference between consecutive elements of a signal.',
    divide: 'Division. Divides the first operand by the second',
    do_nothing: 'This function returns the input signal.',
    elapsed_seconds: 'This function returns the number of seconds that have elapsed since the start of the time frame.',
    equals: 'Equality. Returns true if the operands are equal, otherwise false.',
    exp: 'This function computes the exponential of each element of a signal.',
    fill: 'Fill NaN values in signal a with values of signal b.',
    ffill: ' Fill NaN values by propagating the last valid value to next valid.',
    floor: 'This function rounds down each element of a signal to the nearest integer.',
    floor_divide: 'Floor division. Returns the quotient of the division, discarding any remainder.',
    gradient: 'Numerical gradient of the input signal as 1 / second.',
    gradient_windowed: 'Numerical gradient over a given dx (seconds). Negative or zero dx will result in NaN values. Input dx is assumed to be a constant.',
    greater_than: 'Greater than. Returns true if the first operand is greater than the second.',
    greater_than_or_equal: 'Greater than or equal to. Returns true if the first operand is greater than or equal to the second.',
    if: 'This function performs element-wise conditional operation based on a condition',
    integrate: 'This function integrates the input signal using the composite trapezoidal rule over seconds.',
    interpfill: ' Fill NaN values by linearly interpolating between the last and next valid value.',
    is_finite: 'This function checks if elements of a signal are finite element-wise.',
    less_than: 'Less than. Returns true if the first operand is less than the second.',
    less_than_or_equal: 'Less than or equal to. Returns true if the first operand is less than or equal to the second.',
    ln: 'This function computes the natural logarithm of each element of a signal.',
    log10: 'This function computes the base-10 logarithm of each element of a signal.',
    log2: 'This function computes the base-2 logarithm of each element of a signal.',
    log: 'This function computes the logarithm of each element of a signal with base b.',
    logical_and: 'Logical AND. Returns true if both operands are true, otherwise false.',
    logical_not: 'Unary logical NOT. Returns true if the operand is false, and false if the operand is true.',
    logical_or: 'Logical OR. Returns true if at least one of the operands is true, otherwise false.',
    logical_xor: 'Logical XOR. Returns false if both operants have the same logical state, otherwise true.',
    max: 'If multiple arguments are given this function finds the maximum value among the signals. Otherwise it finds the maximum value among all samples.',
    max_windowed: 'This function calculates the max of the signal values over the given window length in seconds. A negative window length will be converted to a 0 window length, resulting in NaN.',
    mean: 'If multiple arguments are given this function calculates the average over each signal. Otherwise it calculates the average of all samples.',
    mean_windowed: 'This function calculates the mean of the signal values over the given window length in seconds. A negative window length will be converted to a 0 window length, resulting in NaN.',
    median: 'If multiple arguments are given this function finds the median value among the signals. Otherwise it finds the median value among all samples.',
    median_windowed: 'This function calculates the median of the signal values over the given window length in seconds. A negative window length will be converted to a 0 window length resulting in NaN.',
    min: 'If multiple arguments are given this function finds the maximum value among the signals. Otherwise it finds the maximum value among all samples.',
    min_windowed: 'This function calculates the min of the signal values over the given window length in seconds. A negative window length will be converted to a 0 window length, resulting in NaN.',
    modulo: 'Modulus. Returns the remainder of the division of the first operand by the second.',
    multiply: 'Multiplication. Multiplies two operands together.',
    negate: 'This function negates each element of a signal.',
    normalize: 'This function normalizes the values of a signal to the range [0, 1].',
    not_equals: 'Inequality. Returns true if the operands are not equal, otherwise false.',
    percentile: 'This function calculates the percentile of the signal values.',
    power: 'Exponentiation. Raises the first operand to the power of the second operand.',
    radians: 'This function converts each element of a signal from degrees to radians.',
    ramp: 'This function returns a linear ramp from 0 to 1.',
    random: 'This function returns a random number between 0 and 1 for each time step.',
    range: 'If multiple arguments are given this function calculates the range (max - min) over each signal. Otherwise it calculates the range of all samples.',
    range_windowed: 'This function calculates the range of the signal values over the given window length in seconds. A negative window length will be converted to a 0 window length, resulting in NaN.',
    relative_error: 'Returns the relative error `((actual / relative) - 1) * 100` where relative is != 0 and values are finite.',
    round: 'This function rounds each element of a signal to the nearest integer.',
    sections_join: 'Removes all gaps between section that are shorter than the specified threshold by joining the enclosing sections.',
    sections_first: ' Returns the first available section. May return no section if none exist.',
    sections_last: 'Returns the last available section. May return no section if none exist.',
    sections_nth: 'Returns the shortest section(s). May return multiple sections in case that there a multiple sections with minimum duration.',
    sections_shortest: 'Returns the shortest section(s). May return multiple sections in case that there a multiple sections with minimum length.',
    sections_median_duration: 'Returns the section(s) with duration that matches the median of the durations of all detected sections.',
    sections_longest: 'Returns the longest section(s). May return multiple sections in case that there a multiple sections with maximum duration.',
    sections_max_value: 'Returns the section(s) containing the overall largest value. May return multiple sections if multiple sections exist containing the maximum value.',
    sections_median_value: 'Returns the section(s) containing the median value. May return multiple sections if multiple sections exist containing the median.',
    sections_min_value: 'Returns the section(s) containing the smallest value. May return multiple sections if multiple sections exist containing the minimum value.',
    sign: 'This function returns the sign of each element of a signal.',
    sin: 'This function computes the sine of each element of a signal.',
    sqrt: 'This function computes the square root of each element of a signal.',
    stage_number: 'Returns the number of the recipe step at the time, if there is a job active. Otherwise it returns NaN.',
    std: 'If multiple arguments are given this function calculates the standard deviation over each signal. Otherwise it calculates the standard deviation of all samples.',
    std_windowed: 'This function calculates the std of the signal values over the given window length in seconds. A negative window length will be converted to a 0 window length, resulting in NaN.',
    steady_state_detection: 'This function allows you to find areas with almost constant values. The windowlength allows the input signal to be smoothed and the limit value allows a maximum gradient to be defined when the signal is still assumed to be constant.',
    subtract: 'Subtraction. Subtracts the second operand from the first.',
    sum: 'If multiple arguments are given this function calculates the sum over each signal. Otherwise it calculates the sum of all samples.',
    sum_windowed: 'This function calculates the sum of the signal values over the given window length in seconds. A negative window length will be converted to a 0 window length, resulting in NaN.',
    tan: 'This function computes the tangent of each element of a signal.',
    timestamp: 'This function returns the milliseconds since the epoch (1970-01-01 00:00:00 UTC)',
    total_seconds: 'Returns the total number of seconds of the currently visible time frame as constant.',
    trunc: 'This function truncates each element of a signal towards zero.',
    var: 'If multiple arguments are given this function calculates the variance over each signal. Otherwise it calculates the variance of all samples.',
    var_windowed: 'This function calculates the var of the signal values over the given window length in seconds. A negative window length will be converted to a 0 window length resulting in NaN.',
  },
  operators: {
    '!': 'Unary logical NOT. Returns true if the operand is false, and false if the operand is true.',
    '-': 'Subtraction. Subtracts the second operand from the first.',
    '+': 'Addition. Adds two operands together.',
    '**': 'Exponentiation. Raises the first operand to the power of the second operand.',
    '*': 'Multiplication. Multiplies two operands together.',
    '//': 'Floor division. Returns the quotient of the division, discarding any remainder.',
    '/': 'Division. Divides the first operand by the second.',
    '%': 'Modulus. Returns the remainder of the division of the first operand by the second.',
    '==': 'Equality. Returns true if the operands are equal, otherwise false.',
    '!=': 'Inequality. Returns true if the operands are not equal, otherwise false.',
    '<': 'Less than. Returns true if the first operand is less than the second.',
    '<=': 'Less than or equal to. Returns true if the first operand is less than or equal to the second.',
    '>': 'Greater than. Returns true if the first operand is greater than the second.',
    '>=': 'Greater than or equal to. Returns true if the first operand is greater than or equal to the second.',
    '&&': 'Logical AND. Returns true if both operands are true, otherwise false.',
    '||': 'Logical OR. Returns true if at least one of the operands is true, otherwise false.',
    '^': 'Logical XOR. Returns false if both operants have the same logical state, otherwise true.',
  },
};

const DataMigrationState: TComponentKeys<'DataMigrationState'> = {
  assetId: generalTerm.assetId,
  displayName: generalTerm.displayName,
  assetDisplayName: generalTerm.asset,
  lastTimestampAtUtc: 'Most recent data',
  lastEtlRunAtUtc: 'Last migration',
  etlLagSeconds: 'Migration lag',
  dataId: 'Identifier',
  dataFormat: 'Type',
  dataSourceReference: 'Data source reference',
};

const JobMigrationState: TComponentKeys<'JobMigrationState'> = {
  assetId: generalTerm.assetId,
  assetDisplayName: generalTerm.asset,
  lastTimestampAtUtc: 'Most recent data',
  lastEtlRunAtUtc: 'Last migration',
  etlLagSeconds: 'Migration lag',
};

const CarburizingSimulationRecipe: Record<keyof components['schemas']['CarbonitridingRecipeStageMutation']
| (keyof components['schemas']['CarbonitridingRecipeStageMutation'])
| (keyof components['schemas']['LowPressureCarburizingRecipeStageMutation'])
| 'duration', string> = {
  stageId: 'Stage',
  cControl: 'Carbon potential',
  coContent: 'CO',
  h2Content: 'H2',
  tempControl: 'Temperature',
  endControl: 'End condition',
  duration: 'Stage duration',
  nControl: 'Nitrogen potential',
  volControl: 'Volume potential',
  //
  volValue: 'Volume potential',
  endValue: 'End condition',
  tempValue: 'Temperature',
  nValue: 'Nitrogen potential',
  cValue: 'Carbon potential',
};

const QmulusDriver = {
  PT9800: {
    DataMigrationState,
    JobMigrationState,
  },
};

const TableTestData: Partial<TComponentKeys<'TableTestData'>> = {
  jobDisplayName: generalTerm.jobDisplayName,
  jobStartUtc: generalTerm.jobStartedAtUtc,
  jobEndUtc: generalTerm.jobFinishedAtUtc,
  value: 'Value',
  recipeDisplayName: generalTerm.recipe,
  partDisplayName: generalTerm.part,
  sampleDisplayName: 'Sample',
};

const ModelBuilderModelOverview: Partial<TComponentKeys<'ModelBuilderModelOverview'>> = {
  accuracy: 'Accuracy',
  assetId: generalTerm.asset,
  createdAtUtc: generalTerm.createdAt,
  createdByDisplayName: 'Author',
  displayName: 'Model',
  inputDisplayNames: 'Input signals',
  lifecycleState: 'State',
  modelRevision: 'Version',
  modelType: 'Model type',
  outputDisplayName: 'Output signal',
  revisionCount: 'Versions',
};

const ModelLifecycleState: Record<EModelLifecycleState, string> = {
  [EModelLifecycleState.REGISTERED]: 'Registered',
  [EModelLifecycleState.LOADING_DATA]: 'Loading training data',
  [EModelLifecycleState.PREPARING_DATA]: 'Processing training data',
  [EModelLifecycleState.TRAINING]: 'Training ongoing',
  [EModelLifecycleState.SAVING_MODEL]: 'Saving model',
  [EModelLifecycleState.CREATING_METADATA]: 'Registering signals',
  [EModelLifecycleState.READY]: 'Ready',
};

const ModelBuilderModelType: Record<components['schemas']['ModelBuilderModelType'], string> = {
  LINEAR_REGRESSION: 'Lineare Regression',
  SMART_REGRESSION: 'Smart Regression',
};

const ModelBuilderTrainingRequest: Partial<TComponentKeys<'ModelBuilderTrainingRequest'>> = {
  displayName: 'Modelname',
  testSize: 'Test split',
};

const TimeBucketType: Record<TComponentValue<'TimeBucketType'>, string> = {
  day: 'Days',
  week: 'Weeks',
  month: 'Months',
  quarter: 'Quaters',
  year: 'Years',
  full: 'Complete timespan',
  auto: 'Automatic',
};

const AlarmStatistics: Partial<TComponentKeys<'AlarmStatistics'>> = {
  displayName: generalTerm.displayName,
  severity: 'Typ',
};

const AssetBlobIndex: Partial<TComponentKeys<'AssetBlobIndex'>> = {
  displayName: generalTerm.displayName,
  description: generalTerm.description,
  semanticMeaningId: 'Category',
  ownerDisplayName: 'Uploaded by',
};

const BlobMeaning: Record<TComponentValue<'BlobMeaning'>, string> = {
  [EBlobMeaning.MANUAL]: 'Manual',
  [EBlobMeaning.DRAWING]: 'Drawing',
  [EBlobMeaning.CONFIGURATION]: 'Configuration',
  [EBlobMeaning.BILL_OF_MATERIALS]: 'Bill of materials',
  [EBlobMeaning.TENANT_LOGO]: 'Logo',
};

const Meaning: Partial<TComponentKeys<'Meaning'>> = {
  meaningId: 'Meaning',
  userDefinedDisplayName: 'Default name overwrite',
  userDefinedDescription: generalTerm.description,
  physicalDimensionId: 'Physical dimension',
  preferredUnitId: 'Preferred unit',
  defaultColor: generalTerm.color,
};

export default {
  AlarmCollection,
  AlarmSeverity,
  AlarmStatistics,
  AppGroupCommercial,
  AppGroupSemantic,
  AppId,
  AssessmentTestResultType,
  Asset,
  AssetBlobIndex,
  AssetProfileCombinator,
  AssetProfileStatus,
  AssetUtilization,
  AuditEnvelopeNonPassedReasons,
  AuditResultType,
  AuditSpecification,
  AuditSpecificationResultOverview,
  BlobMeaning,
  CalendarEventType,
  CarburizingCarbonControlType,
  CarburizingEndControlType,
  CarburizingEndControlTypeStrings,
  CarburizingNitrogenControlType,
  CarburizingRecipeSimulationValuesType,
  CarburizingSimulationPartShape,
  CarburizingSimulationPartShapeSize,
  CarburizingSimulationRecipe,
  CarburizingSimulatorRecipeType,
  CarburizingTemperatureControlType,
  CarburizingVolumeControlType,
  Chamber,
  ChamberType,
  ChartAssessmentTest,
  ChartAssessmentTestRequirementType,
  CounterAction,
  CsvDatetimeFormat,
  CsvLineTerminator,
  CsvQuoteCharacter,
  CsvQuoteStyle,
  CsvQuoteStyleHint,
  CsvSeperator,
  Customer,
  DataFormat,
  EquipmentProfile,
  EquipmentProfileMeaning,
  EquipmentTreeNavigation,
  FileAssessmentTest,
  HeatTreatmentCapability,
  HeatTreatmentProcessType,
  HierarchyLabel,
  Job,
  JobStage,
  JobValueData,
  MaintenanceEventType,
  MaintenanceJob,
  MaintenanceOccurrence,
  MaintenanceOccurrenceIdentifier,
  MaintenancePeriod,
  MaintenancePriority,
  MaintenanceRecurrenceEndCondition,
  MaintenanceRecurrenceMonthDayDimension,
  MaintenanceRecurrenceMonthDayValue,
  MaintenanceSeries,
  MaintenanceState,
  MaintenanceStateType,
  MaintenanceTask,
  Material,
  MaterialCalculationModelOutput,
  MaterialGroup,
  MaterialNamingSystem,
  MaterialPropertySpectrum,
  Meaning,
  MeasurementDisplayName,
  Metadata,
  MetadataUpdate,
  MetadataWithChartConfigInput,
  ModelBuilderModelOverview,
  ModelBuilderModelType,
  ModelBuilderTrainingRequest,
  ModelLifecycleState,
  NotificationChannelType,
  NotificationTable,
  Observation,
  ObservationType,
  ObservationTypeHeader,
  OpenAPIPrimitiveType,
  Part,
  PartAssetQualification,
  PartAttribute,
  PartMaterialProperties,
  PhysicalDimension,
  ProductionStatistics,
  QmulusDriver,
  QualityPreset,
  QuenchingMedia,
  ReportOverview,
  RootCause,
  RootCauseGroup,
  Sample,
  SettingsActivityCoefficients,
  SimulationStep,
  SimulatorRecipe,
  SparePart,
  SubscriptionTreeNode,
  TableAssessmentTest,
  TableAssessmentTestItem,
  TableTestData,
  TimeBucketType,
  // eslint-disable-next-line camelcase
  TimeBucket_AssetUtilization_,
  User,
  expressionFunctionCategories,
  expressionFunctionsExplanations,

  util: {
    timeBucketName: 'Time period',
  },

  meaningId: {
    ...TimeseriesMeaning,
    ...ConsumptionMeaning,
    ...JobFeatureMeaning,
    ...PhysicalPropertyMeaning,
  },
  npm: npmApiTranslations,
  pt9800: pt9800ApiTranslations,
};

export default {
  assetDocumentation: 'Asset Documentation',
  noAssetBlobs: 'Asset {asset} has no documentation.',
  overheadTime: 'Overhead time',
  never: 'Never',
  engineerNote: 'Assigned person',
  managerFeedback: 'Manager',
  deleteLastEventMessage: 'You are about to delete the last event in the series.\nThis will result in the deletion of the entire series.\n{text}',
  inProgressStateWarning: 'Please mark the tasks as finished or omitted. If any tasks are omitted, leave a comment for each omitted task.',
  inReviewStateWarning: 'To mark an event as "Complete," all tasks must be marked as "Accepted."',
};

export default {
  placeholder: 'Wählen Sie eine Farbe',
  color: {
    custom: 'Benutzerdefiniert',
    105: 'Grau 50',
    106: 'Grau 100',
    107: 'Grau 200',
    108: 'Grau 300',
    109: 'Grau 400',
    110: 'Grau 500',
    111: 'Grau 600',
    112: 'Grau 700',
    113: 'Grau 800',
    114: 'Grau 900',
    115: 'Blau 50',
    116: 'Blau 100',
    117: 'Blau 200',
    118: 'Blau 300',
    119: 'Blau 400',
    120: 'Blau 500',
    121: 'Blau 600',
    122: 'Blau 700',
    123: 'Blau 800',
    124: 'Blau 900',
    125: 'Grün 50',
    126: 'Grün 100',
    127: 'Grün 200',
    128: 'Grün 300',
    129: 'Grün 400',
    130: 'Grün 500',
    131: 'Grün 600',
    132: 'Grün 700',
    133: 'Grün 800',
    134: 'Grün 900',
    135: 'Gelb 50',
    136: 'Gelb 100',
    137: 'Gelb 200',
    138: 'Gelb 300',
    139: 'Gelb 400',
    140: 'Gelb 500',
    141: 'Gelb 600',
    142: 'Gelb 700',
    143: 'Gelb 800',
    144: 'Gelb 900',
    145: 'Cyan 50',
    146: 'Cyan 100',
    147: 'Cyan 200',
    148: 'Cyan 400',
    149: 'Cyan 500',
    150: 'Cyan 600',
    151: 'Cyan 700',
    152: 'Cyan 800',
    153: 'Cyan 900',
    154: 'Pink 50',
    155: 'Pink 100',
    156: 'Pink 200',
    157: 'Pink 300',
    158: 'Pink 400',
    159: 'Pink 500',
    160: 'Pink 600',
    161: 'Pink 700',
    162: 'Pink 800',
    163: 'Pink 900',
    164: 'Indigo 50',
    165: 'Indigo 100',
    167: 'Indigo 200',
    168: 'Indigo 300',
    169: 'Indigo 400',
    170: 'Indigo 500',
    171: 'Indigo 600',
    172: 'Indigo 700',
    173: 'Indigo 800',
    174: 'Indigo 900',
    175: 'Blaugrün 50',
    176: 'Blaugrün 100',
    177: 'Blaugrün 200',
    178: 'Blaugrün 300',
    179: 'Blaugrün 400',
    180: 'Blaugrün 500',
    181: 'Blaugrün 600',
    182: 'Blaugrün 700',
    183: 'Blaugrün 800',
    184: 'Blaugrün 900',
    185: 'Orange 50',
    186: 'Orange 100',
    187: 'Orange 200',
    188: 'Orange 400',
    189: 'Orange 500',
    190: 'Orange 600',
    191: 'Orange 700',
    192: 'Orange 800',
    193: 'Orange 900',
    194: 'Blaugrau 50',
    195: 'Blaugrau 100',
    196: 'Blaugrau 200',
    197: 'Blaugrau 300',
    198: 'Blaugrau 400',
    199: 'Blaugrau 500',
    200: 'Blaugrau 600',
    201: 'Blaugrau 700',
    202: 'Blaugrau 800',
    203: 'Blaugrau 900',
    204: 'Violette 50',
    205: 'Violette 100',
    206: 'Violette 200',
    207: 'Violette 300',
    208: 'Violette 400',
    209: 'Violette 500',
    210: 'Violette 600',
    211: 'Violette 700',
    212: 'Violette 800',
    213: 'Violette 900',
    214: 'Rot 50',
    215: 'Rot 100',
    216: 'Rot 200',
    217: 'Rot 300',
    218: 'Rot 400',
    219: 'Rot 500',
    220: 'Rot 600',
    221: 'Rot 700',
    222: 'Rot 800',
    223: 'Rot 900',
  },
};

import {
  components,
} from '@/types/openapi.ts';

const maintenanceRecurrencePeriod: Record<NonNullable<components['schemas']['MaintenanceSeriesCreate']['recurrence']['period']>, string> = {
  never: 'Never',
  week: 'Weekly',
  month: 'Monthly',
  year: 'Yearly',
};

export default {
  title: 'Recurrence',
  repeat: 'Repeat',
  every: 'Every',
  on: 'On',
  end: 'End',
  occurrenceUnit: 'occurrence | occurrences',
  selectWeekdays: 'Select weekdays',
  selectMonths: 'Select months',
  maintenanceRecurrencePeriod,
};

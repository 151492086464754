export default {
  header: 'Import meanings',
  selectFile: {
    placeholder: 'Choose source',
    selected: 'Source: {fileName}',
  },
  verifyMapping: {
    headline: 'Verify properties',
    selectAttributes: 'Properties to import',
    doImport: 'Import selected meanings',
  },
  migrateProgress: {
    headline: 'Import meanings',
  },
  warning: {
    text: 'Changes made during the migration are not reversible. It is recommended to download a backup of the custom meanings beforehand. This backup can later be imported to restore the current state.',
    downloadBackup: 'Download backup',
  },
  next: 'Next',
  prev: 'Previous',
};

/* eslint-disable camelcase */
import {
  generalTerm,
} from '@/constants/locales/de.shared.ts';
import npmTranslation from '@/generated/locales/de.npm.ts';
import {
  TComponentNpmKeys,
  TComponentNpmValue,
} from '@/types/openapi-type.ts';

const RBBEProcessMode_String: Record<TComponentNpmValue<'RBBEProcessMode_String'>, string> = {
  STANDBY: 'Standby',
  PROCESS: 'Prozess',
  MAINTENANCE: 'Wartung',
  NO_PROCESS: '',
  EMERGENCY: 'Notfall',
  ABORT: 'Abbruch',
  PREHEAT: 'Vorheizen',
  PURGE: 'Purge',
  TRANSFER_IN: 'Transfer In',
  TRANSFER_OUT: 'Transfer Out',
  FINISHED: 'Beendet',
  ERROR: 'Fehler',
};

const LoadJobDto: TComponentNpmKeys<'LoadJobDto'> = {
  recipe_code: generalTerm.recipe,
  customer_code: 'Kunde (Code)',
  customer_name: 'Kunde (Name)',
  operator_name: 'Operator',
  shop_order: 'Shop order',
  part_numbers: 'Teilenummern (Komma separiert)',
  part_count: 'Anzahl',
  job_start_time: 'Startzeitpunkt',
  comment: 'Kommentar',
};

const RecipeListEntryDTO: TComponentNpmKeys<'RecipeListEntryDTO'> = {
  recipe_code: generalTerm.recipe,
  recipe_description: generalTerm.description,
  protected: 'Geschützt',
};

const RecipeDTO: Partial<TComponentNpmKeys<'RecipeDTO', Record<string, string> | string>> = {
  description: generalTerm.description,
  duration_min: npmTranslation.resourceId[1028],
};

const StageDTO: Partial<TComponentNpmKeys<'StageDTO', Record<string, string> | string>> = {
  stage_no: '#',
  name: generalTerm.displayName,
  EndCond: npmTranslation.resourceId[1035],
};

export default {
  LoadJobDto,
  RBBEProcessMode_String,
  RecipeListEntryDTO,
  RecipeDTO,
  StageDTO,
};

import {
  components,
} from '@/types/openapi.ts';

const maintenanceRecurrencePeriod: Record<NonNullable<components['schemas']['MaintenanceSeriesCreate']['recurrence']['period']>, string> = {
  never: 'Niemals',
  week: 'Wöchentlich',
  month: 'Monatlich',
  year: 'Jährlich',
};

export default {
  title: 'Wiederholung',
  repeat: 'Wiederholung',
  every: 'Jeder',
  on: 'Ein',
  end: 'Ende',
  occurrenceUnit: 'Ereignis | Ereignisse',
  selectWeekdays: 'Wochentage auswählen',
  selectMonths: 'Monate auswählen',
  maintenanceRecurrencePeriod,
};

export default {
  filter: {
    timeBucket: 'Granularität',
  },
  stackedPolarChart: {
    title: 'Produktivität (Polar)',
  },
  clusteredStackedBarChart: {
    title: 'Produktivität gruppiert nach Kind',
    hint: 'Der Zeitraum is nur anteilig berücksichtigt. Sie Chart für mehr Details.',
  },
  table: {
    title: 'Produktivität (Tabelle)',
  },
  stackedBarChart: {
    title: 'Produktivität nach Kind',
  },
  paretoChart: {
    title: 'Produktivität (Pareto)',
  },
  visualisationModal: {
    title: 'Anzeigeeinstellungen',
    subtitle: 'Wählen Sie die Diagramme aus, die Sie auf dem Dashboard anzeigen möchten, aus den unten stehenden Listen aus.',
    aggregationTitle: 'Aggregation',
    aggregationDescription: 'Diese Diagramme zeigen die Effizienz für den gesamten ausgewählten Zeitraum an.',
  },
};

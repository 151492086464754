<template>
  <template v-if="inline">
    <PrmSkeleton
      v-if="isLoading"
      style="width: 30%; height: 1.75rem;"
    />
    <template
      v-for="item in flatAppMenu"
      v-else
      :key="item.app?.appId"
    >
      <RouterLinkApp
        v-if="item.app"
        :app-id="item.app.appId"
        :route-to="item.routeTo!"
      />
    </template>
  </template>
  <template v-else>
    <PrmButton
      type="button"
      severity="secondary"
      :loading="isLoading"
      text
      icon="pi pi-arrow-right-arrow-left"
      size="small"
      @click="toggleMenu"
    />
    <PrmMenu
      ref="assetQuickNavigation"
      :model="menuItems"
      popup
    >
      <template #start>
        <div class="menu-item menu-item__headline">
          {{ i18n.global.t(`${I18N_PREFIX}.header`, {assetDisplayName: assetStore.assetOverview(props.assetId)?.displayName}) }}
        </div>
      </template>
      <template #submenuheader="{ item }: {item: TAssetQuickNavigationMenu}">
        <div class="menu-item">
          {{ item.label }}
        </div>
      </template>
      <template #item="{ item, props: slotProps }: {item: TAssetQuickNavigationMenu, props: MenuRouterBindProps}">
        <router-link
          v-if="item.routeTo && item.app"
          v-slot="{ href, navigate }"
          :to="item.routeTo"
          custom
        >
          <a
            v-ripple
            :href="href"
            v-bind="slotProps.action"
            class="menu-item menu-item__app"
            :style="`border-color: ${appTileColorMap[item.app.appGroupSemantic]};`"
            @click="navigate"
          >
            <component
              :is="appListMap[item.app.appId].icon"
              class="menu-item__icon"
            />
            <span class="menu-item__label">{{ item.label }}</span>
          </a>
        </router-link>
      </template>
    </PrmMenu>
  </template>
</template>

<script setup lang="ts">
import {
  computed,
  shallowRef,
} from 'vue';
import PrmButton from 'primevue/button';
import PrmMenu, {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  MenuRouterBindProps,
} from 'primevue/menu';
import {
  MenuItem,
} from 'primevue/menuitem';
import PrmSkeleton from 'primevue/skeleton';
import {
  RouteLocationRaw,
} from 'vue-router';
import {
  i18n,
} from '@i18n';
import {
  useAssetStore,
} from '@/store/pinia/asset.ts';
import {
  getAppMap,
  appListMap,
} from '@/types/app-util.ts';
import {
  useAppStore,
} from '@/store/pinia/app.ts';
import {
  EAppId, TApp, TAppMap,
} from '@/types/app-type.ts';
import {
  ERouteName,
} from '@/router/route-name.ts';
import {
  EHierarchy,
} from '@/types/asset-tree.ts';
import {
  appTileColorMap,
} from '@/utils/app-tile.ts';
import RouterLinkApp from '@/components/router-link-app/router-link-app.vue';

const I18N_PREFIX = 'components/menu-asset-app-navigation/menu-asset-app-navigation';

const assetStore = useAssetStore();
assetStore.loadAssetOverview();
const appStore = useAppStore();
appStore.loadAppList();
appStore.loadCurrentUser(false);

const props = defineProps<{
  assetId: number,
  /**
   * If set to true, this component will no longer show one button that opens a menu
   * but rather provides a button for each app individually. You have control on how
   * these buttons are displayed (row/column, gap), by controlling the container
   * containing this component.
   */
  inline?: boolean,
}>();

interface TAssetQuickNavigationMenu extends Omit<MenuItem, 'icon' | 'items'> {
  routeTo?: RouteLocationRaw,
  items?: TAssetQuickNavigationMenu[],
  app?: TApp,
}
const isLoading = computed<boolean>(() => appStore.appListIsLoading || appStore.isUserLoading || assetStore.isAssetOverviewLoading);
const assetQuickNavigation = shallowRef<PrmMenu>();
const menuItems = computed<TAssetQuickNavigationMenu[]>(() => {
  const appMap: TAppMap = appStore.appList ? getAppMap(appStore.appList) : {} as TAppMap;
  const menu: TAssetQuickNavigationMenu[] = [];

  Object.entries(appMap).forEach(([
    appGroup,
    appList,
  ]) => {
    const groupMenu: TAssetQuickNavigationMenu = {
      label: appGroup,
      items: [],
    };

    appList.forEach((app) => {
      if (!app.isEnabled) {
        return;
      }
      const assetDetails = assetStore.assetOverview(props.assetId);
      if (!assetDetails) {
        return;
      }

      const appMenuEntry: TAssetQuickNavigationMenu = {
        label: app.displayName,
        disabled: !appStore.user?.appPermissions?.[app.appId],
        app,
      };

      // eslint-disable-next-line default-case
      switch (app.appId) {
        case EAppId.ALARM_STATISTICS:
          if (assetDetails.hierarchyLevel === EHierarchy.EQUIPMENT) {
            appMenuEntry.routeTo = {
              name: ERouteName.ALARM_STATISTICS_EQUIPMENT,
              params: {
                assetId: props.assetId,
              },
            };
          }
          break;

        case EAppId.ASSET_MANAGEMENT:
          appMenuEntry.routeTo = {
            name: assetDetails.hierarchyLevel === EHierarchy.EQUIPMENT ? ERouteName.ASSET_MANAGEMENT_MAIN_EQUIPMENT : ERouteName.ASSET_MANAGEMENT_MAIN,
            params: {
              assetId: props.assetId,
              assetHierarchyLevel: assetDetails.hierarchyLevel,
            },
          };
          break;

        case EAppId.LIVE:
          appMenuEntry.routeTo = {
            name: assetDetails.hierarchyLevel === EHierarchy.EQUIPMENT ? ERouteName.LIVE_APP_EQUIPMENT : ERouteName.LIVE_APP_LOCATION,
            params: {
              assetId: props.assetId,
            },
          };
          break;

        case EAppId.HISTORY_AND_TRENDS:
          if (assetDetails.hierarchyLevel === EHierarchy.EQUIPMENT) {
            appMenuEntry.routeTo = {
              name: ERouteName.HISTORY_AND_TRENDS,
              params: {
                assetId: props.assetId,
              },
            };
          }
          break;

        case EAppId.REMOTE_CONTROL:
          if (assetDetails.hierarchyLevel === EHierarchy.EQUIPMENT) {
            appMenuEntry.routeTo = {
              name: ERouteName.REMOTE_CONTROL_DETAILS,
              params: {
                assetId: props.assetId,
              },
            };
          }
          break;

        case EAppId.PERFORMANCE_DASHBOARD:
          appMenuEntry.routeTo = {
            name: ERouteName.PERFORMANCE_DASHBOARD_DETAILS,
            params: {
              assetId: props.assetId,
            },
          };
          break;

        case EAppId.PRODUCTION_LOGS:
          appMenuEntry.routeTo = {
            name: ERouteName.PRODUCTION_LOGS,
            query: {
              assetIdList: [
                props.assetId,
              ],
            },
          };
          break;

        case EAppId.PRODUCTION_STATISTICS:
          appMenuEntry.routeTo = {
            name: ERouteName.PRODUCTION_STATISTICS_DETAILS,
            params: {
              assetId: props.assetId,
              statisticsLevel: assetDetails.hierarchyLevel,
            },
          };
          break;

        case EAppId.ANOMALY_DETECTION:
          if (assetDetails.hierarchyLevel === EHierarchy.EQUIPMENT) {
            appMenuEntry.routeTo = {
              name: ERouteName.ANOMALY_DETECTION_EQUIPMENT,
              params: {
                assetId: props.assetId,
              },
            };
          }
          break;
      }

      if (appMenuEntry.routeTo) {
        groupMenu.items!.push(appMenuEntry);
      }
    });

    if (groupMenu.items?.length) {
      menu.push(groupMenu);
    }
  });

  return menu;
});

function flattenMenuItems<T extends MenuItem>(menu: T[]): T[] {
  const flattenedMenu: T[] = [];

  menu.forEach((item) => {
    if (item.items?.length) {
      const subItems = flattenMenuItems(item.items as T[]);
      if (subItems.length) {
        flattenedMenu.push(...subItems);
      }
    }
    // we are only interested in menu items that are a leaf but not a headeline.
    if (item.app) {
      flattenedMenu.push(item);
    }
  });

  return flattenedMenu;
}

const flatAppMenu = computed<TAssetQuickNavigationMenu[]>(() => flattenMenuItems(menuItems.value).filter((item) => !!item.app));

function toggleMenu(event: Event) {
  assetQuickNavigation.value?.toggle(event);
}
</script>

<style scoped lang="scss">
.menu-item {
  font-size: 0.875rem;;

  &__app {
    border-left: .5rem solid;
  }

  &__headline {
    padding: 0.75rem 1.25rem;
    font-weight: normal;
    font-size: 1.125rem;
  }

  &__icon {
    width: 1.5rem;
    height: 1.5rem;
  }

  &__label {
    margin-left: var(--gap);
  }
}
</style>

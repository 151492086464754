// this file is supported manually, but is connected to 'openapi'.
import {
  components,
} from './openapi.ts';
import {
  components as npmComponents,
  paths as npmPaths,
} from './openapi_npm.ts';
import {
  components as pt9800Components,
  // paths as pt9800Paths,
} from './openapi_pt9800.ts';
import {
  components as legacyComponents,
} from './openapi_legacy.ts';
import {
  ESortBy,
} from './sort.ts';
import {
  TGetObservationListPath,
  TUnlinkCounterActionPath,
  TUnlinkRootCausePath,
  TUpdateCounterActionPath,
  TUpdateRootCausePath,
  TUpdateRootCauseGroupPath,
  TUnlinkRootCauseAndRootCauseGroupPath,
  TLinkRootCauseAndRootCauseGroupPath,
} from '@/api/anomaly-detection.ts';
import {
  TCustomStoragePath,
} from '@/api/custom-storage.ts';
import {
  ICustomStorageItem,
} from '@/utils/custom-storage/export.ts';
import {
  TGetAssetMetadataByTimeseriesPath,
  TGetAssetProductivityPath,
} from '@/api/asset.ts';
import {
  TGetPtDbUserListPath,
  TUpdateUserPermissionsPath,
  TDeleteUserPath,
} from '@/api/user.ts';
import {
  TGetPartDetailsPath,
} from '@/api/part.ts';
import {
  TGetMaterialDetailsPath,
  TGetMaterialGroupPath,
  TUpdateMaterialNamingSystemPath,
} from '@/api/material.ts';
import {
  TGetRolePermissionListPath,
  TUpdateRolePath,
} from '@/api/role.ts';
import {
  TGetSpecificationByRevisionPath,
} from '@/api/specification.ts';
import {
  TGetQualityReportDetailsPath,
  TUpdateQualityPresetPath,
} from '@/api/quality.ts';
import {
  TGetCustomerDetailsPath,
} from '@/api/customer.ts';

export enum EOpenapiNpmPaths {
  FURNACE_RECIPE_GET_CREATE_UPDATE_DELETE = '/furnace/{customer_code}/{plant_name}/{system_id}/recipes/{recipe_code}',
  FURNACE_RECIPE_TEMPLATE_GET = '/furnace/{customer_code}/{plant_name}/{system_id}/recipe-template',
  FURNACE_RECIPE_LIST = '/furnace/{customer_code}/{plant_name}/{system_id}/recipe-list',
}

export enum EOpenapiPaths {
  CORE_OPERATION_PARTS_ONETIME = '/core/operation/parts/onetime',
  QUALITY_SAMPLE_CUSTOM_FIELDS = '/quality/sample/custom-fields',
  QUALITY_TESTS_VERIFY_TABLE = '/quality/tests/verify/table',
  QUALITY_TESTS_VERIFY_CHART = '/quality/tests/verify/chart',
  QUALITY_PRESETS = '/quality/presets',
  QUALITY_PRESETS_BY_PRESET_ID = '/quality/presets/{presetId}',
  QUALITY_REPORTS = '/quality/reports',
  QUALITY_REPORTS_BY_REPORT_ID = '/quality/reports/{reportId}',
  MATERIAL_DETAILS_GET_PUT_DELETE = '/core/material/materials/{materialId}',
  PRODUCTION_STATISTICS_GET = '/production-statistics',
  ANOMALY_DETECTION_OBSERVATION_LIST_GET = '/anomaly-detection/observations/{assetId}',
  ANOMALY_DETECTION_ROOT_CAUSE_GROUP_LIST_GET_POST = '/anomaly-detection/root-cause-groups',
  ANOMALY_DETECTION_ROOT_CAUSE_GROUP_PUT_DELETE = '/anomaly-detection/root-cause-groups/{rootCauseGroupId}',
  ANOMALY_DETECTION_COUNTER_ACTION_LIST_GET_POST = '/anomaly-detection/counter-actions',
  ANOMALY_DETECTION_COUNTER_ACTION_UNLINK = '/anomaly-detection/root-causes/{rootCauseId}/counter-actions/{counterActionId}',
  ANOMALY_DETECTION_COUNTER_ACTION_PUT_DELETE = '/anomaly-detection/counter-actions/{counterActionId}',
  ANOMALY_DETECTION_ROOT_CAUSE_AND_ROOT_CAUSE_GROUP_UNLINK = '/anomaly-detection/root-cause-groups/{rootCauseGroupId}/root-causes/{rootCauseId}',
  ANOMALY_DETECTION_ROOT_CAUSE_AND_ROOT_CAUSE_GROUP_LINK = '/anomaly-detection/root-cause-groups/{rootCauseGroupId}/root-causes',
  ANOMALY_DETECTION_ROOT_CAUSE_AND_OBSERVATION_CLASS_LINK = '/anomaly-detection/observation-classes',
  ANOMALY_DETECTION_ROOT_CAUSE_UNLINK = '/anomaly-detection/observation-classes/{jobFeatureId}/{observationTypeId}/{rootCauseId}',
  ANOMALY_DETECTION_ROOT_CAUSE_PUT_DELETE = '/anomaly-detection/root-causes/{rootCauseId}',
  ANOMALY_DETECTION_ROOT_CAUSE_LIST_GET_POST = '/anomaly-detection/root-causes',
  ANOMALY_DETECTION_JOB_FEATURE_GET = '/anomaly-detection/job-features',
  CUSTOM_USER_STORAGE_GET_PUT = '/core/users/me/settings/{storageKey}',
  ASSET_LIST_GET = '/core/assets',
  ASSET_DETAIL = '/core/assets/{assetId}',
  DATA_ALARM_LIST_GET = '/core/data/alarms',
  ASSET_TREE = '/core/asset-tree',
  ASSET_PROFILE_TREE = '/core/assets/{assetId}/profiles',
  ASSET_ALARM_LIST = '/core/data/alarms',
  ASSET_PROFILE_STATUS_TREE = '/core/assets/{assetId}/profile-mapping-status',
  ASSET_PRODUCTIVITY = '/core/assets/{assetId}/productivity',
  RECIPE_LIST_GET = '/core/recipe',
  ASSET_METADATA_BY_TIMESERIES_GET = '/core/assets/{assetId}/metadata/time-series',
  SEMANTICS_METADATA_DETAIL_GET_PUT = '/core/semantics/metadata/{dataId}',
  SEMANTICS_METADATA_MEANING = '/core/semantics/metadata/{dataId}/meaning',
  SEMANTICS_PROFILES_MEANINGS = '/core/semantics/profiles/{profileId}/meanings',
  DATA_TIMESERIES_DETAIL = '/core/data/timeseries/{dataId}',
  DATA_JOB_VALUES_DETAIL = '/core/data/job-values/{dataId}',
  UPDATE_SPECIFICATION = '/audit/specifications/{auditSpecificationId}/revisions',
  CREATE_SPECIFICATION = '/audit/specifications',
  GET_SPECIFICATION_LIST = '/audit/specifications',
  GET_DELETE_SPECIFICATION = '/audit/specifications/{auditSpecificationId}',
  AUDIT_SPECIFICATIONS_BY_AUDIT_SPECIFICATION_ID_REVISIONS_BY_REVISION = '/audit/specifications/{auditSpecificationId}/revisions/{revision}',
  GET_AUDIT_RESULT = '/audit/results/{auditResultId}',
  GET_MEANING_LIST = '/core/semantics/meanings',
  GET_MEANING_LIST_DETAIL = '/core/semantics/meanings/{meaningId}',
  PART_LIST_GET_POST = '/core/operation/parts',
  PART_DETAILS_GET_PUT_DELETE = '/core/operation/parts/{partId}',
  MATERIAL_LIST_GET_POST = '/core/material/materials',
  MATERIAL_NAMING_SYSTEM_LIST_GET_POST = '/core/material/naming-systems',
  MATERIAL_NAMING_SYSTEM_DEFAULT_GET_PUT = '/core/material/naming-systems/default',
  MATERIAL_NAMING_SYSTEM_DETAILS_PUT_DELETE = '/core/material/naming-systems/{materialNamingSystemId}',
  MATERIAL_GROUP_LIST_GET_POST = '/core/material/groups',
  MATERIAL_GROUP_DETAILS_GET_PUT_DELETE = '/core/material/groups/{materialGroupId}',
  MATERIAL_DRAFT_PUT = '/core/material/materials/draft',
  ATTRIBUTE_GET_POST = '/core/operation/parts/attributes',
  CORE_OPERATION_CUSTOMERS = '/core/operation/customers',
  CORE_OPERATION_CUSTOMERS_BY_CUSTOMER_ID = '/core/operation/customers/{customerId}',
  USER_LIST_GET_CREATE = '/core/users',
  USER_GET_UPDATE_DELETE = '/core/users/{userId}',
  CORE_ROLES = '/core/roles',
  ROLE_GET_PUT_DELETE = '/core/roles/{roleId}',
  ROLE_PERMISSIONS_LIST_GET_PUT = '/core/roles/{roleId}/permissions',
  USER_PERMISSIONS_GET_UPDATE_DELETE = '/core/users/{userId}/permissions',
  PT9800_DATABASES_USER = '/pt9800/databases/{ptDbId}/users',
  PT9800_USER_MAPPING_GET_PUT = '/pt9800/users/mapping',
  CORE_DATA_SOURCES_TREE = '/core/data-sources/tree',
}

export enum EOpenapiLegacyPaths {
  V2_USERS = '/api/v2/users',
  BLOB_GET_DELETE = '/api/v2/blob-store/{namespace}/files/{key}/{filename}',
  BLOB_LIST = '/api/v2/blob-store/{namespace}/content/{key}',
  BLOB_UPLOAD = '/api/v2/blob-store/{namespace}/files/{key}',
  MAINTENANCE_CALENDAR_JOB_CREATE_GET_LIST = '/api/v1/maintenance-calendar/jobs',
  MAINTENANCE_CALENDAR_JOB_GET_UPDATE_DELETE = '/api/v1/maintenance-calendar/jobs/{maintenance_job_id}',
  MAINTENANCE_CALENDAR_OCCURRENCE_GET_PUT = '/api/v1/maintenance-calendar/occurrences/{occurrence_id}',
  MAINTENANCE_CALENDAR_OCCURRENCE_LIST = '/api/v1/maintenance-calendar/occurrences',
  MAINTENANCE_CALENDAR_OCCURRENCE_RESULT_LIST = '/api/v1/maintenance-calendar/occurrences/{occurrence_id}/results',
  MAINTENANCE_CALENDAR_OCCURRENCE_SERIES_CREATE = '/api/v1/maintenance-calendar/series',
  MAINTENANCE_CALENDAR_OCCURRENCE_SERIES_GET = '/api/v1/maintenance-calendar/series/{series_id}',
  MAINTENANCE_CALENDAR_TASK_CREATE_GET_LIST = '/api/v1/maintenance-calendar/tasks',
  MAINTENANCE_CALENDAR_TASK_GET_UPDATE_DELETE = '/api/v1/maintenance-calendar/tasks/{task_id}',
  PRODUCTION_LOGS_FILTERS_DATA_LOCATIONS = '/api/v1/production-logs/filters-data/locations',
  PRODUCTION_LOGS_FILTERS_FURNACE_NAMES = '/api/v1/production-logs/filters-data/furnace-names',
  PRODUCTION_LOGS_FILTERS_FURNACE_TYPES = '/api/v1/production-logs/filters-data/furnace-types',
  RECIPE_ON_DEMAND_PROCESS = '/api/v1/recipe-on-demand/recipes/{recipe_id}/process',
  RECIPE_ON_DEMAND_PROFILE = '/api/v1/recipe-on-demand/recipes/{recipe_id}/profile',
}

export interface ISortItem<T = string> {
  header: T,
  sortOrder: ESortBy,
}

export const computedUrl = {
  // npm
  [EOpenapiNpmPaths.FURNACE_RECIPE_GET_CREATE_UPDATE_DELETE]: (pathParams: npmPaths[EOpenapiNpmPaths.FURNACE_RECIPE_GET_CREATE_UPDATE_DELETE]['get']['parameters']['path']) => `/furnace/${pathParams.customer_code}/${pathParams.plant_name}/${pathParams.system_id}/recipes/${pathParams.recipe_code}`,
  [EOpenapiNpmPaths.FURNACE_RECIPE_TEMPLATE_GET]: (pathParams: npmPaths[EOpenapiNpmPaths.FURNACE_RECIPE_TEMPLATE_GET]['get']['parameters']['path']) => `/furnace/${pathParams.customer_code}/${pathParams.plant_name}/${pathParams.system_id}/recipe-template`,
  [EOpenapiNpmPaths.FURNACE_RECIPE_LIST]: (pathParams: npmPaths[EOpenapiNpmPaths.FURNACE_RECIPE_LIST]['get']['parameters']['path']) => `/furnace/${pathParams.customer_code}/${pathParams.plant_name}/${pathParams.system_id}/recipe-list`,
  // new
  [EOpenapiPaths.QUALITY_PRESETS_BY_PRESET_ID]: (path: TUpdateQualityPresetPath) => `/quality/presets/${path.presetId}`,
  [EOpenapiPaths.QUALITY_REPORTS_BY_REPORT_ID]: (path: TGetQualityReportDetailsPath) => `/quality/reports/${path.reportId}`,
  [EOpenapiPaths.AUDIT_SPECIFICATIONS_BY_AUDIT_SPECIFICATION_ID_REVISIONS_BY_REVISION]: (path: TGetSpecificationByRevisionPath) => `/audit/specifications/${path.auditSpecificationId}/revisions/${path.revision}`,
  [EOpenapiPaths.ANOMALY_DETECTION_ROOT_CAUSE_AND_ROOT_CAUSE_GROUP_LINK]: (path: TLinkRootCauseAndRootCauseGroupPath) => `/anomaly-detection/root-cause-groups/${path.rootCauseGroupId}/root-causes`,
  [EOpenapiPaths.ANOMALY_DETECTION_ROOT_CAUSE_AND_ROOT_CAUSE_GROUP_UNLINK]: (path: TUnlinkRootCauseAndRootCauseGroupPath) => `/anomaly-detection/root-cause-groups/${path.rootCauseGroupId}/root-causes/${path.rootCauseId}`,
  [EOpenapiPaths.ANOMALY_DETECTION_ROOT_CAUSE_GROUP_PUT_DELETE]: (path: TUpdateRootCauseGroupPath) => `/anomaly-detection/root-cause-groups/${path.rootCauseGroupId}`,
  [EOpenapiPaths.ANOMALY_DETECTION_COUNTER_ACTION_PUT_DELETE]: (path: TUpdateCounterActionPath) => `/anomaly-detection/counter-actions/${path.counterActionId}`,
  [EOpenapiPaths.ANOMALY_DETECTION_ROOT_CAUSE_PUT_DELETE]: (path: TUpdateRootCausePath) => `/anomaly-detection/root-causes/${path.rootCauseId}`,
  [EOpenapiPaths.ANOMALY_DETECTION_ROOT_CAUSE_UNLINK]: (path: TUnlinkRootCausePath) => `/anomaly-detection/observation-classes/${path.jobFeatureId}/${path.observationTypeId}/${path.rootCauseId}`,
  [EOpenapiPaths.ANOMALY_DETECTION_COUNTER_ACTION_UNLINK]: (path: TUnlinkCounterActionPath) => `/anomaly-detection/root-causes/${path.rootCauseId}/counter-actions/${path.counterActionId}`,
  [EOpenapiPaths.ANOMALY_DETECTION_OBSERVATION_LIST_GET]: (path: TGetObservationListPath) => `/anomaly-detection/observations/${path.assetId}`,
  [EOpenapiPaths.ASSET_METADATA_BY_TIMESERIES_GET]: (path: TGetAssetMetadataByTimeseriesPath) => `/core/assets/${path.assetId}/metadata/time-series`,
  [EOpenapiPaths.ASSET_PROFILE_TREE]: (assetId: number) => `/core/assets/${assetId}/profiles`,
  [EOpenapiPaths.ASSET_PROFILE_STATUS_TREE]: (assetId: number) => `/core/assets/${assetId}/profile-mapping-status`,
  [EOpenapiPaths.ASSET_DETAIL]: (assetId: number) => `/core/assets/${assetId}`,
  [EOpenapiPaths.ASSET_PRODUCTIVITY]: (path: TGetAssetProductivityPath) => `/core/assets/${path.assetId}/productivity`,
  [EOpenapiPaths.CUSTOM_USER_STORAGE_GET_PUT]: <T extends keyof ICustomStorageItem>(path: TCustomStoragePath<T>) => `/core/users/me/settings/${path.storageKey}`,
  [EOpenapiPaths.DATA_TIMESERIES_DETAIL]: (dataId: string | number) => `/core/data/timeseries/${dataId}`,
  [EOpenapiPaths.DATA_JOB_VALUES_DETAIL]: (dataId: number) => `/core/data/job-values/${dataId}`,
  [EOpenapiPaths.GET_DELETE_SPECIFICATION]: (specificationId: number) => `/audit/specifications/${specificationId}`,
  [EOpenapiPaths.GET_AUDIT_RESULT]: (auditResultId: number) => `/audit/results/${auditResultId}`,
  [EOpenapiPaths.GET_MEANING_LIST]: () => '/core/semantics/meanings',
  [EOpenapiPaths.GET_MEANING_LIST_DETAIL]: (meaningId: number) => `/core/semantics/meanings/${meaningId}`,
  [EOpenapiPaths.MATERIAL_NAMING_SYSTEM_DETAILS_PUT_DELETE]: (path: TUpdateMaterialNamingSystemPath) => `/core/material/naming-systems/${path.materialNamingSystemId}`,
  [EOpenapiPaths.MATERIAL_GROUP_DETAILS_GET_PUT_DELETE]: (path: TGetMaterialGroupPath) => `/core/material/groups/${path.materialGroupId}`,
  [EOpenapiPaths.MATERIAL_DETAILS_GET_PUT_DELETE]: (path: TGetMaterialDetailsPath) => `/core/material/materials/${path.materialId}`,
  [EOpenapiPaths.PART_DETAILS_GET_PUT_DELETE]: (path: TGetPartDetailsPath) => `/core/operation/parts/${path.partId}`,
  [EOpenapiPaths.CORE_OPERATION_CUSTOMERS_BY_CUSTOMER_ID]: (path: TGetCustomerDetailsPath) => `/core/operation/customers/${path.customerId}`,
  [EOpenapiPaths.PT9800_DATABASES_USER]: (path: TGetPtDbUserListPath) => `/pt9800/databases/${path.ptDbId}/users`,
  [EOpenapiPaths.ROLE_GET_PUT_DELETE]: (path: TUpdateRolePath) => `/core/roles/${path.roleId}`,
  [EOpenapiPaths.ROLE_PERMISSIONS_LIST_GET_PUT]: (path: TGetRolePermissionListPath) => `/core/roles/${path.roleId}/permissions`,
  [EOpenapiPaths.SEMANTICS_METADATA_DETAIL_GET_PUT]: (dataId: string | number) => `/core/semantics/metadata/${dataId}`,
  [EOpenapiPaths.SEMANTICS_METADATA_MEANING]: (dataId: string | number) => `/core/semantics/metadata/${dataId}/meaning`,
  [EOpenapiPaths.SEMANTICS_PROFILES_MEANINGS]: (profileId: number) => `/core/semantics/profiles/${profileId}/meanings`,
  [EOpenapiPaths.USER_PERMISSIONS_GET_UPDATE_DELETE]: (path: TUpdateUserPermissionsPath) => `/core/users/${path.userId}/permissions`,
  [EOpenapiPaths.USER_GET_UPDATE_DELETE]: (path: TDeleteUserPath) => `/core/users/${path.userId}`,
  [EOpenapiPaths.UPDATE_SPECIFICATION]: (specificationId: number) => `/audit/specifications/${specificationId}/revisions`,
  // legacy paths must be all re-mapped, because they contain baseUrl in keys
  [EOpenapiLegacyPaths.V2_USERS]: () => '/users',
  [EOpenapiLegacyPaths.PRODUCTION_LOGS_FILTERS_DATA_LOCATIONS]: () => '/production-logs/filters-data/locations',
  [EOpenapiLegacyPaths.PRODUCTION_LOGS_FILTERS_FURNACE_NAMES]: () => '/production-logs/filters-data/furnace-names',
  [EOpenapiLegacyPaths.PRODUCTION_LOGS_FILTERS_FURNACE_TYPES]: () => '/production-logs/filters-data/furnace-types',
  [EOpenapiLegacyPaths.RECIPE_ON_DEMAND_PROFILE]: (recipeId: number) => `recipe-on-demand/recipes/${recipeId}/profile`,
  [EOpenapiLegacyPaths.RECIPE_ON_DEMAND_PROCESS]: (recipeId: number) => `recipe-on-demand/recipes/${recipeId}/process`,
  [EOpenapiLegacyPaths.MAINTENANCE_CALENDAR_JOB_CREATE_GET_LIST]: () => '/maintenance-calendar/jobs',
  [EOpenapiLegacyPaths.MAINTENANCE_CALENDAR_JOB_GET_UPDATE_DELETE]: (maintenanceJobId: number) => `/maintenance-calendar/jobs/${maintenanceJobId}`,
  [EOpenapiLegacyPaths.MAINTENANCE_CALENDAR_TASK_CREATE_GET_LIST]: () => '/maintenance-calendar/tasks',
  [EOpenapiLegacyPaths.MAINTENANCE_CALENDAR_TASK_GET_UPDATE_DELETE]: (taskId: number) => `/maintenance-calendar/tasks/${taskId}`,
  [EOpenapiLegacyPaths.MAINTENANCE_CALENDAR_OCCURRENCE_LIST]: () => '/maintenance-calendar/occurrences',
  [EOpenapiLegacyPaths.MAINTENANCE_CALENDAR_OCCURRENCE_GET_PUT]: (occurrenceId: number) => `/maintenance-calendar/occurrences/${occurrenceId}`,
  [EOpenapiLegacyPaths.MAINTENANCE_CALENDAR_OCCURRENCE_RESULT_LIST]: (occurrenceId: number) => `/maintenance-calendar/occurrences/${occurrenceId}/results`,
  [EOpenapiLegacyPaths.MAINTENANCE_CALENDAR_OCCURRENCE_SERIES_GET]: (seriesId: number) => `/maintenance-calendar/series/${seriesId}`,
  [EOpenapiLegacyPaths.MAINTENANCE_CALENDAR_OCCURRENCE_SERIES_CREATE]: () => '/maintenance-calendar/series',
  [EOpenapiLegacyPaths.BLOB_UPLOAD]: (namespace: string, key: string) => `/blob-store/${namespace}/files/${key}`,
  [EOpenapiLegacyPaths.BLOB_LIST]: (namespace: string, key: string) => `/blob-store/${namespace}/content/${key}`,
  [EOpenapiLegacyPaths.BLOB_GET_DELETE]: (namespace: string, key: string, filename: string) => `/blob-store/${namespace}/files/${key}/${filename}`,
};

export type TLegacyComponentsSchemaKey = keyof legacyComponents['schemas'];
export type TComponentLegacyValue<T extends TLegacyComponentsSchemaKey> = legacyComponents['schemas'][T];
export type TComponentLegacyKeys<T extends TLegacyComponentsSchemaKey, K = string> = Record<keyof legacyComponents['schemas'][T], K>;

export type TComponentsSchemaKey = keyof components['schemas'];
export type TComponentValue<T extends TComponentsSchemaKey> = components['schemas'][T];
export type TComponentKeys<T extends TComponentsSchemaKey, K = string> = Record<keyof components['schemas'][T], K>;

export type TComponentsNpmSchemaKey = keyof npmComponents['schemas'];
export type TComponentNpmValue<T extends TComponentsNpmSchemaKey> = npmComponents['schemas'][T];
export type TComponentNpmKeys<T extends TComponentsNpmSchemaKey, K = string> = Record<keyof npmComponents['schemas'][T], K>;

export type TComponentsPT9800SchemaKey = keyof pt9800Components['schemas'];
export type TComponentPT9800Value<T extends TComponentsPT9800SchemaKey> = pt9800Components['schemas'][T];
export type TComponentPT9800Keys<T extends TComponentsPT9800SchemaKey, K = string> = Record<keyof pt9800Components['schemas'][T], K>;

export type TComputedPT9800PlannedPartInput = TComponentPT9800Value<'plannedPartInput'> & {
  totalWeight: number,
}

export const openAPIPrimitiveTypeMap: Record<components['schemas']['OpenAPIPrimitiveType'], string> = {
  string: 'string',
  number: 'number',
  integer: 'integer',
  boolean: 'boolean',
};

export enum ETimeseriesMeaning {
  TEMPERATURE_SETPOINT = 1,
  TEMPERATURE_READING = 2,
  HEATING_POWER_OUTPUT_SETPOINT = 3,
  HEATING_POWER_OUTPUT_READING = 4,
  HEATING_POWER_ENABLED = 5,
  ELECTRICAL_POWER_SETPOINT = 6,
  ELECTRICAL_POWER_READING = 7,
  TOTAL_HEATING_GAS_SUPPLY_FLOW_READING = 8,
  COOLING_POWER_OUTPUT_SETPOINT = 9,
  COOLING_POWER_OUTPUT_READING = 10,
  COOLING_POWER_ENABLED = 11,
  TOTAL_COOLING_GAS_SUPPLY_FLOW_READING = 12,
  RELATIVE_PRESSURE_SETPOINT = 13,
  RELATIVE_PRESSURE_READING = 14,
  CHAMBER_TEMPERATURE_SETPOINT = 15,
  CHAMBER_TEMPERATURE_READING = 16,
  ABSOLUTE_PRESSURE_SETPOINT = 17,
  ABSOLUTE_PRESSURE_READING = 18,
  VACUUM_PRESSURE_SETPOINT = 19,
  VACUUM_PRESSURE_READING = 20,
  ATMOSPHERIC_PRESSURE_SETPOINT = 21,
  ATMOSPHERIC_PRESSURE_READING = 22,
  ROOM_TEMPERATURE_SETPOINT = 23,
  ROOM_TEMPERATURE_READING = 24,
  ACTIVITY_AH_SETPOINT = 25,
  ACTIVITY_AH_CALCULATION = 26,
  POTENTIAL_HP_SETPOINT = 27,
  POTENTIAL_HP_CALCULATION = 28,
  POTENTIAL_KH_SETPOINT = 29,
  POTENTIAL_KH_CALCULATION = 30,
  ACTIVITY_AO_SETPOINT = 31,
  ACTIVITY_AO_CALCULATION = 32,
  POTENTIAL_OP_SETPOINT = 33,
  POTENTIAL_OP_CALCULATION = 34,
  POTENTIAL_KO_SETPOINT = 35,
  POTENTIAL_KO_CALCULATION = 36,
  ACTIVITY_AC_SETPOINT = 37,
  ACTIVITY_AC_CALCULATION = 38,
  POTENTIAL_CP_SETPOINT = 39,
  POTENTIAL_CP_CALCULATION = 40,
  POTENTIAL_KC_SETPOINT = 41,
  POTENTIAL_KC_CALCULATION = 42,
  ACTIVITY_AN_SETPOINT = 43,
  ACTIVITY_AN_CALCULATION = 44,
  POTENTIAL_NP_SETPOINT = 45,
  POTENTIAL_NP_CALCULATION = 46,
  POTENTIAL_KN_SETPOINT = 47,
  POTENTIAL_KN_CALCULATION = 48,
  ACTIVITY_AB_SETPOINT = 49,
  ACTIVITY_AB_CALCULATION = 50,
  POTENTIAL_BP_SETPOINT = 51,
  POTENTIAL_BP_CALCULATION = 52,
  POTENTIAL_KB_SETPOINT = 53,
  POTENTIAL_KB_CALCULATION = 54,
  ACTIVITY_AS_SETPOINT = 55,
  ACTIVITY_AS_CALCULATION = 56,
  POTENTIAL_SP_SETPOINT = 57,
  POTENTIAL_SP_CALCULATION = 58,
  POTENTIAL_KS_SETPOINT = 59,
  POTENTIAL_KS_CALCULATION = 60,
  ACETYLENE_C2H2_FLOW_SETPOINT = 61,
  ACETYLENE_C2H2_FLOW_READING = 62,
  AIR_FLOW_SETPOINT = 63,
  AIR_FLOW_READING = 64,
  AMMONIA_NH3_FLOW_SETPOINT = 65,
  AMMONIA_NH3_FLOW_READING = 66,
  ARGON_FLOW_SETPOINT = 67,
  ARGON_FLOW_READING = 68,
  CARBON_DIOXIDE_CO2_FLOW_SETPOINT = 69,
  CARBON_DIOXIDE_CO2_FLOW_READING = 70,
  CARBON_MONOXIDE_CO_FLOW_SETPOINT = 71,
  CARBON_MONOXIDE_CO_FLOW_READING = 72,
  DISSOCIATED_AMMONIA_NH3_FLOW_SETPOINT = 73,
  DISSOCIATED_AMMONIA_NH3_FLOW_READING = 74,
  ENDO_GAS_NATURAL_FLOW_SETPOINT = 75,
  ENDO_GAS_NATURAL_FLOW_READING = 76,
  ENDO_GAS_PROPANE_FLOW_SETPOINT = 77,
  ENDO_GAS_PROPANE_FLOW_READING = 78,
  ETHENE_C2H4_FLOW_SETPOINT = 79,
  ETHENE_C2H4_FLOW_READING = 80,
  HELIUM_FLOW_SETPOINT = 81,
  HELIUM_FLOW_READING = 82,
  HYDROGEN_H2_FLOW_SETPOINT = 83,
  HYDROGEN_H2_FLOW_READING = 84,
  METHANOL_CH3OH_FLOW_SETPOINT = 85,
  METHANOL_CH3OH_FLOW_READING = 86,
  NATURAL_GAS_FLOW_SETPOINT = 87,
  NATURAL_GAS_FLOW_READING = 88,
  NITROGEN_FLOW_SETPOINT = 89,
  NITROGEN_FLOW_READING = 90,
  PROPANE_C3H8_FLOW_SETPOINT = 91,
  PROPANE_C3H8_FLOW_READING = 92,
  WATER_FLOW_SETPOINT = 93,
  WATER_FLOW_READING = 94,
  NITROUS_OXIDE_N2O_FLOW_SETPOINT = 95,
  NITROUS_OXIDE_N2O_FLOW_READING = 96,
  METHANE_CH4_FLOW_SETPOINT = 97,
  METHANE_CH4_FLOW_READING = 98,
  OXYGEN_O2_FLOW_SETPOINT = 99,
  OXYGEN_O2_FLOW_READING = 100,
  DISSOCIATION_SETPOINT = 115,
  DISSOCIATION_READING = 116,
  PARTIAL_PRESSURE_ACETYLENE_C2H2_SETPOINT = 131,
  PARTIAL_PRESSURE_ACETYLENE_C2H2_READING = 132,
  PARTIAL_PRESSURE_AIR_SETPOINT = 133,
  PARTIAL_PRESSURE_AIR_READING = 134,
  PARTIAL_PRESSURE_AMMONIA_NH3_SETPOINT = 135,
  PARTIAL_PRESSURE_AMMONIA_NH3_READING = 136,
  PARTIAL_PRESSURE_ARGON_SETPOINT = 137,
  PARTIAL_PRESSURE_ARGON_READING = 138,
  PARTIAL_PRESSURE_CARBON_DIOXIDE_CO2_SETPOINT = 139,
  PARTIAL_PRESSURE_CARBON_DIOXIDE_CO2_READING = 140,
  PARTIAL_PRESSURE_CARBON_MONOXIDE_CO_SETPOINT = 141,
  PARTIAL_PRESSURE_CARBON_MONOXIDE_CO_READING = 142,
  PARTIAL_PRESSURE_DISSOCIATED_AMMONIA_NH3_SETPOINT = 143,
  PARTIAL_PRESSURE_DISSOCIATED_AMMONIA_NH3_READING = 144,
  PARTIAL_PRESSURE_ENDO_GAS_NATURAL_SETPOINT = 145,
  PARTIAL_PRESSURE_ENDO_GAS_NATURAL_READING = 146,
  PARTIAL_PRESSURE_ENDO_GAS_PROPANE_SETPOINT = 147,
  PARTIAL_PRESSURE_ENDO_GAS_PROPANE_READING = 148,
  PARTIAL_PRESSURE_ETHENE_C2H4_SETPOINT = 149,
  PARTIAL_PRESSURE_ETHENE_C2H4_READING = 150,
  PARTIAL_PRESSURE_HELIUM_SETPOINT = 151,
  PARTIAL_PRESSURE_HELIUM_READING = 152,
  PARTIAL_PRESSURE_HYDROGEN_H2_SETPOINT = 153,
  PARTIAL_PRESSURE_HYDROGEN_H2_READING = 154,
  PARTIAL_PRESSURE_METHANOL_CH3OH_SETPOINT = 155,
  PARTIAL_PRESSURE_METHANOL_CH3OH_READING = 156,
  PARTIAL_PRESSURE_NATURAL_GAS_SETPOINT = 157,
  PARTIAL_PRESSURE_NATURAL_GAS_READING = 158,
  PARTIAL_PRESSURE_NITROGEN_SETPOINT = 159,
  PARTIAL_PRESSURE_NITROGEN_READING = 160,
  PARTIAL_PRESSURE_NITROUS_OXIDE_N2O_SETPOINT = 161,
  PARTIAL_PRESSURE_NITROUS_OXIDE_N2O_READING = 162,
  PARTIAL_PRESSURE_PROPANE_C3H8_SETPOINT = 163,
  PARTIAL_PRESSURE_PROPANE_C3H8_READING = 164,
  PARTIAL_PRESSURE_WATER_SETPOINT = 165,
  PARTIAL_PRESSURE_WATER_READING = 166,
  PARTIAL_PRESSURE_ACETYLENE_C2H2_PERCENT_SETPOINT = 167,
  PARTIAL_PRESSURE_ACETYLENE_C2H2_PERCENT_READING = 168,
  PARTIAL_PRESSURE_AIR_PERCENT_SETPOINT = 169,
  PARTIAL_PRESSURE_AIR_PERCENT_READING = 170,
  PARTIAL_PRESSURE_AMMONIA_NH3_PERCENT_SETPOINT = 171,
  PARTIAL_PRESSURE_AMMONIA_NH3_PERCENT_READING = 172,
  PARTIAL_PRESSURE_ARGON_PERCENT_SETPOINT = 173,
  PARTIAL_PRESSURE_ARGON_PERCENT_READING = 174,
  PARTIAL_PRESSURE_CARBON_DIOXIDE_CO2_PERCENT_SETPOINT = 175,
  PARTIAL_PRESSURE_CARBON_DIOXIDE_CO2_PERCENT_READING = 176,
  PARTIAL_PRESSURE_CARBON_MONOXIDE_CO_PERCENT_SETPOINT = 177,
  PARTIAL_PRESSURE_CARBON_MONOXIDE_CO_PERCENT_READING = 178,
  PARTIAL_PRESSURE_DISSOCIATED_AMMONIA_NH3_PERCENT_SETPOINT = 179,
  PARTIAL_PRESSURE_DISSOCIATED_AMMONIA_NH3_PERCENT_READING = 180,
  PARTIAL_PRESSURE_ENDO_GAS_NATURAL_PERCENT_SETPOINT = 181,
  PARTIAL_PRESSURE_ENDO_GAS_NATURAL_PERCENT_READING = 182,
  PARTIAL_PRESSURE_ENDO_GAS_PROPANE_PERCENT_SETPOINT = 183,
  PARTIAL_PRESSURE_ENDO_GAS_PROPANE_PERCENT_READING = 184,
  PARTIAL_PRESSURE_ETHENE_C2H4_PERCENT_SETPOINT = 185,
  PARTIAL_PRESSURE_ETHENE_C2H4_PERCENT_READING = 186,
  PARTIAL_PRESSURE_HELIUM_PERCENT_SETPOINT = 187,
  PARTIAL_PRESSURE_HELIUM_PERCENT_READING = 188,
  PARTIAL_PRESSURE_HYDROGEN_H2_PERCENT_SETPOINT = 189,
  PARTIAL_PRESSURE_HYDROGEN_H2_PERCENT_READING = 190,
  PARTIAL_PRESSURE_METHANOL_CH3OH_PERCENT_SETPOINT = 191,
  PARTIAL_PRESSURE_METHANOL_CH3OH_PERCENT_READING = 192,
  PARTIAL_PRESSURE_NATURAL_GAS_PERCENT_SETPOINT = 193,
  PARTIAL_PRESSURE_NATURAL_GAS_PERCENT_READING = 194,
  PARTIAL_PRESSURE_NITROGEN_PERCENT_SETPOINT = 195,
  PARTIAL_PRESSURE_NITROGEN_PERCENT_READING = 196,
  PARTIAL_PRESSURE_NITROUS_OXIDE_N2O_PERCENT_SETPOINT = 197,
  PARTIAL_PRESSURE_NITROUS_OXIDE_N2O_PERCENT_READING = 198,
  PARTIAL_PRESSURE_PROPANE_C3H8_PERCENT_SETPOINT = 199,
  PARTIAL_PRESSURE_PROPANE_C3H8_PERCENT_READING = 200,
  PARTIAL_PRESSURE_WATER_PERCENT_SETPOINT = 201,
  PARTIAL_PRESSURE_WATER_PERCENT_READING = 202,
  PARTIAL_PRESSURE_OXYGEN_SETPOINT = 203,
  PARTIAL_PRESSURE_OXYGEN_READING = 204,
  PARTIAL_PRESSURE_CARBON_SETPOINT = 205,
  PARTIAL_PRESSURE_CARBON_READING = 206,
  PARTIAL_PRESSURE_BORON_SETPOINT = 207,
  PARTIAL_PRESSURE_BORON_READING = 208,
  PARTIAL_PRESSURE_SULFUR_SETPOINT = 209,
  PARTIAL_PRESSURE_SULFUR_READING = 210,
  PARTIAL_PRESSURE_OXYGEN_PERCENT_SETPOINT = 211,
  PARTIAL_PRESSURE_OXYGEN_PERCENT_READING = 212,
  PARTIAL_PRESSURE_CARBON_PERCENT_SETPOINT = 213,
  PARTIAL_PRESSURE_CARBON_PERCENT_READING = 214,
  PARTIAL_PRESSURE_BORON_PERCENT_SETPOINT = 215,
  PARTIAL_PRESSURE_BORON_PERCENT_READING = 216,
  PARTIAL_PRESSURE_SULFUR_PERCENT_SETPOINT = 217,
  PARTIAL_PRESSURE_SULFUR_PERCENT_READING = 218,
  ETHANE_C2H6_FLOW_SETPOINT = 219,
  ETHANE_C2H6_FLOW_READING = 220,
  PARTIAL_PRESSURE_ETHANE_C2H6_SETPOINT = 221,
  PARTIAL_PRESSURE_ETHANE_C2H6_READING = 222,
  PARTIAL_PRESSURE_ETHANE_C2H6_PERCENT_SETPOINT = 223,
  PARTIAL_PRESSURE_ETHANE_C2H6_PERCENT_READING = 224,
  PARTIAL_PRESSURE_METHANE_CH4_SETPOINT = 225,
  PARTIAL_PRESSURE_METHANE_CH4_READING = 226,
  PARTIAL_PRESSURE_METHANE_CH4_PERCENT_SETPOINT = 227,
  PARTIAL_PRESSURE_METHANE_CH4_PERCENT_READING = 228,
  HIGH_VACUUM_PRESSURE_SETPOINT = 229,
  HIGH_VACUUM_PRESSURE_READING = 230,
  LOW_VACUUM_PRESSURE_SETPOINT = 231,
  LOW_VACUUM_PRESSURE_READING = 232,
  LEAD_TIME_SETPOINT = 233,
  LEAD_TIME_READING = 234,
  BELT_SPEED_SETPOINT = 235,
  BELT_SPEED_READING = 236,
  USER_DEFINED_100 = 9900,
  USER_DEFINED_099 = 9901,
  USER_DEFINED_098 = 9902,
  USER_DEFINED_097 = 9903,
  USER_DEFINED_096 = 9904,
  USER_DEFINED_095 = 9905,
  USER_DEFINED_094 = 9906,
  USER_DEFINED_093 = 9907,
  USER_DEFINED_092 = 9908,
  USER_DEFINED_091 = 9909,
  USER_DEFINED_090 = 9910,
  USER_DEFINED_089 = 9911,
  USER_DEFINED_088 = 9912,
  USER_DEFINED_087 = 9913,
  USER_DEFINED_086 = 9914,
  USER_DEFINED_085 = 9915,
  USER_DEFINED_084 = 9916,
  USER_DEFINED_083 = 9917,
  USER_DEFINED_082 = 9918,
  USER_DEFINED_081 = 9919,
  USER_DEFINED_080 = 9920,
  USER_DEFINED_079 = 9921,
  USER_DEFINED_078 = 9922,
  USER_DEFINED_077 = 9923,
  USER_DEFINED_076 = 9924,
  USER_DEFINED_075 = 9925,
  USER_DEFINED_074 = 9926,
  USER_DEFINED_073 = 9927,
  USER_DEFINED_072 = 9928,
  USER_DEFINED_071 = 9929,
  USER_DEFINED_070 = 9930,
  USER_DEFINED_069 = 9931,
  USER_DEFINED_068 = 9932,
  USER_DEFINED_067 = 9933,
  USER_DEFINED_066 = 9934,
  USER_DEFINED_065 = 9935,
  USER_DEFINED_064 = 9936,
  USER_DEFINED_063 = 9937,
  USER_DEFINED_062 = 9938,
  USER_DEFINED_061 = 9939,
  USER_DEFINED_060 = 9940,
  USER_DEFINED_059 = 9941,
  USER_DEFINED_058 = 9942,
  USER_DEFINED_057 = 9943,
  USER_DEFINED_056 = 9944,
  USER_DEFINED_055 = 9945,
  USER_DEFINED_054 = 9946,
  USER_DEFINED_053 = 9947,
  USER_DEFINED_052 = 9948,
  USER_DEFINED_051 = 9949,
  USER_DEFINED_050 = 9950,
  USER_DEFINED_049 = 9951,
  USER_DEFINED_048 = 9952,
  USER_DEFINED_047 = 9953,
  USER_DEFINED_046 = 9954,
  USER_DEFINED_045 = 9955,
  USER_DEFINED_044 = 9956,
  USER_DEFINED_043 = 9957,
  USER_DEFINED_042 = 9958,
  USER_DEFINED_041 = 9959,
  USER_DEFINED_040 = 9960,
  USER_DEFINED_039 = 9961,
  USER_DEFINED_038 = 9962,
  USER_DEFINED_037 = 9963,
  USER_DEFINED_036 = 9964,
  USER_DEFINED_035 = 9965,
  USER_DEFINED_034 = 9966,
  USER_DEFINED_033 = 9967,
  USER_DEFINED_032 = 9968,
  USER_DEFINED_031 = 9969,
  USER_DEFINED_030 = 9970,
  USER_DEFINED_029 = 9971,
  USER_DEFINED_028 = 9972,
  USER_DEFINED_027 = 9973,
  USER_DEFINED_026 = 9974,
  USER_DEFINED_025 = 9975,
  USER_DEFINED_024 = 9976,
  USER_DEFINED_023 = 9977,
  USER_DEFINED_022 = 9978,
  USER_DEFINED_021 = 9979,
  USER_DEFINED_020 = 9980,
  USER_DEFINED_019 = 9981,
  USER_DEFINED_018 = 9982,
  USER_DEFINED_017 = 9983,
  USER_DEFINED_016 = 9984,
  USER_DEFINED_015 = 9985,
  USER_DEFINED_014 = 9986,
  USER_DEFINED_013 = 9987,
  USER_DEFINED_012 = 9988,
  USER_DEFINED_011 = 9989,
  USER_DEFINED_010 = 9990,
  USER_DEFINED_009 = 9991,
  USER_DEFINED_008 = 9992,
  USER_DEFINED_007 = 9993,
  USER_DEFINED_006 = 9994,
  USER_DEFINED_005 = 9995,
  USER_DEFINED_004 = 9996,
  USER_DEFINED_003 = 9997,
  USER_DEFINED_002 = 9998,
  USER_DEFINED_001 = 9999,
}

export const equipmentGasList = [
  ETimeseriesMeaning.ACETYLENE_C2H2_FLOW_SETPOINT,
  ETimeseriesMeaning.AIR_FLOW_SETPOINT,
  ETimeseriesMeaning.AMMONIA_NH3_FLOW_SETPOINT,
  ETimeseriesMeaning.ARGON_FLOW_SETPOINT,
  ETimeseriesMeaning.CARBON_DIOXIDE_CO2_FLOW_SETPOINT,
  ETimeseriesMeaning.CARBON_MONOXIDE_CO_FLOW_SETPOINT,
  ETimeseriesMeaning.DISSOCIATED_AMMONIA_NH3_FLOW_SETPOINT,
  ETimeseriesMeaning.ENDO_GAS_NATURAL_FLOW_SETPOINT,
  ETimeseriesMeaning.ENDO_GAS_PROPANE_FLOW_SETPOINT,
  ETimeseriesMeaning.ETHENE_C2H4_FLOW_SETPOINT,
  ETimeseriesMeaning.HELIUM_FLOW_SETPOINT,
  ETimeseriesMeaning.HYDROGEN_H2_FLOW_SETPOINT,
  ETimeseriesMeaning.METHANOL_CH3OH_FLOW_SETPOINT,
  ETimeseriesMeaning.NATURAL_GAS_FLOW_SETPOINT,
  ETimeseriesMeaning.NITROGEN_FLOW_SETPOINT,
  ETimeseriesMeaning.PROPANE_C3H8_FLOW_SETPOINT,
  ETimeseriesMeaning.WATER_FLOW_SETPOINT,
  ETimeseriesMeaning.NITROUS_OXIDE_N2O_FLOW_SETPOINT,
  ETimeseriesMeaning.METHANE_CH4_FLOW_SETPOINT,
  ETimeseriesMeaning.OXYGEN_O2_FLOW_SETPOINT,
];

export const timeseriesMeaningMap: Record<components['schemas']['TimeseriesMeaning'], ETimeseriesMeaning> = {
  1: ETimeseriesMeaning.TEMPERATURE_SETPOINT,
  2: ETimeseriesMeaning.TEMPERATURE_READING,
  3: ETimeseriesMeaning.HEATING_POWER_OUTPUT_SETPOINT,
  4: ETimeseriesMeaning.HEATING_POWER_OUTPUT_READING,
  5: ETimeseriesMeaning.HEATING_POWER_ENABLED,
  6: ETimeseriesMeaning.ELECTRICAL_POWER_SETPOINT,
  7: ETimeseriesMeaning.ELECTRICAL_POWER_READING,
  8: ETimeseriesMeaning.TOTAL_HEATING_GAS_SUPPLY_FLOW_READING,
  9: ETimeseriesMeaning.COOLING_POWER_OUTPUT_SETPOINT,
  10: ETimeseriesMeaning.COOLING_POWER_OUTPUT_READING,
  11: ETimeseriesMeaning.COOLING_POWER_ENABLED,
  12: ETimeseriesMeaning.TOTAL_COOLING_GAS_SUPPLY_FLOW_READING,
  13: ETimeseriesMeaning.RELATIVE_PRESSURE_SETPOINT,
  14: ETimeseriesMeaning.RELATIVE_PRESSURE_READING,
  15: ETimeseriesMeaning.CHAMBER_TEMPERATURE_SETPOINT,
  16: ETimeseriesMeaning.CHAMBER_TEMPERATURE_READING,
  17: ETimeseriesMeaning.ABSOLUTE_PRESSURE_SETPOINT,
  18: ETimeseriesMeaning.ABSOLUTE_PRESSURE_READING,
  19: ETimeseriesMeaning.VACUUM_PRESSURE_SETPOINT,
  20: ETimeseriesMeaning.VACUUM_PRESSURE_READING,
  21: ETimeseriesMeaning.ATMOSPHERIC_PRESSURE_SETPOINT,
  22: ETimeseriesMeaning.ATMOSPHERIC_PRESSURE_READING,
  23: ETimeseriesMeaning.ROOM_TEMPERATURE_SETPOINT,
  24: ETimeseriesMeaning.ROOM_TEMPERATURE_READING,
  25: ETimeseriesMeaning.ACTIVITY_AH_SETPOINT,
  26: ETimeseriesMeaning.ACTIVITY_AH_CALCULATION,
  27: ETimeseriesMeaning.POTENTIAL_HP_SETPOINT,
  28: ETimeseriesMeaning.POTENTIAL_HP_CALCULATION,
  29: ETimeseriesMeaning.POTENTIAL_KH_SETPOINT,
  30: ETimeseriesMeaning.POTENTIAL_KH_CALCULATION,
  31: ETimeseriesMeaning.ACTIVITY_AO_SETPOINT,
  32: ETimeseriesMeaning.ACTIVITY_AO_CALCULATION,
  33: ETimeseriesMeaning.POTENTIAL_OP_SETPOINT,
  34: ETimeseriesMeaning.POTENTIAL_OP_CALCULATION,
  35: ETimeseriesMeaning.POTENTIAL_KO_SETPOINT,
  36: ETimeseriesMeaning.POTENTIAL_KO_CALCULATION,
  37: ETimeseriesMeaning.ACTIVITY_AC_SETPOINT,
  38: ETimeseriesMeaning.ACTIVITY_AC_CALCULATION,
  39: ETimeseriesMeaning.POTENTIAL_CP_SETPOINT,
  40: ETimeseriesMeaning.POTENTIAL_CP_CALCULATION,
  41: ETimeseriesMeaning.POTENTIAL_KC_SETPOINT,
  42: ETimeseriesMeaning.POTENTIAL_KC_CALCULATION,
  43: ETimeseriesMeaning.ACTIVITY_AN_SETPOINT,
  44: ETimeseriesMeaning.ACTIVITY_AN_CALCULATION,
  45: ETimeseriesMeaning.POTENTIAL_NP_SETPOINT,
  46: ETimeseriesMeaning.POTENTIAL_NP_CALCULATION,
  47: ETimeseriesMeaning.POTENTIAL_KN_SETPOINT,
  48: ETimeseriesMeaning.POTENTIAL_KN_CALCULATION,
  49: ETimeseriesMeaning.ACTIVITY_AB_SETPOINT,
  50: ETimeseriesMeaning.ACTIVITY_AB_CALCULATION,
  51: ETimeseriesMeaning.POTENTIAL_BP_SETPOINT,
  52: ETimeseriesMeaning.POTENTIAL_BP_CALCULATION,
  53: ETimeseriesMeaning.POTENTIAL_KB_SETPOINT,
  54: ETimeseriesMeaning.POTENTIAL_KB_CALCULATION,
  55: ETimeseriesMeaning.ACTIVITY_AS_SETPOINT,
  56: ETimeseriesMeaning.ACTIVITY_AS_CALCULATION,
  57: ETimeseriesMeaning.POTENTIAL_SP_SETPOINT,
  58: ETimeseriesMeaning.POTENTIAL_SP_CALCULATION,
  59: ETimeseriesMeaning.POTENTIAL_KS_SETPOINT,
  60: ETimeseriesMeaning.POTENTIAL_KS_CALCULATION,
  61: ETimeseriesMeaning.ACETYLENE_C2H2_FLOW_SETPOINT,
  62: ETimeseriesMeaning.ACETYLENE_C2H2_FLOW_READING,
  63: ETimeseriesMeaning.AIR_FLOW_SETPOINT,
  64: ETimeseriesMeaning.AIR_FLOW_READING,
  65: ETimeseriesMeaning.AMMONIA_NH3_FLOW_SETPOINT,
  66: ETimeseriesMeaning.AMMONIA_NH3_FLOW_READING,
  67: ETimeseriesMeaning.ARGON_FLOW_SETPOINT,
  68: ETimeseriesMeaning.ARGON_FLOW_READING,
  69: ETimeseriesMeaning.CARBON_DIOXIDE_CO2_FLOW_SETPOINT,
  70: ETimeseriesMeaning.CARBON_DIOXIDE_CO2_FLOW_READING,
  71: ETimeseriesMeaning.CARBON_MONOXIDE_CO_FLOW_SETPOINT,
  72: ETimeseriesMeaning.CARBON_MONOXIDE_CO_FLOW_READING,
  73: ETimeseriesMeaning.DISSOCIATED_AMMONIA_NH3_FLOW_SETPOINT,
  74: ETimeseriesMeaning.DISSOCIATED_AMMONIA_NH3_FLOW_READING,
  75: ETimeseriesMeaning.ENDO_GAS_NATURAL_FLOW_SETPOINT,
  76: ETimeseriesMeaning.ENDO_GAS_NATURAL_FLOW_READING,
  77: ETimeseriesMeaning.ENDO_GAS_PROPANE_FLOW_SETPOINT,
  78: ETimeseriesMeaning.ENDO_GAS_PROPANE_FLOW_READING,
  79: ETimeseriesMeaning.ETHENE_C2H4_FLOW_SETPOINT,
  80: ETimeseriesMeaning.ETHENE_C2H4_FLOW_READING,
  81: ETimeseriesMeaning.HELIUM_FLOW_SETPOINT,
  82: ETimeseriesMeaning.HELIUM_FLOW_READING,
  83: ETimeseriesMeaning.HYDROGEN_H2_FLOW_SETPOINT,
  84: ETimeseriesMeaning.HYDROGEN_H2_FLOW_READING,
  85: ETimeseriesMeaning.METHANOL_CH3OH_FLOW_SETPOINT,
  86: ETimeseriesMeaning.METHANOL_CH3OH_FLOW_READING,
  87: ETimeseriesMeaning.NATURAL_GAS_FLOW_SETPOINT,
  88: ETimeseriesMeaning.NATURAL_GAS_FLOW_READING,
  89: ETimeseriesMeaning.NITROGEN_FLOW_SETPOINT,
  90: ETimeseriesMeaning.NITROGEN_FLOW_READING,
  91: ETimeseriesMeaning.PROPANE_C3H8_FLOW_SETPOINT,
  92: ETimeseriesMeaning.PROPANE_C3H8_FLOW_READING,
  93: ETimeseriesMeaning.WATER_FLOW_SETPOINT,
  94: ETimeseriesMeaning.WATER_FLOW_READING,
  95: ETimeseriesMeaning.NITROUS_OXIDE_N2O_FLOW_SETPOINT,
  96: ETimeseriesMeaning.NITROUS_OXIDE_N2O_FLOW_READING,
  97: ETimeseriesMeaning.METHANE_CH4_FLOW_SETPOINT,
  98: ETimeseriesMeaning.METHANE_CH4_FLOW_READING,
  99: ETimeseriesMeaning.OXYGEN_O2_FLOW_SETPOINT,
  100: ETimeseriesMeaning.OXYGEN_O2_FLOW_READING,
  115: ETimeseriesMeaning.DISSOCIATION_SETPOINT,
  116: ETimeseriesMeaning.DISSOCIATION_READING,
  131: ETimeseriesMeaning.PARTIAL_PRESSURE_ACETYLENE_C2H2_SETPOINT,
  132: ETimeseriesMeaning.PARTIAL_PRESSURE_ACETYLENE_C2H2_READING,
  133: ETimeseriesMeaning.PARTIAL_PRESSURE_AIR_SETPOINT,
  134: ETimeseriesMeaning.PARTIAL_PRESSURE_AIR_READING,
  135: ETimeseriesMeaning.PARTIAL_PRESSURE_AMMONIA_NH3_SETPOINT,
  136: ETimeseriesMeaning.PARTIAL_PRESSURE_AMMONIA_NH3_READING,
  137: ETimeseriesMeaning.PARTIAL_PRESSURE_ARGON_SETPOINT,
  138: ETimeseriesMeaning.PARTIAL_PRESSURE_ARGON_READING,
  139: ETimeseriesMeaning.PARTIAL_PRESSURE_CARBON_DIOXIDE_CO2_SETPOINT,
  140: ETimeseriesMeaning.PARTIAL_PRESSURE_CARBON_DIOXIDE_CO2_READING,
  141: ETimeseriesMeaning.PARTIAL_PRESSURE_CARBON_MONOXIDE_CO_SETPOINT,
  142: ETimeseriesMeaning.PARTIAL_PRESSURE_CARBON_MONOXIDE_CO_READING,
  143: ETimeseriesMeaning.PARTIAL_PRESSURE_DISSOCIATED_AMMONIA_NH3_SETPOINT,
  144: ETimeseriesMeaning.PARTIAL_PRESSURE_DISSOCIATED_AMMONIA_NH3_READING,
  145: ETimeseriesMeaning.PARTIAL_PRESSURE_ENDO_GAS_NATURAL_SETPOINT,
  146: ETimeseriesMeaning.PARTIAL_PRESSURE_ENDO_GAS_NATURAL_READING,
  147: ETimeseriesMeaning.PARTIAL_PRESSURE_ENDO_GAS_PROPANE_SETPOINT,
  148: ETimeseriesMeaning.PARTIAL_PRESSURE_ENDO_GAS_PROPANE_READING,
  149: ETimeseriesMeaning.PARTIAL_PRESSURE_ETHENE_C2H4_SETPOINT,
  150: ETimeseriesMeaning.PARTIAL_PRESSURE_ETHENE_C2H4_READING,
  151: ETimeseriesMeaning.PARTIAL_PRESSURE_HELIUM_SETPOINT,
  152: ETimeseriesMeaning.PARTIAL_PRESSURE_HELIUM_READING,
  153: ETimeseriesMeaning.PARTIAL_PRESSURE_HYDROGEN_H2_SETPOINT,
  154: ETimeseriesMeaning.PARTIAL_PRESSURE_HYDROGEN_H2_READING,
  155: ETimeseriesMeaning.PARTIAL_PRESSURE_METHANOL_CH3OH_SETPOINT,
  156: ETimeseriesMeaning.PARTIAL_PRESSURE_METHANOL_CH3OH_READING,
  157: ETimeseriesMeaning.PARTIAL_PRESSURE_NATURAL_GAS_SETPOINT,
  158: ETimeseriesMeaning.PARTIAL_PRESSURE_NATURAL_GAS_READING,
  159: ETimeseriesMeaning.PARTIAL_PRESSURE_NITROGEN_SETPOINT,
  160: ETimeseriesMeaning.PARTIAL_PRESSURE_NITROGEN_READING,
  161: ETimeseriesMeaning.PARTIAL_PRESSURE_NITROUS_OXIDE_N2O_SETPOINT,
  162: ETimeseriesMeaning.PARTIAL_PRESSURE_NITROUS_OXIDE_N2O_READING,
  163: ETimeseriesMeaning.PARTIAL_PRESSURE_PROPANE_C3H8_SETPOINT,
  164: ETimeseriesMeaning.PARTIAL_PRESSURE_PROPANE_C3H8_READING,
  165: ETimeseriesMeaning.PARTIAL_PRESSURE_WATER_SETPOINT,
  166: ETimeseriesMeaning.PARTIAL_PRESSURE_WATER_READING,
  167: ETimeseriesMeaning.PARTIAL_PRESSURE_ACETYLENE_C2H2_PERCENT_SETPOINT,
  168: ETimeseriesMeaning.PARTIAL_PRESSURE_ACETYLENE_C2H2_PERCENT_READING,
  169: ETimeseriesMeaning.PARTIAL_PRESSURE_AIR_PERCENT_SETPOINT,
  170: ETimeseriesMeaning.PARTIAL_PRESSURE_AIR_PERCENT_READING,
  171: ETimeseriesMeaning.PARTIAL_PRESSURE_AMMONIA_NH3_PERCENT_SETPOINT,
  172: ETimeseriesMeaning.PARTIAL_PRESSURE_AMMONIA_NH3_PERCENT_READING,
  173: ETimeseriesMeaning.PARTIAL_PRESSURE_ARGON_PERCENT_SETPOINT,
  174: ETimeseriesMeaning.PARTIAL_PRESSURE_ARGON_PERCENT_READING,
  175: ETimeseriesMeaning.PARTIAL_PRESSURE_CARBON_DIOXIDE_CO2_PERCENT_SETPOINT,
  176: ETimeseriesMeaning.PARTIAL_PRESSURE_CARBON_DIOXIDE_CO2_PERCENT_READING,
  177: ETimeseriesMeaning.PARTIAL_PRESSURE_CARBON_MONOXIDE_CO_PERCENT_SETPOINT,
  178: ETimeseriesMeaning.PARTIAL_PRESSURE_CARBON_MONOXIDE_CO_PERCENT_READING,
  179: ETimeseriesMeaning.PARTIAL_PRESSURE_DISSOCIATED_AMMONIA_NH3_PERCENT_SETPOINT,
  180: ETimeseriesMeaning.PARTIAL_PRESSURE_DISSOCIATED_AMMONIA_NH3_PERCENT_READING,
  181: ETimeseriesMeaning.PARTIAL_PRESSURE_ENDO_GAS_NATURAL_PERCENT_SETPOINT,
  182: ETimeseriesMeaning.PARTIAL_PRESSURE_ENDO_GAS_NATURAL_PERCENT_READING,
  183: ETimeseriesMeaning.PARTIAL_PRESSURE_ENDO_GAS_PROPANE_PERCENT_SETPOINT,
  184: ETimeseriesMeaning.PARTIAL_PRESSURE_ENDO_GAS_PROPANE_PERCENT_READING,
  185: ETimeseriesMeaning.PARTIAL_PRESSURE_ETHENE_C2H4_PERCENT_SETPOINT,
  186: ETimeseriesMeaning.PARTIAL_PRESSURE_ETHENE_C2H4_PERCENT_READING,
  187: ETimeseriesMeaning.PARTIAL_PRESSURE_HELIUM_PERCENT_SETPOINT,
  188: ETimeseriesMeaning.PARTIAL_PRESSURE_HELIUM_PERCENT_READING,
  189: ETimeseriesMeaning.PARTIAL_PRESSURE_HYDROGEN_H2_PERCENT_SETPOINT,
  190: ETimeseriesMeaning.PARTIAL_PRESSURE_HYDROGEN_H2_PERCENT_READING,
  191: ETimeseriesMeaning.PARTIAL_PRESSURE_METHANOL_CH3OH_PERCENT_SETPOINT,
  192: ETimeseriesMeaning.PARTIAL_PRESSURE_METHANOL_CH3OH_PERCENT_READING,
  193: ETimeseriesMeaning.PARTIAL_PRESSURE_NATURAL_GAS_PERCENT_SETPOINT,
  194: ETimeseriesMeaning.PARTIAL_PRESSURE_NATURAL_GAS_PERCENT_READING,
  195: ETimeseriesMeaning.PARTIAL_PRESSURE_NITROGEN_PERCENT_SETPOINT,
  196: ETimeseriesMeaning.PARTIAL_PRESSURE_NITROGEN_PERCENT_READING,
  197: ETimeseriesMeaning.PARTIAL_PRESSURE_NITROUS_OXIDE_N2O_PERCENT_SETPOINT,
  198: ETimeseriesMeaning.PARTIAL_PRESSURE_NITROUS_OXIDE_N2O_PERCENT_READING,
  199: ETimeseriesMeaning.PARTIAL_PRESSURE_PROPANE_C3H8_PERCENT_SETPOINT,
  200: ETimeseriesMeaning.PARTIAL_PRESSURE_PROPANE_C3H8_PERCENT_READING,
  201: ETimeseriesMeaning.PARTIAL_PRESSURE_WATER_PERCENT_SETPOINT,
  202: ETimeseriesMeaning.PARTIAL_PRESSURE_WATER_PERCENT_READING,
  203: ETimeseriesMeaning.PARTIAL_PRESSURE_OXYGEN_SETPOINT,
  204: ETimeseriesMeaning.PARTIAL_PRESSURE_OXYGEN_READING,
  205: ETimeseriesMeaning.PARTIAL_PRESSURE_CARBON_SETPOINT,
  206: ETimeseriesMeaning.PARTIAL_PRESSURE_CARBON_READING,
  207: ETimeseriesMeaning.PARTIAL_PRESSURE_BORON_SETPOINT,
  208: ETimeseriesMeaning.PARTIAL_PRESSURE_BORON_READING,
  209: ETimeseriesMeaning.PARTIAL_PRESSURE_SULFUR_SETPOINT,
  210: ETimeseriesMeaning.PARTIAL_PRESSURE_SULFUR_READING,
  211: ETimeseriesMeaning.PARTIAL_PRESSURE_OXYGEN_PERCENT_SETPOINT,
  212: ETimeseriesMeaning.PARTIAL_PRESSURE_OXYGEN_PERCENT_READING,
  213: ETimeseriesMeaning.PARTIAL_PRESSURE_CARBON_PERCENT_SETPOINT,
  214: ETimeseriesMeaning.PARTIAL_PRESSURE_CARBON_PERCENT_READING,
  215: ETimeseriesMeaning.PARTIAL_PRESSURE_BORON_PERCENT_SETPOINT,
  216: ETimeseriesMeaning.PARTIAL_PRESSURE_BORON_PERCENT_READING,
  217: ETimeseriesMeaning.PARTIAL_PRESSURE_SULFUR_PERCENT_SETPOINT,
  218: ETimeseriesMeaning.PARTIAL_PRESSURE_SULFUR_PERCENT_READING,
  219: ETimeseriesMeaning.ETHANE_C2H6_FLOW_SETPOINT,
  220: ETimeseriesMeaning.ETHANE_C2H6_FLOW_READING,
  221: ETimeseriesMeaning.PARTIAL_PRESSURE_ETHANE_C2H6_SETPOINT,
  222: ETimeseriesMeaning.PARTIAL_PRESSURE_ETHANE_C2H6_READING,
  223: ETimeseriesMeaning.PARTIAL_PRESSURE_ETHANE_C2H6_PERCENT_SETPOINT,
  224: ETimeseriesMeaning.PARTIAL_PRESSURE_ETHANE_C2H6_PERCENT_READING,
  225: ETimeseriesMeaning.PARTIAL_PRESSURE_METHANE_CH4_SETPOINT,
  226: ETimeseriesMeaning.PARTIAL_PRESSURE_METHANE_CH4_READING,
  227: ETimeseriesMeaning.PARTIAL_PRESSURE_METHANE_CH4_PERCENT_SETPOINT,
  228: ETimeseriesMeaning.PARTIAL_PRESSURE_METHANE_CH4_PERCENT_READING,
  229: ETimeseriesMeaning.HIGH_VACUUM_PRESSURE_SETPOINT,
  230: ETimeseriesMeaning.HIGH_VACUUM_PRESSURE_READING,
  231: ETimeseriesMeaning.LOW_VACUUM_PRESSURE_SETPOINT,
  232: ETimeseriesMeaning.LOW_VACUUM_PRESSURE_READING,
  233: ETimeseriesMeaning.LEAD_TIME_SETPOINT,
  234: ETimeseriesMeaning.LEAD_TIME_READING,
  235: ETimeseriesMeaning.BELT_SPEED_SETPOINT,
  236: ETimeseriesMeaning.BELT_SPEED_READING,
  9900: ETimeseriesMeaning.USER_DEFINED_100,
  9901: ETimeseriesMeaning.USER_DEFINED_099,
  9902: ETimeseriesMeaning.USER_DEFINED_098,
  9903: ETimeseriesMeaning.USER_DEFINED_097,
  9904: ETimeseriesMeaning.USER_DEFINED_096,
  9905: ETimeseriesMeaning.USER_DEFINED_095,
  9906: ETimeseriesMeaning.USER_DEFINED_094,
  9907: ETimeseriesMeaning.USER_DEFINED_093,
  9908: ETimeseriesMeaning.USER_DEFINED_092,
  9909: ETimeseriesMeaning.USER_DEFINED_091,
  9910: ETimeseriesMeaning.USER_DEFINED_090,
  9911: ETimeseriesMeaning.USER_DEFINED_089,
  9912: ETimeseriesMeaning.USER_DEFINED_088,
  9913: ETimeseriesMeaning.USER_DEFINED_087,
  9914: ETimeseriesMeaning.USER_DEFINED_086,
  9915: ETimeseriesMeaning.USER_DEFINED_085,
  9916: ETimeseriesMeaning.USER_DEFINED_084,
  9917: ETimeseriesMeaning.USER_DEFINED_083,
  9918: ETimeseriesMeaning.USER_DEFINED_082,
  9919: ETimeseriesMeaning.USER_DEFINED_081,
  9920: ETimeseriesMeaning.USER_DEFINED_080,
  9921: ETimeseriesMeaning.USER_DEFINED_079,
  9922: ETimeseriesMeaning.USER_DEFINED_078,
  9923: ETimeseriesMeaning.USER_DEFINED_077,
  9924: ETimeseriesMeaning.USER_DEFINED_076,
  9925: ETimeseriesMeaning.USER_DEFINED_075,
  9926: ETimeseriesMeaning.USER_DEFINED_074,
  9927: ETimeseriesMeaning.USER_DEFINED_073,
  9928: ETimeseriesMeaning.USER_DEFINED_072,
  9929: ETimeseriesMeaning.USER_DEFINED_071,
  9930: ETimeseriesMeaning.USER_DEFINED_070,
  9931: ETimeseriesMeaning.USER_DEFINED_069,
  9932: ETimeseriesMeaning.USER_DEFINED_068,
  9933: ETimeseriesMeaning.USER_DEFINED_067,
  9934: ETimeseriesMeaning.USER_DEFINED_066,
  9935: ETimeseriesMeaning.USER_DEFINED_065,
  9936: ETimeseriesMeaning.USER_DEFINED_064,
  9937: ETimeseriesMeaning.USER_DEFINED_063,
  9938: ETimeseriesMeaning.USER_DEFINED_062,
  9939: ETimeseriesMeaning.USER_DEFINED_061,
  9940: ETimeseriesMeaning.USER_DEFINED_060,
  9941: ETimeseriesMeaning.USER_DEFINED_059,
  9942: ETimeseriesMeaning.USER_DEFINED_058,
  9943: ETimeseriesMeaning.USER_DEFINED_057,
  9944: ETimeseriesMeaning.USER_DEFINED_056,
  9945: ETimeseriesMeaning.USER_DEFINED_055,
  9946: ETimeseriesMeaning.USER_DEFINED_054,
  9947: ETimeseriesMeaning.USER_DEFINED_053,
  9948: ETimeseriesMeaning.USER_DEFINED_052,
  9949: ETimeseriesMeaning.USER_DEFINED_051,
  9950: ETimeseriesMeaning.USER_DEFINED_050,
  9951: ETimeseriesMeaning.USER_DEFINED_049,
  9952: ETimeseriesMeaning.USER_DEFINED_048,
  9953: ETimeseriesMeaning.USER_DEFINED_047,
  9954: ETimeseriesMeaning.USER_DEFINED_046,
  9955: ETimeseriesMeaning.USER_DEFINED_045,
  9956: ETimeseriesMeaning.USER_DEFINED_044,
  9957: ETimeseriesMeaning.USER_DEFINED_043,
  9958: ETimeseriesMeaning.USER_DEFINED_042,
  9959: ETimeseriesMeaning.USER_DEFINED_041,
  9960: ETimeseriesMeaning.USER_DEFINED_040,
  9961: ETimeseriesMeaning.USER_DEFINED_039,
  9962: ETimeseriesMeaning.USER_DEFINED_038,
  9963: ETimeseriesMeaning.USER_DEFINED_037,
  9964: ETimeseriesMeaning.USER_DEFINED_036,
  9965: ETimeseriesMeaning.USER_DEFINED_035,
  9966: ETimeseriesMeaning.USER_DEFINED_034,
  9967: ETimeseriesMeaning.USER_DEFINED_033,
  9968: ETimeseriesMeaning.USER_DEFINED_032,
  9969: ETimeseriesMeaning.USER_DEFINED_031,
  9970: ETimeseriesMeaning.USER_DEFINED_030,
  9971: ETimeseriesMeaning.USER_DEFINED_029,
  9972: ETimeseriesMeaning.USER_DEFINED_028,
  9973: ETimeseriesMeaning.USER_DEFINED_027,
  9974: ETimeseriesMeaning.USER_DEFINED_026,
  9975: ETimeseriesMeaning.USER_DEFINED_025,
  9976: ETimeseriesMeaning.USER_DEFINED_024,
  9977: ETimeseriesMeaning.USER_DEFINED_023,
  9978: ETimeseriesMeaning.USER_DEFINED_022,
  9979: ETimeseriesMeaning.USER_DEFINED_021,
  9980: ETimeseriesMeaning.USER_DEFINED_020,
  9981: ETimeseriesMeaning.USER_DEFINED_019,
  9982: ETimeseriesMeaning.USER_DEFINED_018,
  9983: ETimeseriesMeaning.USER_DEFINED_017,
  9984: ETimeseriesMeaning.USER_DEFINED_016,
  9985: ETimeseriesMeaning.USER_DEFINED_015,
  9986: ETimeseriesMeaning.USER_DEFINED_014,
  9987: ETimeseriesMeaning.USER_DEFINED_013,
  9988: ETimeseriesMeaning.USER_DEFINED_012,
  9989: ETimeseriesMeaning.USER_DEFINED_011,
  9990: ETimeseriesMeaning.USER_DEFINED_010,
  9991: ETimeseriesMeaning.USER_DEFINED_009,
  9992: ETimeseriesMeaning.USER_DEFINED_008,
  9993: ETimeseriesMeaning.USER_DEFINED_007,
  9994: ETimeseriesMeaning.USER_DEFINED_006,
  9995: ETimeseriesMeaning.USER_DEFINED_005,
  9996: ETimeseriesMeaning.USER_DEFINED_004,
  9997: ETimeseriesMeaning.USER_DEFINED_003,
  9998: ETimeseriesMeaning.USER_DEFINED_002,
  9999: ETimeseriesMeaning.USER_DEFINED_001,
};

// we make it an object, so in case new elements are added TS will give error
/** @warning order is important */
/** @warning order must be in sync with BE */
export const chemicalElementInSteelMap: Record<components['schemas']['ChemicalElementInSteel'], components['schemas']['ChemicalElementInSteel']> = {
  Fe: 'Fe',
  C: 'C',
  Mn: 'Mn',
  P: 'P',
  S: 'S',
  Si: 'Si',
  Cr: 'Cr',
  V: 'V',
  W: 'W',
  Mo: 'Mo',
  Co: 'Co',
  Ni: 'Ni',
  Cu: 'Cu',
  Al: 'Al',
  Ti: 'Ti',
  Nb: 'Nb',
  B: 'B',
  Pb: 'Pb',
  Zr: 'Zr',
  Ta: 'Ta',
  N: 'N',
  Se: 'Se',
};

export const chemicalElementInSteelList: components['schemas']['ChemicalElementInSteel'][] = Object.keys(chemicalElementInSteelMap) as components['schemas']['ChemicalElementInSteel'][];
